import RestUtilities, { IRestResponse } from './RestUtilities';
import IAiSearchModel from '../models/app/search/IAiSearchModel';

const resultMappingSheetId = '1Kz_8tizLn9kj088NSjB1ZHPdyj3xK2Ifg_RQWLvRKAg';
// https://docs.google.com/spreadsheets/d/1Kz_8tizLn9kj088NSjB1ZHPdyj3xK2Ifg_RQWLvRKAg/edit#gid=0

export default class SearchService {
  async aiSearch(
    aiSearchModel: IAiSearchModel
  ): Promise<IRestResponse<string>> {
    try {
      const resultMapping = await getResultMapping();
      resultMapping.forEach((mappingRecord: any, i: number) => {
        const keywords = mappingRecord.input.split(',');
        keywords.forEach((keyword: string) => {
          if (
            aiSearchModel.query
              .toLowerCase()
              .includes(keyword.trim().toLowerCase())
          ) {
            resultMapping[i].matchCount++;
          }
        });
      });

      const result = resultMapping
        .filter((mappingRecord: any) => {
          return mappingRecord.matchCount > 0;
        })
        .reduce((prev: any, cur: any) =>
          prev && prev.matchCount > cur.matchCount ? prev : cur
        );

      // await sleep(getRandomArbitrary(2000, 4000));

      return {
        statusCode: 200,
        message: 'Successfully generated results',
        isError: false,
        result: result.link,
      };
    } catch (err) {
      return {
        statusCode: 500,
        message: 'Failed to generate results',
        isError: true,
      };
    }

    function getResultMapping() {
      const url = `https://docs.google.com/spreadsheets/d/${resultMappingSheetId}/gviz/tq`;

      return fetch(url, {
        method: 'GET',
      })
        .then((response: any) => {
          return response.text();
        })
        .then(spreadsheetResponse => {
          const data = JSON.parse(
            spreadsheetResponse
              .split('\n')[1]
              .replace(/google.visualization.Query.setResponse\(|\);/g, '')
          );
          const rows = data.table.rows;
          const resultMapping = rows.map((row: any) => ({
            input: row.c[0].v,
            link: row.c[1].v,
            matchCount: 0,
          }));

          return resultMapping;
        });
    }

    function getRandomArbitrary(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }

    function sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
