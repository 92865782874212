export default class RoutePaths {
  public static Landing = '/';
  public static Callback = '/callback';
  public static EnterDomain = '/enter-domain';
  public static Login = '/login';
  public static Logout = '/logout';
  public static ConfirmUser = '/confirm-user';
  public static PasswordReset = '/password-reset';
  public static ChangeEmail = '/change-email';
  public static GenerateDraft = '/generate-draft';

  public static Dashboard = '/dashboard';
  public static Account = '/account';
  public static Organization = '/organization';

  public static Users = '/users';
  public static UsersNew = '/users/new';
  public static UsersEdit = '/users/edit';

  public static Integrations = '/integrations';
  public static IntegrationsNew = '/integrations/new';
  public static IntegrationsNewGong = '/integrations/new/gong';
  public static IntegrationsEditGong = '/integrations/edit/gong';
  public static IntegrationsNewZoom = '/integrations/new/zoom';
  public static IntegrationsEditZoom = '/integrations/edit/zoom';
  public static IntegrationsAuthorizeZoom = '/integrations/zoom/authorize';
  public static IntegrationsNewGmail = '/integrations/new/gmail';
  public static IntegrationsEditGmail = '/integrations/edit/gmail';
  public static IntegrationsAuthorizeGmail = '/integrations/gmail/authorize';
  public static IntegrationsNewSalesforce = '/integrations/new/salesforce';
  public static IntegrationsEditSalesforce = '/integrations/edit/salesforce';
  public static IntegrationsAuthorizeSalesforce =
    '/integrations/salesforce/authorize';
  public static IntegrationsNewHubspot = '/integrations/new/hubspot';
  public static IntegrationsEditHubspot = '/integrations/edit/hubspot';

  public static Templates = '/templates';
  public static TemplatesNew = '/templates/new';
  public static TemplatesEdit = '/templates/:templateId';
  public static TemplateVariables = '/templates/variables';
  public static TemplateVariablesNew = '/templates/variables/new';
  public static TemplateVariablesEdit =
    '/templates/variables/:templateVariableId';

  public static Opportunities = '/opportunities';
  public static OpportunityMapping = '/opportunities/mapping';
  public static OpportunityTasks = '/opportunities/tasks';
  public static OpportunityTasksReview =
    '/opportunities/tasks/review/:opportunityTaskId';
  public static OpportunityTasksEdit =
    '/opportunities/tasks/:opportunityTaskId';

  public static ExtensionOpportunityTasks = '/extension/opportunities/tasks';

  public static AiSearch = '/ai-search';

  public static UploadCustomPhrases = '/upload-custom-phrases';
  public static UploadRecording = '/upload-recording';
  public static UploadTranscript = '/upload-transcript';

  public static Webhooks = '/webhooks';

  public static IsCurrentLocationWhiteListed = (): boolean => {
    const path = window.location.pathname;
    if (
      path === RoutePaths.Landing ||
      path === RoutePaths.Callback ||
      path === RoutePaths.EnterDomain ||
      path === RoutePaths.Login ||
      path === RoutePaths.Logout ||
      path === RoutePaths.ConfirmUser ||
      path === RoutePaths.PasswordReset ||
      path === RoutePaths.ChangeEmail
    ) {
      return true;
    }
    return false;
  };
}
