import * as React from 'react';
import { Typography, Theme, Grid, Paper, Button } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FormikSynchronousButton from '../form/FormikSynchronousButton';
import DocumentService from '../../services/DocumentService';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import { IError } from '../../services/RestUtilities';
import SingleServerErrorMessage from '../form/ServerServerErrorMessage';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Constants from '../../theme/Constants';
import IUploadTranscriptModel from '../../models/files/IUploadTranscriptModel';
import FormikTextField from '../form/FormikTextField';
import parse from 'date-fns/parse';
import FormikCheckbox from '../form/FormikCheckbox';
const documentService = new DocumentService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      paddingTop: '3%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    flexButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    paper: {
      padding: theme.spacing(4),
    },
    fileName: {
      marginLeft: '10px',
    },
  });

interface UploadTranscriptProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {}

interface UploadTranscriptState {
  success: boolean;
  serverErrors?: IError;
  error: string;
}

class UploadTranscript extends React.Component<
  UploadTranscriptProps,
  UploadTranscriptState
> {
  validationSchema = Yup.object().shape({
    hostEmail: Yup.string().email('Email is invalid'),
    hostName: Yup.string().required('Name is required'),
    meetingTitle: Yup.string().required('Meeting title is required'),
    meetingTime: Yup.string().required('Time is required'),
    meetingDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = parse(originalValue, 'dd.MM.yyyy', new Date());
        return result;
      })
      .typeError('Date is not valid')
      .required('Meeting date is required'),
    internalSpeakers: Yup.string().required('Internal speakers is required'),
    externalSpeakers: Yup.string().required('External speakers is required'),
    meetingTranscript: Yup.string().required('Meeting transcript is required'),
  });

  state: UploadTranscriptState = {
    success: false,
    serverErrors: undefined,
    error: '',
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography align="center" variant="h5">
            Process Transcript
          </Typography>
          <Formik
            initialValues={
              {
                hostEmail: 'dom@cquence.app',
                hostName: 'Dom',
                meetingTitle: `Test Meeting on ${new Date()
                  .toISOString()
                  .slice(0, 19)}`,
                meetingDate: new Date().toISOString().slice(0, 10),
                meetingTime: '12:00',
                internalSpeakers: 'Dom, Larry',
                externalSpeakers: 'John',
                meetingTranscript:
                  'Dom: Hey how is it going, what date are we thinking?\nJohn: We will need at least 2 months before being ready to close.\nLarry: Great, thanks!\nDom: Bye\nJohn: Thanks everyone\n',
                useExisting: false,
              } as IUploadTranscriptModel
            }
            onSubmit={(
              values: IUploadTranscriptModel,
              formikHelpers: FormikHelpers<IUploadTranscriptModel>
            ) => {
              documentService.postProcessTranscript(values).then(response => {
                this.props.enqueueSnackbar(response.message, {
                  variant: 'success',
                });
                formikHelpers.setSubmitting(false);

                this.setState({
                  success: true,
                  serverErrors: undefined,
                  error: '',
                });
              });
            }}
            validateOnMount={true}
            initialErrors={{}}
            validationSchema={this.validationSchema}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="hostEmail"
                      label="Host Email"
                      value={props.values.hostEmail}
                      errorText={props.errors.hostEmail}
                      touched={props.touched.hostEmail}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="hostName"
                      label="Host Name"
                      value={props.values.hostName}
                      errorText={props.errors.hostName}
                      touched={props.touched.hostName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingTitle"
                      label="Meeting Title"
                      value={props.values.meetingTitle}
                      errorText={props.errors.meetingTitle}
                      touched={props.touched.meetingTitle}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingDate"
                      label="Meeting Date"
                      value={props.values.meetingDate}
                      errorText={props.errors.meetingDate}
                      touched={props.touched.meetingDate}
                      placeholder="yyyy-mm-dd"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingTime"
                      label="Meeting Time"
                      value={props.values.meetingTime}
                      errorText={props.errors.meetingTime}
                      touched={props.touched.meetingTime}
                      placeholder="hh:mm"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="internalSpeakers"
                      label="Internal Speakers"
                      value={props.values.internalSpeakers}
                      errorText={props.errors.internalSpeakers}
                      touched={props.touched.internalSpeakers}
                      placeholder=""
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      multiline
                      required
                    />
                    <br />
                    <FormikTextField
                      name="externalSpeakers"
                      label="External Speakers"
                      value={props.values.externalSpeakers}
                      errorText={props.errors.externalSpeakers}
                      touched={props.touched.externalSpeakers}
                      placeholder=""
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      multiline
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingTranscript"
                      label="Meeting Transcript"
                      value={props.values.meetingTranscript}
                      errorText={props.errors.meetingTranscript}
                      touched={props.touched.meetingTranscript}
                      placeholder=""
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      multiline
                      required
                    />
                    <br />
                    <FormikCheckbox
                      name="useExisting"
                      label="Use Existing Templates"
                      value={props.values.useExisting}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </Grid>
                </Grid>
                {this.state.serverErrors && (
                  <SingleServerErrorMessage
                    message={this.state.serverErrors.message}
                    formikErrors={this.state.serverErrors.formikErrors}
                  />
                )}
                {this.state.error && (
                  <SingleServerErrorMessage message={this.state.error} />
                )}
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Process Transcript
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(withRouter(UploadTranscript))
);
