import React from "react";
import {
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export interface WithRouterProps {
  navigate: NavigateFunction;
  path: string;
  params: Readonly<Params<string>>;
  location: Location;
}

export const withRouter = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const WithRouterWrapper = (props: Omit<P, keyof WithRouterProps>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        navigate={navigate}
        path={`${location.pathname}${location.search}`}
        location={location}
        params={params}
        {...(props as P)}
      />
    );
  };

  return WithRouterWrapper;
};
