import * as React from "react";
import {
  DialogActions,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { ApplicationState } from "../store";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { closeDialog } from "../reducers/DialogReducer";
import OpportunitiesService from "../../services/OpportunitiesService";
import { IRecordTypeInfo } from "../../models/app/opportunities/IOpportunityLayoutsResponseModel";
import { OpportunityMeetingTasksState, setNewOpportunityRecordType } from "../reducers/OpportunityMeetingTasksReducer";

interface SelectOpportunityRecordTypeContentProps extends WithSnackbarProps {
  user: User;
  opportunityMeetinTaskState: OpportunityMeetingTasksState;
  closeDialog: () => void;
  onCancel: () => void;
  setNewOpportunityRecordTypeId: (value: IRecordTypeInfo) => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
    opportunityMeetinTaskState: state.opportunityMeetingTasksState
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDialog: () => {
      const action = closeDialog();
      dispatch(action);
    },
    setNewOpportunityRecordTypeId: (value: IRecordTypeInfo) => {
      const action = setNewOpportunityRecordType(value);
      dispatch(action);
    }
  };
};

function SelectOpportunityRecordTypeContent(
  props: SelectOpportunityRecordTypeContentProps
): JSX.Element {

  const [recordTypes, setRecordTypes] = useState([] as IRecordTypeInfo[]);
  const [selectedRecordType, setSelectedRecordType] = useState("");
  const opportunitiesService = new OpportunitiesService();

  useEffect(() => {
    opportunitiesService.getLayouts(props.opportunityMeetinTaskState.selectedOpportunityTask?.createdFor as string).then(response => {
      if (!response.isError && response.result) {
        setRecordTypes(response.result.recordTypeInfos)
      }
    })
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedRecordType(event.target.value)
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <DialogTitle>Select Opportunity Record Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the Record Type for the new Opportunity
          </DialogContentText>
          <br />
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Record Types</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange}
              value={selectedRecordType}
            >
              {recordTypes.map(r => {
                return <FormControlLabel key={r.name} value={r.recordTypeId} control={<Radio />} label={r.name} />
              })}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.closeDialog();
              props.onCancel();
            }}
            color="primary"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            disabled={selectedRecordType == ""}
            variant="text"
            onClick={() => {
              var recordTypeInfo = recordTypes.find(r => r.recordTypeId === selectedRecordType) as IRecordTypeInfo;
              props.setNewOpportunityRecordTypeId(recordTypeInfo)
              props.closeDialog();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </React.Fragment>
    </React.Fragment >
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SelectOpportunityRecordTypeContent));
