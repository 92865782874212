import * as React from "react";
import { MapDispatchToProps, connect } from "react-redux";
import { MergedIntegrationType } from "../../models/app/integrations/MergedIntegrationTypes";
import IntegrationService from "../../services/IntegrationService";
import Loading from "../loading/Loading";
import {
  populateIntegrations,
} from "../reducers/IntegrationReducer";
import { ApplicationState } from "../store";

interface PopulateIntegrationsState {
  wasError: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIntegrations: (integrations: Array<MergedIntegrationType>) => {
      const action = populateIntegrations(integrations);
      dispatch(action);
    },
  };
};

type HocProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const populateIntegrationsHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class PopulateIntegrationsComponent extends React.Component<
    HocProps,
    PopulateIntegrationsState
  > {
    integrationService = new IntegrationService();

    state: PopulateIntegrationsState = {
      wasError: false,
    };
    componentDidUpdate(prevProps: HocProps) {
      if (!this.props.integrationsState.isLoaded) {
        this.integrationService.getIntegrations().then((integrationResponse) => {
          if (integrationResponse.isError) {
            this.setState({ wasError: true });
          } else {
            this.props.setIntegrations(
              integrationResponse.result
                ? integrationResponse.result
                : new Array<MergedIntegrationType>()
            );
          }
        });
      }
    }

    componentDidMount() {
      if (!this.props.integrationsState.isLoaded) {
        this.integrationService.getIntegrations().then((integrationResponse) => {
          if (integrationResponse.isError) {
            this.setState({ wasError: true });
          } else {
            this.props.setIntegrations(
              integrationResponse.result
                ? integrationResponse.result
                : new Array<MergedIntegrationType>()
            );
          }
        });
      }
    }

    render() {
      return this.props.integrationsState.isLoaded && !this.state.wasError ? (
        <Component {...(this.props as P)} />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PopulateIntegrationsComponent);
};

export default populateIntegrationsHOC;
