import * as React from "react";
import { Checkbox, FormControlLabel, Tooltip, Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { CheckboxProps } from "@mui/material/Checkbox";
import InformationOutline from "mdi-material-ui/InformationOutline";
import Constants from "../../theme/Constants";

const styles = (theme: Theme) =>
  createStyles({
    infoText: {
      position: "relative",
      top: "4px",
      color: Constants.COLORS.PRIMARY_COLOR,
    },
    infoTextDisabled: {
      color: 'grey',
      position: "relative",
      top: "4px",
    },
    checkBox: {
      width: "93%"
    }
  });

interface FormikCheckboxProps extends WithStyles<typeof styles> {
  name: string;
  label: string;
  value: boolean;
  color?: "primary" | "secondary" | "default";
  onBlur(e: React.FocusEvent<any>): void;
  onBlur<T = string | any>(
    fieldOrEvent: T
  ): T extends string ? (e: any) => void : void;
  onChange(e: React.ChangeEvent<any>): void;
  onChange<T = string | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
  infoText?: string;
  showDisabledInfoTextIcon?: boolean
}
class FormikCheckbox extends React.Component<
  FormikCheckboxProps & CheckboxProps
> {
  render() {
    const {
      name,
      label,
      onBlur,
      onChange,
      classes,
      value,
      color,
      infoText,
      ...rest
    } = this.props;
    return (
      <React.Fragment>
        <FormControlLabel
          className={classes.checkBox}
          control={
            <Checkbox
              id={name}
              name={name}
              value={value ? "true" : "false"}
              checked={value ? true : false}
              onChange={onChange}
              onBlur={onBlur}
              color={color ? color : "primary"}
              {...rest}
            />
          }
          label={label}
        />
        {(this.props.infoText || this.props.showDisabledInfoTextIcon) && (
          <Tooltip
            className={this.props.showDisabledInfoTextIcon ? classes.infoTextDisabled : classes.infoText}
            title={this.props.infoText}
            placement="top"
          >
            <InformationOutline fontSize="small" />
          </Tooltip>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FormikCheckbox);
