import * as React from "react";
import { RoleEnum } from '../models/RoleEnum';
import { connect } from 'react-redux';
import { User } from "oidc-client";
import { ApplicationState } from '../app/store';
import { WithRouterProps, withRouter } from '../app/hocs/withRouter';
import { userIncludesRole } from '../helpers/Helpers';
import RoutePaths from './RoutePaths';
import { Navigate } from "react-router-dom";

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
  };
};

interface ProtectedRouteProps extends WithRouterProps {
  children: React.ReactNode;
  user: User;
  roles: Array<RoleEnum>
}

class ProtectedRoute extends React.Component<ProtectedRouteProps> {
  render() {
    const {children, user, roles } = this.props;
    const userHasRequiredRole = userIncludesRole(user, roles);
    if (!userHasRequiredRole) {
      return <Navigate to={RoutePaths.Dashboard} />;
    }
    return <React.Fragment>{children}</React.Fragment>
  }
};

export default connect(
  mapStateToProps
)(withRouter(ProtectedRoute));