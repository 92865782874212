import * as React from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import classNames from 'classnames';
import Constants from '../../theme/Constants';
import { Button, Paper, Theme, Typography, Grid } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import FormikTextField from '../form/FormikTextField';
import FormikSynchronousButton from '../form/FormikSynchronousButton';
import { IError } from '../../services/RestUtilities';
import SingleServerErrorMessage from '../form/ServerServerErrorMessage';
import SearchService from '../../services/SearchService';
import IAiSearchModel from '../../models/app/search/IAiSearchModel';

const searchService = new SearchService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    title: {
      marginTop: theme.spacingNumber(6),
      padding: '0px',
    },
    flexButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    resultLinkText: {
      paddingTop: '1em',
      color: theme.palette.primary.main,
    },
  });

interface AiSearchProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {}

interface AiSearchState {
  success: boolean;
  serverErrors?: IError;
  error: string;
  resultLink?: string;
}

class AiSearch extends React.Component<AiSearchProps, AiSearchState> {
  validationSchema = Yup.object().shape({
    query: Yup.string().required('Required'),
  });

  state: AiSearchState = {
    success: false,
    serverErrors: undefined,
    error: '',
    resultLink: '',
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography
          className={classNames(classes.title, classes.paper)}
          align="left"
          variant="h5"
        >
          AI Search
        </Typography>
        <Paper className={classes.paper} elevation={3}>
          <Formik
            initialValues={{ query: '' } as IAiSearchModel}
            onSubmit={(
              values: IAiSearchModel,
              formikHelpers: FormikHelpers<IAiSearchModel>
            ) => {
              searchService.aiSearch(values).then(response => {
                formikHelpers.setSubmitting(false);
                this.setState({
                  success: response.isError,
                  serverErrors: undefined,
                  error: response.isError ? response.message : '',
                  resultLink: response.result,
                });
              });
            }}
            validateOnMount={true}
            initialErrors={{}}
            validationSchema={this.validationSchema}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      type="text"
                      name="query"
                      label="Search Query"
                      value={props.values.query}
                      errorText={props.errors.query}
                      touched={props.touched.query}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      multiline={true}
                      required
                    />
                  </Grid>
                </Grid>
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Generate Results
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
          {this.state.serverErrors && (
            <SingleServerErrorMessage
              message={this.state.serverErrors.message}
              formikErrors={this.state.serverErrors.formikErrors}
            />
          )}
          {this.state.error && (
            <SingleServerErrorMessage message={this.state.error} />
          )}
          {this.state.resultLink && this.state.resultLink !== '' && (
            <div>
              <Button
                sx={{ marginTop: '2em' }}
                variant="outlined"
                onClick={() => {
                  window.open(this.state.resultLink, '_blank');
                }}
              >
                View Results
              </Button>
              <div>
                <Typography
                  className={classNames(classes.resultLinkText)}
                  align="left"
                  variant="subtitle2"
                >
                  {this.state.resultLink}
                </Typography>
              </div>
            </div>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(withRouter(AiSearch))
);
