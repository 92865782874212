import * as React from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { TextField, Theme, Tooltip, Grid, Typography } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { TextFieldProps } from '@mui/material/TextField';
import InformationOutline from 'mdi-material-ui/InformationOutline';
import Constants from '../../theme/Constants';

const styles = (theme: Theme) =>
  createStyles({
    root: { fontSize: theme.typography.fontSize - 2 },
    infoText: {
      position: 'relative',
      top: '20px',
      left: '14px',
      color: Constants.COLORS.PRIMARY_COLOR,
    },
    infoTextDisabled: {
      color: 'grey',
      position: 'relative',
      top: '20px',
      left: '14px',
    },
  });

interface FormikTextFieldProps extends WithStyles<typeof styles> {
  name: string;
  label: string;
  errorText?: string | string[] | FormikErrors<any>;
  touched?: boolean | FormikTouched<any>;
  onBlur(e: React.FocusEvent<any>): void;
  onBlur<T = string | any>(
    fieldOrEvent: T
  ): T extends string ? (e: any) => void : void;
  onChange(e: React.ChangeEvent<any>): void;
  onChange<T = string | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
  value: any;
  infoText?: string;
  showDisabledInfoTextIcon?: boolean;
  validate?: (value: string) => string | void;
  multiline?: boolean;
}

class FormikTextField extends React.Component<
  FormikTextFieldProps & TextFieldProps
> {
  render() {
    const {
      name,
      label,
      errorText,
      touched,
      onBlur,
      onChange,
      classes,
      value,
      infoText,
      validate,
      multiline,
      ...rest
    } = this.props;

    function renderErrorsArray(
      errors: string | string[] | FormikErrors<any> | undefined
    ) {
      if (errors && touched && Boolean(errorText)) {
        if (Array.isArray(errors)) {
          return errors.map(e => (
            <Typography
              className={classes.root}
              color="error"
              fontWeight="bold"
            >
              {e}
            </Typography>
          ));
        } else {
          return errors;
        }
      } else if (validate && Boolean(validate(value))) {
        return (
          <Typography className={classes.root} color="error" fontWeight="bold">
            {validate(value)}
          </Typography>
        );
      } else {
        return '';
      }
    }

    return (
      <Grid container alignContent="center">
        <Grid
          item
          xs={
            this.props.infoText || this.props.showDisabledInfoTextIcon ? 11 : 12
          }
        >
          <TextField
            FormHelperTextProps={{ className: classes.root }}
            id={name}
            name={name}
            label={label}
            value={value || ''}
            helperText={renderErrorsArray(errorText)}
            error={touched && Boolean(errorText)}
            onChange={onChange}
            onBlur={onBlur}
            multiline={multiline}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            {...rest}
          />
        </Grid>
        {(this.props.infoText || this.props.showDisabledInfoTextIcon) && (
          <Grid item xs={1}>
            <Tooltip
              className={
                this.props.showDisabledInfoTextIcon
                  ? classes.infoTextDisabled
                  : classes.infoText
              }
              title={this.props.infoText}
              placement="top"
            >
              <InformationOutline fontSize="small" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(FormikTextField);
