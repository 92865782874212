import * as React from "react";
import IUser from "../../models/app/IUser";
import CustomAddToolbar from "../muiDataTable/CustomAddToolbar";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { UserProfile } from "../../models/UserProfile";
import { Grid, Theme, Paper, Typography } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { ApplicationState } from "../store";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { addUser } from "../reducers/UserReducer";
import populateUsersHOC from "../hocs/PopulateUsersHOC";
import { RoleEnum } from "../../models/RoleEnum";
const MUIDataTable = require("mui-datatables").default;

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.toolbar.height,
      [theme.breakpoints.up(700 + theme.spacingNumber(2 * 2))]: {
        width: 700,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      padding: theme.spacing(4),
    },
    grid: {
      padding: theme.spacing(2),
    },
    footer: {
      display: "block",
      height: "54px",
    },
  });

interface UsersProps extends WithStyles<typeof styles>, WithRouterProps {
  users: IUser[];
  addUser: (user: IUser) => void;
  user: User;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    users: state.usersState.users,
    user: state.oidc.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUser: (user: IUser) => {
      const action = addUser(user);
      dispatch(action);
    },
  };
};

interface UsersState {
  editUser?: IUser;
  displayNewUserFormFlag: boolean;
}

class Users extends React.Component<UsersProps, UsersState> {
  state = {
    editUser: undefined,
    displayNewUserFormFlag: false,
  };

  editUser = (rowData: string[]) => {
    const user = {
      firstName: rowData[0],
      lastName: rowData[1],
      email: rowData[2],
      role: rowData[3],
    } as IUser;
    this.props.navigate("/users/edit?email=" + encodeURIComponent(user.email));
  };

  redirectToNewUserForm = () => {
    this.props.navigate("/users/new");
  };

  mapUsersToTableUsers(users: IUser[]): Array<Array<string>> {
    let tableUsers = new Array<Array<string>>();
    if (users) {
      users.forEach((value) => {
        if (value.email !== (this.props.user.profile as UserProfile).email && value.role !== RoleEnum.OWNER) {
          let status = value.emailConfirmed
            ? "Email Confirmed"
            : "Email Not Confirmed";
          tableUsers.push([
            value.firstName,
            value.lastName,
            value.email,
            value.role,
            status,
          ]);
        }
      });
    }
    return tableUsers;
  }

  columns = [
    {
      name: "First Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Last Name",
      options: {
        filter: false,
        sortOrder: "asc",
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "Role",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
  ];
  options = {
    textLabels: {
      body: {
        noMatch: "Your organization has not created any users yet.",
      },
    },
    print: false,
    download: false,
    search: true,
    filter: false,
    sort: true,

    customToolbar: () => {
      return <CustomAddToolbar onClick={this.redirectToNewUserForm} />;
    },
    elevation: 3,
    selectableRows: "none",
    responsive: "simple",
    viewColumns: false,
    tableBodyMaxHeight: "calc(100vh - 64px - 48px - 64px - 52px)",
    onRowClick: this.editUser,
  };

  render() {
    const { classes, users } = this.props;
    return (
      <Grid className={classes.grid} container alignItems="center">
        <Grid item xs={12}>
          <MUIDataTable
            title={"Users"}
            data={this.mapUsersToTableUsers(users)}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(populateUsersHOC(withRouter(Users))));
