import React, { ChangeEvent, useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Popper from "@mui/material/Popper";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import {
  Button,
  Checkbox,
  ListItem,
  ListItemText,
  List,
  Tooltip,
  FormControlLabel,
  Theme,
  alpha,
  Grid,
} from "@mui/material";
import CheckboxMarked from "mdi-material-ui/CheckboxMarked";
import CheckboxBlankOutline from "mdi-material-ui/CheckboxBlankOutline";
import InformationOutline from "mdi-material-ui/InformationOutline";
import Constants from "../../theme/Constants";
import { ChevronDown, ChevronUp } from "mdi-material-ui";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: "100%",
      textAlign: "left",
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: ".15em 4px",
      lineHeight: "15px",
    },
    inputBase: {
      width: "100%",
      borderBottom: "1px solid #dfe2e5",
      "& input": {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        border: "1px solid #ced4da",
        "&:focus": {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    popper: {
      border: "1px solid rgba(27,31,35,.15)",
      boxShadow: "0 3px 12px rgba(27,31,35,.15)",
      zIndex: 2,
    },
    paper: {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      margin: 0,
      background: theme.palette.common.white,
    },
    option: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: theme.spacing(1),
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    text: {
      flexGrow: 1,
    },
    popperDisablePortal: {
      position: "relative",
    },
    checkbox: {
      marginRight: theme.spacing(1),
    },
    listItem: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      marginBottom: "3px",
    },
    infoText: {
      position: "relative",
      top: "8px",
      left: "14px",
      color: Constants.COLORS.PRIMARY_COLOR
    },
    infoTextDisabled: {
      color: 'grey',
      position: "relative",
      top: "8px",
      left: "14px",
    },
  })
);

interface FormikAutocompleteLabelMultiselectProps {
  name: string;
  label: string;
  options: string[];
  placeholder: string;
  defaultValue?: string[];
  onChange: (event: ChangeEvent<{}>, values: string[]) => void;
  infoText?: string;
  showDisabledInfoTextIcon?: boolean;
  disabled: boolean;
}

export default function FormikAutocompleteLabelMultiselect(
  props: FormikAutocompleteLabelMultiselectProps
) {
  const classes = useStyles(props);
  const [anchorEl] = React.useState<React.RefObject<HTMLButtonElement>>(
    React.createRef()
  );
  const [autoCompleteRef] = React.useState<React.RefObject<null | HTMLElement>>(
    React.createRef()
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string[]>(
    props.defaultValue ? props.defaultValue : []
  );
  const [pendingValue, setPendingValue] = React.useState<string[]>([]);
  useEffect(() => {
    setValue(props.defaultValue ?? []);
  }, [props.defaultValue]);

  const handleClick = (event: any) => {
    setPendingValue(value);
    setOpen(true);
  };

  const handleClose = (event: any) => {
    setValue(pendingValue);
    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    setPendingValue(value);
    props.onChange(event, value);
  };

  const id = open ? "select" : undefined;
  const icon = <CheckboxBlankOutline fontSize="small" />;
  const checkedIcon = <CheckboxMarked fontSize="small" />;

  return (
    <React.Fragment>
      <div>
        <Grid container alignContent="center" spacing={0}>
          <Grid item xs={props.infoText || props.showDisabledInfoTextIcon ? 11 : 12}>
            <Button
              disabled={props.disabled}
              variant="outlined"
              color="primary"
              ref={anchorEl}
              className={classes.button}
              aria-describedby={id}
              onClick={handleClick}
            >
              <React.Fragment>
                {props.label}
                {open ? < ChevronUp color="primary" /> : <ChevronDown color="primary" />}
              </React.Fragment>
            </Button>
            {!props.disabled && (
              <List>
                {value.map((label: string) => (
                  <ListItem key={label} className={classes.listItem}>
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          <Grid item xs={1}>
            {(props.infoText || props.showDisabledInfoTextIcon) && (
              <Tooltip
                className={props.showDisabledInfoTextIcon ? classes.infoTextDisabled : classes.infoText}
                title={props.infoText}
                placement="top"
              >
                <InformationOutline fontSize="small" />
              </Tooltip>
            )}</Grid>
        </Grid>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        className={classes.popper}
        style={{ width: anchorEl.current ? anchorEl.current.offsetWidth : 0 }}
        placement="bottom"
        disablePortal
      >
        <Autocomplete
          ref={autoCompleteRef}
          open
          multiple
          classes={{
            option: classes.option,
            paper: classes.paper,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={handleChange}
          onClose={handleClose}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText="No options available"
          renderOption={(props: any, option: string, { selected }) => (
            <li {...props}>
              <Checkbox
                color="primary"
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
              />
              {option}
            </li>
          )}
          options={props.options.sort((a, b) => {
            // Display the selected labels first.
            let ai = value.indexOf(a);
            ai = ai === -1 ? value.length + props.options.indexOf(a) : ai;
            let bi = value.indexOf(b);
            bi = bi === -1 ? value.length + props.options.indexOf(b) : bi;
            return ai - bi;
          })}
          renderInput={(params: any) => (
            <InputBase
              name={props.name}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
      </Popper>
    </React.Fragment>
  );
}
