import * as React from "react";
import { Button, Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { ButtonProps } from "@mui/material/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: { marginTop: theme.spacing(3), marginLeft: theme.spacing(1) },
    denseMargin: { marginLeft: theme.spacing(1), position: "relative" },
  });

interface FormikCancelButtonProps extends WithStyles<typeof styles> {
  isSubmitting: boolean;
  onClick: () => void;
  denseMargin?: boolean;
}

class FormikCancelButton extends React.Component<
  FormikCancelButtonProps & ButtonProps
> {
  render() {
    const { isSubmitting, classes, onClick, ...rest } = this.props;
    return (
      <div className={this.props.denseMargin ? classes.denseMargin : classes.root}>
        <Button
          onClick={onClick}
          disabled={isSubmitting}
          {...rest}
        >
          Cancel
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(FormikCancelButton);
