import { useParams } from "react-router-dom";

export default function ErrorPage(): JSX.Element {
  const params = useParams();

  const getErrorMessage = () => {
    let message = null;
    switch (params.code) {
      case "email-confirm":
        message = "The email confirmation link you used is invalid or expired.";
        break;
      default:
        message = "An unknown error has occured.";
    }

    return message;
  };

  return (
    <div>
      <h1>Error</h1>
      <p>{getErrorMessage()}</p>
      {params.code && <p>Code: {params.code}</p>}
    </div>
  );
}
