import PusherNotificationModel from '../../../models/PusherNotificationModel';
import { StatusEnum } from '../../../models/StatusEnum';
import TenantService from '../../../services/TenantService';
import {
  SnackbarNotification,
  enqueueSnackbar,
} from '../../reducers/NotificationsReducer';
import {
  addOpportunityMeetingTask,
  removeOpportunityMeetingTasksPendingUpdateByCorrelationId,
} from '../../reducers/OpportunityMeetingTasksReducer';
import { store } from '../../store';

export default function SalesforceOpportunityMeetingTaskHandler(
  data: PusherNotificationModel
): void {
  if (data.status === StatusEnum.REJECTED) {
    const pendingOpportunityMeetingTask = store
      .getState()
      .opportunityMeetingTasksState.opportunityTasksPendingUpdate.find(
        t => t.correlationModel?.correlationId === data.correlationId
      );
    const revertedOpportunityMeetingTask = Object.assign(
      {},
      pendingOpportunityMeetingTask
    );
    revertedOpportunityMeetingTask.correlationModel = undefined;

    var removePendingAction =
      removeOpportunityMeetingTasksPendingUpdateByCorrelationId(
        data.correlationId
      );
    var addAction = addOpportunityMeetingTask(revertedOpportunityMeetingTask);
    store.dispatch(addAction);
    store.dispatch(removePendingAction);

    const snackbarNotification = {
      message: data.message,
      options: {
        variant: 'error',
      },
    } as SnackbarNotification;
    const enqueueSnackbarAction = enqueueSnackbar(snackbarNotification);
    store.dispatch(enqueueSnackbarAction);
  } else if (data.status === StatusEnum.COMPLETED) {
    var action = removeOpportunityMeetingTasksPendingUpdateByCorrelationId(
      data.correlationId
    );
    store.dispatch(action);

    const snackbarNotification = {
      message: data.message,
      options: {
        variant: 'success',
      },
    } as SnackbarNotification;
    const enqueueSnackbarAction = enqueueSnackbar(snackbarNotification);
    store.dispatch(enqueueSnackbarAction);
  }
}
