import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routing/Routes";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { OidcProvider } from "redux-oidc";
import SnackbarProviderWrapper from "./app/notifier/SnackbarProviderWrapper";
import UserManager from "./app/auth/UserManager";
import createCquenceTheme from "./theme/createCquenceTheme";
import ReactDOM from "react-dom";
import Notifier from "./app/notifier/Notifier";
import PrototypeExtensions from "./app/form/debounceNonDistinct";

PrototypeExtensions();
const container = document.getElementById("root")!;
const theme = createCquenceTheme();

// React StrictMode does not work with React 17/MUI v5
ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={UserManager}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <SnackbarProviderWrapper>
            <BrowserRouter>
              <Routes />
              <Notifier />
            </BrowserRouter>
          </SnackbarProviderWrapper>
        </StyledEngineProvider>
      </ThemeProvider>
    </OidcProvider>
  </Provider>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
