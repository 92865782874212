import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IUser from "../../models/app/IUser";
import { StatusEnum } from "../../models/StatusEnum";

export interface UsersState {
  isLoaded: boolean;
  users: Array<IUser>;
  pendingUpdate: Array<IUser>;
  pendingDeletion: Array<IUser>;
}

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoaded: false,
    users: [],
    pendingUpdate: [],
    pendingDeletion: [],
  } as UsersState,
  reducers: {
    addUser: (state, action: PayloadAction<IUser>) => {
      return Object.assign({}, state, {
        users: state.users.concat(action.payload),
      });
    },
    addUserToPendingUpdate: (state, action: PayloadAction<IUser>) => {
      return Object.assign({}, state, {
        pendingUpdate: state.pendingDeletion.concat(action.payload),
      });
    },
    updateUserByCorrelationId: (state, action: PayloadAction<IUser>) => {
      var userIndex = state.users.findIndex(
        (g) =>
          g.correlationModel?.correlationId ===
          action.payload.correlationModel?.correlationId
      );
      var updatedUser = state.users[userIndex];
      if (updatedUser && updatedUser.correlationModel) {
        updatedUser.correlationModel.status = action.payload.correlationModel
          ?.status as StatusEnum;
        updatedUser.id = action.payload.correlationModel?.resourceId;
        let newArray = state.users.slice();
        newArray.splice(userIndex, 1, updatedUser);
        return Object.assign({}, state, { users: newArray });
      }
      return Object.assign({}, state);
    },
    updateUserByEmail: (state, action: PayloadAction<IUser>) => {
      const userIndex = state.users.findIndex((g) => g.id == action.payload.id);
      let newArray = state.users.slice();
      newArray.splice(userIndex, 1, action.payload);
      return Object.assign({}, state, { users: newArray });
    },
    undoPendingDeleteUserByCorrelationId: (
      state,
      action: PayloadAction<string>
    ) => {
      const userToRestore = state.pendingDeletion.find(
        (v: IUser) => v.correlationModel?.correlationId === action.payload
      ) as IUser;
      return Object.assign({}, state, {
        users: state.users.concat(userToRestore),
        pendingDeletion: state.pendingDeletion.filter(
          (v: IUser) => v.correlationModel?.correlationId !== action.payload
        ),
      });
    },
    removePendingDeleteUserByCorrelationId: (
      state,
      action: PayloadAction<string>
    ) => {
      return Object.assign({}, state, {
        pendingDeletion: state.pendingDeletion.filter(
          (v: IUser) => v.correlationModel?.correlationId !== action.payload
        ),
      });
    },
    undoPendingUpdateUserByCorrelationId: (
      state,
      action: PayloadAction<string>
    ) => {
      const idToRestore = state.users.find(
        (v: IUser) => v.correlationModel?.correlationId === action.payload
      )?.id;
      if (idToRestore) {
        const userToRestore = state.pendingUpdate.find(
          (v: IUser) => v.id === idToRestore
        );
        if (userToRestore) {
          return Object.assign({}, state, {
            pendingUpdate: state.pendingUpdate.filter(
              (v: IUser) => v.id !== idToRestore
            ),
            users: state.users
              .filter((v: IUser) => v.id !== idToRestore)
              .concat(userToRestore),
          });
        }
      }
      return Object.assign({}, state);
    },
    removePendingUpdateUserByEmail: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        pendingUpdate: state.pendingUpdate.filter(
          (v: IUser) => v.email !== action.payload
        ),
      });
    },
    removePendingUpdateUserByCorrelationId: (
      state,
      action: PayloadAction<IUser>
    ) => {
      const idToRestore = state.users.find(
        (v: IUser) =>
          v.correlationModel?.correlationId ===
          action.payload.correlationModel?.correlationId
      )?.id;
      if (idToRestore) {
        const userToRestore = state.pendingUpdate.find(
          (v: IUser) => v.id === idToRestore
        );
        if (userToRestore) {
          return Object.assign({}, state, {
            pendingUpdate: state.pendingUpdate.filter(
              (v: IUser) => v.id !== idToRestore
            ),
            users: state.users
              .filter((v: IUser) => v.id !== idToRestore)
              .concat(userToRestore),
          });
        }
      }
      return Object.assign({}, state);
    },
    addUserToPendingDeletion: (state, action: PayloadAction<IUser>) => {
      return Object.assign({}, state, {
        pendingDeletion: state.pendingDeletion.concat(action.payload),
      });
    },
    removeUserByEmail: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        users: state.users.filter((v: IUser) => v.email !== action.payload),
      });
    },
    removeUserByCorrelationId: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        users: state.users.filter(
          (v: IUser) => v.correlationModel?.correlationId !== action.payload
        ),
      });
    },
    populateUsers: (state, action: PayloadAction<Array<IUser>>) => {
      return Object.assign({}, state, {
        users: action.payload,
        isLoaded: true,
      });
    },
  },
});
export const {
  addUser,
  addUserToPendingUpdate,
  updateUserByCorrelationId,
  updateUserByEmail,
  undoPendingUpdateUserByCorrelationId,
  undoPendingDeleteUserByCorrelationId,
  removePendingDeleteUserByCorrelationId,
  removePendingUpdateUserByEmail,
  addUserToPendingDeletion,
  removeUserByEmail,
  removeUserByCorrelationId,
  populateUsers,
} = usersSlice.actions;
export default usersSlice.reducer;
