import orange from "@mui/material/colors/orange";

const Constants = {
  COLORS: {
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    CQUENCE_BLACK: "#202224",
    PRIMARY_COLOR: "#629dd9",
    SECONDARY_COLOR: "#b14a8d",
    WARNING: orange[300],
    LIST_TABLE_HOVER_COLOR: "#d8e4f0",
    ERROR_COLOR: "#bc3025",
  },
  DOCS: {
    WIDTH: 750,
  },
  HEIGHT: {
    TOOLBAR: 80,
  },
  DRAWER: {
    LANDING: {
      WIDTH: 240,
    },
  },
  FORM: {
    WIDTH: 750,
  },
};

export default Constants;
