import * as React from 'react';
import { Typography } from '@mui/material';

class ExtensionError extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Typography>
          There was an error loading the Cquence extension. Please{' '}
          <a href={'https://my.cquence.app/login'} target="_blank">
            sign in
          </a>{' '}
          in a new browser tab, and then close and re-open this extension.
        </Typography>
        <br />
        <Typography>
          If you need additional support, you can contact us via our Slack
          Connect or{' '}
          <a href="mailto:support@cquence.app">support@cquence.app</a>
        </Typography>
      </React.Fragment>
    );
  }
}

export default ExtensionError;
