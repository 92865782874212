import {
  Theme,
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import classNames from "classnames";
import { FormikProps } from "formik";
import { ArrowLeft } from "mdi-material-ui";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import Constants from "../../../theme/Constants";
import RoutePaths from "../../../routing/RoutePaths";
import FormikSelectValues from "../../form/interfaces/FormikSelectValuesProp";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";
import { addIntegration } from "../../reducers/IntegrationReducer";
import integrationForm from "./IntegrationFormHOC";
import GmailIntegration from "../../../models/app/integrations/implementations/GmailIntegration";
import GlobalConfig from "../../../GlobalConfig";
import { ApplicationState } from "../../store";
import { IntegrationsState } from "../../reducers/IntegrationReducer";
import { IntegrationEnum } from "../../../models/app/integrations/IntegrationEnum";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    addBtn: {
      marginTop: theme.spacing(3),
    },
    addDate: {
      padding: theme.spacing(1),
      color: Constants.COLORS.PRIMARY_COLOR,
      fontStyle: "italic",
      position: "relative",
      top: "10px",
    },
  });

interface IGmailIntegrationFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  delete: (formikProps: FormikProps<GmailIntegration>) => void;
  environmentSelectValuesArray: FormikSelectValues;
  isSuccess: boolean;
  isPendingCreation: boolean;
  serverErrors: string[];
  canEdit: boolean;
  integrationsState: IntegrationsState;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchAddIntegration: (integration: GmailIntegration) => {
      const action = addIntegration(integration);
      dispatch(action);
    },
  };
};

class GmailIntegrationForm extends React.Component<IGmailIntegrationFormProps> {
  getGmailOAuthURL(): string {
    const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const { CLIENT_ID, REDIRECT_URI } = GlobalConfig.GMAIL_OAUTH_CREDENTIAL;
    const options = {
      redirect_uri: REDIRECT_URI,
      client_id: CLIENT_ID,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: [
        "https://www.googleapis.com/auth/gmail.compose",
        "https://www.googleapis.com/auth/gmail.modify",
        "https://www.googleapis.com/auth/gmail.readonly",
      ].join(" "),
    };

    const queryString = new URLSearchParams(options);
    return `${rootUrl}?${queryString.toString()}`;
  }

  render() {
    const { classes, integrationsState } = this.props;
    const gmailIntegration = integrationsState.integrations.find(
      (i) => i.type.toLowerCase() === IntegrationEnum.GMAIL.toLowerCase()
    );

    return (
      <React.Fragment>
        <IconButton
          className={classes.return}
          onClick={() => this.props.navigate(RoutePaths.IntegrationsNew)}
        >
          <ArrowLeft />
        </IconButton>
        <Typography
          className={classNames(classes.title, classes.paper)}
          align="left"
          variant="h5"
        >
          Gmail Integration
        </Typography>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h6" gutterBottom>
            Integration Details
          </Typography>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography align="center">
                Allow Cquence to place AI generated emails in your team's Gmail
                drafts.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid container>
              <Grid item>
                <Button
                  className={classNames(classes.addBtn)}
                  disabled={gmailIntegration ? true : false}
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={(): void =>
                    window.location.assign(this.getGmailOAuthURL())
                  }
                >
                  Add
                </Button>
                {gmailIntegration && (
                  <span className={classes.addDate}>
                    Added on{" "}
                    {new Date(gmailIntegration.createdOn).toDateString()}
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  integrationForm(
    withStyles(styles)(withSnackbar(withRouter(GmailIntegrationForm)))
  )
);
