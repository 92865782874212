import * as React from "react";
import IUser from "../../models/app/IUser";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { UserProfile } from "../../models/UserProfile";
import * as Yup from "yup";
import {
  Theme,
  Paper,
  Grid,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { withSnackbar, WithSnackbarProps } from "notistack";
import FormikTextField from "../form/FormikTextField";
import FormikUpdateButton from "../form/FormikUpdateButton";
import {
  IRestResponse,
  IError,
  IFormikErrors,
} from "../../services/RestUtilities";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { User } from "oidc-client";
import { openDialog, DialogContent } from "../reducers/DialogReducer";
import ChangePasswordContent from "../dialogContents/ChangePasswordContent";
import ChangeEmailContent from "../dialogContents/ChangeEmailContent";
import { getRole } from "../../helpers/Helpers";
import IAccountUpdateModel from "../../models/app/IAccountUpdateModel";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Constants from "../../theme/Constants";
import RegularExpressions from "../../helpers/RegularExpressions";
import UserService from "../../services/UserService";
import { red } from "@mui/material/colors";
import RoutePaths from "../../routing/RoutePaths";
import UserManager from "../auth/UserManager";
import classNames from "classnames";
import { withRouter, WithRouterProps } from "../hocs/withRouter";

const userService = new UserService();
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    changePassword: {
      marginTop: theme.spacing(3),
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
    transfer: {
      marginTop: theme.spacing(3),
      color: red[600],
      "&:hover": {
        backgroundColor: "#e539351c",
        color: theme.palette.error.main,
      },
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: red[600],
    },
  });

interface AccountProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  user: User;
  openDialog: (dialogContent: DialogContent) => void;
}

interface AccountState {
  serverErrors?: IFormikErrors;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    openDialog: (dialogContent: DialogContent) => {
      const action = openDialog(dialogContent);
      dispatch(action);
    },
  };
};

class Account extends React.Component<AccountProps, AccountState> {
  state = {
    serverErrors: undefined,
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(48, "Max 48 characters")
      .matches(
        new RegExp(RegularExpressions.NAME),
        "Must begin with and contain alphabetic character. May contain one of ( - ) ( , ) ( ' ) ( . )."
      )
      .required("Required"),
    lastName: Yup.string()
      .max(48, "Max 48 characters")
      .matches(
        new RegExp(RegularExpressions.NAME),
        "Must begin with and contain alphabetic character. May contain one of ( - ) ( , ) ( ' ) ( . )."
      )
      .required("Required"),
  });

  render() {
    const { classes, enqueueSnackbar } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          email: (this.props.user.profile as UserProfile).email as string,
          firstName: (this.props.user.profile as UserProfile).firstName,
          lastName: (this.props.user.profile as UserProfile).lastName,
          role: getRole((this.props.user.profile as UserProfile).role),
          authorizedProjects: (this.props.user.profile as UserProfile)
            .authorizedProjects,
        }}
        validateOnMount={false}
        isInitialValid={false}
        onSubmit={(values: IUser, formikHelpers: FormikHelpers<IUser>) => {
          this.setState({ serverErrors: undefined });
          const accountUpdate = {
            firstName: values.firstName,
            lastName: values.lastName,
          } as IAccountUpdateModel;
          userService
            .updateAccount(accountUpdate)
            .then((response: IRestResponse<void>) => {
              if (response.isError) {
                const { formikErrors: serverErrors, ...formikErrors } =
                  response.error as IError;
                enqueueSnackbar(response.error?.message, { variant: "error" });
                formikHelpers.setStatus(formikErrors as FormikErrors<IUser>);
                this.setState({ serverErrors: serverErrors });
                formikHelpers.setSubmitting(false);
              } else {
                UserManager.signinSilent();
                enqueueSnackbar(response.message, { variant: "success" });
                formikHelpers.setSubmitting(false);
              }
            });
        }}
        validationSchema={this.validationSchema}
      >
        {(props) => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              disabled={props.isSubmitting}
              onClick={() =>
                window.history.length
                  ? window.history.back()
                  : this.props.navigate(RoutePaths.Dashboard)
              }
              size="large"
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              variant="h5"
            >
              Your Account
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="firstName"
                      label="First Name"
                      value={props.values.firstName}
                      errorText={props.errors.firstName}
                      touched={props.touched.firstName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="lastName"
                      label="Last Name"
                      value={props.values.lastName}
                      errorText={props.errors.lastName}
                      touched={props.touched.lastName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="email"
                      label="Email"
                      value={props.values.email}
                      errorText={props.errors.email}
                      touched={props.touched.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="role"
                      label="Role"
                      value={props.values.role}
                      errorText={props.errors.role}
                      touched={props.touched.role}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled
                    />
                  </Grid>
                  {/* {this.state.serverErrors && (
                                        <Grid item xs={12}>
                                            <List>
                                                <ListItem>
                                                    {this.state.serverErrors.map((error) => (
                                                        <ListItemText primary={error} />
                                                    ))}
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    )} */}
                  <Grid item>
                    <FormikUpdateButton
                      color="primary"
                      isValid={props.isValid}
                      isSubmitting={props.isSubmitting}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </form>
            </Paper>
            <Paper className={classNames(classes.paper)} elevation={3}>
              <Typography variant="h6">Change Password</Typography>
              <Typography variant="subtitle1">
                Request a password reset email.
              </Typography>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={() => {
                      this.props.openDialog(
                        new DialogContent(<ChangePasswordContent />)
                      );
                    }}
                    color="primary"
                    className={classes.changePassword}
                    disabled={props.isSubmitting}
                    variant="outlined"
                  >
                    Change
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              className={classNames(classes.paper, classes.bottomPaper)}
              elevation={3}
            >
              <Typography variant="h6">Change Email</Typography>
              <Typography variant="subtitle1">
                Change your account email address.
              </Typography>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={() => {
                      this.props.openDialog(
                        new DialogContent(<ChangeEmailContent />)
                      );
                    }}
                    color="primary"
                    className={classes.changePassword}
                    disabled={props.isSubmitting}
                    variant="outlined"
                  >
                    Change
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(
  withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(Account))
  )
);
