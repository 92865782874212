import { datadogLogs } from '@datadog/browser-logs';
import GlobalConfig from '../GlobalConfig';

datadogLogs.init({
  clientToken: GlobalConfig.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'WebApp',
  env: GlobalConfig.ENVIRONMENT,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});
