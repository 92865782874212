import * as React from "react";
import { Formik, FormikProps, FormikErrors, FormikHelpers } from "formik";
import FormikSelectValues from "../form/interfaces/FormikSelectValuesProp";
import * as Yup from "yup";
import {
  Theme,
  Paper,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { withSnackbar, WithSnackbarProps } from "notistack";
import FormikDeleteButton from "../form/FormikDeleteButton";
import FormikSelect from "../form/FormikSelect";
import {
  IRestResponse,
  IFormikErrors,
  IError,
} from "../../services/RestUtilities";
import { connect } from "react-redux";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Constants from "../../theme/Constants";
import classNames from "classnames";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import RoutePaths from "../../routing/RoutePaths";
import { ApplicationState } from "../store";
import OpportunitiesService from "../../services/OpportunitiesService";
import populateOpportunitiesHOC from "../hocs/PopulateOpportunityMappingsHOC";
import {
  OpportunityFieldMappingsState,
  updateFieldMappings,
  removeFieldMappings,
} from "../reducers/OpportunityFieldMappingsReducer";
import IOpportunityFieldMapping from "../../models/app/opportunities/IOpportunityFieldMapping";
import PostPutOpportunityMappingResponseModel from "../../models/app/opportunities/PostPutOpportunityMappingResponseModel";
import populateTemplatesHOC from "../hocs/PopulateTemplatesHOC";
import { TemplatesState } from "../reducers/TemplatesReducer";

const opportunitiesService = new OpportunitiesService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
  });

interface IOpportunityFieldMappingFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
      updateFieldMappings: (mapping: Array<IOpportunityFieldMapping>) => void;
      removeFieldMappings: () => void;
      opportunityFieldMappingsState: OpportunityFieldMappingsState;
      templatesState: TemplatesState;
    }

type OpportunityFieldMappingFormState = {
  editFieldMappings: Array<IOpportunityFieldMapping>;
  serverErrors?: IFormikErrors;
  isSuccess: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityFieldMappingsState: state.opportunityFieldMappingsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFieldMappings: (mapping: Array<IOpportunityFieldMapping>) => {
      const action = updateFieldMappings(mapping);
      dispatch(action);
    },
    removeFieldMappings: () => {
      const action = removeFieldMappings();
      dispatch(action);
    }
  };
};

class OpportunityFieldMappingForm extends React.Component<
  IOpportunityFieldMappingFormProps,
  OpportunityFieldMappingFormState
> {
  state = {
    serverErrors: undefined,
    isSuccess: false,
    editFieldMappings: [] as Array<IOpportunityFieldMapping>,
  };

  componentDidMount() {
    this.setState({ editFieldMappings: this.props.opportunityFieldMappingsState.fieldMappings });
  }

  deleteFieldMappings(
    props: FormikProps<Array<IOpportunityFieldMapping>>,
    enqueueSnackbar: any
  ) {
    opportunitiesService
      .deleteFieldMappings()
      .then((response) => {
        if (response.isError) {
          enqueueSnackbar(response.error?.message, { variant: "error" });
          props.setStatus(response.error?.message);
        } else {
          this.props.removeFieldMappings();
          enqueueSnackbar(response.message, { variant: "success" });
          this.props.navigate(RoutePaths.Opportunities);
        }
      });
  }

  render() {
    const { classes, enqueueSnackbar } = this.props;
    const doFieldMappingsExist = this.props.opportunityFieldMappingsState.fieldMappings.length;

    const handleFieldVariableSelection = (e: React.ChangeEvent) => {
      var mappings;
      var target = (e.target as HTMLInputElement);
      var targetField = this.state.editFieldMappings.find(field => field.fieldName === target.name);
      if (targetField) {
        mappings = this.state.editFieldMappings.filter(field => field.fieldName !== target.name);
        mappings.push({ ...targetField, variableId: target.value });
      } else {
        mappings = this.state.editFieldMappings.slice();
        mappings.push({ variableId: target.value, fieldName: target.name });
      }
      this.setState({ editFieldMappings: mappings });
    }

    return (
      <Formik
        enableReinitialize
        initialStatus={{ creating: !doFieldMappingsExist }}
        initialValues={ this.state.editFieldMappings }
        onSubmit={(
          values: Array<IOpportunityFieldMapping>,
          formikHelpers: FormikHelpers<IOpportunityFieldMapping[]>
        ) => {
          const currentFieldMappings = this.state.editFieldMappings;
          opportunitiesService
            .upsertFieldMappings(currentFieldMappings)
            .then((response) => {
              this.handlePutResponse(
                response,
                enqueueSnackbar,
                formikHelpers,
                currentFieldMappings,
                values
              );
            });
        }}
        validateOnMount={true}
      >
        {(props) => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              disabled={props.isSubmitting}
              onClick={() => this.props.navigate(RoutePaths.Opportunities)}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              align="left"
              variant="h5"
            >
              { doFieldMappingsExist ? "Edit Fields" : "Setup Fields" }
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    {this.props.opportunityFieldMappingsState.salesforceFields.map(salesforceField => {
                      var salesforceFieldName = salesforceField.name;
                      var value = this.state.editFieldMappings.find(field => field.fieldName === salesforceFieldName)?.variableId;
                      return <div key={salesforceFieldName}>
                        <FormikSelect
                          name={salesforceFieldName}
                          label={salesforceField.relationshipName || salesforceField.label}
                          selectValues={this.getVariableSelectValues()}
                          value={value ? value : ''}
                          errorText={undefined}
                          touched={undefined}
                          onChange={handleFieldVariableSelection}
                          onBlur={props.handleBlur}
                        />
                        <br/>
                      </div>
                    })}
                  </Grid>
                </Grid>
                <Grid item>
                  <FormikSynchronousButton
                    isValid={props.isValid && !!this.state.editFieldMappings.length}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.isSuccess}
                    variant="contained"
                  >
                    {props.status.creating ? "Create" : "Update"}
                  </FormikSynchronousButton>
                </Grid>
              </form>
            </Paper>
            {doFieldMappingsExist && (
              <Paper
                className={classNames(classes.bottomPaper, classes.paper)}
                elevation={3}
              >
                <Typography variant="h6">Delete</Typography>
                <Typography variant="subtitle1">
                  Remove the opportunity mapping
                </Typography>
                <Grid container>
                  <Grid item>
                    <FormikDeleteButton
                      isSubmitting={props.isSubmitting}
                      onConfirm={() => {
                        this.deleteFieldMappings(props, enqueueSnackbar);
                      }}
                      dialogTitle="Delete field mappings?"
                      confirmText="Delete"
                      dialogContent={
                        "Are you sure you want to delete field mappings?"
                      }
                      disabled={!doFieldMappingsExist}
                    >
                      Delete
                    </FormikDeleteButton>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }

  private handlePutResponse(
    response: IRestResponse<PostPutOpportunityMappingResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<Array<IOpportunityFieldMapping>>,
    newOpportunity: Array<IOpportunityFieldMapping>,
    values: Array<IOpportunityFieldMapping>
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;

      formikHelpers.setStatus(
        formikErrors as FormikErrors<IOpportunityFieldMapping>
      );
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newOpportunity });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      this.setState({ isSuccess: true });
      this.props.updateFieldMappings(this.state.editFieldMappings);
      formikHelpers.setStatus({ creating: false });
      // this.props.navigate(RoutePaths.Opportunities);
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }

  private getVariableSelectValues() {
    var templateVariableSelectValues:FormikSelectValues; 
    templateVariableSelectValues = this.props.templatesState.templateVariables.map(variable => ({ label: variable.name, value: variable.id }))
    templateVariableSelectValues.unshift({ label: 'N/A', value: ''});
    return templateVariableSelectValues;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  populateTemplatesHOC(
    populateOpportunitiesHOC(
      withSnackbar(withRouter(withStyles(styles)(OpportunityFieldMappingForm)))
    )
  )
);
