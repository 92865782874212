import * as React from "react";
import { Theme, Grid, TableFooter, Link } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { capitalCase } from "change-case";
import { User } from "oidc-client";
import { connect } from "react-redux";
import RoutePaths from "../../routing/RoutePaths";
import populateTemplatesHOC from "../hocs/PopulateTemplatesHOC";
import CustomAddToolbar from "../muiDataTable/CustomAddToolbar";
import { TemplatesState, addTemplate } from "../reducers/TemplatesReducer";
import { ApplicationState } from "../store";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { WithSnackbarProps } from "notistack";
import format from "date-fns/format";
import ITemplate from "../../models/app/templates/ITemplate";
import { userIsInRole } from "../../helpers/Helpers";
import { RoleEnum } from "../../models/RoleEnum";

const MUIDataTable = require("mui-datatables").default;

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2),
    },
    tableLogoWidth: {
      width: "100px",
    },
  });

interface TemplatesProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  user: User;
  templatesState: TemplatesState;
  addTemplate: (template: ITemplate) => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    templatesState: state.templatesState,
    user: state.oidc.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTemplate: (template: ITemplate) => {
      const action = addTemplate(template);
      dispatch(action);
    },
  };
};

class Templates extends React.Component<TemplatesProps> {
  refs: {
    query: HTMLInputElement;
  };

  editTemplate = (rowData: string[]) => {
    if (userIsInRole(this.props.user, RoleEnum.SUPPORT)) {
      return;
    }
    this.props.navigate(
      RoutePaths.TemplatesEdit.replace(":templateId", rowData[0])
    );
  };

  redirectToNewTemplateForm = () => {
    this.props.navigate(RoutePaths.TemplatesNew);
  };

  mapTemplatesToTableTemplates(templates: ITemplate[]): Array<Array<any>> {
    const tableTemplates = new Array<Array<any>>();
    if (templates) {
      templates.forEach((value) => {
        let link = `https://docs.google.com/document/d/${value.driveId}/edit`;
        let scope = value.isSystemTemplate ? "System" : "Customer";
        tableTemplates.push([
          value.id,
          capitalCase(value.name),
          scope,
          <Link
            onClick={(e) => e.stopPropagation()}
            href={link}
            target="_blank"
          >
            Template
          </Link>,
        ]);
      });
    }
    return tableTemplates;
  }

  columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Template Scope",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Template Link",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  options = {
    textLabels: {
      body: {
        noMatch: "No templates have been added yet.",
      },
    },
    print: false,
    download: false,
    customToolbar: () => {
      if (userIsInRole(this.props.user, RoleEnum.SUPPORT)) {
        return;
      }
      return <CustomAddToolbar onClick={this.redirectToNewTemplateForm} />;
    },
    rowsPerPage: 100,
    elevation: 3,
    selectableRows: "none",
    responsive: "simple",
    viewColumns: false,
    tableBodyMaxHeight: "calc(100vh - 64px - 48px - 64px - 52px)",
    onRowClick: this.editTemplate,
    filter: true,
    search: true,
    sort: true,
  };

  render() {
    const { classes, templatesState } = this.props;

    return (
      <Grid className={classes.grid} container alignItems="center">
        <Grid item xs={12}>
          <MUIDataTable
            title={"Templates"}
            data={this.mapTemplatesToTableTemplates(templatesState.templates)}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(populateTemplatesHOC(withRouter(Templates))));
