import * as React from "react";
import {
  Paper,
  Theme,
  Typography,
  Grid,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { connect } from "react-redux";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Constants from "../../theme/Constants";
import classNames from "classnames";
import populateOrganizationNameHOC from "../hocs/PopulateOrganizationNameHOC";
import ChangeOrganizationNameContent from "../dialogContents/ChangeOrganizationNameContent";
import RoutePaths from "../../routing/RoutePaths";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { openDialog, DialogContent } from "../reducers/DialogReducer";
import { OrganizationState } from "../reducers/OrganizationReducer";
import { ApplicationState } from "../store";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
    changeButton: {
      marginTop: theme.spacing(2),
    },
  });
interface IOrganizationFormProps
  extends WithStyles<typeof styles>,
    WithRouterProps {
  organization: OrganizationState;
  openDialog: (dialogContent: DialogContent) => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    openDialog: (dialogContent: DialogContent) => {
      const action = openDialog(dialogContent);
      dispatch(action);
    },
  };
};

const mapStateToProps = (state: ApplicationState) => {
  return { organization: state.organizationState };
};

class OrganizationForm extends React.Component<IOrganizationFormProps> {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <IconButton
          className={classes.return}
          onClick={() => this.props.navigate(RoutePaths.Dashboard)}
          size="large"
        >
          <ArrowLeft />
        </IconButton>
        <Typography
          className={classNames(classes.title, classes.paper)}
          align="left"
          variant="h5"
        >
          Edit Organization
        </Typography>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h6">Organization Name</Typography>
          <Typography variant="subtitle1">Your organization's name</Typography>
          <Grid container>
            <Grid item>
              <Box marginTop={3}>
                <Typography variant="body1">
                  {this.props.organization.organizationName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Button
                className={classes.changeButton}
                onClick={() => {
                  this.props.openDialog(
                    new DialogContent(<ChangeOrganizationNameContent />)
                  );
                }}
                color="primary"
                variant="outlined"
              >
                Change
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  populateOrganizationNameHOC(withRouter(withStyles(styles)(OrganizationForm)))
);
