import * as React from 'react';
import { connect } from 'react-redux';
import { IField } from '../../models/app/opportunities/IOpportunityLayoutsResponseModel';
import IOpportunityFieldMapping from '../../models/app/opportunities/IOpportunityFieldMapping';
import OpportunitiesService from '../../services/OpportunitiesService';
import Loading from '../loading/Loading';
import {
  OpportunityFieldMappingsState,
  populateFieldMappings,
  populateSalesforceFields,
  populateSalesforceTaskDetails,
  setIsLoaded,
} from '../reducers/OpportunityFieldMappingsReducer';
import { ApplicationState } from '../store';
import { UserProfile } from '../../models/UserProfile';
import { User } from 'oidc-client';
import { withRouter, WithRouterProps } from './withRouter';
import ISalesforceTaskDetails from '../../models/app/opportunities/ISalesforceTaskDetails';
import { IOpportunityLayoutsResponseModel } from '../../models/app/opportunities/IOpportunityLayoutsResponseModel';
import { IntegrationEnum } from '../../models/app/integrations/IntegrationEnum';
import { IntegrationsState } from '../reducers/IntegrationReducer';
import { IRestResponse } from '../../services/RestUtilities';

const opportunitiesService = new OpportunitiesService();

interface PopulateOpportunityFieldMappingsComponentProps
  extends WithRouterProps {
  populateFieldMappings: (
    fieldMappings: Array<IOpportunityFieldMapping>
  ) => void;
  populateSalesforceFields: (fieldMappings: Array<IField>) => void;
  populateSalesforceTaskDetails: (taskDetails: ISalesforceTaskDetails) => void;
  setIsLoaded: (isLoaded: boolean) => void;
  opportunityFieldMappingsState: OpportunityFieldMappingsState;
  user: User;
  integrationsState: IntegrationsState;
}

interface PopulateOpportunityFieldMappingsState {
  wasError: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityFieldMappingsState: state.opportunityFieldMappingsState,
    user: state.oidc.user as User,
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    populateFieldMappings: (fieldMappings: Array<IOpportunityFieldMapping>) => {
      const action = populateFieldMappings(fieldMappings);
      dispatch(action);
    },
    populateSalesforceFields: (salesforceFields: Array<IField>) => {
      const action = populateSalesforceFields(salesforceFields);
      dispatch(action);
    },
    populateSalesforceTaskDetails: (taskDetails: ISalesforceTaskDetails) => {
      const action = populateSalesforceTaskDetails(taskDetails);
      dispatch(action);
    },

    setIsLoaded: (isLoaded: boolean) => {
      dispatch(setIsLoaded(isLoaded));
    },
  };
};

const populateOpportunityFieldMappingsHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class PopulateOpportunityFieldMappingsComponent extends React.Component<
    PopulateOpportunityFieldMappingsComponentProps,
    PopulateOpportunityFieldMappingsState
  > {
    state: PopulateOpportunityFieldMappingsState = {
      wasError: false,
    };

    componentDidUpdate() {
      if (!this.props.opportunityFieldMappingsState.isLoaded) {
        this.getAndPopulateOpportunityFieldMappings();
      }
    }

    componentDidMount() {
      if (!this.props.opportunityFieldMappingsState?.isLoaded) {
        this.getAndPopulateOpportunityFieldMappings();
      }
    }

    private async getAndPopulateOpportunityFieldMappings() {
      let taskDetailsResponse, layoutsResponse;

      const hubspotIntegration = this.props.integrationsState?.integrations
        .map(i => i.type)
        .includes(IntegrationEnum.HUBSPOT);

      if (hubspotIntegration) {
        taskDetailsResponse = { isError: false, result: { fields: [] } };
        layoutsResponse = {
          statusCode: 200,
          message: '',
          isError: false,
          result: { fields: [], recordTypeInfos: [] },
        } as IRestResponse<IOpportunityLayoutsResponseModel>;
      } else {
        const userId = (this.props.user.profile as UserProfile).sub as string;
        taskDetailsResponse = await opportunitiesService.getTaskDetails();
        layoutsResponse = await opportunitiesService.getLayouts(userId);
      }

      var fieldMappings = await opportunitiesService.getFieldMappings();

      if (
        fieldMappings.isError ||
        layoutsResponse.isError ||
        taskDetailsResponse.isError
      ) {
        this.setState({ wasError: true });
      } else {
        var salesforceFields = layoutsResponse.result?.fields.filter(
          field => field.createable
        );
        this.props.populateSalesforceFields(salesforceFields ?? []);
        this.props.populateSalesforceTaskDetails(
          taskDetailsResponse.result ?? { fields: [] }
        );
        this.props.populateFieldMappings(fieldMappings.result ?? []);
        this.props.setIsLoaded(true);
      }
    }

    render() {
      return this.props.opportunityFieldMappingsState?.isLoaded &&
        !this.state.wasError ? (
        <Component {...(this.props as P)} />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PopulateOpportunityFieldMappingsComponent));
};

export default populateOpportunityFieldMappingsHOC;
