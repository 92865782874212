import * as React from "react";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import IUser from "../../models/app/IUser";
import UserService from "../../services/UserService";
import * as queryString from "query-string";
import { User } from "oidc-client";
import { UserProfile } from "../../models/UserProfile";
import Loading from "../loading/Loading";
import RoutePaths from "../../routing/RoutePaths";
import { withRouter, WithRouterProps } from "./withRouter";

const userService = new UserService();

interface PopulateUserProps extends WithRouterProps {
  user?: User;
}

interface PopulateUserState {
  isLoaded: boolean;
  initialUser?: IUser;
  wasError: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return { user: state.oidc.user };
};

const loadUserFromQueryParamHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class LoadUserFromQueryParamComponent extends React.Component<
    PopulateUserProps,
    PopulateUserState
  > {
    state = {
      isLoaded: false,
      initialUser: undefined as IUser | undefined,
      wasError: false,
    };

    setInitialUserInStateFromQuery() {
      const params = queryString.parse(window.location.search);
      const email = params["email"] as string;
      if (email && !this.state.isLoaded) {
        userService.getUser(email).then((v) => {
          if (v.isError) {
            this.setState({ wasError: true });
          } else {
            if (
              v.result?.email !==
              (this.props.user?.profile as UserProfile).email
            ) {
              this.setState({ initialUser: v.result, isLoaded: true });
            } else {
              this.props.navigate(RoutePaths.Users);
            }
          }
        });
      } else {
        this.setState({ isLoaded: true });
      }
    }

    componentDidMount() {
      this.setInitialUserInStateFromQuery();
    }

    render() {
      return this.state.isLoaded && !this.state.wasError ? (
        <Component
          {...(this.props as P)}
          initialUser={this.state.initialUser}
        />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  return connect(
    mapStateToProps,
    null
  )(withRouter(LoadUserFromQueryParamComponent));
};

export default loadUserFromQueryParamHOC;
