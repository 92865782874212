import * as React from "react";
import { Typography, Theme, Button, Grid, Paper } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import * as queryString from "query-string";
import IPasswordResetModel from "../../../models/landing/IPasswordResetModel";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../form/FormikTextField";
import FormikSynchronousButton from "../../form/FormikSynchronousButton";
import UserService from "../../../services/UserService";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";
import RegularExpressions from "../../../helpers/RegularExpressions";
import RoutePaths from "../../../routing/RoutePaths";
import GlobalConfig from "../../../GlobalConfig";
const userService = new UserService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      padding: theme.spacing(4),
      width: "auto",
      marginTop: theme.toolbar.height * 2,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(400 + theme.spacingNumber(2 * 2))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    flexButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });

interface ConfirmUserProps extends WithStyles<typeof styles>, WithRouterProps {}

interface ConfirmUserState {
  domain: string;
  success: boolean;
  serverError: boolean;
}

class ConfirmUser extends React.Component<ConfirmUserProps, ConfirmUserState> {
  validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Min 8 characters")
      .max(64, "Max 64 characters")
      .matches(
        new RegExp(RegularExpressions.PASSWORD_SPECIAL_CHARACTER),
        "Must contain at least 1 special character"
      )
      .matches(
        new RegExp(RegularExpressions.PASSWORD_NUMBER),
        "Must contain at least 1 number"
      )
      .matches(
        new RegExp(RegularExpressions.PASSWORD_LOWERCASE_LETTER),
        "Must contain at least 1 lowercase letter"
      )
      .matches(
        new RegExp(RegularExpressions.PASSWORD_UPPERCASE_LETTER),
        "Must contain at least 1 uppercase letter"
      )
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  state: ConfirmUserState = {
    domain: "",
    success: false,
    serverError: false,
  };

  getTokenFromParams(): string {
    const params = queryString.parse(window.location.search);
    const token = params["token"] as string;
    return token;
  }

  getEmailFromParams(): string {
    const params = queryString.parse(window.location.search);
    const userId = params["email"] as string;
    return userId;
  }

  getTenantIdFromParams(): string {
    const params = queryString.parse(window.location.search);
    const domain = params["tenantId"] as string;
    return domain;
  }

  componentDidMount() {
    const domain = this.getTenantIdFromParams();
    this.setState({ domain: domain });
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.layout} elevation={3}>
        {!this.state.success ? (
          <Formik
            initialValues={
              {
                tenantId: this.getTenantIdFromParams(),
                token: this.getTokenFromParams(),
                newPassword: "",
                confirmPassword: "",
              } as IPasswordResetModel
            }
            onSubmit={(
              values: IPasswordResetModel,
              formikHelpers: FormikHelpers<IPasswordResetModel>
            ) => {
              const email = this.getEmailFromParams();
              userService.confirmUserAndPassword(email, values).then((v) => {
                if (v.isError) {
                  formikHelpers.setSubmitting(false);
                  this.setState({ serverError: true });
                } else {
                  setTimeout(() => {
                    formikHelpers.setSubmitting(false);
                    this.setState({ success: true });
                  }, 350);
                }
              });
            }}
            validateOnMount={false}
            isInitialValid={false}
            validationSchema={this.validationSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <Typography align="center" variant="h5">
                      New Account Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="newPassword"
                      label="New Password"
                      value={props.values.newPassword}
                      errorText={props.errors.newPassword}
                      touched={props.touched.newPassword}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="confirmPassword"
                      label="Confirm password"
                      value={props.values.confirmPassword}
                      errorText={props.errors.confirmPassword}
                      touched={props.touched.confirmPassword}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      type="password"
                      required
                    />
                  </Grid>
                </Grid>
                {this.state.serverError && (
                  <React.Fragment>
                    <Typography align="center" color="error">
                      An error occurred setting your password.
                    </Typography>
                    <Typography align="center" color="error">
                      The link may have expired.
                    </Typography>
                  </React.Fragment>
                )}
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Set Password
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <Grid direction="column" container spacing={3} alignItems="center">
            <Grid item>
              <Typography gutterBottom align="center" variant="h5">
                Your account password has been set.
              </Typography>
              <Typography gutterBottom align="center" variant="subtitle1">
                Click below to get started.
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.flexButtonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.location.assign(
                      `https://${GlobalConfig.SPA_HOST}${RoutePaths.Login}`
                    );
                  }}
                >
                  Sign in
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(ConfirmUser));
