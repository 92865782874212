import RestUtilities, { IRestResponse } from "./RestUtilities";
import IUser from "../models/app/IUser";
import IResetPasswordModel from "../models/landing/IPasswordResetModel";
import IChangeEmailModel from "../models/landing/IChangeEmailModel";
import IAccountUpdateModel from "../models/app/IAccountUpdateModel";
import IRequestEmailChangeModel from "../models/app/IRequestEmailChangeModel";
import IRequestPasswordResetModel from "../models/app/IRequestPasswordResetModel";

export default class UserService {
  async getUsers(): Promise<IRestResponse<IUser[]>> {
    return RestUtilities.get<IUser[]>("/users");
  }

  async getUser(email: string): Promise<IRestResponse<IUser>> {
    return RestUtilities.get<IUser>(`/users/${email}`);
  }

  async postUser(user: IUser): Promise<IRestResponse<IUser>> {
    return RestUtilities.post<IUser>("/users", user);
  }

  async putUser(
    email: string,
    updatedUser: IUser
  ): Promise<IRestResponse<IUser>> {
    return RestUtilities.put<IUser>(`/users/${email}`, updatedUser);
  }

  async confirmUserAndPassword(
    email: string,
    confirmModel: IResetPasswordModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.put(`/users/${email}/confirm`, confirmModel);
  }

  async getAuthorizedProjects(email: string): Promise<IRestResponse<string[]>> {
    return RestUtilities.get(`/users/${email}/authorized-projects`);
  }

  async resetPassword(
    email: string,
    resetModel: IResetPasswordModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.post(`/users/${email}/password-reset`, resetModel);
  }

  async updateAccount(
    accountUpdateModel: IAccountUpdateModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.put(`/account`, accountUpdateModel);
  }

  async deleteUser(email: string): Promise<IRestResponse<void>> {
    return RestUtilities.delete(`/users/${email}`);
  }

  async requestPasswordReset(
    passwordReset: IRequestPasswordResetModel
  ): Promise<IRestResponse<boolean>> {
    return RestUtilities.put(`/users/password-reset`, passwordReset);
  }

  async requestEmailChanage(
    emailChange: IRequestEmailChangeModel
  ): Promise<IRestResponse<boolean>> {
    return RestUtilities.put(`/users/email-change`, emailChange);
  }

  async changeEmail(
    resetModel: IChangeEmailModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.post(
      `/users/${resetModel.email}/email-change`,
      resetModel
    );
  }
}
