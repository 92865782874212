import RestUtilities, { IRestResponse } from "./RestUtilities";
import IReviewForm from "../models/landing/IReviewForm";
import IConfirmModel from "../models/landing/IConfirmModel";
import IOrganizationForm from "../models/IOrganizationForm";

export enum DomainEnabledResults {
  Enabled,
  Disabled,
  NotFound,
}

export default class TenantService {
  async exists(domain: string): Promise<IRestResponse<boolean>> {
    return RestUtilities.get(`/tenants/${domain}/exists`);
  }

  async getOrganizationName(
    tenantId: string
  ): Promise<IRestResponse<IOrganizationForm>> {
    return RestUtilities.get(`/tenants/${tenantId}`);
  }

  async putTenantOrganization(
    domain: string,
    organization: IOrganizationForm
  ): Promise<IRestResponse<void>> {
    return RestUtilities.put(`/tenants/${domain}`, organization);
  }

  async deleteOrganization(domain: string): Promise<IRestResponse<void>> {
    return RestUtilities.delete(`/tenants/${domain}`);
  }

  async confirmed(domain: string): Promise<IRestResponse<boolean>> {
    return RestUtilities.get<boolean>(`/tenants/${domain}/confirmed`);
  }

  async confirm(
    domain: string,
    confirmModel: IConfirmModel
  ): Promise<IRestResponse<boolean>> {
    return RestUtilities.put(`/tenants/${domain}/confirm`, confirmModel);
  }

  async post(reviewForm: IReviewForm): Promise<IRestResponse<void>> {
    return RestUtilities.post("/tenants", reviewForm);
  }
}
