import { IOpportunityLayoutsResponseModel } from '../models/app/opportunities/IOpportunityLayoutsResponseModel';
import { IOpportunityLayoutDetailsResponseModel } from '../models/app/opportunities/IOpportunityLayoutDetailsResponseModel';
import IOpportunityFieldMapping from '../models/app/opportunities/IOpportunityFieldMapping';
import PostPutOpportunityMappingResponseModel from '../models/app/opportunities/PostPutOpportunityMappingResponseModel';
import PostSalesforceTaskModel from '../models/app/opportunities/PostSalesforceTaskModel';
import RestUtilities, { IRestResponse } from './RestUtilities';
import IOpportunityMeetingTask from '../models/app/opportunities/IOpportunityMeetingTask';
import PutOpportunityMeetingTaskFieldValuesModel from '../models/app/opportunities/PutOpportunityMeetingTaskFieldValuesModel';
import SalesforceAccount from '../models/app/SalesforceAccount';
import SalesforceOpportunity from '../models/app/SalesforceOpportunity';
import IOpportunityDetails from '../models/app/opportunities/IOpportunityDetails';

export default class OpportunitiesService {
  async getAccounts(
    userId: string,
    search?: string
  ): Promise<IRestResponse<SalesforceAccount[]>> {
    if (search) {
      return RestUtilities.get<SalesforceAccount[]>(
        `/salesforce/accounts?search=${search}&userId=${userId}`
      );
    } else {
      return RestUtilities.get<SalesforceAccount[]>(
        `/salesforce/accounts?userId=${userId}`
      );
    }
  }

  async searchSalesforceObjects(
    salesforceObject: string,
    userId: string,
    search?: string
  ): Promise<IRestResponse<SalesforceOpportunity[]>> {
    if (search) {
      return RestUtilities.get<SalesforceOpportunity[]>(
        `/salesforce/objects/?salesforceObject=${salesforceObject}&search=${search}&userId=${userId}`
      );
    } else {
      return RestUtilities.get<SalesforceOpportunity[]>(
        `/salesforce/objects/?salesforceObject=${salesforceObject}&userId=${userId}`
      );
    }
  }

  async getOpportunitiesForAccount(
    accountId: string,
    userId: string,
    search?: string
  ): Promise<IRestResponse<SalesforceOpportunity[]>> {
    if (search) {
      return RestUtilities.get<SalesforceOpportunity[]>(
        `/salesforce/accounts/${accountId}/opportunities?search=${search}&userId=${userId}`
      );
    } else {
      return RestUtilities.get<SalesforceOpportunity[]>(
        `/salesforce/accounts/${accountId}/opportunities?userId=${userId}`
      );
    }
  }

  async getOpportunityDetails(
    opportunityId: string,
    userId: string
  ): Promise<IRestResponse<IOpportunityDetails>> {
    return RestUtilities.get<IOpportunityDetails>(
      `/salesforce/opportunities/${opportunityId}?userId=${userId}`
    );
  }

  async putFieldValues(
    taskId: string,
    fieldValues: PutOpportunityMeetingTaskFieldValuesModel
  ): Promise<IRestResponse<any>> {
    return RestUtilities.put<any>(
      `/salesforce/opportunities/meeting-tasks/${taskId}`,
      fieldValues
    );
  }

  async postSalesforceTask(
    salesforceTask: PostSalesforceTaskModel
  ): Promise<IRestResponse<any>> {
    return RestUtilities.post<any>(`/salesforce/tasks`, salesforceTask);
  }

  async postSalesforceOpportunity(
    fieldValues: PutOpportunityMeetingTaskFieldValuesModel
  ): Promise<IRestResponse<any>> {
    return RestUtilities.post<any>(`/salesforce/opportunities`, fieldValues);
  }

  async patchSalesforceOpportunity(
    opportunityId: string,
    fieldValues: PutOpportunityMeetingTaskFieldValuesModel
  ): Promise<IRestResponse<any>> {
    return RestUtilities.patch<any>(
      `/salesforce/opportunities/${opportunityId}`,
      fieldValues
    );
  }

  async getMeetingTasksStages(): Promise<IRestResponse<Array<string>>> {
    return RestUtilities.get<Array<string>>(
      `/salesforce/opportunities/meeting-tasks/stages`
    );
  }

  async getMeetingTasksStatuses(): Promise<IRestResponse<Array<string>>> {
    return RestUtilities.get<Array<string>>(
      `/salesforce/opportunities/meeting-tasks/statuses`
    );
  }

  async getMeetingTasksForTenant(): Promise<
    IRestResponse<Array<IOpportunityMeetingTask>>
  > {
    return RestUtilities.get<Array<IOpportunityMeetingTask>>(
      `/salesforce/opportunities/meeting-tasks/all`
    );
  }

  async getMeetingTasksForUser(
    email: string
  ): Promise<IRestResponse<Array<IOpportunityMeetingTask>>> {
    return RestUtilities.get<Array<IOpportunityMeetingTask>>(
      `/salesforce/opportunities/meeting-tasks?email=${encodeURIComponent(
        email
      )}`
    );
  }

  async getLayouts(
    userId: string
  ): Promise<IRestResponse<IOpportunityLayoutsResponseModel>> {
    return RestUtilities.get<IOpportunityLayoutsResponseModel>(
      `/salesforce/opportunities/layouts?userId=${userId}`
    );
  }

  async getFieldMappings(): Promise<
    IRestResponse<Array<IOpportunityFieldMapping>>
  > {
    return RestUtilities.get(`/salesforce/opportunities/fields`);
  }

  async upsertFieldMappings(
    opportunityFields: IOpportunityFieldMapping[]
  ): Promise<IRestResponse<PostPutOpportunityMappingResponseModel>> {
    return RestUtilities.put(
      `/salesforce/opportunities/fields`,
      opportunityFields
    );
  }

  async deleteFieldMappings(): Promise<IRestResponse<any>> {
    return RestUtilities.delete(`/salesforce/opportunities/fields`);
  }

  async putOpportunityMeetingTaskToSalesforce(
    meetingTaskId: string,
    meetingTask: any
  ): Promise<IRestResponse<any>> {
    return RestUtilities.put(
      `/salesforce/opportunities/tasks/${meetingTaskId}/send`,
      {}
    );
  }

  async getTaskDetails(): Promise<
    IRestResponse<IOpportunityLayoutsResponseModel>
  > {
    return RestUtilities.get<IOpportunityLayoutsResponseModel>(
      `/salesforce/tasks/details`
    );
  }
}
