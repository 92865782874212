// Why this class and not .env files?
// Using configuration that is returned dynamically based on the host allows us to build once and deploy anywhere
// Because we are dockerizing and deploying into kubernetes we cannot dynamically choose which .env file we want to use
// https://devops.com/build-once-deploy-anywhere/


export default class GlobalConfig {
  public static get IDENTITY_AUTHORITY(): string {
    if (window.location.host.includes('localhost')) {
      return 'localhost:5000';
    } else {
      return `${window.location.host}/auth`;
    }
  }

  public static get API_HOST(): string {
    if (window.location.host.includes('localhost')) {
      return 'localhost:8081';
    } else {
      return window.location.host;
    }
  }

  public static get SPA_HOST(): string {
    if (window.location.host.includes('localhost')) {
      return 'localhost:3000';
    } else {
      return window.location.host;
    }
  }

  public static get BASE_PATH(): string {
    if (window.location.host.includes('localhost')) {
      return '';
    } else {
      return '/api';
    }
  }

  public static get PUSHER_API_KEY(): string {
    if (window.location.host.includes('localhost')) {
      return '80b96f8c2bc15e838f3c';
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return '80b96f8c2bc15e838f3c';
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return '078e1bdef75b4dd8ca3b';
    } else {
      return 'c8942933f643ff7f93e2';
    }
  }

  public static get GMAIL_OAUTH_CREDENTIAL(): {
    CLIENT_ID: string;
    REDIRECT_URI: string;
  } {
    if (window.location.host.includes('localhost')) {
      return {
        CLIENT_ID:
          '359226748799-cj2f5d6iqr8fjalpcevugh5g57h1sksk.apps.googleusercontent.com',
        REDIRECT_URI: 'https://localhost:3000/integrations/gmail/authorize',
      };
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return {
        CLIENT_ID:
          '359226748799-cj2f5d6iqr8fjalpcevugh5g57h1sksk.apps.googleusercontent.com',
        REDIRECT_URI: 'https://my-dev.cquence.app/integrations/gmail/authorize',
      };
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return {
        CLIENT_ID:
          '359226748799-cj2f5d6iqr8fjalpcevugh5g57h1sksk.apps.googleusercontent.com',
        REDIRECT_URI:
          'https://my-staging.cquence.app/integrations/gmail/authorize',
      };
    } else {
      return {
        CLIENT_ID:
          '359226748799-cj2f5d6iqr8fjalpcevugh5g57h1sksk.apps.googleusercontent.com',
        REDIRECT_URI: 'https://my.cquence.app/integrations/gmail/authorize',
      };
    }
  }

  public static get ZOOM_OAUTH_CREDENTIAL(): {
    CLIENT_ID: string;
    REDIRECT_URI: string;
  } {
    if (window.location.host.includes('localhost')) {
      return {
        CLIENT_ID: 'AitUMkD3RjqrWGb6Tfdv_w',
        REDIRECT_URI: 'https://localhost:3000/integrations/zoom/authorize',
      };
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return {
        CLIENT_ID: 'zkIjXzx_Rre93e8whjesgQ',
        REDIRECT_URI: 'https://my-dev.cquence.app/integrations/zoom/authorize',
      };
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return {
        CLIENT_ID: 'nZH2o8wXSSeII1ZROL1u3Q',
        REDIRECT_URI:
          'https://my-staging.cquence.app/integrations/zoom/authorize',
      };
    } else {
      return {
        CLIENT_ID: 'xO_Sd6sMSviPRkkQdU12uQ',
        REDIRECT_URI: 'https://my.cquence.app/integrations/zoom/authorize',
      };
    }
  }

  public static get SALESFORCE_OAUTH_CREDENTIAL(): {
    CLIENT_ID: string;
    REDIRECT_URI: string;
  } {
    if (window.location.host.includes('localhost')) {
      return {
        CLIENT_ID:
          '3MVG9sn24bYFReCVkmqGWAHzAP9ySqiTZCfS9KoM0uxnd.SJWyx0UfzHTRYLBC2QtoUiHay9WPWlpBt9aI6uG',
        REDIRECT_URI:
          'https://localhost:3000/integrations/salesforce/authorize',
      };
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return {
        CLIENT_ID:
          '3MVG9sn24bYFReCVkmqGWAHzAP9ySqiTZCfS9KoM0uxnd.SJWyx0UfzHTRYLBC2QtoUiHay9WPWlpBt9aI6uG',
        REDIRECT_URI:
          'https://my-dev.cquence.app/integrations/salesforce/authorize',
      };
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return {
        CLIENT_ID:
          '3MVG9sn24bYFReCVkmqGWAHzAP3G9sE1XLqyHFJbwngVBup7QfiAGooiQifFw36sR_wa3G1rMJ.nY0KVxGLz.',
        REDIRECT_URI:
          'https://my-staging.cquence.app/integrations/salesforce/authorize',
      };
    }

    return {
      CLIENT_ID:
        '3MVG9sn24bYFReCVkmqGWAHzAPzxgICHhioROKC7lNxuczSlvaqF3.3Sj_C_0OPHjB_bhjgIZ6CwrIDLjy3jP',
      REDIRECT_URI: 'https://my.cquence.app/integrations/salesforce/authorize',
    };
  }

  public static get LOGROCKET_APP_ID(): string {
    if (window.location.host.includes('localhost')) {
      return 'hxdblu/web-app-local';
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return 'hxdblu/web-app-development-nhfyf';
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return 'hxdblu/web-app-staging-h7esj';
    } else {
      return 'hxdblu/web-app-lg2ve';
    }
  }

  public static get DATADOG_CLIENT_TOKEN(): string {
    if (window.location.host.includes('localhost')) {
      return 'pub771f8dfd63e448f4a437e9dcb769b783';
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return 'pub771f8dfd63e448f4a437e9dcb769b783';
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return 'pub4497160b0dd81c53ee565fcd73a2e4f6';
    } else {
      return 'pub4497160b0dd81c53ee565fcd73a2e4f6';
    }
  }

  public static get ENVIRONMENT(): string {
    if (window.location.host.includes('localhost')) {
      return 'local';
    } else if (window.location.host.includes('my-dev.cquence.app')) {
      return 'development';
    } else if (window.location.host.includes('my-staging.cquence.app')) {
      return 'staging';
    } else {
      return 'production';
    }
  }
}
