import * as React from 'react';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { Box, Tab, Tabs, Theme, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { OpportunityMeetingTasksState } from '../reducers/OpportunityMeetingTasksReducer';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import TabPanel from '../tabs/TabPanel';
import SupportTabView from './SupportTabView';
import { ILayoutItem } from '../../models/app/opportunities/IOpportunityLayoutDetailsResponseModel';
import { userIsInRole } from '../../helpers/Helpers';
import { User } from 'oidc-client';
import { RoleEnum } from '../../models/RoleEnum';
import ExternalUserTabView from './ExternalUserTabView';

const styles = (theme: Theme) =>
  createStyles({
    form: {
      paddingLeft: theme.spacingNumber(2),
      paddingRight: theme.spacingNumber(2),
    },
    title: {
      marginBottom: theme.spacingNumber(2),
    },
    width100TemporaryChromiumFix: {
      width: '100%',
    },
    toolbar: {
      height: theme.toolbar.height * 1.5,
    },

    header: {
      position: 'sticky',
      backgroundColor: 'white',
      top: '0px',
      zIndex: 2001,
      borderBottom: '1px solid lightgrey',
    },
  });

interface OpportunityMeetingTaskDrawerContentProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  theme: Theme;
  opportunityMeetingTasksState?: OpportunityMeetingTasksState;
  user: User;
}

interface OpportunityMeetingTaskDrawerContentState {
  tabValue: number;
  layoutFields: Array<ILayoutItem>;
}

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityMeetingTasksState: state.opportunityMeetingTasksState,
    user: state.oidc.user as User,
  };
};

class OpportunityMeetingTaskDrawerContent extends React.Component<
  OpportunityMeetingTaskDrawerContentProps,
  OpportunityMeetingTaskDrawerContentState
> {
  state = {
    tabValue:
      userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN) ||
      userIsInRole(this.props.user, RoleEnum.SUPPORT)
        ? 1
        : 0,
  } as OpportunityMeetingTaskDrawerContentState;

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div>
          <div className={classes.header}>
            <div className={classes.toolbar} />
            <div className={classes.form}>
              <Typography className={classes.title} variant="h5" align="left">
                {
                  this.props.opportunityMeetingTasksState
                    ?.selectedOpportunityTask?.meetingTopic
                }
              </Typography>
            </div>
          </div>
          {userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN) ? (
            <>
              <Box>
                <div className={classes.form}>
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="External User" />
                    <Tab label="Support" />
                  </Tabs>
                </div>
              </Box>
              <TabPanel value={this.state.tabValue} index={0}>
                <ExternalUserTabView />
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <SupportTabView />
              </TabPanel>
            </>
          ) : userIsInRole(this.props.user, RoleEnum.SUPPORT) ? (
            <SupportTabView />
          ) : (
            <ExternalUserTabView />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withSnackbar(
    withRouter(
      withStyles(styles, { withTheme: true })(
        OpportunityMeetingTaskDrawerContent
      )
    )
  )
);
