import * as React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import Header from './header/Header';
import Menu from './menu/Menu';
import { connect } from 'react-redux';
import { CssBaseline, Fade, Theme } from '@mui/material';
import Dialog from './dialog/Dialog';
import { User } from 'oidc-client';
import BreadcrumbPath from '../models/app/BreadcrumbPath';
import BreadcrumbPaths from '../routing/BreadcrumbPaths';
import Breadcrumb from '../models/app/Breadcrumb';
import Constants from '../theme/Constants';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { ApplicationState } from './store';
import { Outlet } from 'react-router-dom';
import authorizedRoute from './hocs/AuthorizedRouteHOC';
import { UserProfile } from '../models/UserProfile';
import { withOrganizationProps } from './hocs/PopulateOrganizationNameHOC';
import CquenceDrawer from './drawer/CquenceDrawer';
import { withRouter, WithRouterProps } from './hocs/withRouter';
import populateIntegrationsHOC from './hocs/PopulateIntegrationsHOC';
import { isExtension } from '../helpers/Helpers';

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    toolbarForExtension: {
      height: 0,
    },
    root: {
      display: 'flex',
      height: '100%',
    },
    content: {
      flexGrow: 1,
    },
  });

interface AppLayoutProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps,
    withOrganizationProps {
  user: User;
  exact?: boolean;
  breadcrumbPath?: BreadcrumbPath;
  setTenantId: (tenantId: string) => void;
}

interface AppLayoutState {
  mobileOpen: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
  };
};

class AppLayout extends React.Component<AppLayoutProps, AppLayoutState> {
  state: AppLayoutState = {
    mobileOpen: false,
  };

  componentDidMount() {
    this.props.setTenantId((this.props.user?.profile as UserProfile).tenantId);
  }

  handleDrawerToggle = () => {
    this.setState(() => ({ mobileOpen: !this.state.mobileOpen }));
  };

  completeBreadcrumbsWithProjectName = () => {
    return [new Breadcrumb('', '')];
  };

  render() {
    const { classes, path } = this.props;
    const extension = isExtension(path);
    const breadcrumbs = BreadcrumbPaths.GetBreadcrumbsForPath(path);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Dialog />
        <CquenceDrawer />
        {!extension && (
          <Header
            breadcrumbs={breadcrumbs}
            handleDrawerToggle={this.handleDrawerToggle}
          />
        )}
        {!extension && (
          <Menu
            handleDrawerToggle={this.handleDrawerToggle}
            mobileOpen={this.state.mobileOpen}
          />
        )}
        <Fade in timeout={550}>
          <main className={classes.content}>
            <div
              className={
                extension ? classes.toolbarForExtension : classes.toolbar
              }
            />
            <Outlet />
          </main>
        </Fade>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(
  authorizedRoute(
    withRouter(
      withStyles(styles)(populateIntegrationsHOC(withSnackbar(AppLayout)))
    )
  )
);
