import * as React from "react";
import { Theme, Grid, TableFooter } from "@mui/material";
import { withStyles, createStyles, WithStyles, withTheme } from "@mui/styles";
import { capitalCase } from "change-case";
import { User } from "oidc-client";
import { connect } from "react-redux";
import { getLogoForIntegrationType } from "../../helpers/Helpers";
import { IntegrationEnum } from "../../models/app/integrations/IntegrationEnum";
import { MergedIntegrationType } from "../../models/app/integrations/MergedIntegrationTypes";
import RoutePaths from "../../routing/RoutePaths";
import populateIntegrationsHOC from "../hocs/PopulateIntegrationsHOC";
import CustomAddToolbar from "../muiDataTable/CustomAddToolbar";
import {
  IntegrationsState,
  addIntegration,
} from "../reducers/IntegrationReducer";
import { ApplicationState } from "../store";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import format from "date-fns/format";

import TableImage from "./TableImage";

const MUIDataTable = require("mui-datatables").default;

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2),
    },
    footer: {
      display: "block",
      height: "54px",
    },
    tableLogoWidth: {
      width: "100px",
    },
  });

interface IntegrationsProps
  extends WithStyles<typeof styles>, WithRouterProps
    {
  user: User;
  integrationsState: IntegrationsState;
  addIntegration: (integration: MergedIntegrationType) => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    integrationsState: state.integrationsState,
    user: state.oidc.user as User,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addIntegration: (integration: MergedIntegrationType) => {
      const action = addIntegration(integration);
      dispatch(action);
    },
  };
};

class Integrations extends React.Component<IntegrationsProps> {
  refs: {
    query: HTMLInputElement;
  };

  editIntegration = (rowData: string[]) => {
    const integrationType = capitalCase(rowData[2]);
    switch (integrationType) {
      case IntegrationEnum.GONG: {
        this.props.navigate(
          `${RoutePaths.IntegrationsEditGong}?id=${rowData[0]}`
        );
        break;
      }
      case IntegrationEnum.ZOOM: {
        this.props.navigate(
          `${RoutePaths.IntegrationsEditZoom}?id=${rowData[0]}`
        );
        break;
      }
      case IntegrationEnum.SALESFORCE: {
        this.props.navigate(
          `${RoutePaths.IntegrationsEditSalesforce}?id=${rowData[0]}`
        );
        break;
      }
      case IntegrationEnum.GMAIL: {
        this.props.navigate(
          `${RoutePaths.IntegrationsEditGmail}?id=${rowData[0]}`
        );
        break;
      }
      case IntegrationEnum.HUBSPOT: {
        this.props.navigate(
          `${RoutePaths.IntegrationsEditHubspot}?id=${rowData[0]}`
        );
        break;
      }
    }
  };

  redirectToNewIntegrationForm = () => {
    this.props.navigate(RoutePaths.IntegrationsNew);
  };

  mapIntegrationsToTableIntegrations(
    integrations: MergedIntegrationType[]
  ): Array<Array<any>> {
    const tableIntegrations = new Array<Array<any>>();
    if (integrations) {
      integrations.forEach((value) => {
        tableIntegrations.push([
          value.id,
          <TableImage image={getLogoForIntegrationType(value.type)} />,
          capitalCase(value.type),
          value.createdByEmail,
          format(new Date(value.createdOn), "PPPP"),
          value.updatedOn ? format(new Date(value.updatedOn), "PPPP") : "N/A",
        ]);
      });
    }
    return tableIntegrations;
  }

  columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "",
      options: {
        setCellProps: () => {
          return { className: this.props.classes.tableLogoWidth };
        },
      },
    },
    {
      name: "Integration",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Added By",
      options: {
        filter: true,
      },
    },
    {
      name: "Added On",
      options: {
        filter: true,
      },
    },
    {
      name: "Updated On",
      options: {
        filter: true,
      },
    },
  ];

  options = {
    textLabels: {
      body: {
        noMatch: "No integrations have been added yet.",
      },
    },
    print: false,
    download: false,
    customToolbar: () => {
      return <CustomAddToolbar onClick={this.redirectToNewIntegrationForm} />;
    },
    elevation: 3,
    selectableRows: "none",
    responsive: "simple",
    viewColumns: false,
    tableBodyMaxHeight: "calc(100vh - 64px - 48px - 64px - 52px)",
    onRowClick: this.editIntegration,
    filter: false,
    search: true,
    sort: true,
  };

  render() {
    const { classes, integrationsState } = this.props;

    return (
      <Grid className={classes.grid} container alignItems="center">
        <Grid item xs={12}>
          <MUIDataTable
            title={"Integrations"}
            data={this.mapIntegrationsToTableIntegrations(
              integrationsState.integrations
            )}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, {withTheme: true})(populateIntegrationsHOC(Integrations))));
