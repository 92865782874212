import * as React from 'react';
import {
  Drawer,
  Theme,
  List,
  ListItemText,
  Hidden,
  ListItem,
  ListItemIcon,
  Typography,
  Collapse,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles, WithStyles } from '@mui/styles';
import { createStyles } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Lock from '@mui/icons-material/Lock';
import Domain from 'mdi-material-ui/Domain';
import ArrowDecision from 'mdi-material-ui/ArrowDecision';
import { connect } from 'react-redux';
import { RoleEnum } from '../../models/RoleEnum';
import { User } from 'oidc-client';
import {
  hasIntegrationInstalled,
  userIncludesRole,
  userNotInRole,
  userIsInternal,
} from '../../helpers/Helpers';
import Logout from 'mdi-material-ui/Logout';
import Cog from 'mdi-material-ui/Cog';
import Constants from '../../theme/Constants';
import classNames from 'classnames';
import { red } from '@mui/material/colors';
import RoutePaths from '../../routing/RoutePaths';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import { ApplicationState } from '../store';
import { expandSection } from '../reducers/MenuReducer';
import {
  Article,
  People,
  SwapHoriz,
  TaskAlt,
  Webhook,
  CloudUpload,
  Subject,
} from '@mui/icons-material';
import { IntegrationsState } from '../reducers/IntegrationReducer';
import { IntegrationEnum } from '../../models/app/integrations/IntegrationEnum';

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: theme.drawer.width,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      border: 'none',
      [theme.breakpoints.up('md')]: {
        width: theme.drawer.width,
      },
      zIndex: 1098,
    },
    headerPush: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    logoButtonRoot: {
      '&:hover': {
        background: 'none',
      },
    },
    badge: {
      height: '100%',
      right: '-35px',
    },
    badgeTypography: {
      lineHeight: 'inherit',
    },
    selected: {
      backgroundColor: Constants.COLORS.LIST_TABLE_HOVER_COLOR,
    },
    expandIcon: {
      transition: theme.transitions.create(['transform'], {
        duration: 500,
      }),
    },
    expanded: {
      transform: 'rotate(-180deg)',
    },
    closed: {
      transform: 'rotate(0)',
    },
    red: {
      color: red[500],
    },
    tinyIcon: {
      fontSize: '.75rem',
    },
  });

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
    currentSelection: state.menu.currentSelection,
    canAccessUsersPage: true,
    integrations: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchMenuSelection: (section: string) => {
      const action = expandSection({ currentSelection: section });
      dispatch(action);
    },
  };
};

interface MenuProps extends WithStyles<typeof styles>, WithRouterProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  theme: Theme;
  user: User;
  dispatchMenuSelection: (section: string) => void;
  integrations: IntegrationsState;
  canAccessUsersPage: boolean;
  currentSelection: string;
}

class Menu extends React.Component<MenuProps> {
  handleMenuToggle = (name: string) => {
    const currentSelection = this.props.currentSelection === name ? '' : name;
    this.props.dispatchMenuSelection(currentSelection);
  };

  handleMenuNavigation = (path: string, currentSelection: string) => {
    let pushPath = path;
    this.handleMenuToggle(currentSelection);
    this.props.navigate(pushPath);
  };

  render() {
    const { classes, theme, user, integrations } = this.props;

    // const showAiSearch = userIsInternal(user);
    const showAiSearch = false;

    const showOpportunityTasks =
      hasIntegrationInstalled(integrations, IntegrationEnum.SALESFORCE) ||
      (hasIntegrationInstalled(integrations, IntegrationEnum.HUBSPOT) &&
        userIncludesRole(this.props.user, [
          RoleEnum.SUPPORT,
          RoleEnum.SUPPORTADMIN,
        ]));

    const drawerContent = (
      <React.Fragment>
        <List>
          {showAiSearch && (
            <ListItem
              className={
                this.props.currentSelection === 'aiSearch'
                  ? classes.selected
                  : ''
              }
              button
              onClick={() =>
                this.handleMenuNavigation(RoutePaths.AiSearch, 'aiSearch')
              }
            >
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="AI Search" />
            </ListItem>
          )}
          {showOpportunityTasks && (
            <ListItem
              className={
                this.props.currentSelection === 'opportunityTasks'
                  ? classes.selected
                  : ''
              }
              button
              onClick={() =>
                this.handleMenuNavigation(
                  RoutePaths.OpportunityTasks,
                  'opportunityTasks'
                )
              }
            >
              <ListItemIcon>
                <TaskAlt />
              </ListItemIcon>
              <ListItemText primary="Post-Meeting Tasks" />
            </ListItem>
          )}
          {userNotInRole(this.props.user, RoleEnum.SUPPORT) && (
            <ListItem
              className={
                this.props.currentSelection === 'integrations'
                  ? classes.selected
                  : ''
              }
              id="integrations"
              button
              onClick={() =>
                this.handleMenuNavigation(
                  RoutePaths.Integrations,
                  'integrations'
                )
              }
            >
              <ListItemIcon>
                <ArrowDecision />
              </ListItemIcon>
              <ListItemText primary="Integrations" />
            </ListItem>
          )}
          {userIncludesRole(this.props.user, [RoleEnum.SUPPORTADMIN]) &&
            hasIntegrationInstalled(
              this.props.integrations,
              IntegrationEnum.SALESFORCE
            ) && (
              <React.Fragment>
                <ListItem
                  className={
                    this.props.currentSelection === 'salesforce'
                      ? classes.selected
                      : ''
                  }
                  id="salesforce"
                  button
                  onClick={() => this.handleMenuToggle('salesforce')}
                >
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  <ListItemText primary="Mapping" />
                  <ExpandLess
                    className={classNames(
                      classes.expandIcon,
                      this.props.currentSelection.startsWith('salesforce')
                        ? classes.expanded
                        : classes.closed
                    )}
                  />
                </ListItem>
                <Collapse
                  in={this.props.currentSelection.startsWith('salesforce')}
                  timeout={500}
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      className={classNames(
                        classes.nested,
                        this.props.currentSelection ===
                          'salesforce.opportunities'
                          ? classes.selected
                          : ''
                      )}
                      button
                      onClick={() =>
                        this.handleMenuNavigation(
                          RoutePaths.Opportunities,
                          'salesforce.opportunities'
                        )
                      }
                    >
                      <ListItemIcon>
                        <People />
                      </ListItemIcon>
                      <ListItemText primary="Salesforce Opportunities" />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            )}
          {userIncludesRole(this.props.user, [
            RoleEnum.ADMIN,
            RoleEnum.OWNER,
            RoleEnum.SUPPORTADMIN,
          ]) && (
            <React.Fragment>
              <ListItem
                className={
                  this.props.currentSelection === 'administration'
                    ? classes.selected
                    : ''
                }
                id="administration"
                button
                onClick={() => this.handleMenuToggle('administration')}
              >
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary="Administration" />
                <ExpandLess
                  className={classNames(
                    classes.expandIcon,
                    this.props.currentSelection.startsWith('administration')
                      ? classes.expanded
                      : classes.closed
                  )}
                />
              </ListItem>
              <Collapse
                in={this.props.currentSelection.startsWith('administration')}
                timeout={500}
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {this.props.canAccessUsersPage && (
                    <ListItem
                      className={classNames(
                        classes.nested,
                        this.props.currentSelection === 'administration.users'
                          ? classes.selected
                          : ''
                      )}
                      button
                      onClick={() =>
                        this.handleMenuNavigation(
                          RoutePaths.Users,
                          'administration.users'
                        )
                      }
                    >
                      <ListItemIcon>
                        <People />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                  )}
                  {userIncludesRole(this.props.user, [
                    RoleEnum.ADMIN,
                    RoleEnum.OWNER,
                    RoleEnum.SUPPORTADMIN,
                  ]) && (
                    <ListItem
                      className={classNames(
                        classes.nested,
                        this.props.currentSelection ===
                          'administration.organization'
                          ? classes.selected
                          : ''
                      )}
                      button
                      onClick={() =>
                        this.handleMenuNavigation(
                          RoutePaths.Organization,
                          'administration.organization'
                        )
                      }
                    >
                      <ListItemIcon>
                        <Domain />
                      </ListItemIcon>
                      <ListItemText primary="Organization" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          )}
          {userIncludesRole(this.props.user, [
            RoleEnum.SUPPORT,
            RoleEnum.SUPPORTADMIN,
          ]) && (
            <React.Fragment>
              <ListItem
                className={
                  this.props.currentSelection === 'templates'
                    ? classes.selected
                    : ''
                }
                id="templates"
                button
                onClick={() => this.handleMenuToggle('templates')}
              >
                <ListItemIcon>
                  <Article />
                </ListItemIcon>
                <ListItemText primary="Templates" />
                <ExpandLess
                  className={classNames(
                    classes.expandIcon,
                    this.props.currentSelection.startsWith('templates')
                      ? classes.expanded
                      : classes.closed
                  )}
                />
              </ListItem>
              <Collapse
                in={this.props.currentSelection.startsWith('templates')}
                timeout={500}
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {this.props.canAccessUsersPage && (
                    <ListItem
                      className={classNames(
                        classes.nested,
                        this.props.currentSelection === 'templates.templates'
                          ? classes.selected
                          : ''
                      )}
                      button
                      onClick={() =>
                        this.handleMenuNavigation(
                          RoutePaths.Templates,
                          'templates.templates'
                        )
                      }
                    >
                      <ListItemIcon>
                        <Article />
                      </ListItemIcon>
                      <ListItemText primary="Templates" />
                    </ListItem>
                  )}
                  <ListItem
                    className={classNames(
                      classes.nested,
                      this.props.currentSelection === 'templates.variables'
                        ? classes.selected
                        : ''
                    )}
                    button
                    onClick={() =>
                      this.handleMenuNavigation(
                        RoutePaths.TemplateVariables,
                        'templates.variables'
                      )
                    }
                  >
                    <ListItemIcon>
                      <SwapHoriz />
                    </ListItemIcon>
                    <ListItemText primary="Variables" />
                  </ListItem>
                  {userIncludesRole(this.props.user, [
                    RoleEnum.SUPPORTADMIN,
                  ]) && (
                    <ListItem
                      className={classNames(
                        classes.nested,
                        this.props.currentSelection === 'templates.phrases'
                          ? classes.selected
                          : ''
                      )}
                      button
                      onClick={() =>
                        this.handleMenuNavigation(
                          RoutePaths.UploadCustomPhrases,
                          'templates.phrases'
                        )
                      }
                    >
                      <ListItemIcon>
                        <Domain />
                      </ListItemIcon>
                      <ListItemText primary="Custom Phrases" />
                    </ListItem>
                  )}
                  <ListItem
                    className={classNames(
                      classes.nested,
                      this.props.currentSelection ===
                        'templates.uploadRecording'
                        ? classes.selected
                        : ''
                    )}
                    button
                    onClick={() =>
                      this.handleMenuNavigation(
                        RoutePaths.UploadRecording,
                        'templates.uploadRecording'
                      )
                    }
                  >
                    <ListItemIcon>
                      <CloudUpload />
                    </ListItemIcon>
                    <ListItemText primary="Recording File" />
                  </ListItem>
                  <ListItem
                    className={classNames(
                      classes.nested,
                      this.props.currentSelection ===
                        'templates.uploadTranscript'
                        ? classes.selected
                        : ''
                    )}
                    button
                    onClick={() =>
                      this.handleMenuNavigation(
                        RoutePaths.UploadTranscript,
                        'templates.uploadTranscript'
                      )
                    }
                  >
                    <ListItemIcon>
                      <Subject />
                    </ListItemIcon>
                    <ListItemText primary="Recording Transcript" />
                  </ListItem>
                  <ListItem
                    className={classNames(
                      classes.nested,
                      this.props.currentSelection === 'templates.webhooks'
                        ? classes.selected
                        : ''
                    )}
                    button
                    onClick={() =>
                      this.handleMenuNavigation(
                        RoutePaths.Webhooks,
                        'templates.webhooks'
                      )
                    }
                  >
                    <ListItemIcon>
                      <Webhook />
                    </ListItemIcon>
                    <ListItemText primary="Webhooks" />
                  </ListItem>
                </List>
              </Collapse>
            </React.Fragment>
          )}

          <Hidden mdUp implementation="css">
            <ListItem
              id="settings"
              button
              onClick={() => this.handleMenuToggle('settings')}
            >
              <ListItemIcon>
                <Cog />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              <ExpandLess
                className={classNames(
                  classes.expandIcon,
                  this.props.currentSelection.startsWith('administration')
                    ? classes.expanded
                    : classes.closed
                )}
              />
            </ListItem>
            <Collapse
              in={this.props.currentSelection === 'settings'}
              timeout={500}
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  className={classNames(
                    classes.nested,
                    this.props.currentSelection === 'settings.account'
                      ? classes.selected
                      : ''
                  )}
                  button
                  onClick={() =>
                    this.handleMenuNavigation(
                      RoutePaths.Account,
                      'settings.account'
                    )
                  }
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary="Account" />
                </ListItem>

                <ListItem
                  className={classNames(
                    classes.nested,
                    this.props.currentSelection === 'settings.logout'
                      ? classes.selected
                      : ''
                  )}
                  button
                  onClick={() => {
                    this.props.navigate(RoutePaths.Logout);
                  }}
                >
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Collapse>
          </Hidden>
        </List>
      </React.Fragment>
    );

    return (
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={'top'}
            open={this.props.mobileOpen}
            onClose={this.props.handleDrawerToggle}
            SlideProps={{
              timeout: {
                enter: theme.drawer.enterDuration,
                exit: theme.drawer.leavingDuration,
              },
            }}
          >
            <Button
              classes={{ root: classes.logoButtonRoot }}
              disableRipple={true}
              onClick={() => this.props.navigate(RoutePaths.Landing)}
            >
              <Typography variant="h5">Cquence</Typography>
            </Button>
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            elevation={3}
            anchor={'left'}
            variant="permanent"
            open
          >
            <div className={classes.headerPush} />
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(Menu)));
