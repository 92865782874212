import * as React from "react";
import { Theme, Paper, InputAdornment, Typography } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import FormikTextField from "../../form/FormikTextField";
import FormikSynchronousButton from "../../form/FormikSynchronousButton";
import TenantService from "../../../services/TenantService";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { IRestResponse } from "../../../services/RestUtilities";
import RegularExpressions from "../../../helpers/RegularExpressions";
import RoutePaths from "../../../routing/RoutePaths";
import GlobalConfig from "../../../GlobalConfig";

const tenantService = new TenantService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      paddingTop: "3%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(450 + theme.spacingNumber(2 * 2))]: {
        width: 550,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      padding: theme.spacing(4),
    },
    title: {
      margin: theme.spacing(5),
    },
  });

interface EnterDomainProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps {}
type EnterDomainState = {
  isSuccess: boolean;
};

type Domain = {
  domain: string;
};

class EnterDomain extends React.Component<EnterDomainProps, EnterDomainState> {
  state = {
    isSuccess: false,
  };

  validationSchema = Yup.object().shape({
    domain: Yup.string()
      .min(3, "Min 3 characters")
      .max(28, "Max 28 characters")
      .matches(
        new RegExp(RegularExpressions.ORGANIZATION_DOMAIN),
        "Must begin, end, and contain alphanumeric characters. May contain ( - )."
      )
      .required("Required"),
  });

  render() {
    const { classes, enqueueSnackbar } = this.props;
    return (
      <Formik
        initialValues={{ domain: "" } as Domain}
        onSubmit={(values: Domain, formikHelpers: FormikHelpers<Domain>) => {
          const domain = values.domain;
          tenantService.confirmed(domain).then((v: IRestResponse<boolean>) => {
            if (v.isError) {
              enqueueSnackbar("Could not find the requested domain", {
                variant: "error",
              });
              formikHelpers.setSubmitting(false);
            } else {
              if (v.result) {
                this.setState({ isSuccess: true });
                enqueueSnackbar("The domain was successfully found", {
                  variant: "success",
                });
                const loginPath =
                  "https://" +
                  domain +
                  "." +
                  GlobalConfig.SPA_HOST +
                  RoutePaths.Login;
                window.location.assign(loginPath);
              } else {
                enqueueSnackbar("Could not find the requested domain", {
                  variant: "error",
                });
                formikHelpers.setSubmitting(false);
              }
            }
          });
        }}
        validateOnMount={false}
        isInitialValid={false}
        validationSchema={this.validationSchema}
      >
        {(props) => (
          <div className={classes.layout}>
            <Paper className={classes.paper} elevation={3}>
              <Typography className={classes.title} align="center" variant="h4">
                Welcome to Cquence.
              </Typography>
            </Paper>
          </div>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(withSnackbar(EnterDomain));
