import {
  ComponentsOverrides,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import Constants from './Constants';

import { Theme } from '@mui/material/styles';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    spacingNumber: (value: number) => number;
    drawer: {
      width: React.CSSProperties['width'];
      text: {
        color: React.CSSProperties['color'];
      };
      enterDuration: number;
      leavingDuration: number;
    };
    toolbar: {
      height: number;
    };
    notistack: {
      width: React.CSSProperties['width'];
    };
  }
  interface ThemeOptions {
    drawer?: {
      width?: React.CSSProperties['width'];
      text?: {
        color: React.CSSProperties['color'];
      };
      enterDuration?: number;
      leavingDuration?: number;
    };
    toolbar?: {
      height?: number;
    };
    notistack?: {
      width: React.CSSProperties['width'];
    };
  }
}

export default function createCquenceTheme() {
  const createdTheme = responsiveFontSizes(
    createTheme({
      drawer: {
        width: 240,
        text: {
          color: Constants.COLORS.BLACK,
        },
        enterDuration: 350,
        leavingDuration: 450,
      },
      toolbar: {
        height: Constants.HEIGHT.TOOLBAR,
      },
      notistack: {
        width: '100%',
      },
      components: {
        MuiLink: {
          styleOverrides: {
            button: {
              verticalAlign: 'baseline',
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            container: {
              width: '100% !important',
              margin: '0 !important',
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              minWidth: '30%',
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              margin: '0px 16px',
              width: `calc(100% - 32px)`,
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              //https://github.com/gregnb/mui-datatables/issues/748#issuecomment-524245251
              '&$hover:hover': {
                backgroundColor: Constants.COLORS.LIST_TABLE_HOVER_COLOR,
                cursor: 'pointer',
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: '0px',
            },
          },
        },
        MuiBadge: {
          styleOverrides: {
            badge: {
              right: -3,
              border: `2px solid ${Constants.COLORS.WHITE}`,
              padding: '0 4px',
              borderRadius: '3px',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: Constants.COLORS.WHITE,
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paperAnchorDockedLeft: {
              borderRight: 'none',
              boxShadow:
                '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            rounded: {
              borderRadius: '0px',
            },
          },
        },
        MuiTouchRipple: {
          styleOverrides: {
            root: {
              color: Constants.COLORS.PRIMARY_COLOR,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '0px',
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            underline: {
              '&$disabled': {
                '&:before': {
                  borderBottomStyle: 'none',
                },
              },
            },
          },
        },
        MuiSnackbarContent: {
          styleOverrides: {
            action: {
              marginLeft: '0px',
              marginRight: '0px',
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            button: {
              '&:hover': {
                backgroundColor: Constants.COLORS.LIST_TABLE_HOVER_COLOR,
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: Constants.COLORS.PRIMARY_COLOR,
            },
          },
        },
        MUIDataTable: {
          styleOverrides: {
            responsiveBase: {
              height: '100vh !important',
            },
          },
        },
      },
      palette: {
        mode: 'light',
        primary: {
          main: Constants.COLORS.PRIMARY_COLOR,
          contrastText: Constants.COLORS.WHITE,
        },
        secondary: {
          main: Constants.COLORS.SECONDARY_COLOR,
          contrastText: Constants.COLORS.WHITE,
        },
        text: {
          primary: Constants.COLORS.BLACK,
        },
        background: {
          default: '#fafafa',
        },
        error: {
          main: Constants.COLORS.ERROR_COLOR,
        },
      },
      typography: {
        fontFamily: "'Lato', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
        fontWeightBold: 900,
      },
    })
  );
  createdTheme.spacingNumber = (value: number) => {
    return Number(createdTheme.spacing(value).slice(0, -2));
  };
  return createdTheme;
}
