import * as React from 'react';
import { User } from 'oidc-client';
import { CallbackComponent } from 'redux-oidc';
import { Theme, Grid, LinearProgress } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import LogRocket from 'logrocket';
import initLogRocket from '../logrocket';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import RoutePaths from '../../routing/RoutePaths';
import UserManager from './UserManager';
import Constants from '../../theme/Constants';

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      marginTop: '20%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
  });

interface CallbackProps extends WithStyles<typeof styles>, WithRouterProps {}

class Callback extends React.Component<CallbackProps> {
  successCallback = (user: User) => {
    const email = user.profile.email || '';
    if (!email.includes('cquence.app')) {
      initLogRocket();
      LogRocket.identify(user.profile.sub, {
        name: `${user.profile.firstName} ${user.profile.lastName}`,
        email: email,
        tenantId: user.profile.tenantId,
        role: user.profile.role,
      });
    }

    var redirectPath = user.state.path || RoutePaths.Dashboard;
    this.props.navigate(redirectPath);
  };

  errorCallback = (error: Error) => {
    console.log(error);
    this.props.navigate('/');
  };

  render() {
    return (
      <CallbackComponent
        userManager={UserManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div className={this.props.classes.layout}>
          <Grid container spacing={3} alignItems="baseline">
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        </div>
      </CallbackComponent>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Callback));
