import {
  Theme,
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { ArrowLeft } from 'mdi-material-ui';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import React from 'react';
import { connect } from 'react-redux';
import Constants from '../../../theme/Constants';
import RoutePaths from '../../../routing/RoutePaths';
import IntegrationService from '../../../services/IntegrationService';
import FormikSelectValues from '../../form/interfaces/FormikSelectValuesProp';
import { withRouter, WithRouterProps } from '../../hocs/withRouter';
import { addIntegration } from '../../reducers/IntegrationReducer';
import integrationForm from './IntegrationFormHOC';
import HubspotIntegration from '../../../models/app/integrations/implementations/HubspotIntegration';
import { IntegrationEnum } from '../../../models/app/integrations/IntegrationEnum';
import { ApplicationState } from '../../store';
import { IntegrationsState } from '../../reducers/IntegrationReducer';
import FormikDeleteButton from '../../form/FormikDeleteButton';
import FormikSynchronousButton from '../../form/FormikSynchronousButton';
import FormikTextField from '../../form/FormikTextField';
import { IFormikErrors, IRestResponse } from '../../../services/RestUtilities';
import PostIntegrationResponse from '../../../models/app/integrations/PostIntegrationResponse';
import * as Yup from 'yup';

const integrationService = new IntegrationService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    return: {
      position: 'sticky',
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    title: {
      marginTop: '0px',
      padding: '0px',
    },
    addBtn: {
      marginTop: theme.spacing(3),
    },
    addDate: {
      padding: theme.spacing(1),
      color: Constants.COLORS.PRIMARY_COLOR,
      fontStyle: 'italic',
      position: 'relative',
      top: '10px',
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
  });

interface IHubspotIntegrationFormState {
  serverErrors?: IFormikErrors;
}
interface IHubspotIntegrationFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  editIntegration: HubspotIntegration;
  save: (
    formikBag: FormikHelpers<HubspotIntegration>,
    integration: HubspotIntegration
  ) => Promise<IRestResponse<PostIntegrationResponse>>;
  update: (
    formikBag: FormikHelpers<HubspotIntegration>,
    integration: HubspotIntegration
  ) => Promise<IRestResponse<void>>;
  delete: (
    formikProps: FormikProps<HubspotIntegration>
  ) => Promise<IRestResponse<void>>;
  environmentSelectValuesArray: FormikSelectValues;
  isSuccess: boolean;
  isPendingCreation: boolean;
  serverErrors: string[];
  canDelete: boolean;
  integrationsState: IntegrationsState;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchAddIntegration: (integration: HubspotIntegration) => {
      const action = addIntegration(integration);
      dispatch(action);
    },
  };
};

class HubspotIntegrationForm extends React.Component<
  IHubspotIntegrationFormProps,
  IHubspotIntegrationFormState
> {
  state = {
    serverErrors: undefined,
  };

  validationSchema = Yup.object().shape({
    token: Yup.string().required('Required').max(512, 'Max 512 characters'),
  });

  render() {
    const { classes } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={
          this.props.editIntegration
            ? ({
                ...this.props.editIntegration,
              } as unknown as HubspotIntegration)
            : ({
                type: IntegrationEnum.HUBSPOT,
                token: '',
              } as unknown as HubspotIntegration)
        }
        validateOnMount={true}
        initialErrors={{}}
        onSubmit={(
          values: HubspotIntegration,
          formikBag: FormikHelpers<HubspotIntegration>
        ) => {
          const newIntegration = new HubspotIntegration();
          newIntegration.token = values.token;
          this.props.editIntegration
            ? this.props.update(formikBag, newIntegration).then(r => {
                if (r.isError) {
                  formikBag.resetForm();
                }
              })
            : this.props.save(formikBag, newIntegration);
          this.setState({ serverErrors: undefined });
        }}
      >
        {props => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              onClick={() => this.props.navigate(RoutePaths.Integrations)}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              align="left"
              variant="h5"
            >
              HubSpot Integration
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Integration Details
              </Typography>
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      infoText="The Hubspot Token."
                      name="token"
                      label="Token"
                      value={props.values.token}
                      errorText={props.errors.token}
                      touched={props.touched.token}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  {this.props.serverErrors && (
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          {this.props.serverErrors.map(error => (
                            <ListItemText primary={error} />
                          ))}
                        </ListItem>
                      </List>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item>
                      <FormikSynchronousButton
                        variant="outlined"
                        isValid={props.isValid && props.dirty}
                        isSubmitting={props.isSubmitting}
                        isSuccess={this.props.isSuccess}
                      >
                        {props.initialValues.token === '' ? 'Create' : 'Update'}
                      </FormikSynchronousButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
            {this.props.editIntegration && this.props.canDelete && (
              <React.Fragment>
                <Paper
                  className={classNames(classes.bottomPaper, classes.paper)}
                  elevation={3}
                >
                  <Typography variant="h6">Delete</Typography>
                  <Typography variant="subtitle1">
                    Remove the integration
                  </Typography>
                  <Grid container>
                    <Grid item>
                      {this.props.editIntegration && (
                        <FormikDeleteButton
                          isSubmitting={props.isSubmitting}
                          onConfirm={() => this.props.delete(props)}
                          dialogTitle="Delete integration?"
                          confirmText="Delete"
                          dialogContent={
                            'Are you sure you want to delete the HubSpot integration?'
                          }
                        >
                          Delete
                        </FormikDeleteButton>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  integrationForm(
    withStyles(styles)(withSnackbar(withRouter(HubspotIntegrationForm)))
  )
);
