import * as React from "react";
import { Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";

import RoutePaths from "../../routing/RoutePaths";
import IntegrationService from "../../services/IntegrationService";
import SalesforceIntegration from "../../models/app/integrations/implementations/SalesforceIntegration";
import { withSnackbar, WithSnackbarProps } from "notistack";
import Loading from "../loading/Loading";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import Constants from "../../theme/Constants";

const integrationService = new IntegrationService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      paddingTop: "20%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
  });

interface AuthorizeSalesforceProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  message?: string;
  wasError?: boolean;
}

interface AuthorizeSalesforceState {
  isLoaded: boolean;
  wasError: boolean;
}

class AuthorizeSalesforce extends React.Component<
  AuthorizeSalesforceProps,
  AuthorizeSalesforceState
> {
  componentDidMount() {
    this.handleSalesforceOAuthCallback();
  }

  state: AuthorizeSalesforceState = {
    isLoaded: false,
    wasError: false,
  };

  handleSalesforceOAuthCallback(): void {
    // We only have to handle the user successfully giving us access
    // If the user declines access, zoom will redirect them to the marketplace
    const queryString: string = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get("error");

    if (error) {
      this.setState({ isLoaded: true });

      if (error === "access_denied") {
        this.props.enqueueSnackbar("Salesforce integration not added", {
          variant: "warning",
        });
        this.props.navigate(RoutePaths.Integrations);
        return;
      } else {
        this.setState({ wasError: true });
        this.props.enqueueSnackbar("Failed to add Salesforce integration", {
          variant: "error",
        });
        return;
      }
    }

    const authCode: string = urlParams.get("code")!;
    const salesforceIntegration = new SalesforceIntegration(authCode);

    integrationService.postIntegration(salesforceIntegration).then((data) => {
      if (data.isError) {
        this.setState({ isLoaded: true, wasError: true });
        this.props.enqueueSnackbar(data.message, { variant: "error" });
      } else {
        this.setState({ isLoaded: true });
        // TODO: Forward the users to the integrations page while preserving the snackbar notification
        // this.props.enqueueSnackbar(data.message, { variant: "success" });
        this.props.navigate(RoutePaths.Integrations);
        this.props.navigate(0); // refresh page
      }
    });
  }

  render() {
    return !this.state.isLoaded && !this.state.wasError ? (
      <Loading />
    ) : (
      <Loading wasError={this.state.wasError} />
    );
  }
}

export default withSnackbar(
  withStyles(styles)(withRouter(AuthorizeSalesforce))
);
