import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export class DrawerReducer {
  public constructor(public content: JSX.Element) {}
}

export interface DrawerState {
  isOpen: boolean;
  content?: JSX.Element;
}

export const drawerSlice = createSlice({
  name: "dialog",
  initialState: {} as DrawerState,
  reducers: {
    openDrawer: (state, action: PayloadAction<JSX.Element>) => {
      return Object.assign({}, { isOpen: true, content: action.payload });
    },
    closeDrawer: (state) => {
      return Object.assign({}, state, {
        isOpen: false,
      } as DrawerState);
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
