import * as React from "react";
import {
  Typography,
  Theme,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import * as queryString from "query-string";
import IGenerateDraftModel from "../../../models/landing/IGenerateDraftModel";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../form/FormikTextField";
import FormikSynchronousButton from "../../form/FormikSynchronousButton";
import DocumentService from "../../../services/DocumentService";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";
import RegularExpressions from "../../../helpers/RegularExpressions";
import RoutePaths from "../../../routing/RoutePaths";
import GlobalConfig from "../../../GlobalConfig";
import { IError } from "../../../services/RestUtilities";
import SingleServerErrorMessage from "../../form/ServerServerErrorMessage";
import { enqueueSnackbar } from "../../reducers/NotificationsReducer";
import { withSnackbar, WithSnackbarProps } from "notistack";
const documentService = new DocumentService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      paddingTop: "3%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(650 + theme.spacingNumber(2 * 2))]: {
        width: 650,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    flexButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      padding: theme.spacing(4),
    },
  });

interface GenerateDraftProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {}

interface GenerateDraftState {
  success: boolean;
  serverErrors?: IError;
}

class GenerateDraft extends React.Component<
  GenerateDraftProps,
  GenerateDraftState
> {
  validationSchema = Yup.object().shape({
    key: Yup.string().required("Required"),
    documentFolderId: Yup.string().required("Required"),
  });

  state: GenerateDraftState = {
    success: false,
    serverErrors: undefined,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography align="center" variant="h5">
            Generate Draft Email From Documents
          </Typography>
          <Formik
            initialValues={
              {
                key: "",
                documentFolderId: "",
              } as IGenerateDraftModel
            }
            onSubmit={(
              values: IGenerateDraftModel,
              formikHelpers: FormikHelpers<IGenerateDraftModel>
            ) => {
              documentService.postGenerateDraft(values).then((v) => {
                if (v.isError) {
                  formikHelpers.setSubmitting(false);
                  this.setState({ serverErrors: v.error });
                } else {
                  formikHelpers.setSubmitting(false);
                  this.setState({ success: true });
                  this.props.enqueueSnackbar(v.message, { variant: "success" });
                  formikHelpers.setFieldValue("documentFolderId", "", true);
                }
              });
            }}
            validateOnMount={true}
            initialErrors={{}}
            validationSchema={this.validationSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="key"
                      label="Virtual Assistant Auth Key"
                      value={props.values.key}
                      errorText={props.errors.key}
                      touched={props.touched.key}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="documentFolderId"
                      label="Document Folder Id"
                      value={props.values.documentFolderId}
                      errorText={props.errors.documentFolderId}
                      touched={props.touched.documentFolderId}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                </Grid>
                {this.state.serverErrors && (
                  <SingleServerErrorMessage
                    message={this.state.serverErrors.message}
                  />
                )}
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Generate Draft
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(withRouter(GenerateDraft))
);
