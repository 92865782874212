import * as React from "react";
import { FormikTouched, FormikErrors } from "formik";
import {
  Grid,
  TextareaAutosize,
  TextareaAutosizeProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import InformationOutline from "mdi-material-ui/InformationOutline";
import Constants from "../../theme/Constants";

const styles = () =>
  createStyles({
    width100: {
      width: "100%",
    },
    infoText: {
      position: "relative",
      left: "14px",
      color: Constants.COLORS.PRIMARY_COLOR
    },
    infoTextDisabled: {
      color: 'grey',
      position: "relative",
      left: "14px",
    },
  });

interface FormikTextAreaProps extends WithStyles<typeof styles> {
  name: string;
  placeholder: string;
  errorText?: string | string[] | FormikErrors<any>;
  touched?: boolean | FormikTouched<any>;
  onBlur(e: React.FocusEvent<any>): void;
  onBlur<T = string | any>(
    fieldOrEvent: T
  ): T extends string ? (e: any) => void : void;
  onChange(e: React.ChangeEvent<any>): void;
  onChange<T = string | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
  value: any;
  infoText?: string;
  showDisabledInfoTextIcon?: boolean;
  validate?: (value: string) => string | void
}

class FormikTextField extends React.Component<
  FormikTextAreaProps & TextareaAutosizeProps
> {
  render() {
    const {
      name,
      placeholder: placeholder,
      errorText,
      touched,
      onBlur,
      onChange,
      classes,
      value,
      infoText,
      validate,
      ...rest
    } = this.props;
    if (validate && Boolean(validate(value))) {
      return (
        <Typography className={classes.infoText} color="error">
          {validate(value)}
        </Typography>
      );
    }

    return (
      <Grid container alignContent="center" spacing={0}>
        <Grid item xs={this.props.infoText || this.props.showDisabledInfoTextIcon ? 11 : 12}>
          <TextareaAutosize
            className={classes.width100}
            id={name}
            name={name}
            placeholder={placeholder}
            value={value || ""}
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
          />
          <Grid item xs={12}>
            {touched && Boolean(errorText) ? (
              <Typography color="error" variant="subtitle1">
                {/* {errorText} */}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {(this.props.infoText || this.props.showDisabledInfoTextIcon) && (
          <Grid item xs={1}>
            <Tooltip
              className={this.props.showDisabledInfoTextIcon ? classes.infoTextDisabled : classes.infoText}
              title={this.props.infoText}
              placement="left"
            >
              <InformationOutline fontSize="small" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(FormikTextField);
