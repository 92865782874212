import * as React from "react";
import { Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      margin: theme.spacing(2),
    },
  });

interface DashboardProps extends WithStyles<typeof styles> {}

class Dashboard extends React.Component<DashboardProps> {
  render() {
    const { classes } = this.props;
    return <div className={classes.layout}></div>;
  }
}

export default withStyles(styles)(Dashboard);
