import RestUtilities, { IRestResponse } from './RestUtilities';
import { MergedIntegrationType } from '../models/app/integrations/MergedIntegrationTypes';
import { IntegrationEnum } from '../models/app/integrations/IntegrationEnum';
import GongIntegration from '../models/app/integrations/implementations/GongIntegration';
import Integration from '../models/app/integrations/Integration';
import ZoomIntegration from '../models/app/integrations/implementations/ZoomIntegration';
import GmailIntegration from '../models/app/integrations/implementations/GmailIntegration';
import SalesforceIntegration from '../models/app/integrations/implementations/SalesforceIntegration';
import HubspotIntegration from '../models/app/integrations/implementations/HubspotIntegration';
import PostIntegrationResponse from '../models/app/integrations/PostIntegrationResponse';

export default class IntegrationService {
  async getIntegrations(): Promise<
    IRestResponse<Array<MergedIntegrationType>>
  > {
    return RestUtilities.get<MergedIntegrationType[]>(`/integrations`).then(
      integrationResponse => {
        const result = new Array<MergedIntegrationType>();
        integrationResponse.result?.forEach(i => {
          const type = i.type?.toLowerCase();

          switch (type) {
            case IntegrationEnum.GONG.toLowerCase(): {
              i = i as GongIntegration;
              result.push({
                type: IntegrationEnum.GONG,
                id: i.id,
                createdOn: i.createdOn,
                updatedOn: i.updatedOn,
                createdBy: i.createdBy,
                createdByEmail: i.createdByEmail,
                updatedBy: i.updatedBy,
                updatedByEmail: i.updatedByEmail,
                isTenantLevel: i.isTenantLevel,
                apiKey: i.apiKey,
                webhookPublicKey: i.webhookPublicKey,
              } as GongIntegration);
              break;
            }
            case IntegrationEnum.ZOOM.toLowerCase(): {
              i = i as ZoomIntegration;
              result.push({
                type: IntegrationEnum.ZOOM,
                id: i.id,
                createdOn: i.createdOn,
                updatedOn: i.updatedOn,
                createdBy: i.createdBy,
                createdByEmail: i.createdByEmail,
                updatedBy: i.updatedBy,
                updatedByEmail: i.updatedByEmail,
                isTenantLevel: i.isTenantLevel,
              } as ZoomIntegration);
              break;
            }
            case IntegrationEnum.SALESFORCE.toLowerCase(): {
              i = i as SalesforceIntegration;
              result.push({
                type: IntegrationEnum.SALESFORCE,
                id: i.id,
                createdOn: i.createdOn,
                updatedOn: i.updatedOn,
                createdBy: i.createdBy,
                createdByEmail: i.createdByEmail,
                updatedBy: i.updatedBy,
                updatedByEmail: i.updatedByEmail,
                isTenantLevel: i.isTenantLevel,
              } as SalesforceIntegration);
              break;
            }
            case IntegrationEnum.GMAIL.toLowerCase(): {
              i = i as GmailIntegration;
              result.push({
                type: IntegrationEnum.GMAIL,
                id: i.id,
                createdOn: i.createdOn,
                updatedOn: i.updatedOn,
                createdBy: i.createdBy,
                createdByEmail: i.createdByEmail,
                updatedBy: i.updatedBy,
                updatedByEmail: i.updatedByEmail,
                isTenantLevel: i.isTenantLevel,
              } as GmailIntegration);
              break;
            }
            case IntegrationEnum.HUBSPOT.toLowerCase(): {
              i = i as HubspotIntegration;
              result.push({
                type: IntegrationEnum.HUBSPOT,
                id: i.id,
                token: i.token,
                createdOn: i.createdOn,
                updatedOn: i.updatedOn,
                createdBy: i.createdBy,
                createdByEmail: i.createdByEmail,
                updatedBy: i.updatedBy,
                updatedByEmail: i.updatedByEmail,
                isTenantLevel: i.isTenantLevel,
              } as HubspotIntegration);
              break;
            }
          }
        });

        return Object.assign({}, integrationResponse, {
          result: result,
        } as IRestResponse<Array<MergedIntegrationType>>) as IRestResponse<
          Array<MergedIntegrationType>
        >;
      }
    );
  }

  async postIntegration(
    integration: MergedIntegrationType
  ): Promise<IRestResponse<PostIntegrationResponse>> {
    switch (integration.type) {
      case IntegrationEnum.GONG: {
        return RestUtilities.post<PostIntegrationResponse>(
          `/integrations/gong`,
          integration
        );
      }
      case IntegrationEnum.ZOOM: {
        return RestUtilities.post<PostIntegrationResponse>(
          `/integrations/zoom`,
          integration
        );
      }
      case IntegrationEnum.GMAIL: {
        return RestUtilities.post<PostIntegrationResponse>(
          `/integrations/gmail`,
          integration
        );
      }
      case IntegrationEnum.SALESFORCE: {
        return RestUtilities.post<PostIntegrationResponse>(
          `/integrations/salesforce`,
          integration
        );
      }
      case IntegrationEnum.HUBSPOT: {
        return RestUtilities.post<PostIntegrationResponse>(
          `/integrations/hubspot`,
          integration
        );
      }
      default: {
        throw 'Invalid integration type';
      }
    }
  }

  async putIntegration(
    id: string,
    integration: Integration
  ): Promise<IRestResponse<void>> {
    switch (integration.type) {
      case IntegrationEnum.GONG: {
        return RestUtilities.put(`/integrations/gong/${id}`, integration);
      }
      case IntegrationEnum.ZOOM: {
        return RestUtilities.put(`/integrations/zoom/${id}`, integration);
      }
      case IntegrationEnum.SALESFORCE: {
        return RestUtilities.put(`/integrations/salesforce/${id}`, integration);
      }
      case IntegrationEnum.HUBSPOT: {
        return RestUtilities.put(`/integrations/hubspot/${id}`, integration);
      }
      default: {
        throw 'Invalid integration type';
      }
    }
  }

  async deleteIntegration(
    id: string,
    integration: Integration
  ): Promise<IRestResponse<void>> {
    switch (integration.type) {
      case IntegrationEnum.GONG: {
        return RestUtilities.delete(`/integrations/gong/${id}`, integration);
      }
      case IntegrationEnum.ZOOM: {
        return RestUtilities.delete(`/integrations/zoom/${id}`, integration);
      }
      case IntegrationEnum.SALESFORCE: {
        return RestUtilities.delete(
          `/integrations/salesforce/${id}`,
          integration
        );
      }
      case IntegrationEnum.HUBSPOT: {
        return RestUtilities.delete(`/integrations/hubspot/${id}`, integration);
      }
      default: {
        throw 'Invalid integration type';
      }
    }
  }
}
