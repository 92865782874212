import * as React from 'react';
import { Button, Theme } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { ButtonProps } from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import Progress from '../loading/Progress';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: blue[500],
      '&:hover': {
        backgroundColor: blue[700],
      },
    },
    denseMargin: { marginLeft: theme.spacing(1), position: 'relative' },
  });

interface FormikSynchronousButtonProps extends WithStyles<typeof styles> {
  denseMargin?: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  isSuccess: boolean;
  disabled?: boolean;
}

class FormikSynchronousButton extends React.Component<
  FormikSynchronousButtonProps & ButtonProps
> {
  render() {
    const {
      denseMargin,
      isValid,
      isSubmitting,
      isSuccess,
      classes,
      disabled,
      onClick,
      ...rest
    } = this.props;
    return (
      <div className={denseMargin ? classes.denseMargin : classes.root}>
        <Button
          className={isSuccess ? classes.buttonSuccess : ''}
          disabled={disabled ? disabled : false || !isValid || isSubmitting}
          type={!!onClick ? 'button' : 'submit'}
          color="primary"
          variant="contained"
          onClick={onClick}
          {...rest}
        >
          {this.props.children}
        </Button>
        {isSubmitting && !isSuccess && <Progress />}
      </div>
    );
  }
}

export default withStyles(styles)(FormikSynchronousButton);
