import * as React from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Theme, Grid, Typography, Box, IconButton } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import FormikSelect from '../form/FormikSelect';
import {
  IRestResponse,
  IFormikErrors,
  IError,
} from '../../services/RestUtilities';
import { connect } from 'react-redux';
import FormikSynchronousButton from '../form/FormikSynchronousButton';
import Constants from '../../theme/Constants';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import RoutePaths from '../../routing/RoutePaths';
import { ApplicationState } from '../store';
import OpportunitiesService from '../../services/OpportunitiesService';
import populateOpportunitiesHOC from '../hocs/PopulateOpportunityMappingsHOC';
import {
  OpportunityFieldMappingsState,
  populateSalesforceFields,
} from '../reducers/OpportunityFieldMappingsReducer';
import { TemplatesState } from '../reducers/TemplatesReducer';
import FormikTextField from '../form/FormikTextField';
import FormikCancelButton from '../form/FormikCancelButton';
import { DrawerState, closeDrawer } from '../reducers/DrawerReducer';
import {
  OpportunityMeetingTasksState,
  clearSelectedOpportunityMeetingTask,
  removeOpportunityMeetingTaskById,
  setSelectedOpportunityMeetingTask,
  addOpportunityMeetingTasksPendingUpdate,
} from '../reducers/OpportunityMeetingTasksReducer';
import {
  getSupporTabFormikFieldForSalesforceFieldType,
  getSupporTabFormikFieldForSalesforceCallLog,
  getOrderedMappedValuesForSupportTab,
} from './SalesforceFieldHelpers';
import IOpportunityMeetingTask, {
  IOpportunityFieldValue,
} from '../../models/app/opportunities/IOpportunityMeetingTask';
import PutOpportunityMeetingTaskFieldValuesModel from '../../models/app/opportunities/PutOpportunityMeetingTaskFieldValuesModel';
import { FieldValuesType } from '../../models/app/opportunities/FieldType';
import FormikSelectValues from '../form/interfaces/FormikSelectValuesProp';
import { userIsInRole } from '../../helpers/Helpers';
import { RoleEnum } from '../../models/RoleEnum';
import { User } from 'oidc-client';
import { DialogContent } from '../reducers/DialogReducer';
import { openDialog } from '../reducers/DialogReducer';
import { IField } from '../../models/app/opportunities/IOpportunityLayoutsResponseModel';
import Popout from './Popout';
import { Eye } from 'mdi-material-ui';
import Loading from '../loading/Loading';
import { IntegrationsState } from '../reducers/IntegrationReducer';
import { IntegrationEnum } from '../../models/app/integrations/IntegrationEnum';

const opportunitiesService = new OpportunitiesService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    form: {
      paddingLeft: theme.spacingNumber(2),
      paddingRight: theme.spacingNumber(2),
    },
    return: {
      position: 'sticky',
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: '0px',
      padding: '0px',
    },
    subTitle: {
      marginTop: theme.spacingNumber(2),
      padding: '0px',
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
    controls: {
      zIndex: 1,
      position: 'absolute',
      width: '100%',
      bottom: '0px',
      padding: theme.spacingNumber(2),
      background: theme.palette.common.white,
      boxShadow:
        '0px -3px 3px -2px rgba(0,0,0,0.2), 0px -3px 4px 0px rgba(0,0,0,0.14)',
    },
    leftButtons: {
      flexGrow: 1,
    },
    fullHeight: {
      height: `calc(100vh - 169px - 48px - 76.5px )`,
      overflow: 'scroll',
    },
  });

interface ISupportTabViewProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  updateFieldMappings: (mapping: Array<IOpportunityMeetingTask>) => void;
  removeFieldMappings: () => void;
  closeDrawer: () => void;
  clearSelectedOpportunityTask: () => void;
  removeOpportunityTask: (id: string) => void;
  setSelectedOpportunityTask: (task: IOpportunityMeetingTask) => void;
  addPendingOpportunityTask: (task: IOpportunityMeetingTask) => void;
  openDialog: (dialogContent: DialogContent) => void;
  populateSalesforceFields: (fieldMappings: Array<IField>) => void;
  opportunityMeetingTasksState?: OpportunityMeetingTasksState;
  opportunityFieldMappingsState: OpportunityFieldMappingsState;
  templatesState: TemplatesState;
  editMeetingTask: IOpportunityMeetingTask;
  user: User;
  integrationsState: IntegrationsState;
  drawerState: DrawerState;
}

type SupportTabViewState = {
  serverErrors?: IFormikErrors;
  isSuccess: boolean;
  requireJustifications: boolean;
  popoutAnchorEl: null | React.RefObject<HTMLElement>;
  popoutIsOpen: boolean;
  popoutGeneratedValue: string | undefined;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityFieldMappingsState: state.opportunityFieldMappingsState,
    opportunityMeetingTasksState: state.opportunityMeetingTasksState,
    editMeetingTask:
      state.opportunityMeetingTasksState?.selectedOpportunityTask,
    user: state.oidc.user as User,
    integrationsState: state.integrationsState as IntegrationsState,
    drawerState: state.drawerState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDrawer: () => {
      const action = closeDrawer();
      dispatch(action);
    },
    clearSelectedOpportunityTask: () => {
      const action = clearSelectedOpportunityMeetingTask();
      dispatch(action);
    },
    removeOpportunityTask: (id: string) => {
      const action = removeOpportunityMeetingTaskById(id);
      dispatch(action);
    },
    setSelectedOpportunityTask: (task: IOpportunityMeetingTask) => {
      const action = setSelectedOpportunityMeetingTask(task);
      dispatch(action);
    },
    addPendingOpportunityTask: (task: IOpportunityMeetingTask) => {
      const action = addOpportunityMeetingTasksPendingUpdate(task);
      dispatch(action);
    },
    openDialog: (dialogContents: DialogContent) => {
      const action = openDialog(dialogContents);
      dispatch(action);
    },
    populateSalesforceFields: (salesforceFields: Array<IField>) => {
      const action = populateSalesforceFields(salesforceFields);
      dispatch(action);
    },
  };
};

class SupportTabView extends React.Component<
  ISupportTabViewProps,
  SupportTabViewState
> {
  state = {
    serverErrors: undefined,
    isSuccess: false,
    requireJustifications: true,
    popoutAnchorEl: React.createRef<HTMLElement>(),
    popoutIsOpen: false,
    popoutGeneratedValue: undefined,
  };
  opportunitiesService = new OpportunitiesService();

  closeDrawer() {
    this.props.closeDrawer();
    this.props.navigate(RoutePaths.OpportunityTasks);
  }

  componentDidUpdate(prevProps: Readonly<ISupportTabViewProps>): void {
    if (
      prevProps.opportunityMeetingTasksState?.selectedOpportunityTask
        ?.createdFor !==
        this.props.opportunityMeetingTasksState?.selectedOpportunityTask
          ?.createdFor &&
      !!this.props.opportunityMeetingTasksState?.selectedOpportunityTask
    ) {
      opportunitiesService
        .getLayouts(
          this.props.opportunityMeetingTasksState?.selectedOpportunityTask
            ?.createdFor as string
        )
        .then(layoutsResponse => {
          var salesforceFields = layoutsResponse.result?.fields.filter(
            field => field.createable
          );
          this.props.populateSalesforceFields(salesforceFields ?? []);
        });
    }

    if (prevProps.drawerState.isOpen && !this.props.drawerState.isOpen) {
      this.closePopout();
      this.props.clearSelectedOpportunityTask();
    }
  }

  handlePopoutClick = (e: React.MouseEvent<any>, generatedValue: string) => {
    if (this.state.popoutAnchorEl.current == e.currentTarget) {
      this.closePopout();
    } else {
      this.setState({
        popoutIsOpen: true,
        popoutAnchorEl: { current: e.currentTarget },
        popoutGeneratedValue: generatedValue,
      });
    }
  };

  closePopout = () => {
    this.setState({
      popoutIsOpen: false,
      popoutAnchorEl: { current: null },
      popoutGeneratedValue: undefined,
    });
  };

  render() {
    var validationShape = {
      stage: Yup.string().required('Required'),
    } as any;

    const { classes, enqueueSnackbar, integrationsState } = this.props;

    if (!this.props.editMeetingTask) return <Loading />;

    const hubspotIntegration = integrationsState?.integrations
      .map(i => i.type)
      .includes(IntegrationEnum.HUBSPOT);
    const orderedMappedFieldValuesWithRequiredFields =
      getOrderedMappedValuesForSupportTab(this.props.editMeetingTask);
    let initialValues = {
      stage: this.props.editMeetingTask.stage,
      status: this.props.editMeetingTask.status,
      callLog:
        this.props.opportunityMeetingTasksState?.selectedOpportunityTask
          ?.callLog,
    } as FieldValuesType;
    orderedMappedFieldValuesWithRequiredFields.forEach(v => {
      initialValues[`${v.fieldName}-value`] = v.selectedValue;
      initialValues[`${v.fieldName}-justification`] = v.justification;
      validationShape[`${v.fieldName}-justification`] =
        Yup.mixed().required('Required');
    });

    const availableStageValues = [
      '1st Internal Review',
      '2nd Internal Review',
      'External Review',
      'Ignore',
    ];

    const popoutIcon = (generatedValue: string) => (
      <IconButton
        color="primary"
        disabled={!generatedValue}
        onClick={e => {
          this.handlePopoutClick(e, generatedValue);
        }}
      >
        <Eye />
      </IconButton>
    );

    return (
      <div>
        <Formik
          enableReinitialize
          initialStatus={{}}
          initialValues={initialValues}
          onSubmit={(
            values: FieldValuesType,
            formikHelpers: FormikHelpers<FieldValuesType>
          ) => {
            if (values.stage === 'External Review') {
              this.props.openDialog(
                new DialogContent(
                  `Are you certain "${this.props.editMeetingTask.meetingTopic}" is ready to send to External Review?`,
                  'Send to External Review?',
                  'Ok',
                  () => {
                    this.putFieldValues(
                      values,
                      orderedMappedFieldValuesWithRequiredFields,
                      enqueueSnackbar,
                      formikHelpers
                    );
                    enqueueSnackbar(
                      `${this.props.editMeetingTask.meetingTopic} saved for ${values.stage}`,
                      { variant: 'success' }
                    );
                  }
                )
              );
            } else {
              this.putFieldValues(
                values,
                orderedMappedFieldValuesWithRequiredFields,
                enqueueSnackbar,
                formikHelpers
              );
              enqueueSnackbar(
                `${this.props.editMeetingTask.meetingTopic} saved for ${values.stage}`,
                { variant: 'success' }
              );
            }
          }}
          validateOnMount={true}
          validationSchema={
            this.state.requireJustifications &&
            (Yup.object().shape(validationShape) as any)
          }
        >
          {(props: FormikProps<FieldValuesType>) => (
            <React.Fragment>
              <form
                onSubmit={props.handleSubmit}
                className={classes.fullHeight}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={11}>
                    <Grid
                      className={classes.form}
                      container
                      rowSpacing={3}
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <FormikSelect
                          key="status"
                          name={'status'}
                          label={'Justification To Ignore Meeting'}
                          selectValues={
                            this.props.opportunityMeetingTasksState?.meetingTaskStatuses.map(
                              s => ({ value: s, label: s })
                            ) as FormikSelectValues
                          }
                          value={props.values.status}
                          errorText={undefined}
                          touched={undefined}
                          onChange={(e: React.ChangeEvent<any>) => {
                            if (e.target.value !== 'None') {
                              props.resetForm();
                              props.setErrors({});
                              this.setState({ requireJustifications: false });
                            } else {
                              this.setState({ requireJustifications: true });
                            }
                            props.handleChange(e);
                            props.handleBlur(e);
                          }}
                          onBlur={props.handleBlur}
                        />

                        <Typography
                          className={classes.subTitle}
                          variant="h6"
                          align="left"
                        >
                          Account
                        </Typography>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              border: '1px solid lightgrey',
                              borderRadius: 2,
                              padding: 2,
                              marginTop: 3,
                            }}
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={12}>
                                {getSupporTabFormikFieldForSalesforceCallLog(
                                  props,
                                  props.values.callLog
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        <Typography
                          className={classes.subTitle}
                          variant="h6"
                          align="left"
                        >
                          Opportunity
                        </Typography>
                        {/* For the support tab we don't need to show all fields. Only the mapped fields need to be showed. */}
                        {orderedMappedFieldValuesWithRequiredFields.map(
                          field => {
                            var fieldName = field.fieldName;
                            var justification = '';
                            var generatedValue = '';
                            var selectedValue = '';
                            var label = '';
                            let salesforceField =
                              this.props.opportunityFieldMappingsState.salesforceFields.find(
                                f => f.name === field.fieldName
                              );

                            if (!salesforceField && !hubspotIntegration) {
                              return;
                            }

                            if (!salesforceField) {
                              salesforceField = {
                                createable: false,
                                defaultValue: false,
                                defaultedOnCreate: false,
                                label: field.fieldName,
                                length: 10000,
                                name: field.fieldName,
                                nillable: false,
                                picklistValues: [],
                                precision: 0,
                                referenceTo: [],
                                relationshipName: null,
                                scale: 0,
                                type: 'text',
                              };
                            }

                            label =
                              salesforceField.relationshipName ||
                              salesforceField.label;

                            generatedValue = field.generatedValue;
                            selectedValue = props.values[`${fieldName}-value`];
                            justification =
                              props.values[`${fieldName}-justification`];

                            return (
                              <div key={fieldName}>
                                <br />
                                <Box
                                  sx={{
                                    border: '1px solid lightgrey',
                                    borderRadius: 2,
                                    padding: 2,
                                  }}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                      {popoutIcon(generatedValue)}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={11}
                                      sx={{ paddingLeft: '16px' }}
                                    >
                                      {getSupporTabFormikFieldForSalesforceFieldType(
                                        salesforceField,
                                        props,
                                        props.handleChange,
                                        fieldName,
                                        label,
                                        selectedValue,
                                        !this.state.requireJustifications
                                      )}
                                    </Grid>
                                  </Grid>
                                  <br />
                                  <FormikTextField
                                    key={`${fieldName}-justification`}
                                    name={`${fieldName}-justification`}
                                    label={`${fieldName} Justification`}
                                    value={justification}
                                    errorText={undefined}
                                    touched={undefined}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    disabled={!this.state.requireJustifications}
                                    multiline
                                    required
                                  />
                                </Box>
                                <br />
                              </div>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className={classes.controls}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      {userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN) && (
                        <>
                          <FormikSelect
                            key="stage"
                            name={'stage'}
                            label={'Next Stage'}
                            selectValues={
                              availableStageValues.map(s => ({
                                value: s,
                                label: s,
                              })) as FormikSelectValues
                            }
                            value={props.values.stage}
                            errorText={undefined}
                            touched={undefined}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <br />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <FormikCancelButton
                            denseMargin
                            isSubmitting={false}
                            onClick={() => {
                              this.closeDrawer();
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <FormikSynchronousButton
                            denseMargin
                            isValid={props.isValid}
                            isSubmitting={false}
                            isSuccess={true}
                            disabled={false}
                          >
                            Save
                          </FormikSynchronousButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </React.Fragment>
          )}
        </Formik>
        <Popout
          open={this.state.popoutIsOpen}
          header="AI Generated Value"
          anchorEl={this.state.popoutAnchorEl.current}
          text={this.state.popoutGeneratedValue}
          handleClose={this.closePopout}
        />
      </div>
    );
  }

  private putFieldValues(
    values: FieldValuesType,
    orderedMappedFieldValuesWithRequiredFields: IOpportunityFieldValue[],
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<FieldValuesType>
  ) {
    const putModel = {
      stage: values.stage,
      status: values.status,
      fieldValues: [],
      callLog: values.callLog,
    } as PutOpportunityMeetingTaskFieldValuesModel;
    for (const [key, value] of Object.entries(values)) {
      if ((value && key !== 'status') || key !== 'stage') {
        const fieldName = key
          .replace('-value', '')
          .replace('-justification', '');
        const originalFieldValue =
          orderedMappedFieldValuesWithRequiredFields.find(
            v => v.fieldName === fieldName
          );
        if (originalFieldValue) {
          const newFieldValueAlreadyCreated =
            putModel.fieldValues.findIndex(f => f.fieldName === fieldName) >= 0;
          let newFieldValue = newFieldValueAlreadyCreated
            ? (putModel.fieldValues.find(
                f => f.fieldName === fieldName
              ) as IOpportunityFieldValue)
            : ({} as IOpportunityFieldValue);
          newFieldValue.fieldName = originalFieldValue.fieldName;
          newFieldValue.isSuccess = originalFieldValue.isSuccess;
          newFieldValue.generatedValue = originalFieldValue.generatedValue;
          if (key.includes('-value')) {
            newFieldValue.selectedValue = value;
          } else {
            newFieldValue.justification = value;
          }
          if (!newFieldValueAlreadyCreated) {
            putModel.fieldValues.push(newFieldValue);
          }
        }
      }
    }

    opportunitiesService
      .putFieldValues(this.props.editMeetingTask.id, putModel)
      .then((response: IRestResponse<any>) => {
        if (response.isError) {
          const { formikErrors: serverErrors, ...formikErrors } =
            response.error as IError;
          enqueueSnackbar(response.error?.message, {
            variant: 'error',
          });
          formikHelpers.setStatus(formikErrors as FormikErrors<any>);
          this.setState({ serverErrors: serverErrors });
          formikHelpers.setSubmitting(false);
        } else {
          this.setState({ isSuccess: true });
          this.props.removeOpportunityTask(this.props.editMeetingTask.id);
          this.closeDrawer();
          this.props.navigate(RoutePaths.OpportunityTasks);
        }
      });
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  populateOpportunitiesHOC(
    withSnackbar(withRouter(withStyles(styles)(SupportTabView)))
  )
);
