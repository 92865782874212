import * as React from 'react';
import { Theme, Grid, Button } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { connect } from 'react-redux';
import RoutePaths from '../../routing/RoutePaths';
import populateMeetingTasksHOC from '../hocs/PopulateOpportunityMeetingTasksHOC';
import {
  OpportunityMeetingTasksState,
  setSelectedOpportunityMeetingTask,
  clearSelectedOpportunityMeetingTask,
} from '../reducers/OpportunityMeetingTasksReducer';
import { ApplicationState } from '../store';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import { WithSnackbarProps } from 'notistack';
import { closeDrawer, openDrawer } from '../reducers/DrawerReducer';
import OpportunityMeetingTaskDrawerContent from './OpportunityMeetingTaskDrawerContent';
import { User } from 'oidc-client';
import IOpportunityMeetingTask from '../../models/app/opportunities/IOpportunityMeetingTask';
import { userIsInRole } from '../../helpers/Helpers';
import { RoleEnum } from '../../models/RoleEnum';
import { utcToZonedTime, format } from 'date-fns-tz';
import dayjs from 'dayjs';

const MUIDataTable = require('mui-datatables').default;

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2),
    },
    tableReviewWidth: {
      width: '100px',
    },
  });

interface OpportunityMeetingTasksProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  user: User;
  opportunityMeetingTasksState: OpportunityMeetingTasksState;
  openDrawer: () => void;
  closeDrawer: () => void;
  setSelectedOpportunityTask: (task: IOpportunityMeetingTask) => void;
  clearSelectedOpportunityTask: () => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityMeetingTasksState: state.opportunityMeetingTasksState,
    user: state.oidc.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    openDrawer: () => {
      const action = openDrawer(<OpportunityMeetingTaskDrawerContent />);
      dispatch(action);
    },
    closeDrawer: () => {
      const action = closeDrawer();
      dispatch(action);
    },
    setSelectedOpportunityTask: (task: IOpportunityMeetingTask) => {
      const action = setSelectedOpportunityMeetingTask(task);
      dispatch(action);
    },
    clearSelectedOpportunityTask: () => {
      dispatch(clearSelectedOpportunityMeetingTask());
    },
  };
};

class OpportunityTasks extends React.Component<OpportunityMeetingTasksProps> {
  refs: {
    query: HTMLInputElement;
  };

  componentDidMount() {
    if (this.props.path.includes('review')) {
      const id = this.props.params.opportunityTaskId as string;
      if (id) {
        var result =
          this.props.opportunityMeetingTasksState.opportunityTasks.find(
            i => i.id === id
          );
        if (result) {
          this.props.setSelectedOpportunityTask(result);
          this.props.openDrawer();
        } else {
          setTimeout(() => {
            this.props.navigate(RoutePaths.Opportunities);
          });
        }
      }
    }
  }

  componentDidUpdate(): void {
    if (!this.props.path.includes('review')) {
      this.props.closeDrawer();
    }
  }

  componentWillUnmount(): void {
    this.props.closeDrawer();
  }

  editOpportunityTask = (rowData: string[]) => {
    var task = this.props.opportunityMeetingTasksState.opportunityTasks.find(
      x => x.id == rowData[1]
    );
    if (task) {
      this.props.navigate(
        RoutePaths.OpportunityTasksReview.replace(
          ':opportunityTaskId',
          rowData[1]
        )
      );
      this.props.setSelectedOpportunityTask(task);
      this.props.openDrawer();
    }
  };

  mapOpportunityTasksToTableOpportunityTasksForExternalUser(
    OpportunityTasks: IOpportunityMeetingTask[]
  ): Array<Array<any>> {
    const tableOpportunityTasks = new Array<Array<any>>();
    if (OpportunityTasks) {
      OpportunityTasks.forEach(value => {
        const date = value.meetingDate;
        const dateString = date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        });
        let timeString = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });

        tableOpportunityTasks.push([
          <Button onClick={e => {}} variant="contained">
            Review
          </Button>,
          value.id,
          value.meetingTopic,
          `${dateString} @ ${timeString}`,
          value.participantList,
        ]);
      });
    }
    return tableOpportunityTasks;
  }

  mapOpportunityTasksToTableOpportunityTasksForSupport(
    OpportunityTasks: IOpportunityMeetingTask[]
  ): Array<Array<any>> {
    const tableOpportunityTasks = new Array<Array<any>>();
    const timeZone = 'America/New_York';
    const datePattern = 'MM/dd hh:mm aa';

    if (OpportunityTasks) {
      OpportunityTasks.forEach(value => {
        var dateEST;
        if (value.meetingDate) {
          const zonedDate = utcToZonedTime(value.meetingDate, timeZone);
          dateEST = format(zonedDate, datePattern, { timeZone });
        }
        tableOpportunityTasks.push([
          <Button onClick={e => {}} variant="contained">
            Review
          </Button>,
          value.id,
          dateEST || '',
          value.hostName,
          value.meetingTopic,
          value.platformMeetingId,
          value.stage,
          value.status,
        ]);
      });
    }
    return tableOpportunityTasks;
  }

  externalUserColumns = [
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { className: this.props.classes.tableReviewWidth };
        },
      },
    },
    {
      name: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'Meeting Title',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Meeting Date',
      options: {
        filter: false,
        sort: true,
        sortCompare: (order: any) => {
          return (date1: any, date2: any) => {
            const date1unix = dayjs(date1.data).unix();
            const date2unix = dayjs(date2.data).unix();

            if (order === 'asc') {
              return date1unix > date2unix ? 1 : -1;
            } else {
              return date2unix > date1unix ? 1 : -1;
            }
          };
        },
      },
    },
    {
      name: 'Participants',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: '200px' } }),
      },
    },
  ];

  supportColumns = [
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { className: this.props.classes.tableReviewWidth };
        },
      },
    },
    {
      name: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'Meeting Date',
      options: {
        filter: false,
        sort: true,
        sortCompare: (order: any) => {
          return (date1: any, date2: any) => {
            const date1unix = dayjs(date1.data).unix();
            const date2unix = dayjs(date2.data).unix();

            if (order === 'asc') {
              return date1unix > date2unix ? 1 : -1;
            } else {
              return date2unix > date1unix ? 1 : -1;
            }
          };
        },
      },
    },
    {
      name: 'Host Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Meeting Topic',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Platform Meeting Id',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Current Stage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Justification To Ignore Meeting',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  options = {
    textLabels: {
      body: {
        noMatch: 'All Tasks Complete!',
      },
    },
    print: false,
    download: false,
    rowsPerPage: 100,
    elevation: 3,
    selectableRows: 'none',
    responsive: 'simple',
    viewColumns: false,
    tableBodyMaxHeight: 'calc(100vh - 64px - 48px - 64px - 52px)',
    onRowClick: this.editOpportunityTask,
    filter: false,
    search: true,
    sort: true,
  };

  render() {
    const { classes, opportunityMeetingTasksState } = this.props;

    return (
      <Grid className={classes.grid} container alignItems="center">
        <Grid item xs={12}>
          <MUIDataTable
            title={'Post-Meeting Tasks'}
            data={
              userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN) ||
              userIsInRole(this.props.user, RoleEnum.SUPPORT)
                ? this.mapOpportunityTasksToTableOpportunityTasksForSupport(
                    opportunityMeetingTasksState?.opportunityTasks
                  )
                : this.mapOpportunityTasksToTableOpportunityTasksForExternalUser(
                    opportunityMeetingTasksState?.opportunityTasks
                  )
            }
            columns={
              userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN) ||
              userIsInRole(this.props.user, RoleEnum.SUPPORT)
                ? this.supportColumns
                : this.externalUserColumns
            }
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(populateMeetingTasksHOC(withRouter(OpportunityTasks))));
