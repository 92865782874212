import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import {
  IconButton,
  Theme,
  Fade,
  Popper,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DotsVertical from "mdi-material-ui/DotsVertical";
import ViewDashboard from "mdi-material-ui/ViewDashboard";
import Logout from "mdi-material-ui/Logout";
import Constants from "../../../src/theme/Constants";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import RoutePaths from "../../routing/RoutePaths";

// DO NOT DELETE OR BUILDS WILL BREAK
// There is a mismatch between @types/react@17, React@17, and mui/materials
// Popper in mui is requiring onResize & onResizeCapture which only exists in React@18
declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
    }
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.common.white,
    },
    accountButton: {
      color: Constants.COLORS.CQUENCE_BLACK,
    },
    listItemIcon: {
      minWidth: theme.spacing(4),
    },
  });

interface AccountPopOutProps
  extends WithStyles<typeof styles>,
    WithRouterProps {}

type AccountPopOutState = {
  open: boolean;
};

class AccountPopOut extends React.Component<
  AccountPopOutProps,
  AccountPopOutState
> {
  anchorEl: React.RefObject<HTMLButtonElement>;
  constructor(props: AccountPopOutProps) {
    super(props);
    this.anchorEl = React.createRef();
  }

  state: AccountPopOutState = {
    open: false,
  };

  handleToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  handleClose = (event: any) => {
    if ((this.anchorEl.current as any).contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <IconButton
          className={classes.accountButton}
          ref={this.anchorEl}
          aria-owns={this.state.open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
          size="large"
        >
          <DotsVertical/>
        </IconButton>
        <Popper
          open={this.state.open}
          anchorEl={this.anchorEl.current}
          transition
          disablePortal
          components={{Root: "div"}}
          componentsProps={{root: () => {}}}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={3} className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {RoutePaths.IsCurrentLocationWhiteListed() && (
                      <MenuItem
                        onClick={(e) => {
                          this.props.navigate(RoutePaths.Dashboard);
                          this.handleClose(e);
                        }}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <ViewDashboard fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(e) => {
                        this.props.navigate(RoutePaths.Account);
                        this.handleClose(e);
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <AccountCircle fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Account" />
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        this.props.navigate(RoutePaths.Logout);
                        this.handleClose(e);
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(AccountPopOut));
