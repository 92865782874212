import RestUtilities, { IRestResponse } from "./RestUtilities";
import ITemplate from "../models/app/templates/ITemplate";
import PostPutTemplateResponseModel from "../models/app/templates/PostPutTemplateResponseModel";
import ITemplateVariable from "../models/app/templates/ITemplateVariable";
import ITemplateVariableReplacementStrategy from "../models/app/templates/ITemplateVariableReplacementStrategy";
import IGPTTranscriptPrompt from "../models/app/templates/IGPTTranscriptPrompt";

export default class TemplateService {
  async deleteTenantTemplate(templateId: string): Promise<IRestResponse<any>> {
    return RestUtilities.delete(`/templates/${templateId}`);
  }
  async deleteSystemTemplate(templateId: string): Promise<IRestResponse<any>> {
    return RestUtilities.delete(`/templates/system/${templateId}`);
  }
  async deleteSystemTemplateVariable(
    templateVariableId: string
  ): Promise<IRestResponse<any>> {
    return RestUtilities.delete(
      `/templates/variables/system/${templateVariableId}`
    );
  }
  async deleteTenantTemplateVariable(
    templateVariableId: string
  ): Promise<IRestResponse<any>> {
    return RestUtilities.delete(`/templates/variables/${templateVariableId}`);
  }
  async deleteGPTTranscriptPrompt(
    promptId: string
  ): Promise<IRestResponse<any>> {
    return RestUtilities.delete(
      `/templates/variables/gpt-transcript-prompts/${promptId}`
    );
  }

  async getTenantTemplates(): Promise<IRestResponse<Array<ITemplate>>> {
    return RestUtilities.get<ITemplate[]>(`/templates`);
  }

  async getSystemTemplates(): Promise<IRestResponse<Array<ITemplate>>> {
    return RestUtilities.get<ITemplate[]>(`/templates/system`).then(
      (response) => {
        const result = new Array<ITemplate>();
        response.result?.forEach((template) => {
          result.push({
            id: template.id,
            name: template.name,
            driveId: template.driveId,
            isSystemTemplate: true,
            createdBy: template.createdBy,
            createdOn: template.createdOn,
            updatedOn: template.updatedOn,
            updatedBy: template.updatedBy,
          } as ITemplate);
        });

        return Object.assign({}, response, {
          result: result,
        } as IRestResponse<Array<ITemplate>>) as IRestResponse<
          Array<ITemplate>
        >;
      }
    );
  }

  async getTenantTemplateVariables(): Promise<
    IRestResponse<Array<ITemplateVariable>>
  > {
    return RestUtilities.get<ITemplateVariable[]>(`/templates/variables`);
  }

  async getSystemTemplateVariables(): Promise<
    IRestResponse<Array<ITemplateVariable>>
  > {
    return RestUtilities.get<ITemplateVariable[]>(
      `/templates/variables/system`
    ).then((response) => {
      const result = new Array<ITemplateVariable>();
      response.result?.forEach((template) => {
        result.push({
          ...template,
          isSystemTemplateVariable: true,
        } as ITemplateVariable);
      });

      return Object.assign({}, response, {
        result: result,
      } as IRestResponse<Array<ITemplateVariable>>) as IRestResponse<
        Array<ITemplateVariable>
      >;
    });
  }

  async getTenantVariablesGPTTranscriptPrompts(): Promise<
    IRestResponse<Array<IGPTTranscriptPrompt>>
  > {
    return RestUtilities.get<IGPTTranscriptPrompt[]>(
      `/templates/variables/gpt-transcript-prompts`
    );
  }

  async getSystemVariablesGPTTranscriptPrompts(): Promise<
    IRestResponse<Array<IGPTTranscriptPrompt>>
  > {
    return RestUtilities.get<IGPTTranscriptPrompt[]>(
      `/templates/variables/gpt-transcript-prompts/system`
    ).then((response) => {
      const result = new Array<IGPTTranscriptPrompt>();
      response.result?.forEach((template) => {
        result.push({
          ...template,
          isSystemTemplateVariable: true,
        } as IGPTTranscriptPrompt);
      });

      return Object.assign({}, response, {
        result: result,
      } as IRestResponse<Array<IGPTTranscriptPrompt>>) as IRestResponse<
        Array<IGPTTranscriptPrompt>
      >;
    });
  }

  async getTemplateVariableReplacementStrategies(): Promise<
    IRestResponse<Array<ITemplateVariableReplacementStrategy>>
  > {
    return RestUtilities.get<ITemplateVariableReplacementStrategy[]>(
      `/templates/variables/replacement-strategies`
    );
  }

  async postSystemVariableGPTTranscriptPrompt(
    prompt: IGPTTranscriptPrompt
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.post(
      `/templates/variables/gpt-transcript-prompts/system`,
      prompt
    );
  }

  async putSystemVariableGPTTranscriptPrompt(
    id: string,
    prompt: IGPTTranscriptPrompt
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.put(
      `/templates/variables/gpt-transcript-prompts/system/${id}`,
      prompt
    );
  }

  async postTenantVariableGPTTranscriptPrompt(
    prompt: IGPTTranscriptPrompt
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.post(
      `/templates/variables/gpt-transcript-prompts`,
      prompt
    );
  }

  async putTenantVariableGPTTranscriptPrompt(
    id: string,
    prompt: IGPTTranscriptPrompt
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.put(
      `/templates/variables/gpt-transcript-prompts/${id}`,
      prompt
    );
  }

  async postSystemTemplate(
    template: ITemplate
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.post(`/templates/system`, template);
  }

  async putSystemTemplate(
    id: string,
    template: ITemplate
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.put(`/templates/system/${id}`, template);
  }

  async postTenantTemplate(
    template: ITemplate
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.post(`/templates`, template);
  }

  async putTenantTemplate(
    id: string,
    template: ITemplate
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.put(`/templates/${id}`, template);
  }

  async postSystemTemplateVariable(
    template: ITemplateVariable
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.post(`/templates/variables/system`, template);
  }

  async putSystemTemplateVariable(
    id: string,
    template: ITemplateVariable
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.put(`/templates/variables/system/${id}`, template);
  }

  async postTenantTemplateVariable(
    template: ITemplateVariable
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.post(`/templates/variables`, template);
  }

  async putTenantTemplateVariable(
    id: string,
    template: ITemplateVariable
  ): Promise<IRestResponse<PostPutTemplateResponseModel>> {
    return RestUtilities.put(`/templates/variables/${id}`, template);
  }
}
