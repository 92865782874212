import * as React from "react";
import { Typography, Theme, Grid, Paper, Button } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import IUploadCustomPhrasesModel from "../../models/files/IUploadCustomPhrasesModel";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import DocumentService from "../../services/DocumentService";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { IError } from "../../services/RestUtilities";
import SingleServerErrorMessage from "../form/ServerServerErrorMessage";
import { withSnackbar, WithSnackbarProps } from "notistack";
import Papa from "papaparse";
import Constants from "../../theme/Constants";
const documentService = new DocumentService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      paddingTop: "3%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    flexButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      padding: theme.spacing(4),
    },
    fileName: {
      marginLeft: "10px",
    },
  });

interface CustomPhrasesProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {}

interface CustomPhrasesState {
  success: boolean;
  serverErrors?: IError;
  error: string;
  file: File | null;
}

class CustomPhrases extends React.Component<
  CustomPhrasesProps,
  CustomPhrasesState
> {
  validationSchema = Yup.object().shape({
    phrases: Yup.string().required("Required"),
  });

  state: CustomPhrasesState = {
    success: false,
    serverErrors: undefined,
    error: "",
    file: null,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography align="center" variant="h5">
            Custom Phrases
          </Typography>
          <Formik
            initialValues={
              {
                phrases: "",
              } as IUploadCustomPhrasesModel
            }
            onSubmit={(
              values: IUploadCustomPhrasesModel,
              formikHelpers: FormikHelpers<IUploadCustomPhrasesModel>
            ) => {
              documentService.putCustomPhrases(values).then((v) => {
                if (v.isError) {
                  formikHelpers.setSubmitting(false);
                  this.setState({ serverErrors: v.error });
                } else {
                  formikHelpers.setSubmitting(false);
                  this.setState({
                    success: true,
                    serverErrors: undefined,
                    file: null,
                  });
                  this.props.enqueueSnackbar(v.message, { variant: "success" });
                  formikHelpers.setFieldValue("phrases", "", true);
                }
              });
            }}
            validateOnMount={true}
            initialErrors={{}}
            validationSchema={this.validationSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <Button variant="contained" component="label">
                      Choose CSV File
                      <input
                        hidden
                        accept="text/csv"
                        multiple
                        type="file"
                        onChange={(event) => {
                          const inputFile = event.currentTarget.files![0];
                          this.setState({
                            file: inputFile,
                            success: false,
                            serverErrors: undefined,
                          });

                          const reader = new FileReader();
                          reader.onload = async ({ target }) => {
                            const result = target!.result as string;
                            const parsedResult = Papa.parse(result);
                            if (parsedResult.errors.length) {
                              let errorMessage = "";
                              parsedResult.errors.forEach((err) => {
                                errorMessage += `${err.message} on row ${err.row}. `;
                              });
                              this.setState({ error: errorMessage });
                              return;
                            }

                            let phrases = "";
                            const rows = parsedResult.data;
                            rows.forEach(
                              (row: any) => (phrases += row.join(",") + "\n")
                            );
                            props.setFieldValue(
                              "phrases",
                              phrases.substring(0, phrases.length - 1)
                            );
                          };

                          reader.readAsText(inputFile);
                        }}
                      />
                    </Button>
                    <span className={classes.fileName}>
                      {this.state.file?.name}
                    </span>
                  </Grid>
                </Grid>
                {this.state.serverErrors && (
                  <SingleServerErrorMessage
                    message={this.state.serverErrors.message}
                    formikErrors={this.state.serverErrors.formikErrors}
                  />
                )}
                {this.state.error && (
                  <SingleServerErrorMessage message={this.state.error} />
                )}
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Upload
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(withRouter(CustomPhrases))
);
