import * as React from 'react';
import { Theme, IconButton } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import { connect } from 'react-redux';
import CloseCircle from 'mdi-material-ui/CloseCircle';
import Constants from '../../theme/Constants';
import { removeSnackbar } from '../reducers/NotificationsReducer';

const notistackStyle = (theme: Theme) => {
  return {
    // justifyContent: 'center',
    // maxWidth: '100%',
    // boxShadow: 'none',
    // width: '100%',
    borderRadius: '5px',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  };
};

const styles = (theme: Theme) =>
  createStyles({
    success: {
      ...notistackStyle(theme),
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    error: {
      ...notistackStyle(theme),
      backgroundColor: `${theme.palette.error.main} !important`,
    },
    info: { ...notistackStyle(theme), backgroundColor: '#2e2e2e' },
    warning: notistackStyle(theme),
  });

interface SnackbarProviderWrapperProps extends WithStyles<typeof styles> {
  removeSnackbar: (key: React.ReactText) => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeSnackbar: (key: React.ReactText) => {
      const action = removeSnackbar(key);
      dispatch(action);
    },
  };
};

class SnackbarProviderWrapper extends React.Component<SnackbarProviderWrapperProps> {
  snackbarProviderRef: React.RefObject<any> = React.createRef();

  onClickDismiss = (key: string | number) => () => {
    this.snackbarProviderRef.current.closeSnackbar(key);
    this.props.removeSnackbar(key);
  };

  render() {
    const { classes } = this.props;
    return (
      <SnackbarProvider
        ref={this.snackbarProviderRef}
        classes={{
          // containerAnchorOriginBottomCenter: classes.snackContainer,
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        action={key => (
          <IconButton
            onClick={this.onClickDismiss(key)}
            aria-label="delete"
            size="small"
          >
            <CloseCircle
              htmlColor={Constants.COLORS.WHITE}
              fontSize="inherit"
            />
          </IconButton>
        )}
        hideIconVariant={true}
        maxSnack={1}
        autoHideDuration={3000}
      >
        {this.props.children}
      </SnackbarProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(null, mapDispatchToProps)(SnackbarProviderWrapper)
);
