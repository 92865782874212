import * as React from "react";
import { Button, Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { ButtonProps } from "@mui/material/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: { marginTop: theme.spacing(3) },
  });

interface FormikUpdateButtonProps extends WithStyles<typeof styles> {
  isValid: boolean;
  isSubmitting: boolean;
}

class FormikUpdateButton extends React.Component<
  FormikUpdateButtonProps & ButtonProps
> {
  render() {
    const { isValid, isSubmitting, classes, ...rest } = this.props;
    return (
      <Button
        className={classes.root}
        disabled={!isValid || isSubmitting}
        type="submit"
        color="primary"
        variant="contained"
        {...rest}
      >
        Update
      </Button>
    );
  }
}

export default withStyles(styles)(FormikUpdateButton);
