import * as React from "react";
import { Typography, Theme, Button, Grid, Paper } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import * as queryString from "query-string";
import UserService from "../../../services/UserService";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../form/FormikTextField";
import FormikSynchronousButton from "../../form/FormikSynchronousButton";
import IChangeEmailModel from "../../../models/landing/IChangeEmailModel";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";
import RoutePaths from "../../../routing/RoutePaths";
import GlobalConfig from "../../../GlobalConfig";
const userService = new UserService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      marginTop: theme.toolbar.height * 2,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(400 + theme.spacingNumber(2 * 2))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    flexButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      padding: theme.spacing(4),
    },
  });

interface EmailChangeProps extends WithStyles<typeof styles>, WithRouterProps {}

interface EmailChangeState {
  serverError: string;
  domain: string;
  success: boolean;
}

class EmailChange extends React.Component<EmailChangeProps, EmailChangeState> {
  validationSchema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email."),
  });

  state: EmailChangeState = {
    serverError: "",
    domain: "",
    success: false,
  };

  getTokenFromParams(): string {
    const params = queryString.parse(window.location.search);
    const token = params["token"] as string;
    return token;
  }

  getTenantIdFromParams(): string {
    const params = queryString.parse(window.location.search);
    const tenantId = params["tenantId"] as string;
    return tenantId;
  }

  componentDidMount() {
    const domain = this.getTenantIdFromParams();
    this.setState({ domain: domain });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography align="center" variant="h5">
            Change Email
          </Typography>
          {!this.state.success ? (
            <Formik
              initialValues={
                {
                  tenantId: this.getTenantIdFromParams(),
                  token: this.getTokenFromParams(),
                  email: "",
                } as IChangeEmailModel
              }
              onSubmit={(
                values: IChangeEmailModel,
                formikHelpers: FormikHelpers<IChangeEmailModel>
              ) => {
                userService.changeEmail(values).then((v) => {
                  if (v.isError && v.error) {
                    formikHelpers.setSubmitting(false);
                    this.setState({
                      success: false,
                      serverError: v.error.message,
                    });
                  } else {
                    formikHelpers.setSubmitting(false);
                    this.setState({ success: true });
                  }
                });
              }}
              validateOnMount={false}
              isInitialValid={false}
              validationSchema={this.validationSchema}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                      <Typography align="center" variant="subtitle1">
                        To confirm your new email, enter your current email
                        address.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="email"
                        label="Current Email"
                        value={props.values.email}
                        errorText={props.errors.email}
                        touched={props.touched.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoComplete="off"
                        required
                      />
                    </Grid>
                  </Grid>
                  {this.state.serverError && (
                    <Typography align="center" color="error">
                      {this.state.serverError}
                    </Typography>
                  )}
                  <div className={classes.flexButtonContainer}>
                    <FormikSynchronousButton
                      isValid={props.isValid}
                      isSubmitting={props.isSubmitting}
                      isSuccess={this.state.success}
                    >
                      Change Email
                    </FormikSynchronousButton>
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <Grid
              direction="column"
              container
              rowSpacing={3}
              alignItems="center"
            >
              <Grid item>
                <Typography gutterBottom align="center" variant="h5">
                  Your email has been successfully changed.
                </Typography>
                <Typography gutterBottom align="center" variant="subtitle1">
                  Click below to sign in.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    window.location.assign(
                      `https://${GlobalConfig.SPA_HOST}${RoutePaths.Login}`
                    )
                  }
                >
                  Sign in
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(EmailChange));
