import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MenuState {
  currentSelection: string;
}

export const menuSlice = createSlice({
  name: "menu",
  initialState: { currentSelection: "dashboard" } as MenuState,
  reducers: {
    expandSection: (state, action: PayloadAction<MenuState>) => {
      return Object.assign({}, action.payload);
    },
  },
});

export const { expandSection } = menuSlice.actions;
export default menuSlice.reducer;
