import * as React from "react";
import * as queryString from "query-string";
import { Formik, FormikProps, FormikErrors, FormikHelpers } from "formik";
import FormikSelectValues from "../form/interfaces/FormikSelectValuesProp";
import * as Yup from "yup";
import {
  Theme,
  Paper,
  Grid,
  Typography,
  IconButton,
  ListItem,
  List,
  ListItemText,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { SnackbarProps, withSnackbar, WithSnackbarProps } from "notistack";
import FormikTextField from "../form/FormikTextField";
import FormikDeleteButton from "../form/FormikDeleteButton";
import {
  IRestResponse,
  IFormikErrors,
  IError,
} from "../../services/RestUtilities";
import { connect } from "react-redux";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Constants from "../../theme/Constants";
import classNames from "classnames";
import RegularExpressions from "../../helpers/RegularExpressions";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import RoutePaths from "../../routing/RoutePaths";
import { addTemplate, removeTemplateById } from "../reducers/TemplatesReducer";
import { ApplicationState } from "../store";
import TemplatesService from "../../services/TemplatesService";
import ITemplate from "../../models/app/templates/ITemplate";
import PostPutTemplateResponseModel from "../../models/app/templates/PostPutTemplateResponseModel";
import { useParams } from "react-router-dom";
import FormikCheckbox from "../form/FormikCheckbox";
import populateTemplatesHOC from "../hocs/PopulateTemplatesHOC";

const templateService = new TemplatesService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
  });

interface ITemplatesFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  addTemplate: (template: ITemplate) => void;
  removeTemplateById: (email: string) => void;
  templates: Array<ITemplate>;
}

type TemplatesFormState = {
  editTemplate?: ITemplate;
  assignableRoles: FormikSelectValues;
  serverErrors?: IFormikErrors;
  isSuccess: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    templates: state.templatesState.templates,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTemplate: (template: ITemplate) => {
      const action = addTemplate(template);
      dispatch(action);
    },
    removeTemplateById: (id: string) => {
      const action = removeTemplateById(id);
      dispatch(action);
    },
  };
};

class TemplatesForm extends React.Component<
  ITemplatesFormProps,
  TemplatesFormState
> {
  state = {
    assignableRoles: [] as FormikSelectValues,
    serverErrors: undefined,
    isSuccess: false,
    editTemplate: {} as ITemplate,
  };

  componentDidMount() {
    const id = this.props.params.templateId as string;
    if (id) {
      var result = this.props.templates.find((i) => i.id === id);
      if (result) {
        this.setState({ editTemplate: result });
      } else {
        setTimeout(() => {
          this.props.navigate(RoutePaths.Templates);
        });
      }
    }
  }

  deleteTenantTemplate(props: FormikProps<ITemplate>, enqueueSnackbar: any) {
    templateService
      .deleteTenantTemplate(props.values.id as string)
      .then((response) => {
        if (response.isError) {
          enqueueSnackbar(response.error?.message, { variant: "error" });
          props.setStatus(response.error?.message);
        } else {
          this.props.removeTemplateById(props.values.id as string);
          enqueueSnackbar(response.message, { variant: "success" });
          this.props.navigate(RoutePaths.Templates);
        }
      });
  }

  deleteSystemTemplate(props: FormikProps<ITemplate>, enqueueSnackbar: any) {
    templateService
      .deleteSystemTemplate(props.values.id as string)
      .then((response) => {
        if (response.isError) {
          enqueueSnackbar(response.error?.message, { variant: "error" });
          props.setStatus(response.error?.message);
        } else {
          this.props.removeTemplateById(props.values.id as string);
          enqueueSnackbar(response.message, { variant: "success" });
          this.props.navigate(RoutePaths.Templates);
        }
      });
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().max(512, "Max 512 characters").required("Required"),
    driveId: Yup.string().max(512, "Max 512 characters").required("Required"),
  });

  render() {
    const { classes, enqueueSnackbar } = this.props;
    return (
      <Formik
        enableReinitialize
        initialStatus={{ creating: !this.state.editTemplate.name }}
        initialValues={
          this.state.editTemplate.name
            ? this.state.editTemplate
            : ({
                isSystemTemplate: false,
                id: "",
                name: "",
                driveId: "",
              } as ITemplate)
        }
        onSubmit={(
          values: ITemplate,
          formikHelpers: FormikHelpers<ITemplate>
        ) => {
          const newTemplate = {
            isSystemTemplate: values.isSystemTemplate,
            name: values.name,
            driveId: values.driveId,
          } as ITemplate;
          if (this.state.editTemplate.name) {
            if (this.state.editTemplate.isSystemTemplate) {
              templateService
                .putSystemTemplate(
                  this.state.editTemplate.id as string,
                  newTemplate
                )
                .then(
                  (response: IRestResponse<PostPutTemplateResponseModel>) => {
                    this.handlePutResponse(
                      response,
                      enqueueSnackbar,
                      formikHelpers,
                      { id: this.state.editTemplate.id, ...newTemplate },
                      values
                    );
                  }
                );
            } else {
              templateService
                .putTenantTemplate(
                  this.state.editTemplate.id as string,
                  newTemplate
                )
                .then(
                  (response: IRestResponse<PostPutTemplateResponseModel>) => {
                    this.handlePutResponse(
                      response,
                      enqueueSnackbar,
                      formikHelpers,
                      { id: this.state.editTemplate.id, ...newTemplate },
                      values
                    );
                  }
                );
            }
          } else {
            if (newTemplate.isSystemTemplate) {
              templateService
                .postSystemTemplate(newTemplate)
                .then(
                  (response: IRestResponse<PostPutTemplateResponseModel>) => {
                    this.handlePostResponse(
                      response,
                      enqueueSnackbar,
                      formikHelpers,
                      newTemplate
                    );
                  }
                );
            } else {
              templateService
                .postTenantTemplate(newTemplate)
                .then(
                  (response: IRestResponse<PostPutTemplateResponseModel>) => {
                    this.handlePostResponse(
                      response,
                      enqueueSnackbar,
                      formikHelpers,
                      newTemplate
                    );
                  }
                );
            }
          }
        }}
        validateOnMount={true}
        initialErrors={{}}
        validationSchema={this.validationSchema}
      >
        {(props) => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              disabled={props.isSubmitting}
              onClick={() => this.props.navigate(RoutePaths.Templates)}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              align="left"
              variant="h5"
            >
              {this.state.editTemplate.name ? "Edit Template" : "New Template"}
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Template Details
              </Typography>
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikCheckbox
                      name="isSystemTemplate"
                      label="Is System Wide Template"
                      value={props.values.isSystemTemplate}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={!!this.state.editTemplate.name}
                    />
                    <FormikTextField
                      name="name"
                      label="Name"
                      value={props.values.name}
                      errorText={props.errors.name}
                      touched={props.touched.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      disabled={props.isSubmitting}
                      required={!Boolean(this.state.editTemplate.name)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="driveId"
                      label="Drive Id"
                      value={props.values.driveId}
                      errorText={props.errors.driveId}
                      touched={props.touched.driveId}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      disabled={props.isSubmitting}
                      required={!Boolean(this.state.editTemplate)}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.isSuccess}
                    variant="contained"
                  >
                    {props.status.creating ? "Create" : "Update"}
                  </FormikSynchronousButton>
                </Grid>
              </form>
            </Paper>
            {this.state.editTemplate.name && (
              <Paper
                className={classNames(classes.bottomPaper, classes.paper)}
                elevation={3}
              >
                <Typography variant="h6">Delete</Typography>
                <Typography variant="subtitle1">Remove the template</Typography>
                <Grid container>
                  <Grid item>
                    <FormikDeleteButton
                      isSubmitting={props.isSubmitting}
                      onConfirm={() => {
                        if (this.state.editTemplate.isSystemTemplate) {
                          this.deleteSystemTemplate(props, enqueueSnackbar);
                        } else {
                          this.deleteTenantTemplate(props, enqueueSnackbar);
                        }
                      }}
                      dialogTitle="Delete template?"
                      confirmText="Delete"
                      dialogContent={
                        "Are you sure you want to delete template " +
                        props.values.name +
                        "?"
                      }
                      disabled={!Boolean(this.state.editTemplate.name)}
                    >
                      Delete
                    </FormikDeleteButton>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }

  private handlePostResponse(
    response: IRestResponse<PostPutTemplateResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<ITemplate>,
    newTemplate: ITemplate
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;
      formikHelpers.setStatus(formikErrors as FormikErrors<ITemplate>);
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newTemplate });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      newTemplate.id = response.result?.id as string;
      this.setState({ isSuccess: true });
      this.props.addTemplate(newTemplate);
      formikHelpers.setSubmitting(false);
      this.props.navigate(RoutePaths.Templates);
      formikHelpers.setStatus({ creating: false });
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }

  private handlePutResponse(
    response: IRestResponse<PostPutTemplateResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<ITemplate>,
    newTemplate: ITemplate,
    values: ITemplate
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;

      formikHelpers.setStatus(formikErrors as FormikErrors<ITemplate>);
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newTemplate });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      this.setState({ isSuccess: true });
      this.props.removeTemplateById(values.id as string);
      this.props.addTemplate(newTemplate);
      formikHelpers.setStatus({ creating: false });
      this.props.navigate(RoutePaths.Templates);
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  populateTemplatesHOC(
    withSnackbar(withRouter(withStyles(styles)(TemplatesForm)))
  )
);
