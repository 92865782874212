import * as React from "react";
import { connect } from "react-redux";
import IGPTTranscriptPrompt from "../../models/app/templates/IGPTTranscriptPrompt";
import ITemplate from "../../models/app/templates/ITemplate";
import ITemplateVariable from "../../models/app/templates/ITemplateVariable";
import ITemplateVariableReplacementStrategy from "../../models/app/templates/ITemplateVariableReplacementStrategy";
import TemplateService from "../../services/TemplatesService";
import Loading from "../loading/Loading";
import {
  TemplatesState,
  populateTemplates,
  populateTemplateVariables,
  populateReplacementReplacementStrategies,
  populateGPTTranscriptPrompts,
} from "../reducers/TemplatesReducer";
import { ApplicationState } from "../store";

const templateService = new TemplateService();

interface PopulateTemplatesComponentProps {
  populateTemplates: (templates: Array<ITemplate>) => void;
  populateTemplateVariables: (templates: Array<ITemplateVariable>) => void;
  populateGPTTranscriptPrompts: (prompts: Array<IGPTTranscriptPrompt>) => void;
  populateReplacementReplacementStrategies: (
    templates: Array<ITemplateVariableReplacementStrategy>
  ) => void;
  templatesState: TemplatesState;
}

interface PopulateTemplatesState {
  wasError: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    templatesState: state.templatesState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    populateTemplates: (templates: Array<ITemplate>) => {
      const action = populateTemplates(templates);
      dispatch(action);
    },
    populateTemplateVariables: (
      templateVariables: Array<ITemplateVariable>
    ) => {
      const action = populateTemplateVariables(templateVariables);
      dispatch(action);
    },
    populateGPTTranscriptPrompts: (prompts: Array<IGPTTranscriptPrompt>) => {
      const action = populateGPTTranscriptPrompts(prompts);
      dispatch(action);
    },
    populateReplacementReplacementStrategies: (
      replacementStrategies: Array<ITemplateVariableReplacementStrategy>
    ) => {
      const action = populateReplacementReplacementStrategies(
        replacementStrategies
      );
      dispatch(action);
    },
  };
};

const populateTemplatesHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class PopulateTemplatesComponent extends React.Component<
    PopulateTemplatesComponentProps,
    PopulateTemplatesState
  > {
    state: PopulateTemplatesState = {
      wasError: false,
    };
    componentDidUpdate() {
      if (!this.props.templatesState.isLoaded) {
        this.getAndPopulateTemplates();
        this.getAndPopulateTemplateVariables();
        this.getAndPopulateTemplateVariableReplacementStrategies();
        this.getAndPopulateGPTTranscriptPrompts();
      }
    }

    componentDidMount() {
      if (!this.props.templatesState.isLoaded) {
        this.getAndPopulateTemplates();
        this.getAndPopulateTemplateVariables();
        this.getAndPopulateTemplateVariableReplacementStrategies();
        this.getAndPopulateGPTTranscriptPrompts();
      }
    }

    private getAndPopulateTemplates() {
      templateService.getTenantTemplates().then((tenantTemplatesResponse) => {
        if (tenantTemplatesResponse.isError) {
          this.setState({ wasError: true });
        } else {
          templateService
            .getSystemTemplates()
            .then((systemTemplatesResponse) => {
              if (systemTemplatesResponse.isError) {
                this.setState({ wasError: true });
              } else {
                if (
                  tenantTemplatesResponse.result &&
                  systemTemplatesResponse.result
                ) {
                  const totalTemplates = tenantTemplatesResponse.result?.concat(
                    systemTemplatesResponse.result
                  );

                  this.props.populateTemplates(totalTemplates);
                }
              }
            });
        }
      });
    }

    private getAndPopulateTemplateVariables() {
      templateService
        .getTenantTemplateVariables()
        .then((tenantTemplateVariablesResponse) => {
          if (tenantTemplateVariablesResponse.isError) {
            this.setState({ wasError: true });
          } else {
            templateService
              .getSystemTemplateVariables()
              .then((systemTemplateVariablesResponse) => {
                if (systemTemplateVariablesResponse.isError) {
                  this.setState({ wasError: true });
                } else {
                  if (
                    tenantTemplateVariablesResponse.result &&
                    systemTemplateVariablesResponse.result
                  ) {
                    const totalTemplates =
                      tenantTemplateVariablesResponse.result?.concat(
                        systemTemplateVariablesResponse.result
                      );

                    this.props.populateTemplateVariables(totalTemplates);
                  }
                }
              });
          }
        });
    }

    private getAndPopulateGPTTranscriptPrompts() {
      templateService
        .getTenantVariablesGPTTranscriptPrompts()
        .then((tenantPromptsResponse) => {
          if (tenantPromptsResponse.isError) {
            this.setState({ wasError: true });
          } else {
            templateService
              .getSystemVariablesGPTTranscriptPrompts()
              .then((systemPromptsResponse) => {
                if (systemPromptsResponse.isError) {
                  this.setState({ wasError: true });
                } else {
                  if (
                    tenantPromptsResponse.result &&
                    systemPromptsResponse.result
                  ) {
                    const totalTemplates = tenantPromptsResponse.result?.concat(
                      systemPromptsResponse.result
                    );

                    this.props.populateGPTTranscriptPrompts(totalTemplates);
                  }
                }
              });
          }
        });
    }

    private getAndPopulateTemplateVariableReplacementStrategies() {
      templateService
        .getTemplateVariableReplacementStrategies()
        .then((replacementStrategies) => {
          if (replacementStrategies.isError) {
            this.setState({ wasError: true });
          } else {
            if (replacementStrategies.result) {
              this.props.populateReplacementReplacementStrategies(
                replacementStrategies.result
              );
            }
          }
        });
    }

    render() {
      return this.props.templatesState.isLoaded && !this.state.wasError ? (
        <Component {...(this.props as P)} />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PopulateTemplatesComponent);
};

export default populateTemplatesHOC;
