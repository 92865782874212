import * as React from "react";
import { ApplicationState } from "../store";
import { connect, ConnectedProps } from "react-redux";
import TenantService from "../../services/TenantService";
import Loading from "../loading/Loading";
import {
  OrganizationState,
  populateOrganization,
} from "../reducers/OrganizationReducer";
import { User } from "oidc-client";
import { MapState } from "immer/dist/internal";

export interface withOrganizationProps {
  organization: OrganizationState;
}

interface PopulateOrganizationNameComponentState {
  wasError: boolean;
}

// This was tricky. Follow this pattern for Nested, Connected HOCS:
// https://github.com/piotrwitek/react-redux-typescript-guide#--nested-hoc---wrapping-a-component-injecting-props-and-connecting-to-redux-

const populateOrganizationNameHOC = <P extends withOrganizationProps>(
  Component: React.ComponentType<P>
) => {
  const mapStateToProps = (state: ApplicationState) => {
    return { organization: state.organizationState, user: state.oidc.user };
  };

  const mapDispatchToProps = (dispatch: any) => {
    return {
      populateOrganization: (organizationName: string) => {
        const action = populateOrganization(organizationName);
        dispatch(action);
      },
    };
  };

  type HocProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

  class PopulateOrganizationNameComponent extends React.Component<
    HocProps,
    PopulateOrganizationNameComponentState
  > {
    tenantService = new TenantService();

    state: PopulateOrganizationNameComponentState = {
      wasError: false,
    };

    componentDidUpdate(prevProps: HocProps) {
      if (
        this.props.organization.tenantId !== prevProps.organization.tenantId
      ) {
        this.tenantService
          .getOrganizationName(this.props.organization.tenantId)
          .then((organizationResponse) => {
            if (organizationResponse.isError) {
              this.setState({ wasError: true });
            } else {
              if (organizationResponse.result) {
                this.props.populateOrganization(
                  organizationResponse.result.organizationName
                );
              }
            }
          });
      }
    }

    componentDidMount() {
      if (!this.props.organization.isLoaded) {
        this.tenantService
          .getOrganizationName(this.props.organization.tenantId)
          .then((organizationResponse) => {
            if (organizationResponse.isError) {
              this.setState({ wasError: true });
            } else {
              if (organizationResponse.result) {
                this.props.populateOrganization(
                  organizationResponse.result.organizationName
                );
              }
            }
          });
      }
    }

    render() {
      return this.props.organization.isLoaded && !this.state.wasError ? (
        <Component {...(this.props as unknown as P)} />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  var connector = connect<
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    Omit<P, keyof withOrganizationProps>,
    ApplicationState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(PopulateOrganizationNameComponent);
  return connector;
};

export default populateOrganizationNameHOC;
