import { Box } from '@mui/material';
import * as React from 'react';
import Constants from '../../theme/Constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      // sx={{ height: `calc(100% - ${Constants.HEIGHT.TOOLBAR}px - 32px - 48px - 40px - 76.5px)` }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </Box>
  );
}