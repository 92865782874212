import * as React from 'react';
import { RoutesProps, Route, Routes } from 'react-router-dom';
import RoutePaths from './RoutePaths';
import AppLayout from '../app/AppLayout';
import LandingLayout from '../app/landing/LandingLayout';
import Callback from '../app/auth/Callback';
import NotFound from '../app/error/NotFound';
import LoginRedirect from '../app/auth/LoginRedirect';
import LogoutRedirect from '../app/auth/LogoutRedirect';
import ConfirmUser from '../app/landing/confirmations/ConfirmUser';
import PasswordReset from '../app/landing/confirmations/PasswordReset';
import EmailChange from '../app/landing/confirmations/EmailChange';
import GenerateDraft from '../app/landing/documents/GenerateDraft';
import ErrorPage from '../app/error/Error';
import Dashboard from '../app/dashboard/Dashboard';
import Account from '../app/account/Account';
import OrganizationForm from '../app/organization/OrganizationForm';
import EnterDomain from '../app/landing/enterDomain/EnterDomain';
import Users from '../app/users/Users';
import UserForm from '../app/users/UserForm';
import Integrations from '../app/integrations/Integrations';
import IntegrationsNew from '../app/integrations/form/IntegrationsNew';
import WebhookIntegrationForm from '../app/integrations/form/GongIntegrationForm';
import ZoomIntegrationForm from '../app/integrations/form/ZoomIntegrationForm';
import SalesforceIntegrationForm from '../app/integrations/form/SalesforceIntegrationForm';
import GmailIntegrationForm from '../app/integrations/form/GmailIntegrationForm';
import HubspotIntegrationForm from '../app/integrations/form/HubspotIntegrationForm';
import AuthorizeGmail from '../app/integrations/AuthorizeGmail';
import AuthorizeZoom from '../app/integrations/AuthorizeZoom';
import AuthorizeSalesforce from '../app/integrations/AuthorizeSalesforce';
import UploadCustomPhrases from '../app/documents/UploadCustomPhrases';
import UploadRecording from '../app/documents/UploadRecording';
import UploadTranscript from '../app/documents/UploadTranscript';
import AiSearch from '../app/search/AiSearch';
import Webhooks from '../app/webhooks/Webhooks';
import Templates from '../app/templates/Templates';
import TemplatesForm from '../app/templates/TemplatesForm';
import TemplateVariables from '../app/templates/TemplateVariables';
import TemplateVariablesForm from '../app/templates/TemplateVariablesForm';
import OpportunityMappingForm from '../app/opportunities/OpportunityFieldMappingForm';
import OpportunityTasks from '../app/opportunities/OpportunityMeetingTasks';
import ExtensionOpportunityTasks from '../app/opportunities/ExtensionOpportunityMeetingTasks';
import OpportunityMappings from '../app/opportunities/OpportunityMappings';
import ProtectedRoute from './ProtectedRoute';
import { RoleEnum } from '../models/RoleEnum';
import { isExtension } from '../helpers/Helpers';

class MainRoutes extends React.Component<RoutesProps> {
  render() {
    const extension = isExtension();

    return (
      <Routes>
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<EnterDomain />} />
          <Route path={RoutePaths.Login} element={<LoginRedirect />} />
          <Route path={RoutePaths.Logout} element={<LogoutRedirect />} />
          <Route path={RoutePaths.ConfirmUser} element={<ConfirmUser />} />
          <Route path={RoutePaths.PasswordReset} element={<PasswordReset />} />
          <Route path={RoutePaths.ChangeEmail} element={<EmailChange />} />
          <Route path={RoutePaths.GenerateDraft} element={<GenerateDraft />} />
        </Route>
        <Route path="/" element={<AppLayout />}>
          <Route path={RoutePaths.Dashboard} element={<Dashboard />} />
          <Route path={RoutePaths.Account} element={<Account />} />
          <Route
            path={RoutePaths.Organization}
            element={
              <ProtectedRoute
                roles={[RoleEnum.ADMIN, RoleEnum.OWNER, RoleEnum.SUPPORTADMIN]}
              >
                <OrganizationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.UploadCustomPhrases}
            element={
              <ProtectedRoute roles={[RoleEnum.OWNER, RoleEnum.SUPPORTADMIN]}>
                <UploadCustomPhrases />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.Webhooks}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <Webhooks />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.Users}
            element={
              <ProtectedRoute
                roles={[RoleEnum.ADMIN, RoleEnum.OWNER, RoleEnum.SUPPORTADMIN]}
              >
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.UsersNew}
            element={
              <ProtectedRoute
                roles={[RoleEnum.ADMIN, RoleEnum.OWNER, RoleEnum.SUPPORTADMIN]}
              >
                <UserForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.UsersEdit}
            element={
              <ProtectedRoute
                roles={[RoleEnum.ADMIN, RoleEnum.OWNER, RoleEnum.SUPPORTADMIN]}
              >
                <UserForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.Integrations}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <Integrations />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsNew}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <IntegrationsNew />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsNewGong}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <WebhookIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsEditGong}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <WebhookIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsNewZoom}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <ZoomIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsEditZoom}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <ZoomIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsNewSalesforce}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <SalesforceIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsEditSalesforce}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <SalesforceIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsNewGmail}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <GmailIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsEditGmail}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <GmailIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsNewHubspot}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <HubspotIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsEditHubspot}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <HubspotIntegrationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsAuthorizeGmail}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <AuthorizeGmail />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsAuthorizeZoom}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <AuthorizeZoom />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.IntegrationsAuthorizeSalesforce}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <AuthorizeSalesforce />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.Templates}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <Templates />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.TemplatesNew}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <TemplatesForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.TemplatesEdit}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <TemplatesForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.TemplateVariables}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <TemplateVariables />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.TemplateVariablesNew}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <TemplateVariablesForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.TemplateVariablesEdit}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <TemplateVariablesForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.Opportunities}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORTADMIN]}>
                <OpportunityMappings />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.OpportunityMapping}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORTADMIN]}>
                <OpportunityMappingForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.UploadRecording}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <UploadRecording />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.UploadTranscript}
            element={
              <ProtectedRoute roles={[RoleEnum.SUPPORT, RoleEnum.SUPPORTADMIN]}>
                <UploadTranscript />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.AiSearch}
            element={
              <ProtectedRoute
                roles={[
                  RoleEnum.USER,
                  RoleEnum.ADMIN,
                  RoleEnum.OWNER,
                  RoleEnum.SUPPORTADMIN,
                ]}
              >
                <AiSearch />
              </ProtectedRoute>
            }
          />

          <Route
            path={RoutePaths.OpportunityTasks}
            element={
              extension ? <ExtensionOpportunityTasks /> : <OpportunityTasks />
            }
          />
          <Route
            path={RoutePaths.ExtensionOpportunityTasks}
            element={<ExtensionOpportunityTasks />}
          />
          <Route
            path={RoutePaths.OpportunityTasksReview}
            element={<OpportunityTasks />}
          />
        </Route>
        <Route path={'/error/:code?'} element={<ErrorPage />} />
        <Route
          caseSensitive={true}
          path={RoutePaths.Callback}
          element={<Callback />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

export default MainRoutes;
