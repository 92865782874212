import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import UserManager from '../auth/UserManager';
import RoutePaths from '../../routing/RoutePaths';
import { withRouter, WithRouterProps } from './withRouter';
import { setTenantId } from '../reducers/OrganizationReducer';
import { UserProfile } from '../../models/UserProfile';
import { isExtension } from '../../helpers/Helpers';
import Delayed from '../loading/Delayed';
import ExtensionErrorPage from '../../app/error/ExtensionError';

type AuthorizedRouteProps = StateProps & DispatchProps & OwnProps;

interface StateProps {
  user?: Oidc.User;
  isLoadingUser: boolean;
}
interface DispatchProps {
  setTenantId: (tenantId: string) => void;
}

interface OwnProps extends WithRouterProps {}

interface AuthorizedRouteState {
  isAuthorized: boolean;
  path?: string;
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTenantId: (tenantId: string) => {
      const action = setTenantId(tenantId);
      dispatch(action);
    },
  };
};

const authorizedRoute = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class AuthorizedRouteComponent extends React.Component<
    AuthorizedRouteProps,
    AuthorizedRouteState
  > {
    state: AuthorizedRouteState = {
      isAuthorized: false,
      path: undefined,
    };

    UNSAFE_componentWillMount() {
      this.checkUserIsAuthorized();
    }

    componentDidUpdate(prevProps: AuthorizedRouteProps) {
      if (
        prevProps.user !== this.props.user ||
        prevProps.isLoadingUser !== this.props.isLoadingUser ||
        prevProps.path !== this.props.path
      ) {
        this.checkUserIsAuthorized();
      }
    }

    checkUserIsAuthorized() {
      if (!this.props.isLoadingUser) {
        if (this.props.user && !this.props.user.expired) {
          this.props.setTenantId(
            (this.props.user.profile as UserProfile).tenantId
          );
          this.setState({ isAuthorized: true });
        } else if (!this.props.user || this.props.user.expired) {
          if (!isExtension(this.props.path)) {
            UserManager.signinRedirect({ state: { path: this.props.path } });
          } else {
            UserManager.signinSilent();
          }
        } else {
          this.props.navigate(RoutePaths.Landing);
        }
      }
    }

    render() {
      if (this.state.isAuthorized) return <Component {...(this.props as P)} />;
      if (isExtension(this.props.path)) {
        return (
          // TODO: Avoid using Delayed by relying on when signinSilent is done (after .then)
          <Delayed>
            <ExtensionErrorPage></ExtensionErrorPage>
          </Delayed>
        );
      }
      return null;
    }
  }
  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthorizedRouteComponent)
  );
};

export default authorizedRoute;
