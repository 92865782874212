import * as React from "react";
import { Theme, Grid, TableFooter } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { capitalCase } from "change-case";
import { User } from "oidc-client";
import { connect } from "react-redux";
import RoutePaths from "../../routing/RoutePaths";
import CustomAddToolbar from "../muiDataTable/CustomAddToolbar";
import {
  TemplatesState,
  addTemplateVariable,
} from "../reducers/TemplatesReducer";
import { ApplicationState } from "../store";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { WithSnackbarProps } from "notistack";
import format from "date-fns/format";
import ITemplateVariable from "../../models/app/templates/ITemplateVariable";
import populateTemplatesHOC from "../hocs/PopulateTemplatesHOC";
import { userIsInRole } from "../../helpers/Helpers";
import { RoleEnum } from "../../models/RoleEnum";

const MUIDataTable = require("mui-datatables").default;

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2),
    },
    footer: {
      display: "block",
      height: "54px",
    },
    tableLogoWidth: {
      width: "100px",
    },
  });

interface TemplateVariablesProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  user: User;
  templatesState: TemplatesState;
  addTemplateVariable: (templateVariable: ITemplateVariable) => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    templatesState: state.templatesState,
    user: state.oidc.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTemplate: (template: ITemplateVariable) => {
      const action = addTemplateVariable(template);
      dispatch(action);
    },
  };
};

class TemplateVariables extends React.Component<TemplateVariablesProps> {
  refs: {
    query: HTMLInputElement;
  };

  editTemplate = (rowData: string[]) => {
    this.props.navigate(
      RoutePaths.TemplateVariablesEdit.replace(
        ":templateVariableId",
        rowData[0]
      )
    );
  };

  redirectToNewTemplateForm = () => {
    this.props.navigate(RoutePaths.TemplateVariablesNew);
  };

  mapTemplateVariablesToTableTemplateVariables(
    templateVariables: ITemplateVariable[]
  ): Array<Array<any>> {
    const tableTemplateVariables = new Array<Array<any>>();
    if (templateVariables) {
      templateVariables.forEach((value) => {
        let scope = value.isSystemTemplateVariable ? "System" : "Customer";
        tableTemplateVariables.push([
          value.id,
          capitalCase(value.name),
          scope,
          value.replacementStrategy,
        ]);
      });
    }
    return tableTemplateVariables;
  }

  columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Template Scope",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Strategy",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  options = {
    textLabels: {
      body: {
        noMatch: "No template variables have been added yet.",
      },
    },
    print: false,
    download: false,
    customToolbar: () => {
      if (userIsInRole(this.props.user, RoleEnum.SUPPORT)) {
        return;
      }
      return <CustomAddToolbar onClick={this.redirectToNewTemplateForm} />;
    },
    rowsPerPage: 100,
    elevation: 3,
    selectableRows: "none",
    responsive: "simple",
    viewColumns: false,
    tableBodyMaxHeight: "calc(100vh - 64px - 48px - 64px - 52px)",
    onRowClick: this.editTemplate,
    filter: true,
    search: true,
    sort: true,
  };

  render() {
    const { classes, templatesState } = this.props;

    return (
      <Grid className={classes.grid} container alignItems="center">
        <Grid item xs={12}>
          <MUIDataTable
            title={"Template Variables"}
            data={this.mapTemplateVariablesToTableTemplateVariables(
              templatesState.templateVariables
            )}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(populateTemplatesHOC(withRouter(TemplateVariables))));
