import * as React from 'react';
import { Typography, Theme, Grid, Paper, Button } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FormikSynchronousButton from '../form/FormikSynchronousButton';
import DocumentService from '../../services/DocumentService';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import { IError } from '../../services/RestUtilities';
import SingleServerErrorMessage from '../form/ServerServerErrorMessage';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Constants from '../../theme/Constants';
import IUploadRecordingFileModel from '../../models/files/IUploadRecordingFileModel';
import FormikTextField from '../form/FormikTextField';
import parse from 'date-fns/parse';
import FormikCheckbox from '../form/FormikCheckbox';
const documentService = new DocumentService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      paddingTop: '3%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    flexButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    paper: {
      padding: theme.spacing(4),
    },
    fileName: {
      marginLeft: '10px',
    },
  });

interface UploadRecordingProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {}

interface UploadRecordingState {
  success: boolean;
  serverErrors?: IError;
  error: string;
}

class UploadRecording extends React.Component<
  UploadRecordingProps,
  UploadRecordingState
> {
  validationSchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
    hostEmail: Yup.string().email('Email is invalid'),
    hostName: Yup.string().required('Name is required'),
    meetingTitle: Yup.string().required('Meeting title is required'),
    meetingTime: Yup.string().required('Time is required'),
    meetingDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = parse(originalValue, 'dd.MM.yyyy', new Date());
        return result;
      })
      .typeError('Date is not valid')
      .required('Meeting date is required'),
  });

  state: UploadRecordingState = {
    success: false,
    serverErrors: undefined,
    error: '',
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography align="center" variant="h5">
            Process Recording
          </Typography>
          <Formik
            initialValues={
              {
                file: undefined,
                hostEmail: '',
                hostName: '',
                meetingTitle: '',
                meetingDate: new Date().toISOString().slice(0, 10),
                meetingTime: '12:00',
                useExisting: false,
              } as IUploadRecordingFileModel
            }
            onSubmit={(
              values: IUploadRecordingFileModel,
              formikHelpers: FormikHelpers<IUploadRecordingFileModel>
            ) => {
              documentService
                .postUploadedFileMetaData(values)
                .then(response => {
                  if (response.isError) {
                    formikHelpers.setSubmitting(false);
                    this.setState({ serverErrors: response.error });
                  } else {
                    if (response.result && response.result['folderId']) {
                      documentService
                        .postProcessUploadedFile(
                          values.hostEmail,
                          response.result['folderId'],
                          values.file!
                        )
                        .then(response => {
                          this.props.enqueueSnackbar(response.message, {
                            variant: 'success',
                          });
                          formikHelpers.setSubmitting(false);

                          this.setState({
                            success: true,
                            serverErrors: undefined,
                            error: '',
                          });
                          formikHelpers.resetForm();
                          formikHelpers.setFieldValue('file', null, false);
                        });
                    }
                  }
                });
            }}
            validateOnMount={true}
            initialErrors={{}}
            validationSchema={this.validationSchema}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="hostEmail"
                      label="Host Email"
                      value={props.values.hostEmail}
                      errorText={props.errors.hostEmail}
                      touched={props.touched.hostEmail}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="hostName"
                      label="Host Name"
                      value={props.values.hostName}
                      errorText={props.errors.hostName}
                      touched={props.touched.hostName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingTitle"
                      label="Meeting Title"
                      value={props.values.meetingTitle}
                      errorText={props.errors.meetingTitle}
                      touched={props.touched.meetingTitle}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingDate"
                      label="Meeting Date"
                      value={props.values.meetingDate}
                      errorText={props.errors.meetingDate}
                      touched={props.touched.meetingDate}
                      placeholder="yyyy-mm-dd"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikTextField
                      name="meetingTime"
                      label="Meeting Time"
                      value={props.values.meetingTime}
                      errorText={props.errors.meetingTime}
                      touched={props.touched.meetingTime}
                      placeholder="hh:mm"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      required
                    />
                    <br />
                    <FormikCheckbox
                      name="useExisting"
                      label="Use Existing Templates"
                      value={props.values.useExisting}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <br />
                    <Button variant="contained" component="label">
                      Select Audio/Video File
                      <input
                        name="file"
                        hidden
                        accept="audio/*,video/*"
                        multiple
                        type="file"
                        onChange={event => {
                          const inputFile = event.target.files![0];
                          props.setFieldValue('file', inputFile);
                        }}
                      />
                    </Button>
                    <span className={classes.fileName}>
                      {props.values.file?.name}
                    </span>
                  </Grid>
                </Grid>
                {this.state.serverErrors && (
                  <SingleServerErrorMessage
                    message={this.state.serverErrors.message}
                    formikErrors={this.state.serverErrors.formikErrors}
                  />
                )}
                {this.state.error && (
                  <SingleServerErrorMessage message={this.state.error} />
                )}
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Process Recording
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(withRouter(UploadRecording))
);
