import * as React from 'react';
import {
  Theme,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Grid,
  ButtonBase,
  IconButton,
  Paper,
  Box,
} from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import populateIntegrationsHOC from '../../hocs/PopulateIntegrationsHOC';
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import classNames from 'classnames';
import { withRouter, WithRouterProps } from '../../hocs/withRouter';
import { IntegrationEnum } from '../../../models/app/integrations/IntegrationEnum';
import RoutePaths from '../../../routing/RoutePaths';
import zoomLogo from '../../../assets/zoom-logo.png';
import gmailLogo from '../../../assets/gmail-logo.png';
import gongLogo from '../../../assets/gong-logo.webp';
import outreachLogo from '../../../assets/outreach-logo.png';
import hubspotLogo from '../../../assets/hubspot-logo.png';
import salesforceLogo from '../../../assets/salesforce-logo.png';
import { userIsInRole } from '../../../helpers/Helpers';
import { ApplicationState } from '../../store';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { RoleEnum } from '../../../models/RoleEnum';
import { IntegrationsState } from '../../reducers/IntegrationReducer';
import { UserProfile } from '../../../models/UserProfile';
import Constants from '../../../theme/Constants';

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    root: {
      display: 'flex',
      minWidth: '10%',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    grid: {
      padding: theme.spacing(2),
    },
    card: {
      flexGrow: 1,
      height: '100%',
    },
    title: {
      marginTop: '0px',
      padding: '0px',
    },
    return: {
      position: 'sticky',
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    mediaRoot: {
      backgroundSize: 'contain',
      // width: "50%"
    },
    buttonBase: {
      minWidth: '100%',
    },
    inactiveButtonBase: {
      minWidth: '100%',
      cursor: 'default',
    },
    caption: {
      fontStyle: 'italic',
      fontSize: '12px',
      fontWeight: 300,
    },
    installed: {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
    },
  });

interface IntegrationsProps extends WithStyles<typeof styles>, WithRouterProps {
  user: User;
  integrationsState: IntegrationsState;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
    integrationsState: state.integrationsState,
  };
};

class IntegrationsNew extends React.Component<IntegrationsProps> {
  handleIntegrationSelect = (integrationType: IntegrationEnum) => {
    switch (integrationType) {
      case IntegrationEnum.GONG: {
        this.props.navigate(RoutePaths.IntegrationsNewGong);
        break;
      }
      case IntegrationEnum.ZOOM: {
        this.props.navigate(RoutePaths.IntegrationsNewZoom);
        break;
      }
      case IntegrationEnum.SALESFORCE: {
        this.props.navigate(RoutePaths.IntegrationsNewSalesforce);
        break;
      }
      case IntegrationEnum.GMAIL: {
        this.props.navigate(RoutePaths.IntegrationsNewGmail);
        break;
      }
      case IntegrationEnum.HUBSPOT: {
        this.props.navigate(RoutePaths.IntegrationsNewHubspot);
        break;
      }
    }
  };

  hasUserIntegrationInstalled = (integration: IntegrationEnum) =>
    this.props.integrationsState.integrations.findIndex(
      i =>
        i.createdBy === (this.props.user.profile as UserProfile).sub &&
        i.type === integration
    ) >= 0;

  hasGongIntegrationInstalled = () =>
    this.props.integrationsState.integrations.findIndex(
      i => i.type === IntegrationEnum.GONG
    ) >= 0;

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <IconButton
          className={classes.return}
          onClick={() => this.props.navigate(RoutePaths.Integrations)}
        >
          <ArrowLeft />
        </IconButton>
        <Typography
          className={classNames(classes.title, classes.layout)}
          variant="h5"
          align="left"
        >
          New Integration Type
        </Typography>
        <div className={classes.layout}>
          <Grid container spacing={3} direction="column" alignItems="center">
            <Grid container item>
              <Grid item xs={12}>
                <Paper
                  elevation={
                    this.hasUserIntegrationInstalled(IntegrationEnum.ZOOM)
                      ? 0
                      : 3
                  }
                >
                  <ButtonBase
                    className={classes.buttonBase}
                    onClick={() => {
                      this.handleIntegrationSelect(IntegrationEnum.ZOOM);
                    }}
                    disabled={this.hasUserIntegrationInstalled(
                      IntegrationEnum.ZOOM
                    )}
                  >
                    <Card elevation={0} className={classes.root}>
                      <CardMedia
                        classes={{ root: classes.mediaRoot }}
                        className={classes.cover}
                        image={zoomLogo}
                        sx={{ scale: '90%' }}
                        title="Zoom"
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography variant="h6">
                            Zoom{' '}
                            {this.hasUserIntegrationInstalled(
                              IntegrationEnum.ZOOM
                            ) && (
                              <span className={classes.installed}>
                                Installed!
                              </span>
                            )}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Automate your sales follow-ups from Zoom meeting
                            recordings.
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                  </ButtonBase>
                </Paper>
              </Grid>
            </Grid>
            {(userIsInRole(this.props.user, RoleEnum.ADMIN) ||
              userIsInRole(this.props.user, RoleEnum.OWNER) ||
              userIsInRole(this.props.user, RoleEnum.SUPPORT) ||
              userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN)) && (
              <Grid container item>
                <Grid item xs={12}>
                  <Paper elevation={this.hasGongIntegrationInstalled() ? 0 : 3}>
                    <ButtonBase
                      className={classes.buttonBase}
                      onClick={e => {
                        // e.preventDefault();
                        this.handleIntegrationSelect(IntegrationEnum.GONG);
                      }}
                      disabled={this.hasGongIntegrationInstalled()}
                    >
                      <Card elevation={0} className={classes.root}>
                        <CardMedia
                          classes={{ root: classes.mediaRoot }}
                          className={classes.cover}
                          image={gongLogo}
                          sx={{ scale: '90%' }}
                          title="Gong"
                        />
                        <div className={classes.details}>
                          <CardContent className={classes.content}>
                            <Typography variant="h6">
                              Gong{' '}
                              {this.hasGongIntegrationInstalled() && (
                                <span className={classes.installed}>
                                  Installed!
                                </span>
                              )}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              Automate your sales follow-ups from Gong call
                              recordings.
                            </Typography>
                          </CardContent>
                        </div>
                      </Card>
                    </ButtonBase>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {(userIsInRole(this.props.user, RoleEnum.OWNER) ||
              userIsInRole(this.props.user, RoleEnum.ADMIN) ||
              userIsInRole(this.props.user, RoleEnum.USER)) && (
              <Grid container item>
                <Grid item xs={12}>
                  <Paper
                    elevation={
                      this.hasUserIntegrationInstalled(
                        IntegrationEnum.SALESFORCE
                      )
                        ? 0
                        : 3
                    }
                  >
                    <ButtonBase
                      className={classes.buttonBase}
                      onClick={() => {
                        this.handleIntegrationSelect(
                          IntegrationEnum.SALESFORCE
                        );
                      }}
                      disabled={this.hasUserIntegrationInstalled(
                        IntegrationEnum.SALESFORCE
                      )}
                    >
                      <Card elevation={0} className={classes.root}>
                        <CardMedia
                          classes={{ root: classes.mediaRoot }}
                          className={classes.cover}
                          image={salesforceLogo}
                          sx={{ scale: '90%' }}
                          title="Salesforce"
                        />
                        <div className={classes.details}>
                          <CardContent className={classes.content}>
                            <Typography variant="h6">
                              Salesforce{' '}
                              {this.hasUserIntegrationInstalled(
                                IntegrationEnum.SALESFORCE
                              ) && (
                                <span className={classes.installed}>
                                  Installed!
                                </span>
                              )}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              Associate high leverage sales recording content
                              with your opportunities and accounts.
                            </Typography>
                          </CardContent>
                        </div>
                      </Card>
                    </ButtonBase>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {(userIsInRole(this.props.user, RoleEnum.ADMIN) ||
              userIsInRole(this.props.user, RoleEnum.OWNER) ||
              userIsInRole(this.props.user, RoleEnum.SUPPORT) ||
              userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN)) && (
              <>
                <Grid container item>
                  <Grid item xs={12}>
                    <Paper
                      elevation={
                        this.hasUserIntegrationInstalled(
                          IntegrationEnum.HUBSPOT
                        )
                          ? 0
                          : 3
                      }
                    >
                      <ButtonBase
                        className={classes.buttonBase}
                        onClick={() => {
                          this.handleIntegrationSelect(IntegrationEnum.HUBSPOT);
                        }}
                        disabled={this.hasUserIntegrationInstalled(
                          IntegrationEnum.HUBSPOT
                        )}
                      >
                        <Card elevation={0} className={classes.root}>
                          <CardMedia
                            classes={{ root: classes.mediaRoot }}
                            className={classes.cover}
                            image={hubspotLogo}
                            sx={{ scale: '90%' }}
                            title="Hubspot"
                          />
                          <div className={classes.details}>
                            <CardContent className={classes.content}>
                              <Typography variant="h6">
                                Hubspot{' '}
                                {this.hasUserIntegrationInstalled(
                                  IntegrationEnum.HUBSPOT
                                ) && (
                                  <span className={classes.installed}>
                                    Installed!
                                  </span>
                                )}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                Associate high leverage sales recording content
                                with your deals.
                              </Typography>
                            </CardContent>
                          </div>
                        </Card>
                      </ButtonBase>
                    </Paper>
                  </Grid>
                </Grid>
                {/* <Grid container item>
                    <Grid item xs={12}>
                      <Paper elevation={3}>
                        <ButtonBase
                          className={classes.inactiveButtonBase}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Card elevation={0} className={classes.root}>
                            <CardMedia
                              classes={{ root: classes.mediaRoot }}
                              className={classes.cover}
                              image={gmailLogo}
                              sx={{ marginLeft: "10px" }}
                              title="Gmail"
                            />
                            <div className={classes.details}>
                              <CardContent className={classes.content}>
                                <Typography variant="h6">Gmail</Typography>
                                <Typography
                                  className={classes.caption}
                                  variant="caption"
                                >
                                  Coming Soon
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  Receive your follow-up email drafts directly in
                                  your Gmail’s Drafts folder.
                                </Typography>
                              </CardContent>
                            </div>
                          </Card>
                        </ButtonBase>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={12}>
                      <Paper elevation={3}>
                        <ButtonBase
                          className={classes.inactiveButtonBase}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Card elevation={0} className={classes.root}>
                            <CardMedia
                              classes={{ root: classes.mediaRoot }}
                              className={classes.cover}
                              image={outreachLogo}
                              sx={{ marginLeft: "10px" }}
                              title="Outreach"
                            />
                            <div className={classes.details}>
                              <CardContent className={classes.content}>
                                <Typography variant="h6">Outreach</Typography>
                                <Typography
                                  className={classes.caption}
                                  variant="caption"
                                >
                                  Coming Soon
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  Personalize your drip campaigns at scale from
                                  high leverage sales recording content.
                                </Typography>
                              </CardContent>
                            </div>
                          </Card>
                        </ButtonBase>
                      </Paper>
                    </Grid>
                  </Grid> */}
              </>
            )}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(styles)(populateIntegrationsHOC(IntegrationsNew))));
