import { RoleEnum } from '../models/RoleEnum';
import { UserProfile } from '../models/UserProfile';
import { User } from 'oidc-client';
import { IntegrationEnum } from '../models/app/integrations/IntegrationEnum';
import zoomLogo from '../assets/zoom-logo.png';
import gongLogo from '../assets/gong-logo.webp';
import hubspotLogo from '../assets/hubspot-logo.png';
import salesforceLogo from '../assets/salesforce-logo.png';
import { IntegrationsState } from '../app/reducers/IntegrationReducer';
import { parse } from 'path';

export function userIsInRole(user?: User, role?: RoleEnum) {
  if (user && role) {
    return (
      ((user.profile as UserProfile).role as string).toUpperCase() ===
      role.toUpperCase()
    );
  }
  return false;
}

export function userNotInRole(user?: User, role?: RoleEnum) {
  if (user && role) {
    return (
      ((user.profile as UserProfile).role as string).toUpperCase() !==
      role.toUpperCase()
    );
  }
  return true;
}

export function userIncludesRole(user?: User, roles?: Array<RoleEnum>) {
  if (user && roles) {
    var stringRoles = roles?.map(role => role.toUpperCase());
    return stringRoles?.includes(
      (user.profile as UserProfile).role.toString().toUpperCase()
    );
  }
  return false;
}

export function userIsInternal(user?: User) {
  return user && user.profile.email?.endsWith('@cquence.app');
}

export function hasIntegrationInstalled(
  integrations: IntegrationsState,
  integration: IntegrationEnum
) {
  return integrations.integrations.some(i => i.type === integration);
}

export function replaceNullOrUndefinedProperties<T extends Object>(
  baseObject: T,
  presidenceObject: T
): T {
  const result = {
    ...baseObject,
  };
  Object.entries(presidenceObject).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      (result as any)[key] = value;
    }
  });
  return result;
}

export function getCascadedRoles(role: string): RoleEnum[] {
  var roles = new Array<RoleEnum>();

  if (role === RoleEnum.OWNER) {
    roles.push(RoleEnum.OWNER);
    roles.push(RoleEnum.ADMIN);
    roles.push(RoleEnum.USER);
  } else if (role === RoleEnum.ADMIN) {
    roles.push(RoleEnum.ADMIN);
    roles.push(RoleEnum.USER);
  } else {
    roles.push(RoleEnum.USER);
  }
  return roles;
}

export function getRole(roles: string | string[]) {
  if (Array.isArray(roles)) {
    if (roles.find(r => r === RoleEnum.OWNER)) {
      return RoleEnum.OWNER;
    } else if (roles.find(r => r === RoleEnum.ADMIN)) {
      return RoleEnum.ADMIN;
    } else {
      return RoleEnum.USER;
    }
  } else {
    return roles;
  }
}

export function getLogoForIntegrationType(integrationType: IntegrationEnum) {
  switch (integrationType) {
    case IntegrationEnum.ZOOM: {
      return zoomLogo;
    }
    case IntegrationEnum.SALESFORCE: {
      return salesforceLogo;
    }
    case IntegrationEnum.GONG: {
      return gongLogo;
    }
    case IntegrationEnum.HUBSPOT: {
      return hubspotLogo;
    }
    default: {
      return '';
    }
  }
}

export function isExtension(path: string = '') {
  const pathStartsWithExtension = path.startsWith('/extension');
  return pathStartsWithExtension || inIframe();
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) return parts.pop()?.split(';').shift();
}

export function setCookie(name: string, value: string) {
  let valueAsJson, defaultValue;

  try {
    valueAsJson = JSON.parse(value);
    defaultValue = Array.isArray(valueAsJson) ? '[]' : '{}';
  } catch (err) {
    // NOTE: Handle non-JSON cookie values
    document.cookie = `${name}=${value || ''};path=/;secure;samesite=none`;
  }

  if (name !== 'EXTENSION:OPPORTUNITY_FIELDS') {
    document.cookie = `${name}=${
      value || defaultValue
    };path=/;secure;samesite=none`;
  } else {
    // NOTE: EXTENSION:OPPORTUNITY_FIELDS can get past the cookie size limit of 4KB,
    // so we may need to split into multiple cookies.
    let totalSize = 0;
    let cookieCount = 1;
    let cookieValue = [] as any[];

    valueAsJson.forEach((field: any, index: number) => {
      cookieValue.push(field);
      const size = new Blob([JSON.stringify(field)]).size;
      totalSize += size;

      if (totalSize > 2000 || index === valueAsJson.length - 1) {
        const cookieName = cookieCount === 1 ? name : `${name}_${cookieCount}`;

        // NOTE: Replace semicolons to prevent cookie parsing issue
        const cookieValueAsString = JSON.stringify(cookieValue).replaceAll(
          ';',
          ':'
        );
        document.cookie = `${cookieName}=${cookieValueAsString};path=/;secure;samesite=none`;

        cookieValue = [];
        cookieCount++;
        totalSize = 0;
      }
    });

    if (cookieCount === 1) {
      document.cookie = `${name}=${
        value || defaultValue
      };path=/;secure;samesite=none`;
    }
  }
}

export function getSalesforceIdFromUrl(
  object: string,
  url: string | null | undefined
) {
  if (!url) return null;

  const match = url.match(`\/${object}\/(.*)\/view`);

  if (match) {
    return match[1];
  }
  return null;
}

export function isCreatingSalesforceOpportunityFromUrl(
  url: string | null | undefined
) {
  if (!url) return null;
  const urlLower = url.toLowerCase();

  return (
    urlLower.includes('opportunity/new') && urlLower.includes('recordtypeid=')
  );
}

// NOTE 1: Not sure if this actually returns the deal ID, also not sure if "0-3" is the deal record for all
// instances or only for our instance e.g. https://app.hubspot.com/contacts/8088768/record/0-3/15456889216
// NOTE 2: The object names "Account" and "Opportunity" do not apply to HubSpot, but are being used to stay
// consistent with the Salesforce implementation.
export function getHubspotIdFromUrl(
  object: string,
  url: string | null | undefined
) {
  if (!url) return null;
  if (!object) return null;

  const matchRegex = `\/contacts/([0-9]+)/record/(.*)/([0-9]+)/?(.*)`;
  const match = url.match(matchRegex);

  // NOTE: Send note does not work on Deal /properties page, so set ID to null
  if (object === 'Account' && url.includes('properties')) return null;

  if (match) {
    // const portalId = match[1];
    const dealId = match[3];
    return dealId;
  }

  return null;
}

// NOTE: No need to return true becuase there is no difference between new and existing deals
export function isCreatingHubspotDealFromUrl(url: string | null | undefined) {
  return false;
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
