import * as React from 'react';
import { Theme, Grid, Button, IconButton, Tooltip } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { IRestResponse } from '../../services/RestUtilities';
import populateOpportunitiesHOC from '../hocs/PopulateOpportunityMappingsHOC';
import populateMeetingTasksHOC from '../hocs/PopulateOpportunityMeetingTasksHOC';
import { OpportunityFieldMappingsState } from '../reducers/OpportunityFieldMappingsReducer';
import { OpportunityMeetingTasksState } from '../reducers/OpportunityMeetingTasksReducer';
import { ApplicationState } from '../store';
import OpportunitiesService from '../../services/OpportunitiesService';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { User } from 'oidc-client';
import IOpportunityMeetingTask from '../../models/app/opportunities/IOpportunityMeetingTask';
import PostSalesforceTaskModel from '../../models/app/opportunities/PostSalesforceTaskModel';
import { IntegrationEnum } from '../../models/app/integrations/IntegrationEnum';
import dayjs from 'dayjs';
import { Eye } from 'mdi-material-ui';
import Popout from './Popout';
import {
  getCookie,
  setCookie,
  getSalesforceIdFromUrl,
  getHubspotIdFromUrl,
  isCreatingSalesforceOpportunityFromUrl,
  isCreatingHubspotDealFromUrl,
  isExtension,
} from '../../helpers/Helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { IntegrationsState } from '../reducers/IntegrationReducer';

const opportunitiesService = new OpportunitiesService();

const MUIDataTable = require('mui-datatables').default;

const snackbarOptions = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
} as any;

const styles = (theme: Theme) =>
  createStyles({
    grid: {},
    MUIDataTable: {
      '& table': {
        margin: '0px 0px',
        width: '100%',
      },
    },
    actionColumnClass: {
      width: '195px',
      verticalAlign: 'top',
    },
  });

interface OpportunityMeetingTasksProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  user: User;
  integrationsState: IntegrationsState;
  opportunityFieldMappingsState: OpportunityFieldMappingsState;
  opportunityMeetingTasksState: OpportunityMeetingTasksState;
}

type OpportunityMeetingTasksInternalState = {
  extensionVersion: string | null;
  contentUrl: string | null;
  accountId: string | null;
  opportunityId: string | null;
  creatingOpportunity: boolean | null;
  popoutAnchorEl: null | React.RefObject<HTMLElement>;
  popoutIsOpen: boolean;
  popoutText: string | undefined;
  hubspotIntegration: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityFieldMappingsState: state.opportunityFieldMappingsState,
    opportunityMeetingTasksState: state.opportunityMeetingTasksState,
    user: state.oidc.user,
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

class OpportunityTasks extends React.Component<
  OpportunityMeetingTasksProps,
  OpportunityMeetingTasksInternalState
> {
  refs: {
    query: HTMLInputElement;
  };
  state = {
    contentUrl: null,
    extensionVersion: null,
    accountId: null,
    opportunityId: null,
    creatingOpportunity: false,
    popoutAnchorEl: React.createRef<HTMLElement>(),
    popoutIsOpen: false,
    popoutText: undefined,
    hubspotIntegration: false,
  };

  componentDidMount() {
    const { enqueueSnackbar } = this.props;

    const url = getCookie('EXTENSION:URL');
    const extensionVersion = getCookie('EXTENSION:VERSION') || null;

    this.setState({
      contentUrl: url || null,
      extensionVersion,
      accountId:
        getSalesforceIdFromUrl('Account', url) ||
        getHubspotIdFromUrl('Account', url),
      opportunityId:
        getSalesforceIdFromUrl('Opportunity', url) ||
        getHubspotIdFromUrl('Opportunity', url),
      creatingOpportunity:
        isCreatingSalesforceOpportunityFromUrl(url) ||
        isCreatingHubspotDealFromUrl(url),
      hubspotIntegration: this.props.integrationsState?.integrations
        .map(i => i.type)
        .includes(IntegrationEnum.HUBSPOT),
    });

    // @ts-ignore
    if (cookieStore) {
      // @ts-ignore
      cookieStore.addEventListener(
        'change',
        ({ changed }: { changed: { name: string; value: string }[] }) => {
          for (const { name, value } of changed) {
            if (name === 'EXTENSION:VERSION') {
              this.setState({ extensionVersion: value });

              datadogLogs.logger.info('Extension version set', {
                ...datadogLogMetadata(this.state, this.props),
              });
            }

            if (name === 'EXTENSION:URL') {
              this.setState({
                contentUrl: value,
                accountId:
                  getSalesforceIdFromUrl('Account', value) ||
                  getHubspotIdFromUrl('Account', value),
                opportunityId:
                  getSalesforceIdFromUrl('Opportunity', value) ||
                  getHubspotIdFromUrl('Opportunity', value),
                creatingOpportunity:
                  isCreatingSalesforceOpportunityFromUrl(value) ||
                  isCreatingHubspotDealFromUrl(value),
              });
            }

            if (name === 'EXTENSION:SEND_OPPORTUNITY_STATUS') {
              const message = this.state.hubspotIntegration
                ? 'Successfully sent deal data to HubSpot'
                : 'Successfully sent opportunity data to Salesforce';

              if (value === 'SUCCESS') {
                enqueueSnackbar(message, {
                  variant: 'success',
                  ...snackbarOptions,
                });

                datadogLogs.logger.info(message, {
                  ...datadogLogMetadata(this.state, this.props),
                });
              }

              if (value === 'FAILURE') {
                const message = this.state.hubspotIntegration
                  ? 'Failed to send deal data to HubSpot. Try refreshing the HubSpot page.'
                  : 'Failed to send opportunity data to Salesforce. Try refreshing the Salesforce page.';

                enqueueSnackbar(message, {
                  variant: 'error',
                  ...snackbarOptions,
                });

                datadogLogs.logger.error(message, {
                  ...datadogLogMetadata(this.state, this.props),
                });
              }
            }

            if (name === 'EXTENSION:SEND_CALL_LOG_STATUS') {
              if (value === 'SUCCESS') {
                enqueueSnackbar('Successfully sent note to HubSpot', {
                  variant: 'success',
                  ...snackbarOptions,
                });

                datadogLogs.logger.info('Successfully sent note to HubSpot', {
                  ...datadogLogMetadata(this.state, this.props),
                });
              }

              if (value === 'FAILURE') {
                enqueueSnackbar('Failed to send call log to HubSpot', {
                  variant: 'error',
                  ...snackbarOptions,
                });

                datadogLogs.logger.error('Failed to send call log to HubSpot', {
                  ...datadogLogMetadata(this.state, this.props),
                });
              }
            }

            if (name === 'EXTENSION:REFRESH') {
              if (value === 'TRUE') {
                setCookie('EXTENSION:REFRESH', 'IDLE');

                window.location.reload();

                datadogLogs.logger.info('Meeting tasks view refreshed', {
                  ...datadogLogMetadata(this.state, this.props),
                });
              }
            }

            if (name === 'EXTENSION:DATADOG_LOG') {
              const valueAsJson = JSON.parse(value) as any;

              if (valueAsJson.level === 'info') {
                datadogLogs.logger.info(valueAsJson.message, {
                  ...datadogLogMetadata(this.state, this.props),
                  fromExtension: true,
                  ...valueAsJson.messageContext,
                });
              }
            }
          }
        }
      );

      const userProfile = this.props.user.profile;
      setCookie(
        'EXTENSION:USER_INFO',
        JSON.stringify({
          userId: userProfile.sub,
          tenantId: userProfile.tenantId,
        })
      );
    } else {
      console.error('cookieStore not available');
    }

    datadogLogs.logger.info('Meeting tasks view opened', {
      ...datadogLogMetadata(this.state, this.props),
    });
  }

  handlePopoutClick = (e: any, popoutText: string) => {
    if (this.state.popoutAnchorEl.current == e.currentTarget) {
      this.closePopout();
    } else {
      this.setState({
        popoutIsOpen: true,
        popoutAnchorEl: { current: e.currentTarget },
        popoutText,
      });
    }
  };

  closePopout = () => {
    this.setState({
      popoutIsOpen: false,
      popoutAnchorEl: { current: null },
      popoutText: undefined,
    });
  };

  postCallLog({
    taskId,
    accountId,
    callLog,
    enqueueSnackbar,
    meetingDate,
  }: {
    taskId: string | null;
    accountId: string | null;
    callLog: string | null;
    enqueueSnackbar: any;
    meetingDate: Date | undefined;
  }) {
    if (!taskId || taskId === '') return;
    if (!accountId || accountId === '') return;
    if (!callLog || callLog === '') return;

    const salesforceTask: PostSalesforceTaskModel = {
      taskId,
      subject: 'Call',
      taskSubtype: 'Call',
      status: 'Completed',
      whatId: accountId,
      description: callLog,
      activityDate: meetingDate || new Date(),
    };
    opportunitiesService
      .postSalesforceTask(salesforceTask)
      .then((response: IRestResponse<any>) => {
        if (response.isError) {
          console.error(response.error);

          enqueueSnackbar('Failed to create call log in Salesforce', {
            variant: 'error',
            ...snackbarOptions,
          });

          datadogLogs.logger.error(
            'Failed to create call log in Salesforce',
            {
              ...datadogLogMetadata(this.state, this.props),
              taskId,
              callLog,
            },
            new Error(response.error?.message)
          );
        } else {
          enqueueSnackbar('Successfully created call log in Salesforce', {
            variant: 'success',
            ...snackbarOptions,
          });

          datadogLogs.logger.info('Created call log in Salesforce', {
            ...datadogLogMetadata(this.state, this.props),
            taskId: response.result,
            callLog,
          });
        }
      });
  }

  sendCallLog({
    callLog,
    hubspotIntegration,
  }: {
    callLog: string;
    hubspotIntegration: boolean;
  }) {
    if (!callLog) return;

    // NOTE: Setting time to force cookie to change and trigger extension cookie watcher
    // For some reason, using max-age is not working, maybe something to do with iFrame?
    const time = new Date().getTime();

    setCookie('EXTENSION:CALL_LOG', JSON.stringify({ callLog, time }));

    const logMessage = hubspotIntegration
      ? 'Sent deal note to HubSpot'
      : 'Sent opportunity call log to Salesforce';
    datadogLogs.logger.info(logMessage, {
      ...datadogLogMetadata(this.state, this.props),
      callLog,
    });
  }

  sendOpportunity({
    fieldValues,
    hubspotIntegration,
  }: {
    fieldValues: any;
    hubspotIntegration: boolean;
  }) {
    if (!fieldValues) return;

    // NOTE: Setting time to force cookie to change and trigger extension cookie watcher
    // For some reason, using max-age is not working, maybe something to do with iFrame?
    const time = new Date().getTime();

    const opportunitiesFields = fieldValues.map((fieldValue: any) => ({
      name: fieldValue.fieldName,
      value: fieldValue.justification, // NOTE: This is to support backwards capability with the extension < v1.15.0
      fieldValue: fieldValue.selectedValue,
      fieldJustification: fieldValue.justification,
      fieldType: this.getFieldType({ fieldName: fieldValue.fieldName }),
      time,
    }));

    const opportunitiesFieldNames = fieldValues.map((fieldValue: any) => ({
      name: fieldValue.fieldName,
    }));

    setCookie(
      'EXTENSION:OPPORTUNITY_FIELDS',
      JSON.stringify(opportunitiesFields)
    );

    const logMessage = hubspotIntegration
      ? 'Sent deal data to HubSpot'
      : 'Sent opportunity data to Salesforce';
    datadogLogs.logger.info(logMessage, {
      ...datadogLogMetadata(this.state, this.props),
      opportunitiesFieldNames,
    });
  }

  mapOpportunityTasksToTableOpportunityTasksForExternalUser(
    OpportunityTasks: IOpportunityMeetingTask[],
    enqueueSnackbar: any
  ): Array<Array<any>> {
    const tableOpportunityTasks = new Array<Array<any>>();
    if (OpportunityTasks) {
      OpportunityTasks.forEach(value => {
        const date = value.meetingDate;
        const dateString = date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        });
        let timeString = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });

        const tableOpportunityTaskHeader: any[] = [
          value.id,
          value.meetingTopic,
          `${dateString} @ ${timeString}`,
          value.participantList,
        ];

        let accountTooltipTitle = '';
        if (!this.state.hubspotIntegration && !this.state.accountId) {
          accountTooltipTitle = 'Navigate to a HubSpot Deal page to send note';
        }
        tableOpportunityTaskHeader.push(
          <div>
            <Tooltip title={accountTooltipTitle}>
              <span>
                <Button
                  onClick={() =>
                    this.state.hubspotIntegration
                      ? this.sendCallLog({
                          callLog: value.callLog,
                          hubspotIntegration: this.state.hubspotIntegration,
                        })
                      : this.postCallLog({
                          taskId: value.id,
                          accountId: this.state.accountId,
                          callLog: value.callLog,
                          enqueueSnackbar,
                          meetingDate: date,
                        })
                  }
                  variant="contained"
                  disabled={!this.state.accountId}
                >
                  {this.state.hubspotIntegration ? (
                    <span>Create Note</span>
                  ) : (
                    <span>Create Call Log</span>
                  )}
                </Button>
              </span>
            </Tooltip>
            {!this.state.hubspotIntegration && (
              <IconButton
                color="primary"
                onClick={e => this.handlePopoutClick(e, value.callLog)}
                disabled={!this.state.accountId}
              >
                <Eye />
              </IconButton>
            )}
          </div>
        );

        let opportunityTooltipTitle = '';
        if (!this.state.hubspotIntegration && !this.state.opportunityId) {
          opportunityTooltipTitle =
            'Navigate to a Salesforce Opportunity page to populate Opportunity data';
        }
        tableOpportunityTaskHeader.push(
          <div>
            <Tooltip title={opportunityTooltipTitle}>
              <span>
                <Button
                  onClick={() => {
                    this.sendOpportunity({
                      fieldValues: value.fieldValues,
                      hubspotIntegration: this.state.hubspotIntegration,
                    });
                  }}
                  variant="contained"
                  disabled={
                    !this.state.opportunityId && !this.state.creatingOpportunity
                  }
                >
                  {this.state.hubspotIntegration
                    ? 'Send Deal Data'
                    : 'Send Opportunity Data'}
                </Button>
              </span>
            </Tooltip>
          </div>
        );

        tableOpportunityTasks.push(tableOpportunityTaskHeader);
      });
    }
    return tableOpportunityTasks;
  }

  getFieldType = ({ fieldName }: { fieldName: string }) => {
    const { salesforceFields } = this.props.opportunityFieldMappingsState;
    const salesforceField = salesforceFields.find(sf => sf.name === fieldName);

    return salesforceField?.type;
  };

  options = {
    textLabels: {
      body: {
        noMatch: 'All Tasks Complete!',
      },
    },
    print: false,
    download: false,
    rowsPerPage: 100,
    elevation: 3,
    selectableRows: 'none',
    responsive: 'simple',
    viewColumns: false,
    tableBodyMaxHeight: 'calc(100vh)',
    filter: false,
    searchAlwaysOpen: true,
    search: true,
    sort: true,
  };

  render() {
    const { classes, opportunityMeetingTasksState, enqueueSnackbar } =
      this.props;

    const externalUserColumns = [
      {
        name: 'Id',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'Meeting Title',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'Meeting Date',
        options: {
          filter: false,
          sort: true,
          sortCompare: (order: any) => {
            return (date1: any, date2: any) => {
              const date1unix = dayjs(date1.data).unix();
              const date2unix = dayjs(date2.data).unix();

              if (order === 'asc') {
                return date1unix > date2unix ? 1 : -1;
              } else {
                return date2unix > date1unix ? 1 : -1;
              }
            };
          },
        },
      },
      {
        name: 'Participants',
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ style: { maxWidth: '200px' } }),
        },
      },
    ];

    externalUserColumns.push({
      name: this.state.hubspotIntegration ? 'Activities' : 'Account',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return {
            className: this.props.classes.actionColumnClass,
            style: { maxWidth: 'default' },
          };
        },
      },
    });

    externalUserColumns.push({
      name: this.state.hubspotIntegration ? 'Deal' : 'Opportunity',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return {
            className: this.props.classes.actionColumnClass,
            style: { maxWidth: 'default' },
          };
        },
      },
    });

    return (
      <div>
        <Grid className={classes.grid} container alignItems="center">
          <Grid item xs={12}>
            <MUIDataTable
              className={classes.MUIDataTable}
              data={this.mapOpportunityTasksToTableOpportunityTasksForExternalUser(
                opportunityMeetingTasksState?.opportunityTasks,
                enqueueSnackbar
              )}
              columns={externalUserColumns}
              options={this.options}
            />
          </Grid>
        </Grid>
        <Popout
          open={this.state.popoutIsOpen}
          placement="bottom-end"
          width="100vw"
          header="Call Log"
          anchorEl={this.state.popoutAnchorEl.current}
          text={this.state.popoutText}
          handleClose={this.closePopout}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    populateMeetingTasksHOC(
      populateOpportunitiesHOC(withSnackbar(withRouter(OpportunityTasks)))
    )
  )
);

function datadogLogMetadata(
  state: OpportunityMeetingTasksInternalState,
  props: OpportunityMeetingTasksProps
) {
  const { user } = props;
  const {
    hubspotIntegration,
    extensionVersion,
    contentUrl,
    accountId,
    opportunityId,
  } = state;

  const crmMetadata = {
    ...(!hubspotIntegration && { accountId }),
    ...(!hubspotIntegration && { opportunityId }),
    ...(hubspotIntegration && { dealId: accountId }),
  };

  return {
    user: user.profile,
    hubspotIntegration,
    isExtension: isExtension(),
    extensionVersion,
    contentUrl,
    ...crmMetadata,
  };
}
