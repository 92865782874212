import * as React from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Unsubscribe } from 'redux';
import { connect } from 'react-redux';
import Pusher, { Channel } from 'pusher-js';
import SalesforceOpportunityMeetingTaskHandler from './pusherHandlers/SalesforceOpportunityMeetingTaskHandler';
import { ApplicationState, store } from '../store';

import {
  removeSnackbar,
  SnackbarNotification,
} from '../reducers/NotificationsReducer';
import { UserState } from 'redux-oidc';
import GlobalConfig from '../../GlobalConfig';
import { UserProfile } from '../../models/UserProfile';

interface NotifierProps extends WithSnackbarProps {
  notifications: SnackbarNotification[];
  removeSnackbar: (key: React.ReactText) => void;
}

class Notifier extends React.Component<NotifierProps> {
  displayed = [] as React.ReactText[];
  pusher?: Pusher = undefined;
  registrationChannel?: Channel = undefined;
  userChannel?: Channel = undefined;
  domainChannel?: Channel = undefined;
  currentTenantOnbaordChannel = '';
  unsubscribe?: Unsubscribe;

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;
    notifications.forEach(({ key, message, options = {} }) => {
      if (!this.displayed.includes(key)) {
        this.props.enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
            this.props.removeSnackbar(key);
          },
        });
        this.storeDisplayed(key);
      }
    });
  }

  shouldComponentUpdate({ notifications: newSnacks = [] }: NotifierProps) {
    if (!newSnacks.length) {
      this.displayed = [];
      return false;
    }

    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      const newSnack = newSnacks[i];
      if (newSnack.dismissed) {
        this.props.closeSnackbar(newSnack.key);
        this.props.removeSnackbar(newSnack.key);
      }

      if (notExists) {
        continue;
      }
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnack.key === key).length;
    }
    return notExists;
  }

  storeDisplayed = (id: React.ReactText) => {
    this.displayed = [...this.displayed, id];
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      const oidcState = store.getState().oidc;
      if (!this.pusher && !oidcState.isLoadingUser) {
        this.pusher = new Pusher(`${GlobalConfig.PUSHER_API_KEY}`, {
          cluster: 'us2',
          forceTLS: true,
          channelAuthorization: {
            transport: 'ajax',
            endpoint:
              'https://' +
              GlobalConfig.API_HOST +
              GlobalConfig.BASE_PATH +
              '/pusher/auth',
            headers: {
              Authorization: 'Bearer ' + oidcState.user?.access_token,
              'api-version': '1.0',
            },
          },
        });

        if (!this.userChannel) {
          this.componentDidMountDomainUserChannelEvents(oidcState);
        }
      }
    });
  }

  private componentDidMountDomainUserChannelEvents(oidc: UserState) {
    if (oidc.user) {
      if (this.pusher) {
        const userChannel = `private-${
          (oidc.user.profile as UserProfile).tenantId
        }-${oidc.user.profile.sub}`;
        this.userChannel = this.pusher.subscribe(userChannel);

        this.userChannel.bind(
          'send-salesforce-opportunity-meeting-task',
          SalesforceOpportunityMeetingTaskHandler
        );
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (store: ApplicationState) => ({
  notifications: store.notifications,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeSnackbar: (key: React.ReactText) => {
      const action = removeSnackbar(key);
      dispatch(action);
    },
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Notifier)
);
