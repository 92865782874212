import {
  Theme,
  Paper,
  Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import classNames from "classnames";
import { FormikProps, Formik, FormikHelpers } from "formik";
import { ArrowLeft } from "mdi-material-ui";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import GongIntegration from "../../../models/app/integrations/implementations/GongIntegration";
import { IntegrationEnum } from "../../../models/app/integrations/IntegrationEnum";
import RoutePaths from "../../../routing/RoutePaths";
import Constants from "../../../theme/Constants";
import FormikDeleteButton from "../../form/FormikDeleteButton";
import FormikSynchronousButton from "../../form/FormikSynchronousButton";
import FormikTextField from "../../form/FormikTextField";
import { WithRouterProps } from "../../hocs/withRouter";
import { addIntegration } from "../../reducers/IntegrationReducer";
import { ApplicationState } from "../../store";
import integrationForm from "./IntegrationFormHOC";
import * as Yup from "yup";
import { IFormikErrors, IRestResponse } from "../../../services/RestUtilities";
import PostIntegrationResponse from "../../../models/app/integrations/PostIntegrationResponse";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
    signingKey: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  });

interface IGongIntegrationFormState {
  serverErrors?: IFormikErrors;
}

interface IGongIntegrationFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  editIntegration: GongIntegration;
  save: (
    formikBag: FormikHelpers<GongIntegration>,
    integration: GongIntegration
  ) => Promise<IRestResponse<PostIntegrationResponse>>;
  update: (
    formikBag: FormikHelpers<GongIntegration>,
    integration: GongIntegration
  ) => Promise<IRestResponse<void>>;
  delete: (
    formikProps: FormikProps<GongIntegration>
  ) => Promise<IRestResponse<void>>;
  canDelete: boolean;
  isSuccess: boolean;
  serverErrors: string[];
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchAddIntegration: (integration: GongIntegration) => {
      const action = addIntegration(integration);
      dispatch(action);
    },
  };
};
class GongIntegrationForm extends React.Component<
  IGongIntegrationFormProps,
  IGongIntegrationFormState
> {
  state = {
    serverErrors: undefined,
  };

  validationSchema = Yup.object().shape({
    apiKey: Yup.string().required("Required").max(512, "Max 512 characters"),
    apiKeySecret: Yup.string()
      .required("Required")
      .max(512, "Max 512 characters"),
    webhookPublicKey: Yup.string()
      .required("Required")
      .max(512, "Max 512 characters"),
  });

  render() {
    const { classes } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={
          this.props.editIntegration
            ? ({
                ...this.props.editIntegration,
                apiKeySecret: "some_long_string_to_look_like_a_password",
              } as unknown as GongIntegration)
            : ({
                type: IntegrationEnum.GONG,
                apiKey: "",
                apiKeySecret: "",
                webhookPublicKey: "",
              } as unknown as GongIntegration)
        }
        validateOnMount={true}
        initialErrors={{}}
        onSubmit={(
          values: GongIntegration,
          formikBag: FormikHelpers<GongIntegration>
        ) => {
          const newIntegration = new GongIntegration();
          newIntegration.apiKey = values.apiKey;
          newIntegration.apiKeySecret =
            this.props.editIntegration &&
            values.apiKeySecret == "some_long_string_to_look_like_a_password"
              ? ""
              : values.apiKeySecret;
          newIntegration.webhookPublicKey = values.webhookPublicKey;
          this.props.editIntegration
            ? this.props.update(formikBag, newIntegration).then((r) => {
                if (r.isError) {
                  formikBag.resetForm();
                }
              })
            : this.props.save(formikBag, newIntegration);
          this.setState({ serverErrors: undefined });
        }}
        validationSchema={this.validationSchema}
      >
        {(props) => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              disabled={props.isSubmitting}
              onClick={() => this.props.navigate(RoutePaths.Integrations)}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              align="left"
              variant="h5"
            >
              {this.props.editIntegration
                ? "Edit Gong Integration"
                : "New Gong Integration"}
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Integration Details
              </Typography>
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      infoText="The Gong API Key."
                      name="apiKey"
                      label="API Key"
                      value={props.values.apiKey}
                      errorText={props.errors.apiKey}
                      touched={props.touched.apiKey}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      infoText="The Gong API Key Secret."
                      name="apiKeySecret"
                      label="API Key Secret"
                      value={props.values.apiKeySecret}
                      type={this.props.editIntegration ? "password" : "text"}
                      errorText={props.errors.apiKeySecret}
                      touched={props.touched.apiKeySecret}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      infoText="The Gong Webhook JSON Public Key."
                      name="webhookPublicKey"
                      label="Webhook JSON Public Key"
                      value={props.values.webhookPublicKey}
                      errorText={props.errors.webhookPublicKey}
                      touched={props.touched.webhookPublicKey}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  {this.props.serverErrors && (
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          {this.props.serverErrors.map((error) => (
                            <ListItemText primary={error} />
                          ))}
                        </ListItem>
                      </List>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item>
                      <FormikSynchronousButton
                        variant="outlined"
                        isValid={props.isValid && props.dirty}
                        isSubmitting={props.isSubmitting}
                        isSuccess={this.props.isSuccess}
                      >
                        {props.initialValues.apiKey === ""
                          ? "Create"
                          : "Update"}
                      </FormikSynchronousButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
            {this.props.editIntegration && this.props.canDelete && (
              <React.Fragment>
                <Paper
                  className={classNames(classes.bottomPaper, classes.paper)}
                  elevation={3}
                >
                  <Typography variant="h6">Delete</Typography>
                  <Typography variant="subtitle1">
                    Remove the integration
                  </Typography>
                  <Grid container>
                    <Grid item>
                      {this.props.editIntegration && (
                        <FormikDeleteButton
                          isSubmitting={props.isSubmitting}
                          onConfirm={() => this.props.delete(props)}
                          dialogTitle="Delete integration?"
                          confirmText="Delete"
                          dialogContent={
                            "Are you sure you want to delete the Gong integration?"
                          }
                        >
                          Delete
                        </FormikDeleteButton>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(integrationForm(withStyles(styles)(withSnackbar(GongIntegrationForm))));
