import * as React from "react";
import { Theme, Breadcrumbs, Link, Typography } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import Breadcrumb from "../../models/app/Breadcrumb";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { withRouter, WithRouterProps } from "../hocs/withRouter";

const styles = (theme: Theme) =>
  createStyles({
    textColor: {
      // color: theme.palette.common.white,
    },
  });

interface CustomBreadcrumbProps extends WithStyles<typeof styles> {
  label: string;
  onClick?: () => void;
}

class CustomBreadcrumb extends React.Component<CustomBreadcrumbProps> {
  render() {
    const { classes, label, ...rest } = this.props;
    return (
      <Link
        classes={{ button: classes.textColor }}
        key={label}
        component="button"
        variant="subtitle1"
        underline="none"
        {...rest}
      >
        {label}
      </Link>
    );
  }
}
const StyledBreadcrumb = withStyles(styles)(CustomBreadcrumb);

interface CustomizedBreadcrumbsProps
  extends WithStyles<typeof styles>,
    WithRouterProps {
  breadcrumbs: Breadcrumb[];
}

class CustomizedBreadcrumbs extends React.Component<CustomizedBreadcrumbsProps> {
  handleClick(path: string) {
    let pushPath = path;
    this.props.navigate(pushPath);
  }

  render() {
    const { classes } = this.props;
    return (
      <Breadcrumbs
        classes={{ li: classes.textColor }}
        separator={<ChevronRight fontSize="small" />}
        arial-label="Breadcrumb"
      >
        {this.props.breadcrumbs.map((b, i, a) => {
          return i === a.length - 1 ? (
            <Typography variant="subtitle1" key={b.path}>
              {b.label}
            </Typography>
          ) : (
            <StyledBreadcrumb
              key={b.path}
              label={b.label}
              onClick={() => {
                this.handleClick(b.path);
              }}
            />
          );
        })}
      </Breadcrumbs>
    );
  }
}

export default withRouter(withStyles(styles)(CustomizedBreadcrumbs));
