import RestUtilities, { IRestResponse } from "./RestUtilities";
import IReprocessGongWebhookModel from "../models/webhooks/IReprocessGongWebhookModel";
import IReprocessZoomWebhookModel from "../models/webhooks/IReprocessZoomWebhookModel";

export default class WebhooksService {
  async postReprocessGongWebhook(
    reprocessGongWebhookModel: IReprocessGongWebhookModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.post<void>(
      `/webhooks/gong/reprocess`,
      reprocessGongWebhookModel
    );
  }

  async postReprocessZoomWebhook(
    reprocessZoomWebhookModel: IReprocessZoomWebhookModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.post<void>(
      `/webhooks/zoom/reprocess`,
      reprocessZoomWebhookModel
    );
  }
}
