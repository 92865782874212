import * as React from "react";
import { connect } from "react-redux";
import OpportunitiesService from "../../services/OpportunitiesService";
import Loading from "../loading/Loading";
import {
  OpportunityMeetingTasksState,
  populateOpportunityMeetingTasks,
  populateOpportunityMeetingTaskStages,
  populateOpportunityMeetingTaskStatuses,
  setIsLoadedTrue,
} from "../reducers/OpportunityMeetingTasksReducer";
import { ApplicationState } from "../store";
import { Profile, User } from "oidc-client";
import IOpportunityMeetingTask from "../../models/app/opportunities/IOpportunityMeetingTask";
import { userIsInRole } from "../../helpers/Helpers";
import { RoleEnum } from "../../models/RoleEnum";
import UserService from "../../services/UserService";

const opportunitiesService = new OpportunitiesService();
const userService = new UserService();

interface PopulateOpportunityMeetingTasksComponentProps {
  populateOpportunityTasks: (opportunities: Array<IOpportunityMeetingTask>) => void;
  populateOpportunityMeetingTaskStatuses: (opportunities: Array<string>) => void;
  populateOpportunityMeetingTaskStages: (stages: Array<string>) => void;
  setIsLoadedTrue: () => void;
  opportunityMeetingTasksState: OpportunityMeetingTasksState;
  user: User;
}

interface PopulateOpportunityMeetingTasksState {
  wasError: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityMeetingTasksState: state.opportunityMeetingTasksState,
    user: state.oidc.user as User,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    populateOpportunityTasks: (opportunityTasks: Array<IOpportunityMeetingTask>) => {
      const action = populateOpportunityMeetingTasks(opportunityTasks);
      dispatch(action);
    },
    populateOpportunityMeetingTaskStatuses: (statuses: Array<string>) => {
      const action = populateOpportunityMeetingTaskStatuses(statuses);
      dispatch(action);
    },
    populateOpportunityMeetingTaskStages: (stages: Array<string>) => {
      const action = populateOpportunityMeetingTaskStages(stages);
      dispatch(action);
    },
    setIsLoadedTrue: () => {
      dispatch(setIsLoadedTrue());
    },
  };
};

const populateOpportunityMeetingTasksHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class PopulateOpportunityMeetingTasksComponent extends React.Component<
    PopulateOpportunityMeetingTasksComponentProps,
    PopulateOpportunityMeetingTasksState
  > {
    state: PopulateOpportunityMeetingTasksState = {
      wasError: false,
    };
    componentDidUpdate() {
      if (!this.props.opportunityMeetingTasksState.isLoaded) {
        this.getAndPopulateOpportunityTasks();
        this.getAndPopulateOpportunityMeetingTaskStatuses();
        this.getAndPopulateOpportunityMeetingTaskStages();
      }
    }

    componentDidMount() {
      if (!this.props.opportunityMeetingTasksState?.isLoaded) {
        this.getAndPopulateOpportunityTasks();
        this.getAndPopulateOpportunityMeetingTaskStatuses();
        this.getAndPopulateOpportunityMeetingTaskStages();
      }
    }

    private async getAndPopulateOpportunityTasks() {
      if (userIsInRole(this.props.user, RoleEnum.SUPPORT) || userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN)) {
        var tasks = await opportunitiesService.getMeetingTasksForTenant();
        var users = await userService.getUsers();
        tasks.result?.forEach(task => {
          var user = users.result?.find(user => user.id === task.createdFor);
          task.hostName = user ? `${user.firstName} ${user.lastName}` : "";
        })
      }
      else {
        var tasks = await opportunitiesService.getMeetingTasksForUser((this.props.user.profile as Profile).email as string)
      }
      if (tasks.isError) {
        this.setState({ wasError: true });
      } else {
        this.props.populateOpportunityTasks(tasks.result ?? []);
        this.props.setIsLoadedTrue();
      }
    }

    private async getAndPopulateOpportunityMeetingTaskStatuses() {
      let statuses = await opportunitiesService.getMeetingTasksStatuses();

      if (statuses.isError) {
        this.setState({ wasError: true });
      } else {
        this.props.populateOpportunityMeetingTaskStatuses(statuses.result ?? []);
        this.props.setIsLoadedTrue();
      }
    }

    private async getAndPopulateOpportunityMeetingTaskStages() {
      let stages = await opportunitiesService.getMeetingTasksStages();

      if (stages.isError) {
        this.setState({ wasError: true });
      } else {
        this.props.populateOpportunityMeetingTaskStages(stages.result ?? []);
        this.props.setIsLoadedTrue();
      }
    }

    render() {
      return this.props.opportunityMeetingTasksState?.isLoaded && !this.state.wasError ? (
        <Component {...(this.props as P)} />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PopulateOpportunityMeetingTasksComponent);
};

export default populateOpportunityMeetingTasksHOC;
