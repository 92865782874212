import * as React from "react";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Hidden, Button, Theme } from "@mui/material";
import { User } from "oidc-client";
import AccountPopOut from "../../accountPopOut/AccountPopOut";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";
import RoutePaths from "../../../routing/RoutePaths";
import NameLogo from "../../../theme/NameLogo";
import Logo from "../../../theme/Logo";

const styles = (theme: Theme) =>
  createStyles({
    toolbarLeft: {
      flexGrow: 1,
    },
    appBar: {
      background: "white",
      zIndex: theme.zIndex.drawer + 1,
    },
    landingLink: {
      minWidth: "0px",
      marginLeft: "17px",
      marginRight: "17px",
    },
    actionContainer: {
      display: "inline-flex",
    },
    menuButton: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    logoContainer: {
      [theme.breakpoints.up("md")]: {
        width: theme.drawer.width as number,
      },
    },
    logoButtonRoot: {
      "&:hover": {
        background: "none",
      },
    },
  });

interface LandingHeaderProps
  extends WithStyles<typeof styles>,
    WithRouterProps {
  user?: User;
  handleDrawerToggle: () => void;
}

class LandingHeader extends React.Component<LandingHeaderProps> {
  hasSubDomain = (): boolean => {
    const results = window.location.hostname.split(".");
    return results.length === 3;
  };

  handleSignInClick = () => {
    this.props.navigate(RoutePaths.Login);
  };

  handleSignUpClick = () => {
    window.location.assign("https://calendly.com/cquence/intro-call");
  };

  handleLogoClick = () => {
    this.props.navigate(RoutePaths.Landing);
  };

  render() {
    const { classes } = this.props;
    return (
      <AppBar elevation={2} position="fixed" className={classes.appBar}>
        <Toolbar id="back-to-top-anchor">
          <div className={classes.toolbarLeft}>
            <Button
              className={classes.logoContainer}
              classes={{ root: classes.logoButtonRoot }}
              disableRipple={true}
              onClick={() => this.props.navigate(RoutePaths.Landing)}
            >
              <Logo width={70} />
            </Button>
          </div>
          <Hidden mdDown implementation="css">
            <div className={classes.actionContainer}>
              {this.props.user && !this.props.user.expired ? (
                <AccountPopOut />
              ) : (
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    classes={{ root: classes.landingLink }}
                    onClick={this.handleSignInClick}
                  >
                    Sign in
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleSignUpClick}
                    classes={{ root: classes.landingLink }}
                  >
                    Get Started
                  </Button>
                </div>
              )}
            </div>
          </Hidden>
          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            onClick={this.props.handleDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withRouter(LandingHeader));
