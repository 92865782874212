import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Integration from "../../models/app/integrations/Integration";
import { MergedIntegrationType } from "../../models/app/integrations/MergedIntegrationTypes";

export interface IntegrationsState {
  isLoaded: boolean;
  integrations: Array<MergedIntegrationType>;
  pendingUpdate: Array<MergedIntegrationType>;
  pendingDeletion: Array<MergedIntegrationType>;
}

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    isLoaded: false,
    integrations: [],
    pendingUpdate: [],
    pendingDeletion: [],
  } as IntegrationsState,
  reducers: {
    addIntegration: (state, action: PayloadAction<MergedIntegrationType>) => {
      return Object.assign({}, state, {
        integrations: state.integrations.concat(action.payload),
      });
    },
    updateIntegrationById: (
      state,
      action: PayloadAction<MergedIntegrationType>
    ) => {
      const integrationIndex = state.integrations.findIndex(
        (g) => g.id == action.payload.id
      );
      let newArray = state.integrations.slice();
      newArray.splice(integrationIndex, 1, action.payload);
      return Object.assign({}, state, { integrations: newArray });
    },
    removePendingUpdateIntegrationById: (
      state,
      action: PayloadAction<string>
    ) => {
      return Object.assign({}, state, {
        pendingUpdate: state.pendingUpdate.filter(
          (v: Integration) => v.id !== action.payload
        ),
      });
    },
    addIntegrationToPendingDeletion: (
      state,
      action: PayloadAction<MergedIntegrationType>
    ) => {
      return Object.assign({}, state, {
        pendingDeletion: state.pendingDeletion.concat(action.payload),
      });
    },
    addIntegrationToPendingUpdate: (
      state,
      action: PayloadAction<MergedIntegrationType>
    ) => {
      return Object.assign({}, state, {
        pendingUpdate: state.pendingDeletion.concat(action.payload),
      });
    },
    removeIntegrationById: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        integrations: state.integrations.filter(
          (v: Integration) => v.id !== action.payload
        ),
      });
    },
    populateIntegrations: (
      state,
      action: PayloadAction<Array<MergedIntegrationType>>
    ) => {
      return Object.assign({}, state, {
        integrations: action.payload,
        isLoaded: true,
      });
    },
    resetIntegrations: (
      state,
      action: PayloadAction<MergedIntegrationType>
    ) => {
      return Object.assign({}, state, action.payload);
    },
  },
});

export const {
  addIntegration,
  addIntegrationToPendingDeletion,
  addIntegrationToPendingUpdate,
  updateIntegrationById,
  removeIntegrationById,
  removePendingUpdateIntegrationById,
  populateIntegrations,
  resetIntegrations,
} = integrationsSlice.actions;
export default integrationsSlice.reducer;
