import RestUtilities, { IRestResponse } from './RestUtilities';
import IGenerateDraftModel from '../models/landing/IGenerateDraftModel';
import IUploadCustomPhrasesModel from '../models/files/IUploadCustomPhrasesModel';
import IUploadRecordingFileModel from '../models/files/IUploadRecordingFileModel';
import IUploadTranscriptModel from '../models/files/IUploadTranscriptModel';
export default class DocumentService {
  async postGenerateDraft(
    generateDraftModel: IGenerateDraftModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.put<void>(
      `/documents/generate-draft`,
      generateDraftModel
    );
  }
  async putCustomPhrases(
    uploadCustomPhrasesModel: IUploadCustomPhrasesModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.put<void>(
      `/documents/custom-phrases`,
      uploadCustomPhrasesModel
    );
  }
  async postUploadedFileMetaData(
    data: IUploadRecordingFileModel
  ): Promise<IRestResponse<void>> {
    var metaData = {
      hostEmail: data.hostEmail,
      hostName: data.hostName,
      meetingTitle: data.meetingTitle,
      contentType: data.file?.type,
      meetingDate: new Date(`${data.meetingDate}T${data.meetingTime}Z`),
      useExisting: data.useExisting,
    };
    return RestUtilities.post<void>(`/documents/recording/metadata`, metaData);
  }
  async postProcessUploadedFile(
    hostEmail: string,
    folderId: string,
    file: File
  ): Promise<IRestResponse<void>> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        RestUtilities.post<void>(
          `/documents/recording?email=${hostEmail}&folderId=${folderId}`,
          reader.result,
          true
        ).then(response => resolve(response));
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
  async postProcessTranscript(
    data: IUploadTranscriptModel
  ): Promise<IRestResponse<void>> {
    return RestUtilities.post<void>(`/documents/transcript`, data);
  }
}
