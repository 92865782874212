import * as React from 'react';
import { Theme, LinearProgress, Typography, Grid } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';

import RoutePaths from '../../routing/RoutePaths';
import IntegrationService from '../../services/IntegrationService';
import GmailIntegration from '../../models/app/integrations/implementations/GmailIntegration';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Loading from '../loading/Loading';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import Constants from '../../theme/Constants';

const integrationService = new IntegrationService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      paddingTop: '20%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
  });

interface AuthorizeGmailProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  message?: string;
  wasError?: boolean;
}

interface AuthorizeGmailState {
  isLoaded: boolean;
  wasError: boolean;
}

class AuthorizeGmail extends React.Component<
  AuthorizeGmailProps,
  AuthorizeGmailState
> {
  componentDidMount() {
    this.handleGoogleOAuthCallback();
  }

  state: AuthorizeGmailState = {
    isLoaded: false,
    wasError: false,
  };

  handleGoogleOAuthCallback(): void {
    const queryString: string = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('error')) {
      // user rejected access to their google account
      this.setState({ wasError: true });
      this.props.enqueueSnackbar('Gmail integration was not authorized', {
        variant: 'error',
      });
      return;
    }

    const authCode: string = urlParams.get('code')!;
    const gmailIntegration = new GmailIntegration(authCode);

    integrationService.postIntegration(gmailIntegration).then(data => {
      if (data.isError) {
        this.setState({ isLoaded: true, wasError: true });
        this.props.enqueueSnackbar(data.error?.message, { variant: 'error' });
      } else {
        this.setState({ isLoaded: true });
        // TODO: Forward the users to the integrations page while preserving the snackbar notification
        // this.props.enqueueSnackbar(data.message, { variant: "success" });
        this.props.navigate(RoutePaths.Integrations);
        this.props.navigate(0); // refresh page
      }
    });
  }

  render() {
    return !this.state.isLoaded && !this.state.wasError ? (
      <Loading />
    ) : (
      <Loading wasError={this.state.wasError} />
    );
  }
}

export default withSnackbar(withStyles(styles)(withRouter(AuthorizeGmail)));
