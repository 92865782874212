import * as React from "react";
import { Theme, Grid, Button } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { User } from "oidc-client";
import { connect } from "react-redux";
import RoutePaths from "../../routing/RoutePaths";
import populateOpportunitiesHOC from "../hocs/PopulateOpportunityMappingsHOC";
import CustomAddToolbar from "../muiDataTable/CustomAddToolbar";
import { ApplicationState } from "../store";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { WithSnackbarProps } from "notistack";
import { OpportunityFieldMappingsState } from "../reducers/OpportunityFieldMappingsReducer";
import IOpportunityFieldMapping from "../../models/app/opportunities/IOpportunityFieldMapping";

const MUIDataTable = require("mui-datatables").default;

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2),
    },
  });

interface OpportunitiesProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    WithSnackbarProps {
  user: User;
  opportunityFieldMappingsState: OpportunityFieldMappingsState;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityFieldMappingsState: state.opportunityFieldMappingsState,
    user: state.oidc.user,
  };
};

class Opportunities extends React.Component<OpportunitiesProps> {
  refs: {
    query: HTMLInputElement;
  };

  redirectToOpportunityFieldMappingForm = () => {
    this.props.navigate(RoutePaths.OpportunityMapping);
  };

  mapOpportunitiesToTableOpportunities(
    fieldMappings: IOpportunityFieldMapping[]
  ): Array<Array<any>> {
    const tableOpportunities = new Array<Array<any>>();
    if (fieldMappings.length) {
      fieldMappings.forEach((field) => {
        tableOpportunities.push([field.id, field.fieldName, field.createdOn]);
      });
    }
    return tableOpportunities;
  }

  columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Field Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Created On",
      options: {
        filter: false,
        sort: false,
      },
    }
  ];

  options = {
    textLabels: {
      body: {
        noMatch: "No Opportunity fields have been added yet.",
      },
    },
    print: false,
    download: false,
    customToolbar: () => {
      return (
      <Button
        color="primary"
        variant="contained"
        onClick={this.redirectToOpportunityFieldMappingForm}
      >
        Edit
      </Button>
      );
    },
    rowsPerPage: 100,
    elevation: 3,
    selectableRows: "none",
    responsive: "simple",
    viewColumns: false,
    tableBodyMaxHeight: "calc(100vh - 64px - 48px - 64px - 52px)",
    // onRowClick: this.editOpportunityTask,
    filter: true,
    search: true,
    sort: true,
  };

  render() {
    const { classes, opportunityFieldMappingsState } = this.props;

    return (
      <Grid className={classes.grid} container alignItems="center">
        <Grid item xs={12}>
          <MUIDataTable
            title={"Opportunity Field Mappings"}
            data={this.mapOpportunitiesToTableOpportunities(
              opportunityFieldMappingsState.fieldMappings
            )}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(populateOpportunitiesHOC(withRouter(Opportunities))));