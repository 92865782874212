import React, { ChangeEvent } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Button, Grid, Theme, Tooltip, Typography } from '@mui/material';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import { FormikErrors, FormikTouched } from 'formik';
import FormikTextField from './FormikTextField';
import { InformationOutline } from 'mdi-material-ui';
import Constants from '../../theme/Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: 0,
      background: theme.palette.common.white,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: theme.spacing(1),
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    infoText: {
      position: 'relative',
      top: '20px',
      left: '14px',
      color: Constants.COLORS.PRIMARY_COLOR,
    },
    infoTextDisabled: {
      color: 'grey',
      position: 'relative',
      top: '20px',
      left: '14px',
    },
  })
);

interface FormikAutocompleteServerSearchProps {
  name: string;
  label: string;
  renderOption?: (props: any, displayOption: string) => JSX.Element;
  options: string[];
  value?: string;
  disabled?: boolean;
  noOptionsText?: string;
  onChange: (
    event: ChangeEvent<{}>,
    values: string | null,
    reason?: string
  ) => void;
  onInputChange:
    | ((
        event: React.SyntheticEvent<Element, Event>,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => void)
    | undefined;
  onBlur: (event: ChangeEvent<{}>, values: string) => void;
  errorText: string | FormikErrors<any>;
  touched: boolean | FormikTouched<any>;
  required: boolean;
  infoText?: string;
  loading?: boolean;
  displayCreateNew?: boolean;
  createNewName?: string;
  showDisabledInfoTextIcon?: boolean;
  createNewCallback?: () => void;
  createNewOptionText?: string;
  createNewLabel?: string;
  createNewClearCallback?: () => void;
}

export default function FormikAutocompleteServerSearch(
  props: FormikAutocompleteServerSearchProps
) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isCreatingNew, setIsCreatingnew] = React.useState<boolean>(false);
  const StaticCreateNewElementOptionValue = 'static-create-new-element';
  const classes = useStyles(props);
  const { value: value, createNewCallback } = props;

  const openPopper = () => {
    setOpen(true);
  };

  const closePopper = () => {
    setOpen(false);
  };

  if (createNewCallback) {
    if (props.options[0] !== StaticCreateNewElementOptionValue) {
      props.options.unshift(StaticCreateNewElementOptionValue);
    }
  }

  const createNewLabel = () =>
    props.createNewLabel ? props.createNewLabel : 'Creating new...';

  const createNewOptionText = () =>
    props.createNewOptionText ? props.createNewOptionText : ' + Create New';

  const renderCreateNewElement = (
    props: React.HTMLAttributes<HTMLLIElement>,
    onCreateCallback: () => void
  ): JSX.Element => {
    const { onClick: onClick, ...rest } = props;
    return (
      <li
        {...rest}
        onClick={() => {
          setIsCreatingnew(true);
          setOpen(false);
          onCreateCallback();
        }}
      >
        <Typography>{createNewOptionText()}</Typography>
      </li>
    );
  };

  const renderLIElement = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: string
  ): JSX.Element => {
    const { onClick: onClick, ...rest } = props;
    return (
      <li
        {...rest}
        onClick={event => {
          setIsCreatingnew(false);
          setOpen(false);
          if (onClick) {
            onClick(event);
          }
        }}
      >
        <Typography>{option}</Typography>
      </li>
    );
  };

  const gridSize = () => {
    let size = 12;
    if (props.infoText || props.showDisabledInfoTextIcon) size -= 1;
    if (isCreatingNew) size -= 3;
    return size;
  };

  return (
    <>
      <Grid container alignContent="center">
        <Grid item xs={gridSize()}>
          <Autocomplete
            classes={{
              option: classes.option,
              paper: classes.paper,
            }}
            noOptionsText={props.noOptionsText ?? 'Type to begin searching...'}
            freeSolo={true}
            forcePopupIcon={true}
            renderOption={(renderProps, renderOption, renderState) => {
              if (
                renderOption === StaticCreateNewElementOptionValue &&
                props.createNewCallback
              ) {
                return renderCreateNewElement(
                  renderProps,
                  props.createNewCallback
                );
              } else {
                if (props.renderOption) {
                  props.renderOption(renderProps, renderOption);
                } else {
                  return renderLIElement(renderProps, renderOption);
                }
              }
            }}
            open={open}
            options={props.options}
            filterOptions={x => x}
            value={value}
            onChange={(event, value, reason, details) => {
              if (value !== StaticCreateNewElementOptionValue) {
                props.onChange(event, value);
              }
            }}
            disabled={props.disabled || isCreatingNew}
            onInputChange={(event, value, reason) => {
              if (value !== StaticCreateNewElementOptionValue) {
                if (props.onInputChange) {
                  props.onInputChange(event, value, reason);
                }
              }
            }}
            clearOnEscape
            renderInput={(params: any) => (
              <FormikTextField
                name={props.name}
                label={isCreatingNew ? createNewLabel() : props.label}
                value={value}
                errorText={props.errorText}
                touched={props.touched}
                onChange={() => {}}
                onBlur={(event: React.ChangeEvent<{}>, values: string) => {
                  closePopper();
                  props.onBlur(event, values);
                }}
                autoComplete="off"
                required={props.required}
                disabled={props.disabled}
                onFocus={openPopper}
                {...params}
              />
            )}
          />
        </Grid>
        {(props.infoText || props.showDisabledInfoTextIcon) && (
          <Grid item xs={1}>
            <Tooltip
              className={
                props.showDisabledInfoTextIcon
                  ? classes.infoTextDisabled
                  : classes.infoText
              }
              title={props.infoText}
              placement="left"
            >
              <InformationOutline fontSize="small" />
            </Tooltip>
          </Grid>
        )}

        {isCreatingNew && (
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  sx={{ marginTop: '16px', marginLeft: '8px' }}
                  variant="outlined"
                  onClick={() => {
                    setIsCreatingnew(false);
                    if (props.createNewClearCallback) {
                      props.createNewClearCallback();
                    }
                  }}
                >
                  Use Existing
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
