import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { IFormikErrors } from "../../services/RestUtilities";
import Constants from "../../theme/Constants";

export default function SingleServerErrorMessage(props: {
  message: string;
  formikErrors?: IFormikErrors;
}): JSX.Element {
  let errors: string[] = [];
  if (props.formikErrors) {
    const formikErrors = { ...props.formikErrors };
    Object.values(formikErrors).forEach(err => {
      if (typeof err === "string") {
        errors.push(err);
      } else {
        err.forEach(errMessage => errors.push(errMessage));
      }
    });
  }

  return (
    <Grid item xs={12}>
      <List
        sx={{
          color: Constants.COLORS.ERROR_COLOR,
          textAlign: "center",
        }}
      >
        <ListItem>
          <ListItemText primary={props.message} />
        </ListItem>
        {errors && errors.map(text => <ListItem><ListItemText primary={text} /></ListItem>)}
      </List>
    </Grid>
  );
}