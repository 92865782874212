import {
  Theme,
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import { ArrowLeft } from "mdi-material-ui";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import Constants from "../../../theme/Constants";
import RoutePaths from "../../../routing/RoutePaths";
import FormikSelectValues from "../../form/interfaces/FormikSelectValuesProp";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";
import { addIntegration } from "../../reducers/IntegrationReducer";
import integrationForm from "./IntegrationFormHOC";
import SalesforceIntegration from "../../../models/app/integrations/implementations/SalesforceIntegration";
import GlobalConfig from "../../../GlobalConfig";
import { ApplicationState } from "../../store";
import { IntegrationsState } from "../../reducers/IntegrationReducer";
import FormikDeleteButton from "../../form/FormikDeleteButton";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    addBtn: {
      marginTop: theme.spacing(3),
    },
    addDate: {
      padding: theme.spacing(1),
      color: Constants.COLORS.PRIMARY_COLOR,
      fontStyle: "italic",
      position: "relative",
      top: "10px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
  });

interface ISalesforceIntegrationFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  editIntegration: SalesforceIntegration;
  delete: (formikProps: FormikProps<{}>) => void;
  environmentSelectValuesArray: FormikSelectValues;
  isSuccess: boolean;
  isPendingCreation: boolean;
  serverErrors: string[];
  canDelete: boolean;
  integrationsState: IntegrationsState;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchAddIntegration: (integration: SalesforceIntegration) => {
      const action = addIntegration(integration);
      dispatch(action);
    },
  };
};

class SalesforceIntegrationForm extends React.Component<ISalesforceIntegrationFormProps> {
  state = {
    isDeleting: false,
  };
  getSalesforceOAuthURL(): string {
    const { CLIENT_ID, REDIRECT_URI } =
      GlobalConfig.SALESFORCE_OAUTH_CREDENTIAL;
    return `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
  }

  render() {
    const { classes } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{}}
        validateOnMount={true}
        initialErrors={{}}
        onSubmit={() => {}}
      >
        {(props) => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              onClick={() => this.props.navigate(RoutePaths.Integrations)}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              align="left"
              variant="h5"
            >
              Salesforce Integration
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom>
                Integration Details
              </Typography>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography align="center">
                    Allow Cquence to create and update opportunities from your
                    sales calls.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid container>
                  <Grid item>
                    <Button
                      className={classNames(classes.addBtn)}
                      disabled={this.props.editIntegration ? true : false}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={(): void =>
                        window.location.assign(this.getSalesforceOAuthURL())
                      }
                    >
                      Add
                    </Button>
                    {this.props.editIntegration && (
                      <span className={classes.addDate}>
                        Added on{" "}
                        {new Date(
                          this.props.editIntegration.createdOn
                        ).toDateString()}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {this.props.editIntegration && this.props.canDelete && (
              <React.Fragment>
                <Paper
                  className={classNames(classes.bottomPaper, classes.paper)}
                  elevation={3}
                >
                  <Typography variant="h6">Delete</Typography>
                  <Typography variant="subtitle1">
                    Remove the integration
                  </Typography>
                  <Grid container>
                    <Grid item>
                      {this.props.editIntegration && (
                        <FormikDeleteButton
                          isSubmitting={props.isSubmitting}
                          onConfirm={() => this.props.delete(props)}
                          dialogTitle="Delete integration?"
                          confirmText="Delete"
                          dialogContent={
                            "Are you sure you want to delete the Salesforce integration?"
                          }
                        >
                          Delete
                        </FormikDeleteButton>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  integrationForm(
    withStyles(styles)(withSnackbar(withRouter(SalesforceIntegrationForm)))
  )
);
