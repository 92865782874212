import { FormikBag, FormikProps } from 'formik';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import {
  replaceNullOrUndefinedProperties,
  userIsInRole,
} from '../../../helpers/Helpers';
import { IntegrationEnum } from '../../../models/app/integrations/IntegrationEnum';
import { MergedIntegrationType } from '../../../models/app/integrations/MergedIntegrationTypes';
import { RoleEnum } from '../../../models/RoleEnum';
import RoutePaths from '../../../routing/RoutePaths';
import IntegrationService from '../../../services/IntegrationService';
import populateIntegrationsHOC from '../../hocs/PopulateIntegrationsHOC';
import { withRouter, WithRouterProps } from '../../hocs/withRouter';
import {
  IntegrationsState,
  addIntegration,
  removeIntegrationById,
} from '../../reducers/IntegrationReducer';
import { ApplicationState } from '../../store';
import * as queryString from 'query-string';
import { UserProfile } from '../../../models/UserProfile';
import Integration from '../../../models/app/integrations/Integration';
import { IRestResponse } from '../../../services/RestUtilities';
import PostIntegrationResponse from '../../../models/app/integrations/PostIntegrationResponse';

const integrationService = new IntegrationService();

type IntegrationFormHOCProps = StateProps & DispatchProps & OwnProps;

interface OwnProps extends WithSnackbarProps, WithRouterProps {}

interface StateProps {
  user: User;
  integrationsState: IntegrationsState;
}

interface DispatchProps {
  saveIntegrationToState: (integration: MergedIntegrationType) => void;
  removeIntegration: (name: string) => void;
}

interface IntegrationFormHOCState {
  editIntegration?: MergedIntegrationType;
  isSuccess: boolean;
  serverErrors?: string[];
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    user: state.oidc.user as User,
    integrationsState: state.integrationsState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveIntegrationToState: (integration: MergedIntegrationType) => {
      const action = addIntegration(integration);
      dispatch(action);
    },
    removeIntegration: (id: string) => {
      const action = removeIntegrationById(id);
      dispatch(action);
    },
  };
};

const integrationForm = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class IntegrationFormHOCComponent extends React.Component<
    IntegrationFormHOCProps,
    IntegrationFormHOCState
  > {
    state: IntegrationFormHOCState = {
      editIntegration: undefined,
      isSuccess: false,
      serverErrors: undefined,
    };

    componentDidMount() {
      if (window.location.pathname.includes(RoutePaths.IntegrationsEditGong)) {
        this.checkIntegrationIsCorrectTypeForRoute(IntegrationEnum.GONG);
      } else if (
        window.location.pathname.includes(RoutePaths.IntegrationsEditZoom)
      ) {
        this.checkIntegrationIsCorrectTypeForRoute(IntegrationEnum.ZOOM);
      } else if (
        window.location.pathname.includes(RoutePaths.IntegrationsEditSalesforce)
      ) {
        this.checkIntegrationIsCorrectTypeForRoute(IntegrationEnum.SALESFORCE);
      } else if (
        window.location.pathname.includes(RoutePaths.IntegrationsEditHubspot)
      ) {
        this.checkIntegrationIsCorrectTypeForRoute(IntegrationEnum.HUBSPOT);
      }
    }

    checkIntegrationIsCorrectTypeForRoute(integrationType: IntegrationEnum) {
      switch (integrationType) {
        case IntegrationEnum.GONG: {
          this.setEditIntegrationOrPushToIntegrations(IntegrationEnum.GONG);
          break;
        }
        case IntegrationEnum.ZOOM: {
          this.setEditIntegrationOrPushToIntegrations(IntegrationEnum.ZOOM);
          break;
        }
        case IntegrationEnum.SALESFORCE: {
          this.setEditIntegrationOrPushToIntegrations(
            IntegrationEnum.SALESFORCE
          );
          break;
        }
        case IntegrationEnum.HUBSPOT: {
          this.setEditIntegrationOrPushToIntegrations(IntegrationEnum.HUBSPOT);
          break;
        }
        default: {
          setTimeout(() => {
            this.props.navigate(RoutePaths.Integrations);
          });
        }
      }
    }

    private setEditIntegrationOrPushToIntegrations(
      integrationEnum: IntegrationEnum
    ) {
      const params = queryString.parse(window.location.search);
      const id = params['id'] as string;
      if (id) {
        var result = this.props.integrationsState.integrations.find(
          i => i.id === id
        );
        if (result && result.type === integrationEnum) {
          this.setState({ editIntegration: result });
        } else {
          setTimeout(() => {
            this.props.navigate(RoutePaths.Integrations);
          });
        }
      }
    }

    saveIntegration = async (
      formikBag: FormikBag<
        FormikProps<MergedIntegrationType>,
        MergedIntegrationType
      >,
      integration: MergedIntegrationType
    ): Promise<IRestResponse<PostIntegrationResponse>> => {
      var response = await integrationService.postIntegration(integration);
      if (!response.isError) {
        integration.createdBy = (this.props.user.profile as UserProfile).id;
        integration.createdOn = new Date();
        integration.createdByEmail = (this.props.user.profile as UserProfile)
          .email as string;
        integration.id = response.result?.id as string;
        this.setState({ isSuccess: true });
        this.props.saveIntegrationToState(integration);
        this.props.enqueueSnackbar(response.message, { variant: 'success' });
        this.props.navigate(RoutePaths.Integrations);
        return response;
      } else {
        formikBag.setSubmitting(false);
        this.setState({ isSuccess: false });
        this.props.enqueueSnackbar(response.error?.message, {
          variant: 'error',
        });
        return response;
      }
    };

    updateIntegration = async (
      formikBag: FormikBag<
        FormikProps<MergedIntegrationType>,
        MergedIntegrationType
      >,
      integration: MergedIntegrationType
    ): Promise<IRestResponse<void>> => {
      var response = await integrationService.putIntegration(
        this.state.editIntegration?.id as string,
        integration
      );

      if (!response.isError && this.state.editIntegration) {
        this.setState({ isSuccess: true });
        this.props.removeIntegration(this.state.editIntegration.id);
        var updatedIntegration = replaceNullOrUndefinedProperties(
          this.state.editIntegration,
          integration
        );
        updatedIntegration.updatedOn = new Date();
        updatedIntegration.updatedBy = (
          this.props.user.profile as UserProfile
        ).id;
        updatedIntegration.updatedByEmail = (
          this.props.user.profile as UserProfile
        ).id;

        this.props.saveIntegrationToState(updatedIntegration);
        this.props.enqueueSnackbar(response.message, { variant: 'success' });
        this.props.navigate(RoutePaths.Integrations);
        return response;
      } else {
        formikBag.setSubmitting(false);
        this.setState({ isSuccess: false });
        this.props.enqueueSnackbar(response.error?.message, {
          variant: 'error',
        });
        return response;
      }
    };

    deleteIntegration = async (
      formikProps: FormikProps<MergedIntegrationType>
    ): Promise<IRestResponse<void>> => {
      var response = await integrationService.deleteIntegration(
        this.state.editIntegration?.id as string,
        this.state.editIntegration as Integration
      );
      if (!response.isError) {
        this.props.removeIntegration(this.state.editIntegration?.id as string);
        this.props.enqueueSnackbar(response.message, { variant: 'success' });
        this.props.navigate(RoutePaths.Integrations);
        return response;
      } else {
        formikProps.setSubmitting(false);
        this.setState({ isSuccess: false });
        this.props.enqueueSnackbar(response.error?.message, {
          variant: 'error',
        });
        return response;
      }
    };

    render() {
      return (
        <Component
          {...(this.props as P)}
          editIntegration={this.state.editIntegration}
          save={this.saveIntegration}
          update={this.updateIntegration}
          delete={this.deleteIntegration}
          isSuccess={this.state.isSuccess}
          serverErrors={this.state.serverErrors}
          canDelete={
            userIsInRole(this.props.user, RoleEnum.OWNER) ||
            userIsInRole(this.props.user, RoleEnum.SUPPORT) ||
            userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN)
          }
        />
      );
    }
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withRouter(
      populateIntegrationsHOC(withSnackbar(IntegrationFormHOCComponent))
    )
  );
};

export default integrationForm;
