import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IGPTTranscriptPrompt from "../../models/app/templates/IGPTTranscriptPrompt";
import ITemplate from "../../models/app/templates/ITemplate";
import ITemplateVariable from "../../models/app/templates/ITemplateVariable";
import ITemplateVariableReplacementStrategy from "../../models/app/templates/ITemplateVariableReplacementStrategy";

export interface TemplatesState {
  isLoaded: boolean;
  templates: Array<ITemplate>;
  templateVariables: Array<ITemplateVariable>;
  replacementStrategies: Array<ITemplateVariableReplacementStrategy>;
  gptTranscriptPrompts: Array<IGPTTranscriptPrompt>;
  pendingUpdate: Array<ITemplate>;
  pendingDeletion: Array<ITemplate>;
}

export const templatesSlice = createSlice({
  name: "templates",
  initialState: {
    isLoaded: false,
    templates: [],
    templateVariables: [],
    replacementStrategies: [],
    gptTranscriptPrompts: [],
    pendingUpdate: [],
    pendingDeletion: [],
  } as TemplatesState,
  reducers: {
    addTemplate: (state, action: PayloadAction<ITemplate>) => {
      return Object.assign({}, state, {
        templates: state.templates.concat(action.payload),
      });
    },
    removeTemplateById: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        templates: state.templates.filter(
          (v: ITemplate) => v.id !== action.payload
        ),
      });
    },
    populateTemplates: (state, action: PayloadAction<Array<ITemplate>>) => {
      return Object.assign({}, state, {
        templates: action.payload,
        isLoaded: true,
      });
    },
    addGPTTranscriptPrompt: (
      state,
      action: PayloadAction<IGPTTranscriptPrompt>
    ) => {
      return Object.assign({}, state, {
        gptTranscriptPrompts: state.gptTranscriptPrompts.concat(action.payload),
      });
    },
    removeGPTTranscriptPromptById: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        gptTranscriptPrompts: state.gptTranscriptPrompts.filter(
          (v: IGPTTranscriptPrompt) => v.id !== action.payload
        ),
      });
    },
    populateGPTTranscriptPrompts: (
      state,
      action: PayloadAction<Array<IGPTTranscriptPrompt>>
    ) => {
      return Object.assign({}, state, {
        gptTranscriptPrompts: action.payload,
        isLoaded: true,
      });
    },
    addTemplateVariable: (state, action: PayloadAction<ITemplateVariable>) => {
      return Object.assign({}, state, {
        templateVariables: state.templateVariables.concat(action.payload),
      });
    },
    removeTemplateVariableById: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        templateVariables: state.templateVariables.filter(
          (v: ITemplateVariable) => v.id !== action.payload
        ),
      });
    },
    populateTemplateVariables: (
      state,
      action: PayloadAction<Array<ITemplateVariable>>
    ) => {
      return Object.assign({}, state, {
        templateVariables: action.payload,
        isLoaded: true,
      });
    },
    populateReplacementReplacementStrategies: (
      state,
      action: PayloadAction<Array<ITemplateVariableReplacementStrategy>>
    ) => {
      return Object.assign({}, state, {
        replacementStrategies: action.payload,
        isLoaded: true,
      });
    },
  },
});
export const {
  addTemplate,
  removeTemplateById,
  populateTemplates,
  addTemplateVariable,
  removeTemplateVariableById,
  addGPTTranscriptPrompt,
  removeGPTTranscriptPromptById,
  populateGPTTranscriptPrompts,
  populateTemplateVariables,
  populateReplacementReplacementStrategies,
} = templatesSlice.actions;
export default templatesSlice.reducer;
