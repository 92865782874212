import * as React from "react";
import {
  DialogActions,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import FormikTextField from "../form/FormikTextField";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { closeDialog } from "../reducers/DialogReducer";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { ApplicationState } from "../store";
import UserService from "../../services/UserService";
import { WithSnackbarProps, withSnackbar } from "notistack";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import { IRestResponse } from "../../services/RestUtilities";
import IRequestEmailChangeModel from "../../models/app/IRequestEmailChangeModel";

var userService = new UserService();

interface ChangeEmailContentProps extends WithSnackbarProps {
  user: User;
  closeDialog: () => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user as User,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDialog: () => {
      const action = closeDialog();
      dispatch(action);
    },
  };
};

function ChangeEmailContent(
  changeEmailContentProps: ChangeEmailContentProps
): JSX.Element {
  const [serverError, setServerError] = useState(
    undefined as string | undefined
  );
  const [success, setSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Required")
      .email("Must be a valid email address"),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{ email: "" }}
        validateOnMount={false}
        isInitialValid={false}
        onSubmit={(
          values: IRequestEmailChangeModel,
          formikHelpers: FormikHelpers<IRequestEmailChangeModel>
        ) => {
          setServerError(undefined);
          userService
            .requestEmailChanage(values)
            .then((response: IRestResponse<boolean>) => {
              formikHelpers.setSubmitting(false);
              if (response.isError) {
                setServerError(response.error?.message);
              } else {
                setSuccess(true);
                changeEmailContentProps.closeDialog();
                changeEmailContentProps.enqueueSnackbar(response.message, {
                  variant: "success",
                });
              }
            });
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <React.Fragment>
            <DialogTitle>Change account email</DialogTitle>
            <form onSubmit={props.handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  Please enter your new email address.
                </DialogContentText>
                <FormikTextField
                  name="email"
                  label="Email"
                  value={props.values.email}
                  errorText={props.errors.email}
                  touched={props.touched.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  autoComplete="off"
                  required
                />
                {serverError && (
                  <Typography color="error">{serverError}</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={props.isSubmitting}
                  onClick={changeEmailContentProps.closeDialog}
                  color="primary"
                  variant="text"
                >
                  Cancel
                </Button>
                <FormikSynchronousButton
                  denseMargin
                  isSuccess={success}
                  isValid={props.isValid}
                  isSubmitting={props.isSubmitting}
                  variant="text"
                >
                  Request Email Change
                </FormikSynchronousButton>
              </DialogActions>
            </form>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ChangeEmailContent));
