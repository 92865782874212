import { CircularProgress } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

const useStyles = makeStyles(() =>
  createStyles({
    buttonProgress: {
      color: blue[600],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface ProgressProps {}

const Progress = function (props: ProgressProps) {
  const classes = useStyles(props);
  return <CircularProgress size={24} className={classes.buttonProgress} />;
};

export default Progress;
