import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CorrelationModel from "../../models/CorrelationModel";

export interface OrganizationState extends CorrelationModel {
  tenantId: string;
  organizationName: string;
  isLoaded: boolean;
}

export const organizationSlice = createSlice({
  name: "organization",
  initialState: { isLoaded: false } as OrganizationState,
  reducers: {
    setTenantId: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, { tenantId: action.payload });
    },
    populateOrganization: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        organizationName: action.payload,
        isLoaded: true,
      });
    },
  },
});

export const { setTenantId, populateOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
