import * as React from "react";
import IUser from "../../models/app/IUser";
import UserService from "../../services/UserService";
import { Formik, FormikProps, FormikErrors, FormikHelpers } from "formik";
import FormikSelectValues from "../form/interfaces/FormikSelectValuesProp";
import * as Yup from "yup";
import { Theme, Paper, Grid, Typography, IconButton } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { withSnackbar, WithSnackbarProps } from "notistack";
import FormikTextField from "../form/FormikTextField";
import FormikSelect from "../form/FormikSelect";
import FormikDeleteButton from "../form/FormikDeleteButton";
import {
  IRestResponse,
  IFormikErrors,
  IError,
} from "../../services/RestUtilities";
import { connect } from "react-redux";
import { User } from "oidc-client";
import loadUserFromQueryParamHOC from "../hocs/LoadUserFromQueryParamHOC";
import { StatusEnum } from "../../models/StatusEnum";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import { getRole, getCascadedRoles } from "../../helpers/Helpers";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Constants from "../../theme/Constants";
import classNames from "classnames";
import RegularExpressions from "../../helpers/RegularExpressions";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import RoutePaths from "../../routing/RoutePaths";
import {
  addUser,
  addUserToPendingDeletion,
  addUserToPendingUpdate,
  removeUserByEmail,
} from "../reducers/UserReducer";
import { ApplicationState } from "../store";

const userService = new UserService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
  });
interface IUserFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  addUser: (user: IUser) => void;
  addUserToPendingDelete: (user: IUser) => void;
  addUserToPendingUpdate: (user: IUser) => void;
  removeUserByEmail: (email: string) => void;
  user?: User;
  users: Array<IUser>;
  initialUser: IUser;
}

type UserFormState = {
  assignableRoles: FormikSelectValues;
  serverErrors?: IFormikErrors;
  isSuccess: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user,
    users: state.usersState.users,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUser: (user: IUser) => {
      const action = addUser(user);
      dispatch(action);
    },
    addUserToPendingDelete: (user: IUser) => {
      const action = addUserToPendingDeletion(user);
      dispatch(action);
    },
    addUserToPendingUpdate: (user: IUser) => {
      const action = addUserToPendingUpdate(user);
      dispatch(action);
    },
    removeUserByEmail: (email: string) => {
      const action = removeUserByEmail(email);
      dispatch(action);
    },
  };
};

class UserForm extends React.Component<IUserFormProps, UserFormState> {
  state = {
    assignableRoles: [] as FormikSelectValues,
    serverErrors: undefined,
    isSuccess: false,
  };

  deleteUser(props: FormikProps<IUser>, enqueueSnackbar: any) {
    userService.deleteUser(props.values.email as string).then((response) => {
      if (response.isError) {
        enqueueSnackbar(response.error?.message, { variant: "error" });
        props.setStatus(response.error?.message);
      } else {
        this.props.initialUser.correlationModel = {
          correlationId: response.correlationId as string,
          status: StatusEnum.PENDING,
        };
        this.props.addUserToPendingDelete(this.props.initialUser);
        this.props.removeUserByEmail(props.values.email as string);
        this.props.navigate(RoutePaths.Users);
      }
    });
  }

  UNSAFE_componentWillMount() {
    this.setState((state, props) => ({
      assignableRoles: this.getAssignableRolesFromCurrentUser(
        props.user as User
      ),
    }));
  }

  getAssignableRolesFromCurrentUser(user: User): FormikSelectValues {
    const roleArray: FormikSelectValues = [];

    var role = getRole(user.profile.role as string[]);
    var cascadedRoles = getCascadedRoles(role).reverse();
    cascadedRoles.forEach((value) => {
      roleArray.push({ value: value, label: value });
    });
    return roleArray;
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(48, "Max 48 characters")
      .matches(
        new RegExp(RegularExpressions.NAME),
        "Must begin with and contain alphabetic character. May contain one of ( - ) ( , ) ( ' ) ( . )."
      )
      .required("Required"),
    lastName: Yup.string()
      .max(48, "Max 48 characters")
      .matches(
        new RegExp(RegularExpressions.NAME),
        "Must begin with and contain alphabetic character. May contain one of ( - ) ( , ) ( ' ) ( . )."
      )
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    role: Yup.mixed().required("Role is required"),
  });

  render() {
    const { classes, enqueueSnackbar } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={
          this.props.initialUser
            ? {
                ...this.props.initialUser,
              }
            : ({
                email: "",
                firstName: "",
                lastName: "",
                role: "User",
                authorizedProjects: [],
              } as IUser)
        }
        onSubmit={(values: IUser, formikHelpers: FormikHelpers<IUser>) => {
          const newUser = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
          } as IUser;
          if (this.props.initialUser) {
            userService
              .putUser(newUser.email, newUser)
              .then((response: IRestResponse<IUser>) => {
                if (response.isError) {
                  const { formikErrors: serverErrors, ...formikErrors } =
                    response.error as IError;
                  enqueueSnackbar(response.error?.message, {
                    variant: "error",
                  });
                  formikHelpers.setStatus(formikErrors as FormikErrors<IUser>);
                  this.setState({ serverErrors: serverErrors });
                  formikHelpers.setSubmitting(false);
                  formikHelpers.resetForm({ values: newUser });
                } else {
                  this.setState({ isSuccess: true });
                  newUser.correlationModel = {
                    correlationId: response.correlationId as string,
                    status: StatusEnum.PENDING,
                  };
                  this.props.removeUserByEmail(this.props.initialUser.email);
                  this.props.addUserToPendingUpdate(this.props.initialUser);
                  this.props.addUser(newUser);
                  this.props.navigate(RoutePaths.Users);
                  formikHelpers.setSubmitting(false);
                }
              });
          } else {
            userService
              .postUser(newUser)
              .then((response: IRestResponse<IUser>) => {
                if (response.isError) {
                  const { formikErrors: serverErrors, ...formikErrors } =
                    response.error as IError;
                  enqueueSnackbar(response.error?.message, {
                    variant: "error",
                  });
                  formikHelpers.setStatus(formikErrors as FormikErrors<IUser>);
                  this.setState({ serverErrors: serverErrors });
                  formikHelpers.setSubmitting(false);
                  formikHelpers.resetForm({ values: newUser });
                } else {
                  this.setState({ isSuccess: true });
                  newUser.correlationModel = {
                    correlationId: response.correlationId as string,
                    status: StatusEnum.PENDING,
                  };
                  this.props.addUser(newUser);
                  formikHelpers.setSubmitting(false);
                  this.props.navigate(RoutePaths.Users);
                }
              });
          }
        }}
        validateOnMount={true}
        initialErrors={{}}
        validationSchema={this.validationSchema}
      >
        {(props) => (
          <React.Fragment>
            <IconButton
              className={classes.return}
              disabled={props.isSubmitting}
              onClick={() => this.props.navigate(RoutePaths.Users)}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              className={classNames(classes.title, classes.paper)}
              align="left"
              variant="h5"
            >
              {this.props.initialUser ? "Edit User" : "New User"}
            </Typography>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom>
                User Details
              </Typography>
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="firstName"
                      label="First Name"
                      value={props.values.firstName}
                      errorText={props.errors.firstName}
                      touched={props.touched.firstName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      disabled={
                        props.initialValues.firstName === "" ? false : true
                      }
                      required={!Boolean(this.props.initialUser)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="lastName"
                      label="Last Name"
                      value={props.values.lastName}
                      errorText={props.errors.lastName}
                      touched={props.touched.lastName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      disabled={
                        props.initialValues.lastName === "" ? false : true
                      }
                      required={!Boolean(this.props.initialUser)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="email"
                      label="Email"
                      value={props.values.email}
                      errorText={props.errors.email}
                      touched={props.touched.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      disabled={props.initialValues.email === "" ? false : true}
                      required={!Boolean(this.props.initialUser)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      name="role"
                      label="Role"
                      value={props.values.role}
                      selectValues={this.state.assignableRoles}
                      errorText={props.errors.role}
                      touched={props.touched.role}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      required
                    />
                  </Grid>
                  {/* {this.state.serverErrors && (
                                        <Grid item xs={12}>
                                            <List>
                                                <ListItem>
                                                    {this.state.serverErrors.map((error) => (
                                                        <ListItemText primary={error} />
                                                    ))}
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    )} */}
                </Grid>

                <Grid item>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.isSuccess}
                    variant="contained"
                  >
                    {props.initialValues.email === "" ? "Create" : "Update"}
                  </FormikSynchronousButton>
                </Grid>
              </form>
            </Paper>
            {this.props.initialUser && (
              <Paper
                className={classNames(classes.bottomPaper, classes.paper)}
                elevation={3}
              >
                <Typography variant="h6">Delete</Typography>
                <Typography variant="subtitle1">
                  Remove the user from your organization.
                </Typography>
                <Grid container>
                  <Grid item>
                    <FormikDeleteButton
                      isSubmitting={props.isSubmitting}
                      onConfirm={() => {
                        this.deleteUser(props, enqueueSnackbar);
                      }}
                      dialogTitle="Delete user?"
                      confirmText="Delete"
                      dialogContent={
                        "Are you sure you want to delete user " +
                        props.values.email +
                        "?"
                      }
                      disabled={!Boolean(this.props.initialUser)}
                    >
                      Delete
                    </FormikDeleteButton>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  loadUserFromQueryParamHOC(
    withStyles(styles)(withSnackbar(withRouter(UserForm)))
  )
);
