import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export class DialogContent {
  public constructor(
    public content: JSX.Element | string,
    public title?: string,
    public confirmText?: string,
    public confirmAction?: Function,
    public isDestructive?: boolean,
    public cancelAction?: Function
  ) {}
}

export interface DialogState {
  visible: boolean;
  content: DialogContent;
}

export const dialogSlice = createSlice({
  name: "dialog",
  initialState: {} as DialogState,
  reducers: {
    openDialog: (state, action: PayloadAction<DialogContent>) => {
      return Object.assign({}, { visible: true, content: action.payload });
    },
    closeDialog: () => {
      return Object.assign({}, {
        visible: false,
        content: new DialogContent(
          "",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ),
      } as DialogState);
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
