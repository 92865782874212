import * as React from "react";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountPopOut from "../accountPopOut/AccountPopOut";
import { Hidden, Typography, Theme, Button, Link } from "@mui/material";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { User } from "oidc-client";
import CustomizedBreadcrumbs from "./Breadcrumbs";
import Breadcrumb from "../../models/app/Breadcrumb";
import Logo from "../../theme/Logo";
import RoutePaths from "../../routing/RoutePaths";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import Constants from "../../../src/theme/Constants";
import populateOrganizationNameHOC, {
  withOrganizationProps,
} from "../hocs/PopulateOrganizationNameHOC";

const styles = (theme: Theme) =>
  createStyles({
    toolbarLeft: {
      flexGrow: 1,
      paddingLeft: theme.spacingNumber(2),
    },
    logoContainer: {
      [theme.breakpoints.up("md")]: {
        width: theme.drawer.width as number,
        textAlign: "center",
      },
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "80px",
        zIndex: theme.zIndex.drawer + 1,
      },
      background: theme.palette.common.white,
      display: "flex",
      justifyContent: "center",
      color: Constants.COLORS.CQUENCE_BLACK,
    },
    menuButton: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      color: Constants.COLORS.CQUENCE_BLACK,
    },
    logoButtonRoot: {
      "&:hover": {
        background: "none",
      },
      scale: 1.75,
      height: "auto",
      color: theme.palette.common.white,
    },
    toolbarPadding: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: 0,
      },
    },
  });

const mapStateToProps = (state: ApplicationState) => {
  return { user: state.oidc.user };
};

interface HeaderProps
  extends WithStyles<typeof styles>,
    WithRouterProps,
    withOrganizationProps {
  breadcrumbs: Breadcrumb[];
  handleDrawerToggle: () => void;
  user?: User;
}

class Header extends React.Component<HeaderProps> {
  render() {
    const { classes, breadcrumbs } = this.props;
    return (
      <AppBar elevation={2} position="fixed" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbarPadding}>
          <Link className={classes.logoContainer} href={RoutePaths.Landing}>
            <Logo width={64} />
          </Link>
          <div className={classes.toolbarLeft}>
            <Hidden mdDown implementation="css">
              <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            </Hidden>
          </div>
          <Hidden mdDown implementation="css">
            <AccountPopOut />
          </Hidden>
          {this.props.user && (
            <Typography variant="subtitle1">
              {this.props.user.profile.firstName +
                " " +
                `(${this.props.organization.organizationName})`}
            </Typography>
          )}
          <Hidden mdUp implementation="css">
            <IconButton
              className={classes.menuButton}
              aria-label="Menu"
              onClick={this.props.handleDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withRouter(populateOrganizationNameHOC(withStyles(styles)(Header))));
