import * as React from "react";
import { Formik, FormikProps, FormikErrors, FormikHelpers } from "formik";
import FormikSelectValues from "../form/interfaces/FormikSelectValuesProp";
import * as Yup from "yup";
import { Theme, Paper, Grid, Typography, IconButton, Box } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { withSnackbar, WithSnackbarProps } from "notistack";
import FormikTextField from "../form/FormikTextField";
import FormikDeleteButton from "../form/FormikDeleteButton";
import {
  IRestResponse,
  IFormikErrors,
  IError,
} from "../../services/RestUtilities";
import { connect } from "react-redux";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Constants from "../../theme/Constants";
import classNames from "classnames";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import RoutePaths from "../../routing/RoutePaths";
import {
  addGPTTranscriptPrompt,
  addTemplateVariable,
  removeGPTTranscriptPromptById,
  removeTemplateVariableById,
  TemplatesState,
} from "../reducers/TemplatesReducer";
import { ApplicationState } from "../store";
import TemplatesService from "../../services/TemplatesService";
import ITemplateVariable from "../../models/app/templates/ITemplateVariable";
import PostPutTemplateResponseModel from "../../models/app/templates/PostPutTemplateResponseModel";
import FormikSelect from "../form/FormikSelect";
import ITemplateVariableReplacementStrategy from "../../models/app/templates/ITemplateVariableReplacementStrategy";
import FormikTextArea from "../form/FormikTextArea";
import FormikCheckbox from "../form/FormikCheckbox";
import IGPTTranscriptPrompt from "../../models/app/templates/IGPTTranscriptPrompt";
import populateTemplatesHOC from "../hocs/PopulateTemplatesHOC";
import { userIsInRole } from "../../helpers/Helpers";
import { User } from "oidc-client";
import { RoleEnum } from "../../models/RoleEnum";

const templateService = new TemplatesService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    delete: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
    return: {
      position: "sticky",
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: "0px",
      padding: "0px",
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
    displayNone: {
      // display: "none",
    },
  });

interface ITemplateVariablesFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  addTemplateVariable: (templateVariable: ITemplateVariable) => void;
  removeTemplateVariableById: (id: string) => void;
  addGPTTranscriptPrompt: (prompt: IGPTTranscriptPrompt) => void;
  removeGPTTranscriptPrompt: (id: string) => void;
  templateState: TemplatesState;
  replacementStrategies: FormikSelectValues;
  user: User;
}

type TemplateVariablesFormState = {
  editTemplateVariable?: ITemplateVariable;
  gptTranscriptPrompt?: IGPTTranscriptPrompt;
  systemTemplateVariableId?: string;
  tenantTemplateVariableId?: string;
  serverErrors?: IFormikErrors;
  isSuccess: boolean;
  isSystemPrompt: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    templateState: state.templatesState,
    user: state.oidc.user,
    replacementStrategies: getReplacementStrategies(
      state.templatesState.replacementStrategies
    ),
  };
};

const getReplacementStrategies = (
  strategies: ITemplateVariableReplacementStrategy[]
): FormikSelectValues => {
  const strategyFormikValues: FormikSelectValues = [];
  strategies.forEach((x) => {
    strategyFormikValues.push({ value: x.name, label: x.name });
  });
  return strategyFormikValues;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTemplateVariable: (templateVariable: ITemplateVariable) => {
      const action = addTemplateVariable(templateVariable);
      dispatch(action);
    },
    removeTemplateVariableById: (id: string) => {
      const action = removeTemplateVariableById(id);
      dispatch(action);
    },
    addGPTTranscriptPrompt: (prompt: IGPTTranscriptPrompt) => {
      const action = addGPTTranscriptPrompt(prompt);
      dispatch(action);
    },
    removeGPTTranscriptPrompt: (id: string) => {
      const action = removeGPTTranscriptPromptById(id);
      dispatch(action);
    },
  };
};

class TemplateVariablesForm extends React.Component<
  ITemplateVariablesFormProps,
  TemplateVariablesFormState
> {
  state = {
    serverErrors: undefined,
    isSuccess: false,
    editTemplateVariable: {} as ITemplateVariable,
    gptTranscriptPrompt: {} as IGPTTranscriptPrompt,
    systemTemplateVariableId: undefined,
    tenantTemplateVariableId: undefined,
    isSystemPrompt: false,
  };

  componentDidMount() {
    const id = this.props.params.templateVariableId as string;
    if (id) {
      var templateVariableResult =
        this.props.templateState.templateVariables.find((i) => i.id === id);
      if (templateVariableResult) {
        this.setState({
          editTemplateVariable: templateVariableResult,
          isSystemPrompt: templateVariableResult.isSystemTemplateVariable,
        });
        if (templateVariableResult.isSystemTemplateVariable) {
          this.setState({
            systemTemplateVariableId: templateVariableResult.id as string,
          });
        } else {
          this.setState({
            tenantTemplateVariableId: templateVariableResult.id as string,
          });
        }
        var gptTranscriptPromptResult =
          this.props.templateState.gptTranscriptPrompts.find(
            (x) =>
              x.systemTemplateVariableId == id ||
              x.tenantTemplateVariableId == id
          );
        if (gptTranscriptPromptResult) {
          this.setState({ gptTranscriptPrompt: gptTranscriptPromptResult });
        }
      } else {
        setTimeout(() => {
          this.props.navigate(RoutePaths.TemplateVariables);
        });
      }
    }
  }
  deleteTenantTemplateVariable(
    props: FormikProps<ITemplateVariable>,
    enqueueSnackbar: any
  ) {
    templateService
      .deleteTenantTemplateVariable(props.values.id as string)
      .then((response) => {
        if (response.isError) {
          enqueueSnackbar(response.error?.message, { variant: "error" });
          props.setStatus(response.error?.message);
        } else {
          this.props.removeTemplateVariableById(props.values.id as string);
          this.setState({ editTemplateVariable: {} as ITemplateVariable });
          enqueueSnackbar(response.message, { variant: "success" });
          this.props.navigate(RoutePaths.TemplateVariables);
        }
      });
  }
  deleteSystemTemplateVariable(
    props: FormikProps<ITemplateVariable>,
    enqueueSnackbar: any
  ) {
    templateService
      .deleteSystemTemplateVariable(props.values.id as string)
      .then((response) => {
        if (response.isError) {
          enqueueSnackbar(response.error?.message, { variant: "error" });
          props.setStatus(response.error?.message);
        } else {
          this.props.removeTemplateVariableById(props.values.id as string);
          this.setState({ editTemplateVariable: {} as ITemplateVariable });
          enqueueSnackbar(response.message, { variant: "success" });
          this.props.navigate(RoutePaths.TemplateVariables);
        }
      });
  }

  deleteGPTTranscriptPrompt(
    props: FormikProps<IGPTTranscriptPrompt>,
    enqueueSnackbar: any
  ) {
    templateService
      .deleteGPTTranscriptPrompt(props.values.id as string)
      .then((response) => {
        if (response.isError) {
          enqueueSnackbar(response.error?.message, { variant: "error" });
          props.setStatus(response.error?.message);
        } else {
          this.props.removeTemplateVariableById(props.values.id as string);
          this.setState({ gptTranscriptPrompt: {} as IGPTTranscriptPrompt });
          enqueueSnackbar(response.message, { variant: "success" });
        }
      });
  }

  templateVariablesValidationSchema = Yup.object().shape({
    name: Yup.string().max(512, "Max 512 characters").required("Required"),
    replacementVariableText: Yup.string()
      .max(512, "Max 512 characters")
      .required("Required"),
    replacementStrategy: Yup.string().required("Required"),
    replacementStrategyArguments: Yup.string()
      .max(1024, "Max 1024 characters")
      .required("Required"),
  });

  gptTranscriptPromptValidationSchema = Yup.object().shape({
    promptName: Yup.string()
      .max(512, "Max 512 characters")
      .required("Required"),
    gptTranscriptPromptArguments: Yup.string()
      .max(5000, "Max 5000 characters")
      .required("Required"),
  });

  render() {
    const { classes, enqueueSnackbar } = this.props;
    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          initialStatus={{ creating: !this.state.editTemplateVariable.name }}
          initialValues={
            this.state.editTemplateVariable.name
              ? this.state.editTemplateVariable
              : ({
                  isSystemTemplateVariable: false,
                  name: "",
                  replacementStrategy: "",
                  replacementStrategyArguments: "",
                  replacementVariableText: "",
                  formatStrategy: "STRING",
                } as ITemplateVariable)
          }
          onSubmit={(
            values: ITemplateVariable,
            formikHelpers: FormikHelpers<ITemplateVariable>
          ) => {
            const newTemplateVariable = {
              isSystemTemplateVariable: values.isSystemTemplateVariable,
              name: values.name,
              formatStrategy: values.formatStrategy,
              replacementStrategy: values.replacementStrategy,
              replacementStrategyArguments: values.replacementStrategyArguments,
              replacementVariableText: values.replacementVariableText,
            } as ITemplateVariable;
            if (this.state.editTemplateVariable.name) {
              if (this.state.editTemplateVariable.isSystemTemplateVariable) {
                templateService
                  .putSystemTemplateVariable(
                    this.state.editTemplateVariable.id as string,
                    newTemplateVariable
                  )
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleVariablePutResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        {
                          id: this.state.editTemplateVariable.id,
                          ...newTemplateVariable,
                        },
                        values
                      );
                    }
                  );
              } else {
                templateService
                  .putTenantTemplateVariable(
                    this.state.editTemplateVariable.id as string,
                    newTemplateVariable
                  )
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleVariablePutResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        {
                          id: this.state.editTemplateVariable.id,
                          ...newTemplateVariable,
                        },
                        values
                      );
                    }
                  );
              }
            } else {
              if (newTemplateVariable.isSystemTemplateVariable) {
                templateService
                  .postSystemTemplateVariable(newTemplateVariable)
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleVariablePostResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        newTemplateVariable
                      );
                    }
                  );
              } else {
                templateService
                  .postTenantTemplateVariable(newTemplateVariable)
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleVariablePostResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        newTemplateVariable
                      );
                    }
                  );
              }
            }
          }}
          validateOnMount={true}
          initialErrors={{}}
          validationSchema={this.templateVariablesValidationSchema}
        >
          {(props) => (
            <React.Fragment>
              <IconButton
                className={classes.return}
                disabled={props.isSubmitting}
                onClick={() =>
                  this.props.navigate(RoutePaths.TemplateVariables)
                }
              >
                <ArrowLeft />
              </IconButton>
              <Typography
                className={classNames(classes.title, classes.paper)}
                align="left"
                variant="h5"
              >
                {this.state.editTemplateVariable.name
                  ? "Edit Template Variable"
                  : "New Template Variable"}
              </Typography>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="h6" gutterBottom>
                  Template Variable Details
                </Typography>
                <form onSubmit={props.handleSubmit}>
                  <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                      <FormikCheckbox
                        name="isSystemTemplateVariable"
                        label="Is System Wide Variable"
                        value={props.values.isSystemTemplateVariable}
                        onChange={(e: React.ChangeEvent<any>) => {
                          props.handleChange(e);
                          this.setState({
                            isSystemPrompt: e.target.checked,
                          });
                        }}
                        onBlur={props.handleBlur}
                        disabled={!!this.state.editTemplateVariable.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="name"
                        label="Name"
                        value={props.values.name}
                        errorText={props.errors.name}
                        touched={props.touched.name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoComplete="off"
                        disabled={props.isSubmitting}
                        required={
                          !Boolean(this.state.editTemplateVariable.name)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="replacementVariableText"
                        label="Replacement Variable Text"
                        value={props.values.replacementVariableText}
                        errorText={props.errors.replacementVariableText}
                        touched={props.touched.replacementVariableText}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoComplete="off"
                        disabled={props.isSubmitting}
                        required={
                          !Boolean(
                            this.state.editTemplateVariable
                              .replacementVariableText
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSelect
                        name="replacementStrategy"
                        label="replacementStrategy"
                        value={props.values.replacementStrategy}
                        selectValues={this.props.replacementStrategies}
                        errorText={props.errors.replacementStrategy}
                        touched={props.touched.replacementStrategy}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextArea
                        name="replacementStrategyArguments"
                        placeholder="Replacement Variable Arguments"
                        value={props.values.replacementStrategyArguments}
                        errorText={props.errors.replacementStrategyArguments}
                        touched={props.touched.replacementStrategyArguments}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoComplete="off"
                        disabled={props.isSubmitting}
                        required={
                          !Boolean(
                            this.state.editTemplateVariable
                              .replacementStrategyArguments
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <FormikSynchronousButton
                      isValid={props.isValid && props.dirty}
                      isSubmitting={props.isSubmitting}
                      isSuccess={this.state.isSuccess}
                      variant="contained"
                      disabled={userIsInRole(this.props.user, RoleEnum.SUPPORT)}
                    >
                      {props.status.creating ? "Create" : "Update"}
                    </FormikSynchronousButton>
                  </Grid>
                </form>
                {this.state.editTemplateVariable.name && (
                  <React.Fragment>
                    <Box className={classNames(classes.delete)}>
                      <Typography variant="h6">Delete</Typography>
                      <Typography variant="subtitle1">
                        Remove the Template Variable and associated GPT Prompt
                      </Typography>
                    </Box>
                    <Grid container>
                      <Grid item>
                        <FormikDeleteButton
                          isSubmitting={props.isSubmitting}
                          onConfirm={() => {
                            if (
                              this.state.editTemplateVariable
                                .isSystemTemplateVariable
                            ) {
                              this.deleteSystemTemplateVariable(
                                props,
                                enqueueSnackbar
                              );
                            } else {
                              this.deleteTenantTemplateVariable(
                                props,
                                enqueueSnackbar
                              );
                            }
                          }}
                          dialogTitle="Delete Template Variable?"
                          confirmText="Delete"
                          dialogContent={
                            "Are you sure you want to delete template variable " +
                            this.state.editTemplateVariable.name +
                            " and the associated GPT Prompt?"
                          }
                          disabled={
                            Boolean(!this.state.editTemplateVariable.name || 
                              userIsInRole(this.props.user, RoleEnum.SUPPORT)
                            )
                          }
                        >
                          Delete
                        </FormikDeleteButton>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Paper>
            </React.Fragment>
          )}
        </Formik>
        <Formik
          enableReinitialize
          initialStatus={{
            creating: !this.state.gptTranscriptPrompt.promptName,
          }}
          initialValues={
            this.state.gptTranscriptPrompt.promptName
              ? this.state.gptTranscriptPrompt
              : ({
                  id: "",
                  promptName: "",
                  gptTranscriptPromptArguments: "",
                } as IGPTTranscriptPrompt)
          }
          onSubmit={(
            values: IGPTTranscriptPrompt,
            formikHelpers: FormikHelpers<IGPTTranscriptPrompt>
          ) => {
            const newTemplateVariable = {
              promptName: values.promptName,
              gptTranscriptPromptArguments: values.gptTranscriptPromptArguments,
              systemTemplateVariableId: this.state.systemTemplateVariableId,
              tenantTemplateVariableId: this.state.tenantTemplateVariableId,
            } as IGPTTranscriptPrompt;
            if (this.state.gptTranscriptPrompt.promptName) {
              if (this.state.isSystemPrompt) {
                templateService
                  .putSystemVariableGPTTranscriptPrompt(
                    this.state.gptTranscriptPrompt.id as string,
                    newTemplateVariable
                  )
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleGPTTranscriptPromptPutResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        {
                          id: this.state.gptTranscriptPrompt.id,
                          ...newTemplateVariable,
                        },
                        values
                      );
                    }
                  );
              } else {
                templateService
                  .putTenantVariableGPTTranscriptPrompt(
                    this.state.gptTranscriptPrompt.id as string,
                    newTemplateVariable
                  )
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleGPTTranscriptPromptPutResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        {
                          id: this.state.gptTranscriptPrompt.id,
                          ...newTemplateVariable,
                        },
                        values
                      );
                    }
                  );
              }
            } else {
              if (this.state.isSystemPrompt) {
                templateService
                  .postSystemVariableGPTTranscriptPrompt(newTemplateVariable)
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleGPTTranscriptPromptPostResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        newTemplateVariable
                      );
                    }
                  );
              } else {
                templateService
                  .postTenantVariableGPTTranscriptPrompt(newTemplateVariable)
                  .then(
                    (response: IRestResponse<PostPutTemplateResponseModel>) => {
                      this.handleGPTTranscriptPromptPostResponse(
                        response,
                        enqueueSnackbar,
                        formikHelpers,
                        newTemplateVariable
                      );
                    }
                  );
              }
            }
          }}
          validateOnMount={true}
          initialErrors={{}}
          validationSchema={this.gptTranscriptPromptValidationSchema}
        >
          {(props) => (
            <React.Fragment>
              <Paper
                className={classNames(classes.bottomPaper, classes.paper)}
                elevation={3}
              >
                <Typography variant="h6" gutterBottom>
                  GPT Prompt
                </Typography>
                <form onSubmit={props.handleSubmit}>
                  <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="promptName"
                        label="Name"
                        value={props.values.promptName}
                        errorText={props.errors.promptName}
                        touched={props.touched.promptName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoComplete="off"
                        disabled={props.isSubmitting}
                        required={
                          !Boolean(this.state.gptTranscriptPrompt.promptName)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextArea
                        name="gptTranscriptPromptArguments"
                        placeholder="GPT Prompt Arguments"
                        value={props.values.gptTranscriptPromptArguments}
                        errorText={props.errors.gptTranscriptPromptArguments}
                        touched={props.touched.gptTranscriptPromptArguments}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoComplete="off"
                        disabled={props.isSubmitting}
                        required={
                          !Boolean(
                            this.state.gptTranscriptPrompt
                              .gptTranscriptPromptArguments
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <FormikSynchronousButton
                      isValid={
                        props.isValid &&
                        props.dirty &&
                        Boolean(
                          this.state.systemTemplateVariableId ||
                            this.state.tenantTemplateVariableId
                        )
                      }
                      isSubmitting={props.isSubmitting}
                      isSuccess={this.state.isSuccess}
                      variant="contained"
                      disabled={userIsInRole(this.props.user, RoleEnum.SUPPORT)}
                    >
                      {props.status.creating ? "Create" : "Update"}
                    </FormikSynchronousButton>
                  </Grid>
                </form>
                {this.state.gptTranscriptPrompt.promptName && (
                  <React.Fragment>
                    <Grid container>
                      <Grid item>
                        <Box className={classes.delete}>
                          <Typography variant="h6">Delete</Typography>
                          <Typography variant="subtitle1">
                            Remove the GPT Prompt
                          </Typography>
                        </Box>
                        <FormikDeleteButton
                          isSubmitting={props.isSubmitting}
                          onConfirm={() => {
                            this.deleteGPTTranscriptPrompt(
                              props,
                              enqueueSnackbar
                            );
                          }}
                          dialogTitle="Delete GPT Prompt?"
                          confirmText="Delete"
                          dialogContent={
                            "Are you sure you want to delete GPT Prompt " +
                            this.state.gptTranscriptPrompt.promptName +
                            "?"
                          }
                          disabled={
                            Boolean(!this.state.gptTranscriptPrompt.promptName ||
                              userIsInRole(this.props.user, RoleEnum.SUPPORT)
                            )
                          }
                        >
                          Delete
                        </FormikDeleteButton>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Paper>
            </React.Fragment>
          )}
        </Formik>
      </React.Fragment>
    );
  }

  private handleVariablePostResponse(
    response: IRestResponse<PostPutTemplateResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<ITemplateVariable>,
    newTemplateVariable: ITemplateVariable
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;

      formikHelpers.setStatus(formikErrors as FormikErrors<ITemplateVariable>);
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newTemplateVariable });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      if (this.state.isSystemPrompt) {
        this.setState({
          systemTemplateVariableId: response.result?.id as string,
        });
      } else {
        this.setState({
          tenantTemplateVariableId: response.result?.id as string,
        });
      }
      newTemplateVariable.id = response.result?.id as string;
      this.setState({
        isSuccess: true,
        editTemplateVariable: newTemplateVariable,
      });
      this.props.addTemplateVariable(newTemplateVariable);
      formikHelpers.setSubmitting(false);
      formikHelpers.setStatus({ creating: false });
      formikHelpers.resetForm({ values: newTemplateVariable });
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }

  private handleVariablePutResponse(
    response: IRestResponse<PostPutTemplateResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<ITemplateVariable>,
    newTemplateVariable: ITemplateVariable,
    values: ITemplateVariable
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;

      formikHelpers.setStatus(formikErrors as FormikErrors<ITemplateVariable>);
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newTemplateVariable });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      this.setState({ isSuccess: true });
      this.props.removeTemplateVariableById(values.id as string);
      this.props.addTemplateVariable(newTemplateVariable);
      formikHelpers.setSubmitting(false);
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }

  private handleGPTTranscriptPromptPostResponse(
    response: IRestResponse<PostPutTemplateResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<IGPTTranscriptPrompt>,
    newPrompt: IGPTTranscriptPrompt
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;

      formikHelpers.setStatus(
        formikErrors as FormikErrors<IGPTTranscriptPrompt>
      );
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newPrompt });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      newPrompt.id = response.result?.id as string;
      this.setState({
        isSuccess: true,
        gptTranscriptPrompt: newPrompt,
      });
      this.props.addGPTTranscriptPrompt(newPrompt);
      formikHelpers.setSubmitting(false);
      formikHelpers.setStatus({ creating: false });
      formikHelpers.resetForm({ values: newPrompt });
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }

  private handleGPTTranscriptPromptPutResponse(
    response: IRestResponse<PostPutTemplateResponseModel>,
    enqueueSnackbar: any,
    formikHelpers: FormikHelpers<IGPTTranscriptPrompt>,
    newPrompt: IGPTTranscriptPrompt,
    values: IGPTTranscriptPrompt
  ) {
    if (response.isError) {
      const { formikErrors: serverErrors, ...formikErrors } =
        response.error as IError;

      formikHelpers.setStatus(formikErrors as FormikErrors<ITemplateVariable>);
      this.setState({ serverErrors: serverErrors });
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newPrompt });
      enqueueSnackbar(response.error?.message, {
        variant: "error",
      });
    } else {
      this.setState({ isSuccess: true });
      this.props.removeTemplateVariableById(values.id as string);
      this.props.addGPTTranscriptPrompt(newPrompt);
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm({ values: newPrompt });
      enqueueSnackbar(response.message, { variant: "success" });
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  populateTemplatesHOC(
    withSnackbar(withStyles(styles)(withRouter(TemplateVariablesForm)))
  )
);
