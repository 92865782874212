import CquenceLogo from "../assets/cquence.svg";
interface LogoProps {
  width: number;
}

const Logo = (props: LogoProps) => (
  <img src={CquenceLogo} width={props.width} alt="Cquence Logo" />
);

export default Logo;
