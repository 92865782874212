import { createUserManager } from "redux-oidc";
import { UserManagerSettings } from "oidc-client";
import GlobalConfig from "../../GlobalConfig";

const UserManagerConfig: UserManagerSettings = {
  client_id: "cquence-web-main",
  authority: `https://${GlobalConfig.IDENTITY_AUTHORITY}`,
  redirect_uri: `https://${GlobalConfig.SPA_HOST}/callback`,
  response_type: "code",
  scope: "openid profile apiGateway",
  automaticSilentRenew: true,
  silent_redirect_uri: `https://${GlobalConfig.SPA_HOST}/silent-renew.html`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  post_logout_redirect_uri: `https://${GlobalConfig.SPA_HOST}`,
};

const UserManager = createUserManager(UserManagerConfig);

export default UserManager;
