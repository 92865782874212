import * as React from "react";
import { Typography } from "@mui/material";

class NotFound extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Typography>Not Found</Typography>
      </React.Fragment>
    );
  }
}

export default NotFound;
