import Integration from "../Integration";
import { IntegrationEnum } from "../IntegrationEnum";
import KeyValuePair from "../../../KeyValuePair";
import CorrelationModel from "../../../CorrelationModel";
import { EnvironmentEnum } from "../../../EnvironmentEnum";

export default class GongIntegration implements Integration {
  constructor() {
    this.type = IntegrationEnum.GONG;
  }
  type: IntegrationEnum;
  id: string;
  apiKey: string;
  apiKeySecret: string;
  webhookPublicKey: string;
  createdOn: Date;
  updatedOn: Date | null;
  createdBy: string;
  createdByEmail: string;
  updatedBy: string | null;
  updatedByEmail: string;
  integrationType: number;
  isTenantLevel: boolean;
}
