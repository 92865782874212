import * as React from "react";
import { Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import Loading from "../loading/Loading";
import RoutePaths from "../../routing/RoutePaths";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import UserManager from "./UserManager";
import GlobalConfig from "../../GlobalConfig";
import Constants from "../../theme/Constants";

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      marginTop: theme.toolbar.height * 2,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
  });

interface LoginRedirectProps
  extends WithStyles<typeof styles>,
    WithRouterProps {}

class LoginRedirect extends React.Component<LoginRedirectProps> {
  componentDidMount() {
    const redirectUri = `https://${GlobalConfig.SPA_HOST}/callback`;
    UserManager.signinRedirect({
      // acr_values: "tenant:" + domain,
      redirect_uri: redirectUri,
      data: { redirectUrl: RoutePaths.Dashboard },
    });
  }

  render() {
    return <Loading />;
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(LoginRedirect)
);
