import Integration from "../Integration";
import { IntegrationEnum } from "../IntegrationEnum";

export default class SalesforceIntegration implements Integration {
  constructor(authCode: string) {
    this.type = IntegrationEnum.SALESFORCE;
    this.authCode = authCode;
  }
  type: IntegrationEnum;
  id: string;
  authCode: string;
  createdOn: Date;
  updatedOn: Date | null;
  createdBy: string;
  createdByEmail: string;
  updatedBy: string | null;
  updatedByEmail: string;
  integrationType: number;
  isTenantLevel: boolean;
}
