import * as React from 'react';
import { openDialog, DialogContent } from '../reducers/DialogReducer';
import { Formik, FormikProps, FormikErrors, FormikHelpers } from 'formik';
import { Theme, Grid, Typography, Box, IconButton } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import {
  IRestResponse,
  IFormikErrors,
  IError,
} from '../../services/RestUtilities';
import { connect } from 'react-redux';
import FormikSynchronousButton from '../form/FormikSynchronousButton';
import Constants from '../../theme/Constants';
import { withRouter, WithRouterProps } from '../hocs/withRouter';
import RoutePaths from '../../routing/RoutePaths';
import { ApplicationState } from '../store';
import OpportunitiesService from '../../services/OpportunitiesService';
import populateOpportunitiesHOC from '../hocs/PopulateOpportunityMappingsHOC';
import {
  OpportunityFieldMappingsState,
  updateFieldMappings,
  removeFieldMappings,
} from '../reducers/OpportunityFieldMappingsReducer';
import IOpportunityFieldMapping from '../../models/app/opportunities/IOpportunityFieldMapping';
import { TemplatesState } from '../reducers/TemplatesReducer';
import { DrawerState, closeDrawer } from '../reducers/DrawerReducer';
import {
  clearSelectedOpportunityMeetingTask,
  removeOpportunityMeetingTaskById,
  setSelectedOpportunityMeetingTask,
  addOpportunityMeetingTasksPendingUpdate,
  OpportunityMeetingTasksState,
  clearNewOpportunityRecordType,
} from '../reducers/OpportunityMeetingTasksReducer';
import IOpportunityMeetingTask, {
  IOpportunityFieldValue,
} from '../../models/app/opportunities/IOpportunityMeetingTask';
import { FieldValuesType } from '../../models/app/opportunities/FieldType';
import {
  getOrderedMappedValuesWithRequiredFieldsForExternalUserTab,
  getExternalUserFormikFieldForSalesforceFieldType,
  getExternalUserFormikFieldForSalesforceCallLog,
  formatUtcTimeToReadable,
  formatUtcDateTimeToLocaleReadable,
  convertDateTimesToUTC,
  convertBooleanStringsToBoolean,
} from './SalesforceFieldHelpers';
import { Subject, Subscription, debounceTime } from 'rxjs';
import SalesforceAccount from '../../models/app/SalesforceAccount';
import FormikAutocompleteServerSearch from '../form/FormikAutocompleteServerSearch';
import SalesforceOpportunity from '../../models/app/SalesforceOpportunity';
import { IOpportunityLayoutDetailsResponseModel } from '../../models/app/opportunities/IOpportunityLayoutDetailsResponseModel';
import IOpportunityDetails from '../../models/app/opportunities/IOpportunityDetails';
import * as Yup from 'yup';
import { userIsInRole, isExtension } from '../../helpers/Helpers';
import { User } from 'oidc-client';
import { RoleEnum } from '../../models/RoleEnum';
import SelectOpportunityRecordTypeContent from '../dialogContents/SelectOpportunityRecordTypeContent';
import PostPatchSalesforceOpportunityModel from '../../models/app/opportunities/PostPatchSalesforceOpportunityModel';
import PostSalesforceTaskModel from '../../models/app/opportunities/PostSalesforceTaskModel';
import {
  SalesforceReferenceValues,
  SalesforceObjectNameId,
} from '../../models/app/SalesforceReferenceValues';
import Popout from './Popout';
import { Eye, ArrowUp } from 'mdi-material-ui';
import { datadogLogs } from '@datadog/browser-logs';

const opportunitiesService = new OpportunitiesService();

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    form: {
      paddingLeft: theme.spacingNumber(2),
      paddingRight: theme.spacingNumber(2),
    },
    return: {
      position: 'sticky',
      top: theme.toolbar.height + theme.spacingNumber(4),
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      height: Constants.HEIGHT.TOOLBAR,
    },
    title: {
      marginTop: '0px',
      padding: '0px',
    },
    subTitle: {
      marginTop: theme.spacingNumber(2),
      padding: '0px',
    },
    bottomPaper: {
      marginBottom: theme.spacing(3),
    },
    controls: {
      zIndex: 1,
      display: 'flex',
      position: 'absolute',
      width: '100%',
      bottom: '0px',
      paddingLeft: theme.spacingNumber(2),
      paddingRight: theme.spacingNumber(2),
      paddingBottom: theme.spacingNumber(2),
      background: theme.palette.common.white,
      boxShadow:
        '0px -3px 3px -2px rgba(0,0,0,0.2), 0px -3px 4px 0px rgba(0,0,0,0.14)',
    },
    leftButtons: {
      flexGrow: 1,
    },
    fullHeight: {
      height: `calc(100vh - 169px - 48px - 76.5px )`,
      overflow: 'scroll',
    },
    infoText: {
      fontSize: '12px',
      fontWeight: 600,
    },
  });

interface IExternalUserTabViewFormProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {
  updateFieldMappings: (mapping: IOpportunityMeetingTask) => void;
  removeFieldMappings: () => void;
  closeDrawer: () => void;
  clearSelectedOpportunityTask: () => void;
  removeOpportunityTask: (id: string) => void;
  setSelectedOpportunityTask: (task: IOpportunityMeetingTask) => void;
  addPendingOpportunityTask: (task: IOpportunityMeetingTask) => void;
  openDialog: (dialogContent: DialogContent) => void;
  opportunityMeetingTasksState?: OpportunityMeetingTasksState;
  opportunityFieldMappingsState: OpportunityFieldMappingsState;
  templatesState: TemplatesState;
  editMeetingTask: IOpportunityMeetingTask;
  defaultAccounts: SalesforceAccount[];
  defaultOpportunities: SalesforceOpportunity[];
  user: User;
  drawerState: DrawerState;
  clearNewOpportunityRecordTypeId: () => void;
}

type ExternalUserTabViewFormState = {
  serverErrors?: IFormikErrors;
  isSuccess: boolean;
  accounts: SalesforceAccount[];
  opportunities: SalesforceOpportunity[];
  referenceFields: SalesforceReferenceValues[];
  opportunityLayoutDetails: IOpportunityLayoutDetailsResponseModel;
  selectedOpportunityDetails: IOpportunityDetails;
  accountWhileSearching: string;
  opportunityWhileSearching: string;
  popoutAnchorEl: null | React.RefObject<HTMLElement>;
  popoutIsOpen: boolean;
  popoutJustification: string | undefined;
  callLogSent: boolean;
  creatingNewOpportunity: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    opportunityFieldMappingsState: state.opportunityFieldMappingsState,
    opportunityMeetingTasksState: state.opportunityMeetingTasksState,
    editMeetingTask: state.opportunityMeetingTasksState.selectedOpportunityTask,
    defaultAccounts: state.opportunityMeetingTasksState.defaultAccounts,
    defaultOpportunities:
      state.opportunityMeetingTasksState.defaultOpportunities,
    user: state.oidc.user as User,
    drawerState: state.drawerState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFieldMappings: (mapping: Array<IOpportunityFieldMapping>) => {
      const action = updateFieldMappings(mapping);
      dispatch(action);
    },
    removeFieldMappings: () => {
      const action = removeFieldMappings();
      dispatch(action);
    },
    closeDrawer: () => {
      const action = closeDrawer();
      dispatch(action);
    },
    clearSelectedOpportunityTask: () => {
      const action = clearSelectedOpportunityMeetingTask();
      dispatch(action);
    },
    removeOpportunityTask: (id: string) => {
      const action = removeOpportunityMeetingTaskById(id);
      dispatch(action);
    },
    setSelectedOpportunityTask: (task: IOpportunityMeetingTask) => {
      const action = setSelectedOpportunityMeetingTask(task);
      dispatch(action);
    },
    addPendingOpportunityTask: (task: IOpportunityMeetingTask) => {
      const action = addOpportunityMeetingTasksPendingUpdate(task);
      dispatch(action);
    },
    openDialog: (dialogContent: DialogContent) => {
      const action = openDialog(dialogContent);
      dispatch(action);
    },
    clearNewOpportunityRecordTypeId: () => {
      const action = clearNewOpportunityRecordType();
      dispatch(action);
    },
  };
};

class ExternalUserTabView extends React.Component<
  IExternalUserTabViewFormProps,
  ExternalUserTabViewFormState
> {
  state = {
    serverErrors: undefined,
    isSuccess: false,
    accounts: [] as SalesforceAccount[],
    opportunities: [] as SalesforceOpportunity[],
    referenceFields: [] as SalesforceReferenceValues[],
    opportunityLayoutDetails: {} as IOpportunityLayoutDetailsResponseModel,
    isSearchingAccounts: true,
    selectedOpportunityDetails: {} as IOpportunityDetails,
    accountWhileSearching: '',
    opportunityWhileSearching: '',
    popoutAnchorEl: React.createRef<HTMLElement>(),
    popoutIsOpen: false,
    popoutJustification: undefined,
    callLogSent: false,
    creatingNewOpportunity: false,
  };
  opportunitiesService = new OpportunitiesService();
  onAccountSearch$: Subject<string>;
  onOpportunitySearch$: Subject<string>;
  accountSearchSubscription: Subscription;
  opportunitySearchSubscription: Subscription;

  constructor(props: IExternalUserTabViewFormProps) {
    super(props);
    this.onAccountSearch$ = new Subject<string>();
    this.onOpportunitySearch$ = new Subject<string>();
    this.handleReferenceFieldSearchChange =
      this.handleReferenceFieldSearchChange.bind(this);
    this.swapReferenceFieldValuesWithIds =
      this.swapReferenceFieldValuesWithIds.bind(this);
  }

  componentDidUpdate(prevProps: IExternalUserTabViewFormProps) {
    if (prevProps.drawerState.isOpen && !this.props.drawerState.isOpen) {
      this.closePopout();
    }
    if (
      this.props.opportunityMeetingTasksState?.selectedOpportunityTask?.id !==
      prevProps.opportunityMeetingTasksState?.selectedOpportunityTask?.id
    ) {
      this.setState({
        accountWhileSearching: '',
        opportunityWhileSearching: '',
        callLogSent: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.accountSearchSubscription) {
      this.accountSearchSubscription.unsubscribe();
    }
    if (this.opportunitySearchSubscription) {
      this.opportunitySearchSubscription.unsubscribe();
    }
  }

  closeDrawer() {
    this.props.closeDrawer();
    this.props.navigate(RoutePaths.OpportunityTasks);
  }

  handleAccountSearchChange(search: string) {
    this.onAccountSearch$.next(search);
  }

  handleOpportunitySearchChange(search: string) {
    this.onOpportunitySearch$.next(search);
  }

  handleReferenceFieldSearchChange(
    fieldName: string,
    values: SalesforceObjectNameId[]
  ) {
    const filteredReferenceFields = this.state.referenceFields.filter(
      field => field.fieldName.toLowerCase() !== fieldName.toLowerCase()
    );
    const replacedReferenceFieldValue = {
      fieldName,
      values,
    } as SalesforceReferenceValues;
    filteredReferenceFields.push(replacedReferenceFieldValue);
    this.setState({ referenceFields: filteredReferenceFields });
  }

  swapReferenceFieldValuesWithIds(
    opportunity: PostPatchSalesforceOpportunityModel
  ) {
    opportunity.fieldValues.forEach(field => {
      const fieldName = field.fieldName.toLowerCase();
      const fieldValue = field.selectedValue;
      const type =
        this.props.opportunityFieldMappingsState.salesforceFields.find(
          x => x.name.toLowerCase() === fieldName
        )?.type;

      if (type && type.toLowerCase() === 'reference') {
        const selectedReferenceField = this.state.referenceFields.find(
          ref => ref.fieldName.toLowerCase() === fieldName
        );
        const selectedValue = selectedReferenceField?.values.find(
          value => value.name === fieldValue
        );
        if (selectedValue?.id) {
          field.selectedValue = selectedValue.id;
        }
      }
    });
  }

  private componentDidMountToAccountResponse(
    props: FormikProps<FieldValuesType>
  ) {
    this.accountSearchSubscription = this.onAccountSearch$
      .pipe(debounceTime(300))
      .subscribe(v => {
        {
          this.opportunitiesService
            .getAccounts(
              this.props.opportunityMeetingTasksState?.selectedOpportunityTask
                ?.createdFor as string,
              v
            )
            .then(response => {
              if (response.result) {
                this.setState({ accounts: response.result ?? [] });
              }
            });
        }
      });
  }

  private componentDidMountToOppportunityResponse(
    props: FormikProps<FieldValuesType>
  ) {
    this.opportunitySearchSubscription = this.onOpportunitySearch$
      .pipe(debounceTime(300))
      .subscribe(v => {
        {
          var accountId = this.state.accounts.filter(
            a => a.name === props.values.accountId
          )[0].id;
          this.opportunitiesService
            .getOpportunitiesForAccount(
              accountId,
              this.props.opportunityMeetingTasksState?.selectedOpportunityTask
                ?.createdFor as string,
              v
            )
            .then(response => {
              if (response.result) {
                this.setState({ opportunities: response.result ?? [] });
              }
            });
        }
      });
  }

  handlePopoutClick = (e: React.MouseEvent<any>, generatedValue: string) => {
    if (this.state.popoutAnchorEl.current == e.currentTarget) {
      this.closePopout();
    } else {
      this.setState({
        popoutIsOpen: true,
        popoutAnchorEl: { current: e.currentTarget },
        popoutJustification: generatedValue,
      });
    }
  };

  closePopout = () => {
    this.setState({
      popoutIsOpen: false,
      popoutAnchorEl: { current: null },
      popoutJustification: undefined,
    });
  };

  handleSendCallLogToSalesforce = ({
    taskId,
    accountId,
    callLog,
    callLogSubject,
    callLogSent,
    meetingDate,
    setCallLogSent,
    enqueueSnackbar,
  }: {
    taskId: string;
    accountId: string;
    callLog: string;
    callLogSubject: string;
    callLogSent: boolean;
    meetingDate: Date | undefined;
    setCallLogSent: any;
    enqueueSnackbar: any;
  }) => {
    if (callLogSent) return;
    if (!taskId || taskId === '') return;
    if (!accountId || accountId === '') return;
    if (!callLog || callLog === '') return;
    if (!callLogSubject || callLogSubject === '') return;

    const salesforceTask: PostSalesforceTaskModel = {
      taskId,
      subject: callLogSubject,
      taskSubtype: 'Call',
      status: 'Completed',
      whatId: accountId,
      description: callLog,
      activityDate: meetingDate || new Date(),
    };

    opportunitiesService
      .postSalesforceTask(salesforceTask)
      .then((response: IRestResponse<any>) => {
        if (response.isError) {
          enqueueSnackbar('Failed to create call log in Salesforce', {
            variant: 'error',
          });

          datadogLogs.logger.error(
            'Failed to create call log in Salesforce',
            {
              ...datadogLogMetadata(this.props),
              taskId,
              accountId,
              callLog,
            },
            new Error(response.error?.message)
          );
        } else {
          enqueueSnackbar('Successfully created call log in Salesforce', {
            variant: 'success',
          });

          datadogLogs.logger.info('Created call log in Salesforce', {
            ...datadogLogMetadata(this.props),
            taskId: response.result,
            accountId,
            callLog,
          });

          setCallLogSent();
        }
      });
  };

  render() {
    const { classes, enqueueSnackbar } = this.props;
    var validationShape = {
      accountId: Yup.string().required('Required'),
    } as any;

    let orderedMappedFieldValuesWithRequiredFields =
      getOrderedMappedValuesWithRequiredFieldsForExternalUserTab(
        this.props.opportunityFieldMappingsState.salesforceFields,
        this.props.editMeetingTask
      );
    let initialValues = {
      accountId: this.state.accountWhileSearching ?? '',
      opportunityId: this.state.opportunityWhileSearching ?? '',
      callLog:
        this.props.opportunityMeetingTasksState?.selectedOpportunityTask
          ?.callLog,
      callLogSubject: '',
    } as FieldValuesType;

    orderedMappedFieldValuesWithRequiredFields.forEach(v => {
      const selectedOpportunityValue =
        this.state.selectedOpportunityDetails?.fields?.filter(
          f => f.name.toLowerCase() == v.fieldName.toLowerCase()
        )[0]?.value ?? '';
      initialValues[`${v.fieldName}-value`] = selectedOpportunityValue;
      initialValues[`${v.fieldName}-justification`] = v.justification;
      if (v.required) {
        validationShape[`${v.fieldName}-value`] =
          Yup.mixed().required('Required');
      }
    });

    const validationSchema = Yup.object().shape(validationShape) as any;

    const isSupportRole =
      userIsInRole(this.props.user, RoleEnum.SUPPORT) ||
      userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN);

    const popoutIcon = (justification: string) => (
      <IconButton
        color="primary"
        disabled={!justification}
        onClick={e => this.handlePopoutClick(e, justification)}
      >
        <Eye />
      </IconButton>
    );

    return (
      <div>
        <Formik
          enableReinitialize
          initialStatus={{}}
          initialValues={initialValues}
          onSubmit={(
            values: FieldValuesType,
            formikHelpers: FormikHelpers<FieldValuesType>
          ) => {
            const stage =
              this.props.opportunityMeetingTasksState?.selectedOpportunityTask
                ?.stage;
            const status =
              this.props.opportunityMeetingTasksState?.selectedOpportunityTask
                ?.status;
            const postPatchModel = {
              taskId: this.props.editMeetingTask.id,
              stage,
              status,
              fieldValues: [],
              callLog: values.callLog,
            } as PostPatchSalesforceOpportunityModel;
            for (const [key, value] of Object.entries(values)) {
              if (value && key !== 'status') {
                const fieldName = key
                  .replace('-value', '')
                  .replace('-justification', '');
                const originalFieldValue =
                  orderedMappedFieldValuesWithRequiredFields.find(
                    v => v.fieldName === fieldName
                  );
                if (originalFieldValue) {
                  const newFieldValueAlreadyCreated =
                    postPatchModel.fieldValues.findIndex(
                      f => f.fieldName === fieldName
                    ) >= 0;
                  let newFieldValue = newFieldValueAlreadyCreated
                    ? (postPatchModel.fieldValues.find(
                        f => f.fieldName === fieldName
                      ) as IOpportunityFieldValue)
                    : ({} as IOpportunityFieldValue);
                  newFieldValue.fieldName = originalFieldValue?.fieldName || '';
                  newFieldValue.isSuccess =
                    originalFieldValue?.isSuccess || false;
                  newFieldValue.generatedValue =
                    originalFieldValue?.generatedValue || '';
                  if (key.includes('-value')) {
                    newFieldValue.selectedValue = value;
                  } else {
                    newFieldValue.justification = value;
                  }
                  if (!newFieldValueAlreadyCreated) {
                    postPatchModel.fieldValues.push(newFieldValue);
                  }
                }
              }
            }

            convertBooleanStringsToBoolean(
              postPatchModel,
              this.props.opportunityFieldMappingsState.salesforceFields
            );
            convertDateTimesToUTC(
              postPatchModel,
              this.props.opportunityFieldMappingsState.salesforceFields
            );
            this.swapReferenceFieldValuesWithIds(postPatchModel);

            if (
              this.props.opportunityMeetingTasksState
                ?.newOpportunityRecordTypeId
            ) {
              var account = this.state.accountWhileSearching;
              var accountId = this.state.accounts.filter(
                a => a.name == account
              )[0].id;
              let recordTypeId =
                this.props.opportunityMeetingTasksState
                  ?.newOpportunityRecordTypeId?.recordTypeId;
              postPatchModel.fieldValues.push({
                fieldName: 'RecordTypeId',
                selectedValue: recordTypeId,
              } as IOpportunityFieldValue);
              postPatchModel.fieldValues.push({
                fieldName: 'AccountId',
                selectedValue: accountId,
              } as IOpportunityFieldValue);

              opportunitiesService
                .postSalesforceOpportunity(postPatchModel)
                .then((response: IRestResponse<any>) => {
                  if (response.isError) {
                    const { formikErrors: serverErrors, ...formikErrors } =
                      response.error as IError;
                    enqueueSnackbar(response.error?.message, {
                      variant: 'error',
                      // TODO: How do we set this as a default option?
                      style: { whiteSpace: 'pre-line' },
                    });
                    formikHelpers.setStatus(formikErrors as FormikErrors<any>);
                    this.setState({ serverErrors: serverErrors });
                    formikHelpers.setSubmitting(false);

                    datadogLogs.logger.error(
                      'Failed to create opportunity in Salesforce',
                      {
                        ...datadogLogMetadata(this.props),
                        accountId,
                      },
                      new Error(response.error?.message)
                    );
                  } else {
                    this.setState({ isSuccess: true });
                    this.props.removeOpportunityTask(
                      this.props.editMeetingTask.id
                    );
                    this.closeDrawer();
                    this.props.navigate(RoutePaths.OpportunityTasks);

                    enqueueSnackbar(
                      'Successfully sent opportunity to Salesforce',
                      { variant: 'success' }
                    );

                    datadogLogs.logger.info(
                      'Created opportunity in Salesforce',
                      {
                        ...datadogLogMetadata(this.props),
                        accountId,
                        opportunityId: response.result,
                      }
                    );
                  }
                });
            } else {
              var opportunity = this.state.opportunityWhileSearching;
              var opportunityId = this.state.opportunities.filter(
                o => `${o.name} (${o.stageName})` == opportunity
              )[0].id;

              opportunitiesService
                .patchSalesforceOpportunity(opportunityId, postPatchModel)
                .then((response: IRestResponse<any>) => {
                  if (response.isError) {
                    const { formikErrors: serverErrors, ...formikErrors } =
                      response.error as IError;
                    enqueueSnackbar(response.error?.message, {
                      variant: 'error',
                      style: { whiteSpace: 'pre-line' },
                    });
                    formikHelpers.setStatus(formikErrors as FormikErrors<any>);
                    this.setState({ serverErrors: serverErrors });
                    formikHelpers.setSubmitting(false);

                    datadogLogs.logger.error(
                      'Failed to update opportunity in Salesforce',
                      {
                        ...datadogLogMetadata(this.props),
                        accountId,
                        opportunityId,
                      },
                      new Error(response.error?.message)
                    );
                  } else {
                    this.setState({ isSuccess: true });
                    this.props.removeOpportunityTask(
                      this.props.editMeetingTask.id
                    );
                    this.closeDrawer();
                    this.props.navigate(RoutePaths.OpportunityTasks);

                    enqueueSnackbar(
                      'Successfully sent opportunity to Salesforce',
                      { variant: 'success' }
                    );

                    datadogLogs.logger.info(
                      'Updated opportunity in Salesforce',
                      {
                        ...datadogLogMetadata(this.props),
                        accountId,
                        opportunityId,
                      }
                    );
                  }
                });
            }
          }}
          validateOnMount={true}
          validationSchema={validationSchema}
        >
          {props => {
            const { accountId, callLog, callLogSubject } = props.values;
            const sendCallLogValid: boolean =
              !!accountId &&
              accountId !== '' &&
              !!callLog &&
              callLog !== '' &&
              !!callLogSubject &&
              callLogSubject !== '' &&
              !this.state.callLogSent;

            return (
              <React.Fragment>
                <form onSubmit={props.handleSubmit}>
                  <Grid container justifyContent="center">
                    <Grid item xs={11}>
                      <Grid className={classes.form} container rowSpacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.subTitle}
                            variant="h6"
                            align="left"
                          >
                            Account
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormikAutocompleteServerSearch
                            name="accountId"
                            label="Search for Account"
                            options={this.state.accounts.map(a => a.name)}
                            errorText={props.errors.accountId as string}
                            touched={props.touched.accountId as boolean}
                            value={props.values.accountId}
                            onChange={(e, value) => {
                              if (value) {
                                var accountId = this.state.accounts.filter(
                                  a => a.name === value
                                )[0].id;
                                opportunitiesService
                                  .getOpportunitiesForAccount(
                                    accountId,
                                    this.props.opportunityMeetingTasksState
                                      ?.selectedOpportunityTask
                                      ?.createdFor as string
                                  )
                                  .then(response => {
                                    if (!response.isError && response.result) {
                                      this.setState({
                                        opportunities: response.result,
                                      });
                                    }
                                    this.setState({
                                      accountWhileSearching: value,
                                    });
                                  });
                              }
                              this.setState({ accountWhileSearching: '' });
                            }}
                            onInputChange={(
                              e: React.ChangeEvent<{}>,
                              value: string,
                              reason: string
                            ) => {
                              if (value && reason === 'input') {
                                if (!this.accountSearchSubscription) {
                                  this.componentDidMountToAccountResponse(
                                    props
                                  );
                                }
                                this.handleAccountSearchChange(value);
                              }
                            }}
                            onBlur={props.handleBlur}
                            required
                            disabled={isSupportRole}
                          />
                        </Grid>
                        {!props.values.accountId && (
                          <div>
                            <ArrowUp />
                            <span className={classes.infoText}>
                              Select an account to start.
                            </span>
                          </div>
                        )}
                        {!!props.values.accountId && (
                          <>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  border: '1px solid lightgrey',
                                  borderRadius: 2,
                                  padding: 2,
                                  marginTop: 3,
                                }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item xs={12}>
                                    {getExternalUserFormikFieldForSalesforceCallLog(
                                      props,
                                      this.props.opportunityFieldMappingsState
                                        .taskDetails,
                                      props.values.callLog,
                                      props.values.callLogSubject,
                                      isSupportRole || this.state.callLogSent
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <FormikSynchronousButton
                                isValid={sendCallLogValid}
                                isSubmitting={false}
                                isSuccess={true}
                                disabled={!sendCallLogValid}
                                onClick={() => {
                                  const taskId = this.props.editMeetingTask.id;
                                  const account = props.values.accountId;
                                  const accountId = this.state.accounts.filter(
                                    a => a.name == account
                                  )[0].id;
                                  const callLog = props.values.callLog;
                                  const callLogSubject =
                                    props.values.callLogSubject;
                                  const callLogSent = this.state.callLogSent;
                                  const meetingDate =
                                    this.props.opportunityMeetingTasksState
                                      ?.selectedOpportunityTask?.meetingDate;

                                  this.handleSendCallLogToSalesforce({
                                    taskId,
                                    accountId,
                                    callLog,
                                    callLogSubject,
                                    callLogSent,
                                    meetingDate,
                                    setCallLogSent: () =>
                                      this.setState({ callLogSent: true }),
                                    enqueueSnackbar: this.props.enqueueSnackbar,
                                  });
                                }}
                              >
                                Send Call Log To Salesforce
                              </FormikSynchronousButton>
                              {!props.values.opportunityId &&
                                !this.state.creatingNewOpportunity && (
                                  <div>
                                    <ArrowUp />
                                    <span className={classes.infoText}>
                                      Clicking this button only sends Account
                                      data. To send Opportunity data, please
                                      ALSO select an Opportunity below and click
                                      the Send Opportunity button at the bottom
                                      of this screen.
                                    </span>
                                  </div>
                                )}
                              {(!!props.values.opportunityId ||
                                this.state.creatingNewOpportunity) && (
                                <div>
                                  <ArrowUp />
                                  <span className={classes.infoText}>
                                    Clicking this button only sends Account
                                    data. To send Opportunity data, please ALSO
                                    click the Send Opportunity button at the
                                    bottom of this screen.
                                  </span>
                                </div>
                              )}
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                className={classes.subTitle}
                                variant="h6"
                                align="left"
                              >
                                Opportunity
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <FormikAutocompleteServerSearch
                                name="opportunityId"
                                label="Search for Opportunity"
                                options={this.state.opportunities.map(
                                  a => `${a.name} (${a.stageName})`
                                )}
                                errorText={props.errors.opportunityId as string}
                                touched={props.touched.oppoprtunity as boolean}
                                value={props.values.opportunityId}
                                onChange={(e, value) => {
                                  if (value) {
                                    var opportunityId =
                                      this.state.opportunities.filter(
                                        o =>
                                          `${o.name} (${o.stageName})` == value
                                      )[0].id;
                                    if (opportunityId) {
                                      opportunitiesService
                                        .getOpportunityDetails(
                                          opportunityId,
                                          this.props
                                            .opportunityMeetingTasksState
                                            ?.selectedOpportunityTask
                                            ?.createdFor as string
                                        )
                                        .then(r => {
                                          if (!r.isError && r.result)
                                            this.setState({
                                              selectedOpportunityDetails:
                                                r.result,
                                            });
                                        });
                                    }
                                    this.setState({
                                      opportunityWhileSearching: value,
                                    });
                                  }
                                }}
                                createNewCallback={() => {
                                  this.setState({
                                    selectedOpportunityDetails:
                                      {} as IOpportunityDetails,
                                    creatingNewOpportunity: true,
                                  });
                                  this.props.openDialog(
                                    new DialogContent(
                                      (
                                        <SelectOpportunityRecordTypeContent
                                          onCancel={() => {
                                            this.props.clearNewOpportunityRecordTypeId();
                                            props.setFieldValue(
                                              'opportunityId',
                                              ''
                                            );
                                          }}
                                        />
                                      )
                                    )
                                  );
                                }}
                                createNewOptionText=" + Create New Opportunity"
                                createNewLabel="Creating a New Opportunity..."
                                createNewClearCallback={() => {
                                  this.props.clearNewOpportunityRecordTypeId();
                                  props.setFieldValue('opportunityId', '');
                                  this.setState({
                                    creatingNewOpportunity: false,
                                  });
                                }}
                                onInputChange={(
                                  e: React.ChangeEvent<{}>,
                                  value: string,
                                  reason
                                ) => {
                                  if (value && reason === 'input') {
                                    if (!this.opportunitySearchSubscription) {
                                      this.componentDidMountToOppportunityResponse(
                                        props
                                      );
                                    }
                                    this.handleOpportunitySearchChange(value);
                                  } else {
                                    this.setState({
                                      selectedOpportunityDetails:
                                        {} as IOpportunityDetails,
                                    });
                                  }
                                }}
                                onBlur={props.handleBlur}
                                required
                                disabled={
                                  isSupportRole || !props.values.accountId
                                }
                              />
                            </Grid>
                            {!props.values.opportunityId &&
                              !this.state.creatingNewOpportunity && (
                                <div style={{ paddingBottom: '2em' }}>
                                  <ArrowUp />
                                  <span className={classes.infoText}>
                                    Select an opportunity to load field values.
                                  </span>
                                </div>
                              )}
                            {(!!props.values.opportunityId ||
                              this.state.creatingNewOpportunity) && (
                              <>
                                <Grid item xs={12}>
                                  {orderedMappedFieldValuesWithRequiredFields.map(
                                    field => {
                                      const fieldName = field.fieldName;
                                      const salesforceField =
                                        this.props.opportunityFieldMappingsState.salesforceFields.find(
                                          f => f.name === field.fieldName
                                        );
                                      let justification = '';
                                      let value = '';
                                      let label = '';

                                      if (!salesforceField) return;

                                      label =
                                        salesforceField.relationshipName ||
                                        salesforceField.label;
                                      if (label === 'Name') {
                                        label = 'Opportunity Name';
                                      }
                                      value =
                                        props.values[`${fieldName}-value`];
                                      justification =
                                        props.values[
                                          `${fieldName}-justification`
                                        ];

                                      if (
                                        salesforceField.type.toLowerCase() ===
                                        'time'
                                      ) {
                                        value = formatUtcTimeToReadable(value);
                                      } else if (
                                        salesforceField.type.toLowerCase() ===
                                        'datetime'
                                      ) {
                                        value =
                                          formatUtcDateTimeToLocaleReadable(
                                            value
                                          );
                                      }

                                      return (
                                        <div key={fieldName}>
                                          <br />
                                          <Box
                                            sx={{
                                              border: '1px solid lightgrey',
                                              borderRadius: 2,
                                              padding: 2,
                                            }}
                                          >
                                            <Grid container alignItems="center">
                                              <Grid item xs={1}>
                                                {popoutIcon(justification)}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={11}
                                                sx={{ paddingLeft: '16px' }}
                                              >
                                                {getExternalUserFormikFieldForSalesforceFieldType(
                                                  this.props.user.profile.sub,
                                                  salesforceField,
                                                  props,
                                                  classes,
                                                  fieldName,
                                                  label,
                                                  value,
                                                  field.selectedValue,
                                                  field.required,
                                                  isSupportRole,
                                                  this.state.referenceFields,
                                                  this
                                                    .handleReferenceFieldSearchChange,
                                                  props.setFieldValue
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Box>
                                          <br />
                                        </div>
                                      );
                                    }
                                  )}
                                </Grid>
                                <Box sx={{ paddingBottom: 6 }}>
                                  <Grid item xs={12} rowSpacing={1}>
                                    <FormikSynchronousButton
                                      isValid={props.isValid}
                                      isSubmitting={props.isSubmitting}
                                      isSuccess={true}
                                      disabled={
                                        !props.isValid || props.isSubmitting
                                      }
                                    >
                                      Send Opportunity To Salesforce
                                    </FormikSynchronousButton>
                                  </Grid>
                                  <div>
                                    <ArrowUp />
                                    <span className={classes.infoText}>
                                      Clicking this button only sends
                                      Opportunity data. To send Account data,
                                      please click the Send Call Log button
                                      above.
                                    </span>
                                  </div>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </React.Fragment>
            );
          }}
        </Formik>
        <Popout
          open={this.state.popoutIsOpen}
          header="Cquence AI Justification"
          anchorEl={this.state.popoutAnchorEl.current}
          text={this.state.popoutJustification}
          handleClose={this.closePopout}
        />
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  populateOpportunitiesHOC(
    withSnackbar(withRouter(withStyles(styles)(ExternalUserTabView)))
  )
);

function datadogLogMetadata(props: IExternalUserTabViewFormProps) {
  const { user } = props;

  return {
    user: user.profile,
    isExtension: isExtension(),
  };
}
