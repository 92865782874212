import { FormikProps } from 'formik';
import {
  IFieldDetails,
  IPicklistValue,
} from '../../models/app/opportunities/IOpportunityLayoutDetailsResponseModel';
import { IField } from '../../models/app/opportunities/IOpportunityLayoutsResponseModel';
import FormikAutocompleteLabelMultiselect from '../form/FormikAutocompleteLabelMultiselect';
import FormikCheckbox from '../form/FormikCheckbox';
import FormikSelect from '../form/FormikSelect';
import FormikTextField from '../form/FormikTextField';
import FormikSelectValues from '../form/interfaces/FormikSelectValuesProp';
import { ChangeEvent } from 'react';
import { FieldValuesType } from '../../models/app/opportunities/FieldType';
import IOpportunityMeetingTask, {
  IOpportunityFieldValue,
} from '../../models/app/opportunities/IOpportunityMeetingTask';
import PostPatchSalesforceOpportunityModel from '../../models/app/opportunities/PostPatchSalesforceOpportunityModel';
import { utcToZonedTime, format } from 'date-fns-tz';
import {
  SalesforceObjectNameId,
  SalesforceReferenceValues,
} from '../../models/app/SalesforceReferenceValues';
import FormikAutocompleteServerSearch from '../form/FormikAutocompleteServerSearch';
import OpportunitiesService from '../../services/OpportunitiesService';
import { Tooltip, Typography } from '@mui/material';
import ISalesforceTaskDetails from '../../models/app/opportunities/ISalesforceTaskDetails';

const opportunitiesService = new OpportunitiesService();

export function getOrderedMappedValuesForSupportTab(
  meetingTask: IOpportunityMeetingTask
): IOpportunityFieldValue[] {
  if (!meetingTask || !meetingTask.fieldValues)
    return [] as IOpportunityFieldValue[];

  var orderedMappedFieldValuesWithRequiredFields = Object.assign(
    [],
    meetingTask.fieldValues
  ) as IOpportunityFieldValue[];
  orderedMappedFieldValuesWithRequiredFields.sort((a, b) => {
    if (a.fieldName < b.fieldName) {
      return -1;
    }
    if (a.fieldName > b.fieldName) {
      return 1;
    }
    return 0;
  });
  return orderedMappedFieldValuesWithRequiredFields;
}

export function getOrderedMappedValuesWithRequiredFieldsForExternalUserTab(
  salesforceFields: IField[],
  meetingTask: IOpportunityMeetingTask
): IOpportunityFieldValue[] {
  let missingRequiredFields = Object.assign(
    [],
    salesforceFields.filter(
      f =>
        f.nillable === false &&
        f.defaultedOnCreate === false &&
        !meetingTask.fieldValues.some(m => m.fieldName === f.name)
    )
  ) as IField[];

  const requiredMappedFields = salesforceFields.filter(
    f =>
      f.nillable === false &&
      f.defaultedOnCreate === false &&
      meetingTask.fieldValues.some(m => m.fieldName === f.name)
  );
  var mappedRequiredFieldValues = Object.assign(
    [],
    meetingTask.fieldValues.map(f => ({ ...f, required: false }))
  ) as IOpportunityFieldValue[];
  for (const field of mappedRequiredFieldValues) {
    if (requiredMappedFields.some(r => r.name === field.fieldName)) {
      field.required = true;
    }
  }

  var orderedMappedFieldValuesWithRequiredFields = Object.assign(
    [],
    mappedRequiredFieldValues
  ) as IOpportunityFieldValue[];
  missingRequiredFields.forEach(f => {
    orderedMappedFieldValuesWithRequiredFields.push({
      generatedValue: '',
      selectedValue: '',
      justification: '',
      isSuccess: false,
      fieldName: f.name,
      required: true,
    } as IOpportunityFieldValue);
  });

  orderedMappedFieldValuesWithRequiredFields.sort((a, b) => {
    if (a.fieldName < b.fieldName) {
      return -1;
    }
    if (a.fieldName > b.fieldName) {
      return 1;
    }
    return 0;
  });
  const nameIndex = orderedMappedFieldValuesWithRequiredFields.findIndex(
    o => o.fieldName === 'Name'
  );
  // return orderedMappedFieldValuesWithRequiredFields;
  array_move(orderedMappedFieldValuesWithRequiredFields, nameIndex, 0);
  return orderedMappedFieldValuesWithRequiredFields;
}

export function getSupporTabFormikFieldForSalesforceFieldType(
  salesforceField: IField | IFieldDetails,
  props: FormikProps<FieldValuesType>,
  onChange: (e: React.ChangeEvent) => void,
  fieldName: string,
  label: string,
  selectedValue: string,
  disabled: boolean
): any {
  var fieldType = salesforceField.type.toLowerCase();
  var formikField;

  if (fieldType === 'string') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="text"
        validate={stringLengthValidator(maxLength)}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'reference') {
    const valueList: FormikSelectValues = [
      { label: 'random', value: 'random' },
    ];
    formikField = (
      <FormikSelect
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        selectValues={valueList}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'currency') {
    const scale = salesforceField.scale;
    const precision = salesforceField.precision;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="number"
        onFocus={e =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        validate={precisionScaleValidator(precision, scale)}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'date') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="text"
        validate={dateValidator}
        placeholder="yyyy-mm-dd"
        disabled={disabled}
      />
    );
  } else if (fieldType === 'picklist') {
    const valueList = generateFormikSelectValues(
      salesforceField.picklistValues
    );
    formikField = (
      <FormikSelect
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        selectValues={valueList}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'multipicklist') {
    const options = generateFormikMultiSelectValues(
      salesforceField.picklistValues
    );
    const selectedValues = selectedValue ? selectedValue.split(';') : []; //"John;Krunal;Max"
    formikField = (
      <FormikAutocompleteLabelMultiselect
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        options={options}
        placeholder=""
        disabled={disabled}
        onChange={(event: ChangeEvent<any>, values: string[]) => {
          props.setFieldValue(`${fieldName}-value`, values.join(';'), true);
        }}
        defaultValue={selectedValues}
      />
    );
  } else if (fieldType === 'textarea') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        placeholder={fieldName}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        validate={stringLengthValidator(maxLength)}
        multiline
        disabled={disabled}
      />
    );
  } else if (fieldType === 'email') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        placeholder={fieldName}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        validate={emailValidator}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'boolean') {
    formikField = (
      <FormikCheckbox
        key={`${fieldName}-value`}
        label={label}
        name={`${fieldName}-value`}
        onBlur={props.handleBlur}
        onChange={onChange}
        value={formatBooleanToReadable(selectedValue)}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'percent') {
    const scale = salesforceField.scale;
    const precision = salesforceField.precision;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        placeholder="%"
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="number"
        onFocus={e =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        validate={precisionScaleValidator(precision, scale)}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'double') {
    const scale = salesforceField.scale;
    const precision = salesforceField.precision;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="number"
        onFocus={e =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        validate={precisionScaleValidator(precision, scale)}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'time') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        validate={timeValidator}
        placeholder="hh:mm am/pm"
        disabled={disabled}
      />
    );
  } else if (fieldType === 'datetime') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        validate={dateTimeValidator}
        placeholder="yyyy-mm-dd hh:mm am/pm"
        disabled={disabled}
      />
    );
  } else if (fieldType === 'phone') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="text"
        validate={stringLengthValidator(maxLength)}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'url') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        type="text"
        validate={stringLengthValidator(maxLength)}
        disabled={disabled}
      />
    );
  } else {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        placeholder={''}
        value={selectedValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={onChange}
        onBlur={props.handleBlur}
        multiline
        disabled={disabled}
      />
    );
  }

  return formikField;
}

export function getExternalUserFormikFieldForSalesforceFieldType(
  userId: string,
  salesforceField: IField | IFieldDetails,
  props: FormikProps<FieldValuesType>,
  classes: any,
  fieldName: string,
  label: string,
  currentValue: string | undefined,
  proposedNewValue: string,
  required: boolean,
  disabled: boolean,
  referenceFields: SalesforceReferenceValues[],
  handleReferenceFieldSearchChange: (
    fieldName: string,
    values: SalesforceObjectNameId[]
  ) => void,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
): any {
  const fieldType = salesforceField.type.toLowerCase();
  let formikField;

  if (fieldType === 'string') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        validate={stringLengthValidator(maxLength)}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'reference') {
    const fieldValues = referenceFields.find(
      field => field.fieldName.toLowerCase() === fieldName.toLowerCase()
    ) || { values: [] };
    const options = fieldValues.values.map(value => value.name);

    formikField = (
      <FormikAutocompleteServerSearch
        name={fieldName}
        label={`Search for ${salesforceField.label}`}
        options={options}
        errorText={props.errors.referenceField as string}
        touched={props.touched.referenceField as boolean}
        value={props.values[`${fieldName}-value`]}
        onChange={(e, value) => {
          if (value) {
            setFieldValue(`${fieldName}-value`, value);
          }
        }}
        onInputChange={(
          e: React.ChangeEvent<{}>,
          value: string,
          reason: string
        ) => {
          if (value && reason === 'input') {
            opportunitiesService
              .searchSalesforceObjects(
                salesforceField.referenceTo[0],
                userId,
                value
              )
              .then(data =>
                handleReferenceFieldSearchChange(fieldName, data.result || [])
              );
          }
        }}
        onBlur={props.handleBlur}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'currency') {
    const scale = salesforceField.scale;
    const precision = salesforceField.precision;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="number"
        onFocus={e =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        validate={precisionScaleValidator(precision, scale)}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'date') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        validate={dateValidator}
        placeholder="yyyy-mm-dd"
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'picklist') {
    const valueList = generateFormikSelectValues(
      salesforceField.picklistValues
    );
    formikField = (
      <FormikSelect
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        selectValues={valueList}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'multipicklist') {
    proposedNewValue = proposedNewValue
      ? proposedNewValue.split(';').join(', ')
      : '';
    const options = generateFormikMultiSelectValues(
      salesforceField.picklistValues
    );
    const selectedValues = currentValue ? currentValue.split(';') : []; //"John;Krunal;Max"
    formikField = (
      <FormikAutocompleteLabelMultiselect
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        options={options}
        placeholder=""
        disabled={disabled}
        onChange={(event: ChangeEvent<{}>, values: string[]) => {
          props.setFieldValue(`${fieldName}-value`, values.join(';'), true);
        }}
        defaultValue={selectedValues}
      />
    );
  } else if (fieldType === 'textarea') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        validate={stringLengthValidator(maxLength)}
        multiline
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'email') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        validate={emailValidator}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'boolean') {
    proposedNewValue = !!formatBooleanToReadable(proposedNewValue)
      ? 'Checked'
      : 'Unchecked';
    formikField = (
      <FormikCheckbox
        key={`${fieldName}-value`}
        label={label}
        name={`${fieldName}-value`}
        onBlur={props.handleBlur}
        onChange={props.handleChange}
        value={formatBooleanToReadable(currentValue)}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'percent') {
    const scale = salesforceField.scale;
    const precision = salesforceField.precision;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        placeholder="%"
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="number"
        onFocus={e =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        validate={precisionScaleValidator(precision, scale)}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'double') {
    const scale = salesforceField.scale;
    const precision = salesforceField.precision;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="number"
        onFocus={e =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        validate={precisionScaleValidator(precision, scale)}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'time') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        validate={timeValidator}
        placeholder="hh:mm am/pm"
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'datetime') {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        placeholder="yyyy-mm-dd hh:mm am/pm"
        validate={dateTimeValidator}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'phone') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        validate={stringLengthValidator(maxLength)}
        required={required}
        disabled={disabled}
      />
    );
  } else if (fieldType === 'url') {
    const maxLength = salesforceField.length;
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type="text"
        validate={stringLengthValidator(maxLength)}
        required={required}
        disabled={disabled}
      />
    );
  } else {
    formikField = (
      <FormikTextField
        key={`${fieldName}-value`}
        name={`${fieldName}-value`}
        label={label}
        placeholder={''}
        value={currentValue}
        errorText={props.errors[`${fieldName}-value}`]}
        touched={props.touched[`${fieldName}-value`]}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        multiline
        required={required}
        disabled={disabled}
      />
    );
  }

  let proposedNewValueTooltip;
  switch (fieldType) {
    case 'boolean':
      proposedNewValueTooltip = 'Select or Deselect the above checkbox';
      break;
    case 'reference':
      proposedNewValueTooltip = 'Search and Select value in the above field';
      break;
    case 'picklist':
      proposedNewValueTooltip = 'Select value in the above dropdown';
      break;
    case 'multipicklist':
      proposedNewValueTooltip = 'Select value(s) in the above dropdown';
      break;
    default:
      proposedNewValueTooltip = 'Copy / Paste into the above field';
  }

  return (
    <>
      {formikField}
      {proposedNewValue && (
        <>
          <Typography
            color="green"
            fontSize="12px"
            fontWeight="bold"
            paddingTop="1em"
          >
            Proposed new value for "{label}":
          </Typography>
          <Tooltip title={proposedNewValueTooltip} placement="bottom-start">
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {' '}
              {proposedNewValue}
            </Typography>
          </Tooltip>
        </>
      )}
    </>
  );
}

// TODO: Export these values (maybe move to constants file)
const salesforceCallLog = {
  description: {
    fieldName: 'callLog',
    maxLength: 32000,
    label: 'Call Log',
  },
  subject: {
    fieldName: 'callLogSubject',
    label: 'Subject',
  },
};

export function getSupporTabFormikFieldForSalesforceCallLog(
  props: FormikProps<FieldValuesType>,
  currentValue: string | undefined
): any {
  const { fieldName, maxLength, label } = salesforceCallLog.description;
  const formikField = (
    <FormikTextField
      key={`${fieldName}`}
      name={`${fieldName}`}
      label={label}
      value={currentValue}
      errorText={props.errors[`${fieldName}`]}
      touched={props.touched[`${fieldName}`]}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      validate={stringLengthValidator(maxLength)}
      multiline
      required={false}
      disabled={false}
    />
  );

  return <>{formikField}</>;
}

export function getExternalUserFormikFieldForSalesforceCallLog(
  props: FormikProps<FieldValuesType>,
  taskDetails: ISalesforceTaskDetails,
  currentDescriptionValue: string | undefined,
  currentSubjectValue: string | undefined,
  disabled: boolean
): any {
  let subjectValueList: FormikSelectValues = [];
  const subjectField = taskDetails.fields.find(f => f.name === 'Subject');
  if (subjectField?.picklistValues) {
    subjectValueList = generateFormikSelectValues(
      subjectField.picklistValues,
      false
    );
  }

  const callLogDescriptionField = (
    <FormikTextField
      key={`${salesforceCallLog.description.fieldName}`}
      name={`${salesforceCallLog.description.fieldName}`}
      label={salesforceCallLog.description.label}
      value={currentDescriptionValue}
      errorText={props.errors[salesforceCallLog.description.fieldName]}
      touched={props.touched[salesforceCallLog.description.fieldName]}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      validate={stringLengthValidator(salesforceCallLog.description.maxLength)}
      multiline
      required={false}
      disabled={disabled}
    />
  );
  const callLogSubjectField = (
    <FormikSelect
      key={salesforceCallLog.subject.fieldName}
      name={salesforceCallLog.subject.fieldName}
      label={salesforceCallLog.subject.label}
      selectValues={subjectValueList}
      value={currentSubjectValue}
      errorText={props.errors[salesforceCallLog.subject.fieldName]}
      touched={props.touched[salesforceCallLog.subject.fieldName]}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      disabled={disabled}
    />
  );

  return (
    <>
      {callLogDescriptionField}
      <br />
      {callLogSubjectField}
    </>
  );
}

export function convertDateTimesToUTC(
  opportunity: PostPatchSalesforceOpportunityModel,
  salesforceFields: IField[]
) {
  opportunity.fieldValues.forEach(field => {
    const salesforceField = salesforceFields.find(
      salesforceField => salesforceField.name == field.fieldName
    );
    if (salesforceField && salesforceField.type === 'time') {
      const time24Hour = convertTime12to24(field.selectedValue);
      if (field.selectedValue && field.selectedValue.length > 0) {
        field.selectedValue = `${time24Hour}Z`;
      }
    } else if (
      salesforceField &&
      salesforceField.type === 'datetime' &&
      field.selectedValue
    ) {
      let timeIdx = field.selectedValue.indexOf(' ');
      let date = field.selectedValue.slice(0, timeIdx);
      let time = field.selectedValue.slice(timeIdx + 1);
      const time24Hour = convertTime12to24(time);
      let utcDateTime = new Date(`${date} ${time24Hour}`).toISOString();
      field.selectedValue = utcDateTime;
    }
  });
}

export function convertBooleanStringsToBoolean(
  opportunity: PostPatchSalesforceOpportunityModel,
  salesforceFields: IField[]
) {
  opportunity.fieldValues.forEach(field => {
    const salesforceField = salesforceFields.find(
      salesforceField => salesforceField.name == field.fieldName
    );
    if (salesforceField && salesforceField.type === 'boolean') {
      field.selectedValue = formatBooleanToReadable(
        field.selectedValue
      ).toString();
    }
  });
}

export function formatUtcTimeToReadable(utcTime: string) {
  if (!utcTime || utcTime === '' || !utcTime.toLowerCase().includes('z')) {
    return utcTime;
  }

  let modifier: string;
  let hours: string | number;
  let minutes: string | number;
  let secIdx = utcTime.indexOf(':', 3);
  let hourTime = utcTime.slice(0, secIdx);
  [hours, minutes] = hourTime.split(':');

  if (parseInt(hours, 10) >= 13) {
    modifier = 'PM';
    hours = parseInt(hours, 10) - 12;
  } else {
    modifier = 'AM';
  }

  return `${hours}:${minutes} ${modifier}`;
}

export function formatUtcDateTimeToLocaleReadable(dateTime: string) {
  if (!dateTime) return dateTime;

  const regEx =
    /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}\s([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // 05/31/2023 21:49:12
  if (!dateTime.match(regEx)) {
    return dateTime;
  }

  dateTime += 'Z';
  const date = new Date(dateTime);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'yyyy-MM-dd HH:mm';
  const localDateTime = format(zonedDate, pattern, { timeZone });

  let [localDate, localTime] = localDateTime.split(' ');
  let modifier: string;
  let hours: string | number;
  let minutes: string | number;

  [hours, minutes] = localTime.split(':');

  if (parseInt(hours, 10) >= 13) {
    modifier = 'PM';
    hours = parseInt(hours, 10) - 12;
  } else {
    modifier = 'AM';
  }

  return `${localDate} ${hours}:${minutes} ${modifier}`;
}

export function formatBooleanToReadable(
  value: string | boolean | undefined
): boolean {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
  }

  return false;
}

function generateFormikSelectValues(
  picklistValues: IPicklistValue[],
  shouldAddNoValue: boolean = true
) {
  var fieldSelectValues: FormikSelectValues = [];

  if (shouldAddNoValue) {
    fieldSelectValues.push({ label: '(no value)', value: null });
  }
  picklistValues.forEach(option => {
    if (option.active) {
      fieldSelectValues.push({ label: option.label, value: option.value });
    }
  });

  return fieldSelectValues;
}

function generateFormikMultiSelectValues(picklistValues: IPicklistValue[]) {
  var values: string[] = [];

  picklistValues.forEach(option => {
    if (option.active) {
      values.push(option.value);
    }
  });

  return values;
}

// https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
function array_move(arr: any[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  return arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function stringLengthValidator(
  maxLength: number
): (a: string) => string | void {
  return (value: string): string | void => {
    if (value && value.length > maxLength) {
      return `Must be ${maxLength} characters or less`;
    }
  };
}

export function precisionScaleValidator(
  precision: number,
  scale: number
): (value: string | number) => string | void {
  // No need to enforce scale as Salesforce will round to the appropriate scale
  return (value: string | number): string | void => {
    if (!value) return;

    const decimalString = value.toString();
    const decimalParts = decimalString.split('.');
    const leftSideLength = decimalParts[0].length;
    if (leftSideLength > precision - scale) {
      return `Value must be less than ${10 ** (precision - scale)}`;
    }
  };
}

export const dateValidator = (value: string): string | void => {
  if (!value) return;

  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!value.match(regEx)) {
    return 'Format must be YYYY-MM-DD e.g. 2020-12-31';
  }

  const d = new Date(value);
  const dNum = d.getTime();
  if ((!dNum && dNum !== 0) || d.toISOString().slice(0, 10) !== value) {
    return 'Invalid date';
  }
};

export const timeValidator = (value: string): string | void => {
  if (!value) return;

  const regEx = /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/;
  if (!value.match(regEx)) {
    return 'Format must be HH:MM am/pm e.g. 02:30 pm';
  }
};

export const dateTimeValidator = (value: string): string | void => {
  if (!value) return;

  const regEx =
    /^\d{4}-\d{2}-\d{2}\s([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/;
  if (!value.match(regEx)) {
    return 'Format must be YYYY-MM-DD HH:MM am/pm e.g. 2020-12-31 02:30 pm';
  }

  const d = new Date(value.split(' ')[0]);
  const dNum = d.getTime();
  if (
    (!dNum && dNum !== 0) ||
    d.toISOString().slice(0, 10) !== value.split(' ')[0]
  ) {
    return 'Invalid date';
  }
};

export const emailValidator = (value: string): string | void => {
  if (value && !/^[\w.-]+@[a-zA-Z]+\.[a-zA-Z]{2,3}$/.test(value)) {
    return 'Invalid email address';
  }
};

export const convertTime12to24 = (time12h: string) => {
  if (!time12h) return time12h;

  let hours: string | number;
  let minutes: string | number;
  const [time, modifier] = time12h.split(' ');

  if (!modifier) return time12h;

  [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier.toLowerCase() === 'pm') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};
