import * as React from "react";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { userIsInRole } from "../../helpers/Helpers";
import IUser from "../../models/app/IUser";
import { RoleEnum } from "../../models/RoleEnum";
import UserService from "../../services/UserService";
import { UsersState, populateUsers } from "../reducers/UserReducer";
import { ApplicationState } from "../store";
import Loading from "../loading/Loading";

const userService = new UserService();

interface PopulateUsersComponentProps {
  setUsers: (users: IUser[]) => void;
  usersState: UsersState;
  user?: User;
}
interface PopulateUsersComponentState {
  wasError: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return { usersState: state.usersState, user: state.oidc.user };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUsers: (users: IUser[]) => {
      const action = populateUsers(users);
      dispatch(action);
    },
  };
};

const populateUsersHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  class PopulateUsersComponent extends React.Component<
    PopulateUsersComponentProps,
    PopulateUsersComponentState
  > {
    state: PopulateUsersComponentState = {
      wasError: false,
    };
    componentDidMount() {
      if (userIsInRole(this.props.user, RoleEnum.ADMIN) ||
        userIsInRole(this.props.user, RoleEnum.OWNER) ||
        userIsInRole(this.props.user, RoleEnum.SUPPORT) ||
        userIsInRole(this.props.user, RoleEnum.SUPPORTADMIN)) {
        if (!this.props.usersState.isLoaded) {
          userService.getUsers().then((userResponse) => {
            if (userResponse.isError) {
              this.setState({ wasError: true });
            } else {
              this.props.setUsers(
                userResponse.result ? userResponse.result : new Array<IUser>()
              );
            }
          });
        }
      } else {
        this.props.setUsers(new Array<IUser>());
      }
    }

    render() {
      return this.props.usersState.isLoaded && !this.state.wasError ? (
        <Component {...(this.props as P)} />
      ) : (
        <Loading wasError={this.state.wasError} />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(PopulateUsersComponent);
};

export default populateUsersHOC;
