import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IOpportunityMeetingTask from "../../models/app/opportunities/IOpportunityMeetingTask";
import SalesforceAccount from "../../models/app/SalesforceAccount";
import SalesforceOpportunity from "../../models/app/SalesforceOpportunity";
import { IRecordTypeInfo } from "../../models/app/opportunities/IOpportunityLayoutsResponseModel";

export interface OpportunityMeetingTasksState {
  isLoaded: boolean;
  opportunityTasks: Array<IOpportunityMeetingTask>;
  meetingTaskStatuses: Array<string>;
  meetingTaskStages: Array<string>;
  defaultAccounts: Array<SalesforceAccount>;
  defaultOpportunities: Array<SalesforceOpportunity>;
  selectedOpportunityTask?: IOpportunityMeetingTask;
  newOpportunityRecordTypeId?: IRecordTypeInfo;
  opportunityTasksPendingUpdate: Array<IOpportunityMeetingTask>;
}

export const opportunityMeetingTasksSlice = createSlice({
  name: "opportunity meeting tasks",
  initialState: {
    isLoaded: false,
    opportunityTasks: [],
    meetingTaskStatuses: [],
    meetingTaskStages: [],
    defaultAccounts: [],
    defaultOpportunities: [],
    selectedOpportunityTask: undefined,
    newOpportunityRecordTypeId: undefined,
    opportunityTasksPendingUpdate: [],
  } as OpportunityMeetingTasksState,
  reducers: {
    setSelectedOpportunityMeetingTask: (
      state,
      action: PayloadAction<IOpportunityMeetingTask>
    ) => {
      return Object.assign({}, state, {
        selectedOpportunityTask: action.payload,
      });
    },
    clearSelectedOpportunityMeetingTask: (state) => {
      return Object.assign({}, state, {
        selectedOpportunityTask: undefined,
      });
    },
    addOpportunityMeetingTask: (state, action: PayloadAction<IOpportunityMeetingTask>) => {
      return Object.assign({}, state, {
        opportunityTasks: state.opportunityTasks.concat(action.payload),
      });
    },
    removeOpportunityMeetingTaskById: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, {
        opportunityTasks: state.opportunityTasks.filter(
          (v) => v.id !== action.payload
        ),
      });
    },
    addOpportunityMeetingTasksPendingUpdate: (
      state,
      action: PayloadAction<IOpportunityMeetingTask>
    ) => {
      return Object.assign({}, state, {
        opportunityTasksPendingUpdate:
          state.opportunityTasksPendingUpdate.concat(action.payload),
      });
    },
    removeOpportunityMeetingTasksPendingUpdateByCorrelationId: (
      state,
      action: PayloadAction<string>
    ) => {
      return Object.assign({}, state, {
        opportunityTasksPendingUpdate:
          state.opportunityTasksPendingUpdate.filter(
            (v) => v.correlationModel?.correlationId !== action.payload
          ),
      });
    },
    populateOpportunityMeetingTasks: (
      state,
      action: PayloadAction<Array<IOpportunityMeetingTask>>
    ) => {
      return Object.assign({}, state, {
        opportunityTasks: action.payload.map(v => (
          {
            ...v,
            createdOn: new Date(v.createdOn),
            updatedOn: v.updatedOn ? new Date(v.updatedOn) : undefined,
            meetingDate: v.meetingDate ? new Date(v.meetingDate) : undefined
          }))
      });
    },
    populateOpportunityMeetingTaskStatuses: (
      state,
      action: PayloadAction<Array<string>>
    ) => {
      return Object.assign({}, state, {
        meetingTaskStatuses: action.payload,
      });
    },
    populateOpportunityMeetingTaskStages: (
      state,
      action: PayloadAction<Array<string>>
    ) => {
      return Object.assign({}, state, {
        meetingTaskStages: action.payload,
      });
    },
    setIsLoadedTrue: (state) => {
      return Object.assign({}, state, {
        isLoaded: true,
      });
    },
    setNewOpportunityRecordType: (state, action: PayloadAction<IRecordTypeInfo>) => {
      return Object.assign({}, state, {
        newOpportunityRecordTypeId: action.payload,
      })
    },
    clearNewOpportunityRecordType: (state) => {
      return Object.assign({}, state, {
        newOpportunityRecordTypeId: undefined,
      });
    }
  }
});
export const {
  setSelectedOpportunityMeetingTask,
  clearSelectedOpportunityMeetingTask,
  addOpportunityMeetingTask,
  removeOpportunityMeetingTaskById,
  addOpportunityMeetingTasksPendingUpdate,
  removeOpportunityMeetingTasksPendingUpdateByCorrelationId,
  populateOpportunityMeetingTasks,
  populateOpportunityMeetingTaskStatuses,
  populateOpportunityMeetingTaskStages,
  setIsLoadedTrue,
  setNewOpportunityRecordType,
  clearNewOpportunityRecordType
} = opportunityMeetingTasksSlice.actions;
export default opportunityMeetingTasksSlice.reducer;
