import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountSlice, { AccountState } from './reducers/AccountReducer';
import dialogReducer, { DialogState } from './reducers/DialogReducer';
import menuReducer, { MenuState } from './reducers/MenuReducer';
import organizationReducer, {
  OrganizationState,
} from './reducers/OrganizationReducer';
import { reducer as oidcReducer, UserState } from 'redux-oidc';
import notificationsReducer, {
  SnackbarNotification,
} from './reducers/NotificationsReducer';

import '../app/datadog';
import UserManager from './auth/UserManager';
import RoutePaths from '../routing/RoutePaths';
// import logger from 'redux-logger';
import GlobalConfig from '../GlobalConfig';
import usersReducer, { UsersState } from './reducers/UserReducer';
import integrationsReducer, {
  IntegrationsState,
} from './reducers/IntegrationReducer';
import TemplatesReducer, { TemplatesState } from './reducers/TemplatesReducer';
import OpportunityFieldMappingsReducer, {
  OpportunityFieldMappingsState,
} from './reducers/OpportunityFieldMappingsReducer';
import DrawerReducer, { DrawerState } from './reducers/DrawerReducer';
import OpportunityMeetingTasksReducer, {
  OpportunityMeetingTasksState,
} from './reducers/OpportunityMeetingTasksReducer';
import LogRocket from 'logrocket';
export interface ApplicationState {
  oidc: UserState;
  accountState: AccountState;
  dialog: DialogState;
  menu: MenuState;
  usersState: UsersState;
  integrationsState: IntegrationsState;
  organizationState: OrganizationState;
  notifications: SnackbarNotification[];
  templatesState: TemplatesState;
  opportunityFieldMappingsState: OpportunityFieldMappingsState;
  opportunityMeetingTasksState: OpportunityMeetingTasksState;
  drawerState: DrawerState;
}

const configureCquenceStore = () => {
  UserManager.events.addSilentRenewError(function (error) {
    console.error('Error while renewing the access token.', error);
    const domains = window.location.hostname.split('.');
    if (domains.length === 3) {
      const domain = domains[0];
      const redirectUri = `https://${GlobalConfig.SPA_HOST}/callback`;
      UserManager.signinRedirect({
        acr_values: 'tenant:' + domain,
        redirect_uri: redirectUri,
        data: { redirectUrl: RoutePaths.Dashboard },
      });
    } else {
      window.location.href = '/';
      return null;
    }
  });

  const reducers = {
    oidc: oidcReducer,
    accountState: accountSlice,
    dialog: dialogReducer,
    usersState: usersReducer,
    integrationsState: integrationsReducer,
    menu: menuReducer,
    organizationState: organizationReducer,
    notifications: notificationsReducer,
    templatesState: TemplatesReducer,
    opportunityFieldMappingsState: OpportunityFieldMappingsReducer,
    opportunityMeetingTasksState: OpportunityMeetingTasksReducer,
    drawerState: DrawerReducer,
  };

  if (window.location.host.includes('cquence.app')) {
    const store = configureStore({ reducer: reducers });
    return store;
  } else {
    const store = configureStore({
      reducer: reducers,
      middleware: [
        // logger,
        LogRocket.reduxMiddleware(),
      ],
    });
    return store;
  }
};

export const store = configureCquenceStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
