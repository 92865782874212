import * as React from "react";
import {
  Drawer,
  Theme,
  List,
  ListItemText,
  Divider,
  Hidden,
  ListItem,
  ListItemIcon,
  Typography,
  Collapse,
  Button,
  Fade,
  Grid,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ViewDashboard from "mdi-material-ui/ViewDashboardOutline";
import Lock from "@mui/icons-material/Lock";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import Domain from "mdi-material-ui/Domain";
import Email from "mdi-material-ui/Email";
import { User } from "oidc-client";
import ArrowRightCircleOutline from "mdi-material-ui/ArrowRightCircleOutline";
import RoutePaths from "../../../routing/RoutePaths";
import { withRouter, WithRouterProps } from "../../hocs/withRouter";

const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: theme.drawer.width,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    logo: {
      ...theme.mixins.toolbar,
      "&:hover": { background: "none" },
    },
    divider: {
      position: "relative",
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      margin: "auto",
      width: "90%",
    },
    menuButton: {
      margin: "auto",
      width: "70%",
    },
    logoButtonRoot: {
      "&:hover": {
        background: "none",
      },
    },
  });
interface LandingMenuProps extends WithStyles<typeof styles>, WithRouterProps {
  user?: User;
  theme: Theme;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

type LandingMenuState = {
  accountListItemExpanded: boolean;
};

class LandingMenu extends React.Component<LandingMenuProps, LandingMenuState> {
  state: LandingMenuState = {
    accountListItemExpanded: false,
  };

  hasSubDomain = (): boolean => {
    const results = window.location.hostname.split(".");
    return results.length === 3;
  };

  handleSignInClick = () => {
    if (this.hasSubDomain()) {
      this.props.navigate(RoutePaths.Login);
    } else {
      this.props.navigate(RoutePaths.EnterDomain);
    }
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      accountListItemExpanded: !prevState.accountListItemExpanded,
    }));
  };

  render() {
    const { classes, theme } = this.props;

    const drawerContent = (
      <div>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.logo}
        >
          <Grid item>
            <Button
              classes={{ root: classes.logoButtonRoot }}
              disableRipple
              onClick={() => this.props.navigate(RoutePaths.Landing)}
            >
              <Typography align="center" variant="h5">
                Cquence
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <List>
          {this.props.user && !this.props.user.expired && (
            <ListItem
              button
              onClick={() => this.props.navigate(RoutePaths.Dashboard)}
            >
              <ListItemIcon>
                <ViewDashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}

          {this.props.user && !this.props.user.expired ? (
            <ListItem id="account" button onClick={this.toggleExpanded}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Account" />
              <Fade in={this.state.accountListItemExpanded} timeout={500}>
                <ExpandLess />
              </Fade>
            </ListItem>
          ) : (
            <React.Fragment>
              <ListItem button onClick={this.handleSignInClick}>
                <ListItemIcon>
                  <AccountCircle htmlColor={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary="Sign in" />
              </ListItem>
            </React.Fragment>
          )}
          <Collapse
            in={this.state.accountListItemExpanded}
            timeout={500}
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  this.props.navigate(RoutePaths.Account);
                }}
              >
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  this.props.navigate(RoutePaths.Logout);
                }}
              >
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    );

    return (
      <Hidden mdUp implementation="css">
        <nav>
          <Drawer
            variant="temporary"
            anchor={"top"}
            open={this.props.mobileOpen}
            onClose={this.props.handleDrawerToggle}
            SlideProps={{
              timeout: {
                enter: theme.drawer.enterDuration,
                exit: theme.drawer.leavingDuration,
              },
            }}
          >
            {drawerContent}
          </Drawer>
        </nav>
      </Hidden>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(LandingMenu));
