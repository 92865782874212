import { Regex } from 'mdi-material-ui';
import Breadcrumb from '../models/app/Breadcrumb';
import BreadcrumbPath from '../models/app/BreadcrumbPath';
import RoutePaths from './RoutePaths';

const dashboardBreadcrumb = new Breadcrumb('Dashboard', RoutePaths.Dashboard);
const accountBreadcrumb = new Breadcrumb('Account', RoutePaths.Account);

const integrationsBreadcrumb = new Breadcrumb(
  'Integrations',
  RoutePaths.Integrations
);
const integrationsNewBreadcrumb = new Breadcrumb(
  'New',
  RoutePaths.IntegrationsNew
);
const integrationsEditZoomBreadcrumb = new Breadcrumb(
  'Edit Zoom',
  RoutePaths.IntegrationsEditZoom
);
const integrationsNewZoomBreadcrumb = new Breadcrumb(
  'New Zoom',
  RoutePaths.IntegrationsNewZoom
);
const integrationsEditGongBreadcrumb = new Breadcrumb(
  'Edit Gong',
  RoutePaths.IntegrationsEditGong
);
const integrationsNewGongBreadcrumb = new Breadcrumb(
  'New Gong',
  RoutePaths.IntegrationsNewGong
);
const integrationsEditHubspotBreadcrumb = new Breadcrumb(
  'Edit Hubspot',
  RoutePaths.IntegrationsEditGong
);
const integrationsNewHubspotBreadcrumb = new Breadcrumb(
  'New Hubspot',
  RoutePaths.IntegrationsNewGong
);

const opportunitiesBreadcrumb = new Breadcrumb(
  'Salesforce Opportunities',
  RoutePaths.Opportunities
);
const opportunitiesMappingBreadcrumb = new Breadcrumb(
  'Opportunity Mapping',
  RoutePaths.OpportunityMapping
);
const opportunityTasksBreadcrumb = new Breadcrumb(
  'Post-Meeting Tasks',
  RoutePaths.OpportunityTasks
);
const opportunityTasksReviewBreadcrumb = new Breadcrumb(
  'Review',
  RoutePaths.OpportunityTasksReview
);

const usersBreadcrumb = new Breadcrumb('Users', RoutePaths.Users);
const usersNewBreadcrumb = new Breadcrumb('New', RoutePaths.UsersNew);
const usersEditBreadcrumb = new Breadcrumb('Edit', RoutePaths.UsersEdit);

const organizationBreadcrumb = new Breadcrumb(
  'Organization',
  RoutePaths.Organization
);

export default class BreadcrumbPaths {
  public static GetBreadcrumbsForPath(path: string): Array<Breadcrumb> {
    const matchingRoutePath = this.getMatchingRoutePath(path);
    switch (matchingRoutePath) {
      case RoutePaths.Integrations:
        return new Array<Breadcrumb>(integrationsBreadcrumb);
      case RoutePaths.IntegrationsNew:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsNewBreadcrumb
        );
      case RoutePaths.IntegrationsEditZoom:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsEditZoomBreadcrumb
        );
      case RoutePaths.IntegrationsNewZoom:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsNewZoomBreadcrumb
        );
      case RoutePaths.IntegrationsEditGong:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsEditGongBreadcrumb
        );
      case RoutePaths.IntegrationsNewGong:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsNewGongBreadcrumb
        );
      case RoutePaths.IntegrationsEditHubspot:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsEditHubspotBreadcrumb
        );
      case RoutePaths.IntegrationsNewHubspot:
        return new Array<Breadcrumb>(
          integrationsBreadcrumb,
          integrationsNewHubspotBreadcrumb
        );
      case RoutePaths.Users:
        return new Array<Breadcrumb>(usersBreadcrumb);
      case RoutePaths.UsersEdit:
        return new Array<Breadcrumb>(usersBreadcrumb, usersEditBreadcrumb);
      case RoutePaths.UsersNew:
        return new Array<Breadcrumb>(usersBreadcrumb, usersNewBreadcrumb);

      case RoutePaths.Opportunities:
        return new Array<Breadcrumb>(opportunitiesBreadcrumb);
      case RoutePaths.OpportunityMapping:
        return new Array<Breadcrumb>(
          opportunitiesBreadcrumb,
          opportunitiesMappingBreadcrumb
        );
      case RoutePaths.OpportunityTasks:
        return new Array<Breadcrumb>(opportunityTasksBreadcrumb);

      case RoutePaths.OpportunityTasksReview:
        return new Array<Breadcrumb>(
          opportunityTasksBreadcrumb,
          opportunityTasksReviewBreadcrumb
        );

      case RoutePaths.Organization:
        return new Array<Breadcrumb>(organizationBreadcrumb);

      case RoutePaths.Account:
        return new Array<Breadcrumb>(accountBreadcrumb);

      default:
        return [];
    }
  }

  private static getMatchingRoutePath(path: string): string {
    var keyValues = new Map<string, string>();
    var keyValueRegexs = new Map<string, string>();
    const keys = Object.keys(RoutePaths);
    const values = Object.values(RoutePaths);
    for (let i = 0; i < keys.length; i++) {
      if (typeof values[i] === 'string' || values[i] instanceof String) {
        const value = values[i] as string;
        if (value && value !== '/') {
          path = path.replace(/\?.*/, '');
          keyValues.set(keys[i], value);
          keyValueRegexs.set(keys[i], value.replace(/\:[a-zA-Z]+/, '.+') + '$');
        }
      }
    }
    for (const [key, value] of keyValueRegexs) {
      if (path.match(value)) {
        return keyValues.get(key) as string;
      }
    }
    return '';
  }
}
