import { Theme } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    image: {
      marginRight: theme.spacing(2),
      width: "100px",
    },
  });

interface TableImageProps {
  image: string;
}

function TableImage(props: TableImageProps): JSX.Element {
  return (
    <div className="container">
      <div>
        <img width="100px" src={props.image} />
      </div>
    </div>
  );
}

export default withStyles(styles)(TableImage);
