import * as React from "react";
import { connect } from "react-redux";
import { Grid, LinearProgress, Theme } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { ApplicationState } from "../store";
import UserManager from "./UserManager";
import RoutePaths from "../../routing/RoutePaths";
import Constants from "../../theme/Constants";

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      marginTop: theme.toolbar.height * 2,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(Constants.FORM.WIDTH + theme.spacingNumber(2 * 2))]:
        {
          width: Constants.FORM.WIDTH,
          marginLeft: "auto",
          marginRight: "auto",
        },
    },
  });
interface LogoutRedirectProps
  extends WithStyles<typeof styles>,
    WithRouterProps {
  user?: Oidc.User;
  isLoadingUser: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
  };
};

class LogoutRedirect extends React.Component<LogoutRedirectProps> {
  componentDidMount() {
    if (!this.props.isLoadingUser && this.props.user) {
      const idTokenHint = this.props.user.id_token;
      UserManager.signoutRedirect({ id_token_hint: idTokenHint });
    } else {
      this.props.navigate(RoutePaths.Landing);
    }
  }

  render() {
    return (
      <div className={this.props.classes.layout}>
        <Grid container spacing={3} alignItems="baseline">
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(withRouter(LogoutRedirect))
);
