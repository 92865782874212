import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IField } from '../../models/app/opportunities/IOpportunityLayoutsResponseModel';
import IOpportunityFieldMapping from '../../models/app/opportunities/IOpportunityFieldMapping';
import ISalesforceTaskDetails from '../../models/app/opportunities/ISalesforceTaskDetails';
export interface OpportunityFieldMappingsState {
  isLoaded: boolean;
  salesforceFields: Array<IField>;
  fieldMappings: Array<IOpportunityFieldMapping>;
  // TODO: taskDetails is a different Salesforce object, should not be part of opportunityFieldMappingsSlice
  taskDetails: ISalesforceTaskDetails;
}

export const opportunityFieldMappingsSlice = createSlice({
  name: 'opportunity field mappings',
  initialState: {
    isLoaded: false,
    salesforceFields: [],
    fieldMappings: [],
    taskDetails: { fields: [] },
  } as OpportunityFieldMappingsState,
  reducers: {
    updateFieldMappings: (
      state,
      action: PayloadAction<Array<IOpportunityFieldMapping>>
    ) => {
      return Object.assign({}, state, {
        fieldMappings: action.payload.filter(field => field.variableId),
      });
    },
    removeFieldMappings: state => {
      return Object.assign({}, state, { fieldMappings: {} });
    },
    populateFieldMappings: (
      state,
      action: PayloadAction<Array<IOpportunityFieldMapping>>
    ) => {
      return Object.assign({}, state, {
        fieldMappings: action.payload,
      });
    },
    populateSalesforceFields: (state, action: PayloadAction<Array<IField>>) => {
      return Object.assign({}, state, {
        salesforceFields: action.payload.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
      });
    },
    populateSalesforceTaskDetails: (
      state,
      action: PayloadAction<ISalesforceTaskDetails>
    ) => {
      return Object.assign({}, state, {
        taskDetails: action.payload,
      });
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, {
        isLoaded: action.payload,
      });
    },
  },
});
export const {
  updateFieldMappings,
  removeFieldMappings,
  populateFieldMappings,
  populateSalesforceFields,
  populateSalesforceTaskDetails,
  setIsLoaded,
} = opportunityFieldMappingsSlice.actions;
export default opportunityFieldMappingsSlice.reducer;
