import * as React from "react";
import {
  Typography,
  Theme,
  Grid,
  Paper,
  Tooltip
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import InformationOutline from "mdi-material-ui/InformationOutline";
import { IRestResponse } from "../../services/RestUtilities";
import { MeetingSourceEnum } from '../../models/MeetingSourceEnum';
import IReprocessWebhookModel from "../../models/webhooks/IReprocessWebhookModel";
import IReprocessGongWebhookModel from "../../models/webhooks/IReprocessGongWebhookModel";
import IReprocessZoomWebhookModel from "../../models/webhooks/IReprocessZoomWebhookModel";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import FormikTextField from "../form/FormikTextField";
import FormikSelect from "../form/FormikSelect";
import FormikSynchronousButton from "../form/FormikSynchronousButton";
import FormikCheckbox from "../form/FormikCheckbox";
import WebhooksService from "../../services/WebhooksService";
import { withRouter, WithRouterProps } from "../hocs/withRouter";
import { IError } from "../../services/RestUtilities";
import SingleServerErrorMessage from "../form/ServerServerErrorMessage";
import { withSnackbar, WithSnackbarProps } from "notistack";
const webhooksService = new WebhooksService();

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      paddingTop: "3%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(650 + theme.spacingNumber(2 * 2))]: {
        width: 650,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    flexButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      padding: theme.spacing(4),
    },
  });

interface ReprocessWebhookProps
  extends WithStyles<typeof styles>,
    WithSnackbarProps,
    WithRouterProps {}

interface ReprocessWebhookState {
  success: boolean;
  serverErrors?: IError;
  meetingSource: MeetingSourceEnum;
}

class ReprocessWebhook extends React.Component<
  ReprocessWebhookProps,
  ReprocessWebhookState
> {
  state: ReprocessWebhookState = {
    success: false,
    serverErrors: undefined,
    meetingSource: MeetingSourceEnum.GONG,
  };

  render() {
    const { classes } = this.props;

    const validationSchema = Yup.object().shape({
      meetingId: Yup.string().required("Required"),
      ...(this.state.meetingSource === MeetingSourceEnum.GONG ? { hostEmail: Yup.string().required("Required") } : {})
    });

    return (
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography align="center" variant="h5">
            Reprocess Webhook
          </Typography>
          <Formik
            initialValues={
              {
                meetingSource: MeetingSourceEnum.GONG,
                meetingId: "",
                hostEmail: "",
                forceProcessing: true,
                useExistingTemplate: true
              } as IReprocessWebhookModel
            }
            onSubmit={(
              values: IReprocessWebhookModel,
              formikHelpers: FormikHelpers<IReprocessWebhookModel>
            ) => {
              let webhoookServiceFunction : Function = () => {};
              let serviceValues! : IReprocessGongWebhookModel | IReprocessZoomWebhookModel;

              if (values.meetingSource === MeetingSourceEnum.GONG) {
                webhoookServiceFunction = webhooksService.postReprocessGongWebhook;
                serviceValues = {
                  GongMeetingId: values.meetingId,
                  HostEmail: values.hostEmail,
                  ForceProcessing: values.forceProcessing,
                  UseExistingTemplate: values.useExistingTemplate
                };
              }

              if (values.meetingSource === MeetingSourceEnum.ZOOM) {
                webhoookServiceFunction = webhooksService.postReprocessZoomWebhook;
                serviceValues = {
                  Uuid: values.meetingId,
                  ForceProcessing: values.forceProcessing,
                  UseExistingTemplate: values.useExistingTemplate
                }
              }

              webhoookServiceFunction(serviceValues).then((v : IRestResponse<void>) => {
                if (v.isError) {
                  formikHelpers.setSubmitting(false);
                  this.setState({ serverErrors: v.error });
                } else {
                  formikHelpers.setSubmitting(false);
                  this.setState({ success: true, serverErrors: undefined });
                  formikHelpers.setFieldValue("meetingId", "", true);
                  formikHelpers.setFieldValue("hostEmail", "", true);
                  this.props.enqueueSnackbar(v.message, { variant: "success" });
                }
              });
            }}
            validateOnMount={true}
            initialErrors={{}}
            validationSchema={validationSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikSelect
                      key="meetingSource"
                      name={"meetingSource"}
                      label={"Meeting Source"}
                      selectValues={[
                        { value: MeetingSourceEnum.GONG, label: "Gong" },
                        { value: MeetingSourceEnum.ZOOM, label: "Zoom" }
                      ]}
                      value={props.values.meetingSource}
                      errorText={undefined}
                      touched={undefined}
                      onChange={(e: React.ChangeEvent<any>) => {
                        this.setState({ meetingSource: e.target.value });
                        props.handleChange(e);
                      }}
                      onBlur={props.handleBlur}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="meetingId"
                      label="Meeting Id"
                      value={props.values.meetingId}
                      errorText={props.errors.meetingId}
                      touched={props.touched.meetingId}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                </Grid>
                {props.values.meetingSource === MeetingSourceEnum.GONG && (
                 <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="hostEmail"
                      label="Host Email"
                      value={props.values.hostEmail}
                      errorText={props.errors.hostEmail}
                      touched={props.touched.hostEmail}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      autoComplete="off"
                      required={props.values.meetingSource === MeetingSourceEnum.GONG}
                    />
                  </Grid>
                </Grid>
                )}
                <Grid container rowSpacing={3}>
                <Grid item xs={6}>
                    <FormikCheckbox
                      name="forceProcessing"
                      label="Force Processing"
                      value={props.values.forceProcessing}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Tooltip title={"This will force a meeting to reprocess, even if it would normally be skipped due to automation logic. You almost always want to check this."}>
                      <InformationOutline fontSize="small" />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <FormikCheckbox
                      name="useExistingTemplate"
                      label="Use Existing Template"
                      value={props.values.useExistingTemplate}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Tooltip title={"This will use existing templates in the Google Drive folder for that meeting, instead of creating new templates from scratch. This is very useful when some variables were generated, but a few failed. Checking this will only regenerate the ones that failed last time, so it should run much faster."}>
                      <InformationOutline fontSize="small" />
                    </Tooltip>
                  </Grid>
                </Grid>
                {this.state.serverErrors && (
                  <SingleServerErrorMessage
                    message={this.state.serverErrors.message}
                  />
                )}
                <div className={classes.flexButtonContainer}>
                  <FormikSynchronousButton
                    isValid={props.isValid && props.dirty}
                    isSubmitting={props.isSubmitting}
                    isSuccess={this.state.success}
                  >
                    Reprocess Webhook
                  </FormikSynchronousButton>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(withRouter(ReprocessWebhook))
);
