import { Backdrop, Drawer, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { WithSnackbarProps, withSnackbar } from "notistack";
import * as React from "react";
import { connect } from "react-redux";
import { closeDrawer, DrawerState } from "../reducers/DrawerReducer";
import { ApplicationState } from "../store";

const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      zIndex: theme.zIndex.appBar - 1,
      width: "60%",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });

const mapStateToProps = (state: ApplicationState) => {
  return {
    drawerState: state.drawerState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDrawer: () => {
      const action = closeDrawer();
      dispatch(action);
    },
  };
};

interface CquenceDrawerProps
  extends WithStyles<typeof styles>,
  WithSnackbarProps {
  theme: Theme;
  closeDrawer: () => void;
  drawerState: DrawerState;
}

class CquenceDrawer extends React.Component<CquenceDrawerProps> {
  constructor(props: CquenceDrawerProps) {
    super(props);
  }

  closeDrawer = () => {
    this.props.closeDrawer();
  };

  render() {
    const { classes, theme, drawerState } = this.props;
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.appBar - 1 }}
          open={drawerState.isOpen || false}
          onClick={this.closeDrawer}
        ></Backdrop>
        <Drawer
          variant="persistent"
          anchor={"right"}
          open={drawerState.isOpen || false}
          classes={{
            paper: classes.drawerPaper,
          }}
          elevation={2}
          SlideProps={{
            direction: "left",
            timeout: {
              enter: theme.drawer.enterDuration,
              exit: theme.drawer.leavingDuration,
            },
            mountOnEnter: true,
            unmountOnExit: false,
          }}
        >
          {drawerState.content}
        </Drawer>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles, { withTheme: true })(CquenceDrawer)));
