import Integration from "../Integration";
import { IntegrationEnum } from "../IntegrationEnum";

export default class HubspotIntegration implements Integration {
  constructor() {
    this.type = IntegrationEnum.HUBSPOT;
  }
  type: IntegrationEnum;
  id: string;
  token: string;
  createdOn: Date;
  updatedOn: Date | null;
  createdBy: string;
  createdByEmail: string;
  updatedBy: string | null;
  updatedByEmail: string;
  integrationType: number;
  isTenantLevel: boolean;
}
