import { OptionsObject } from 'notistack';
import { ReactText } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const defaultOptions = {
  variant: 'info',
} as OptionsObject;

export interface SnackbarNotification {
  message: any;
  options?: OptionsObject;
  key: ReactText;
  dismissed: boolean;
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: [] as SnackbarNotification[],
  reducers: {
    enqueueSnackbar: (state, action: PayloadAction<SnackbarNotification>) => {
      return Object.assign(
        [],
        [
          ...state,
          {
            ...action.payload,
            key: action.payload.key,
            options: {
              ...defaultOptions,
              ...action.payload.options,
            },
          },
        ]
      );
    },
    closeSnackbar: (state, action: PayloadAction<number | undefined>) => {
      return state.map(notification =>
        !action.payload || notification.key === action.payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar: (state, action: PayloadAction<React.ReactText>) => {
      return state.filter(notification => notification.key !== action.payload);
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
