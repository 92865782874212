import * as React from 'react';
import {
  Box,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@mui/material';
import { PopperPlacementType } from '@mui/material/Popper';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    width: (props: PopoutProps) => props?.width || 500,
    maxHeight: '700px',
    overflowY: 'scroll',
    boxShadow: '0px 0px 15px grey',
  },
  header: {
    height: '64px',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
  },
  content: {
    padding: '0px 20px 20px 20px',
  },
  popper: {
    zIndex: 2000,
    '&[data-popper-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: '4px',
      marginBottom: '4px',
      '&::before': {
        transformOrigin: '100% 100%',
      },
    },
    '&[data-popper-placement*="left"] $arrow': {
      right: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: '4px',
      marginBottom: '4px',
    },
  },
  arrow: {
    position: 'absolute',
    width: '1em',
    height: '0.71em',
    boxSizing: 'border-box',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      transform: 'rotate(45deg)',
    },
  },
}));

interface PopoutProps {
  anchorEl: null | HTMLElement;
  placement?: string;
  width?: string;
  open: boolean;
  handleClose: () => void;
  header: string;
  text?: string;
}

export default function Popout(props: PopoutProps) {
  const classes = useStyles(props);
  const canBeOpen = props.open && Boolean(props.anchorEl) && props.text;
  const [arrowRef, setArrowRef] = React.useState<null | HTMLElement>(null);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return props.text && props.anchorEl ? (
    <Popper
      id={id}
      open={props.open}
      className={classes.popper}
      anchorEl={props.anchorEl}
      placement={(props.placement as PopperPlacementType) || 'left'}
      transition
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
            padding: 0,
          },
        },
        { name: 'offset', options: { offset: [0, 8] } },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={0} sx={{ boxShadow: 'none' }}>
            <div>
              <span className={classes.arrow} ref={setArrowRef} />
              <Paper className={classes.root}>
                <Box className={classes.content}>
                  <Grid
                    className={classes.header}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h5">{props.header}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={props.handleClose}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {props.text}
                  </Typography>
                </Box>
              </Paper>
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>
  ) : null;
}
