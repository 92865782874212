import * as React from "react";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import LandingHeader from "./header/LandingHeader";
import LandingMenu from "./menu/LandingMenu";
import { connect } from "react-redux";
import { Fade, Theme, CssBaseline } from "@mui/material";
import Dialog from "../dialog/Dialog";
import { User } from "oidc-client";
import RoutePaths from "../../routing/RoutePaths";
import { ApplicationState } from "../store";
import { Outlet } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    main: {
      position: "absolute",
      width: "100%",
    },
    toolbar: {
      height: "64px",
    },
  });

interface LandingLayoutProps extends WithStyles<typeof styles> {
  user?: User;
  exact?: boolean;
  path?: string | string[];
}
type LandingLayoutState = {
  mobileOpen: boolean;
  safeToPassRef: boolean;
  atPageTop: boolean;
};

const mapStateToProps = (state: ApplicationState) => {
  return { user: state.oidc.user };
};

class LandingLayout extends React.Component<
  LandingLayoutProps,
  LandingLayoutState
> {
  constructor(props: LandingLayoutProps) {
    super(props);
    if (window.location.pathname === RoutePaths.Landing) {
      this.state = {
        mobileOpen: false,
        safeToPassRef: false,
        atPageTop: false,
        slideIn: true,
      };
    }
  }
  state = {
    mobileOpen: false,
    safeToPassRef: false,
    atPageTop: false,
    slideIn: false,
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  componentDidMount() {
    this.setState({ safeToPassRef: true });
  }

  render() {
    const { classes, user } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Dialog />
        {this.state.safeToPassRef && (
          <LandingHeader
            user={user}
            handleDrawerToggle={this.handleDrawerToggle}
          />
        )}
        <LandingMenu
          user={user}
          handleDrawerToggle={this.handleDrawerToggle}
          mobileOpen={this.state.mobileOpen}
        />
        <Fade in timeout={550}>
          <main className={classes.main}>
            <div style={{ position: "relative" }}>
              <div id="toolbar-push" className={classes.toolbar} />
              <Outlet />
            </div>
          </main>
        </Fade>
      </div>
    );
  }
}
export default withStyles(styles)(connect(mapStateToProps)(LandingLayout));
