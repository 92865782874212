import * as React from "react";
import FormikSelectValues from "./interfaces/FormikSelectValuesProp";
import { Theme, TextField, MenuItem, Tooltip, Grid } from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import { TextFieldProps } from "@mui/material/TextField";
import InformationOutline from "mdi-material-ui/InformationOutline";
import { FormikErrors, FormikTouched } from "formik";
import Constants from "../../theme/Constants";

const styles = (theme: Theme) =>
  createStyles({
    menu: {
      width: 200,
    },
    infoText: {
      position: "relative",
      top: "20px",
      left: "14px",
      color: Constants.COLORS.PRIMARY_COLOR
    },
    infoTextDisabled: {
      color: 'grey',
      position: "relative",
      top: "20px",
      left: "14px",
    },
  });

interface FormikSelectProps extends WithStyles<typeof styles> {
  name: string;
  label: string;
  errorText?: string | FormikErrors<any>;
  touched?: boolean | FormikTouched<any>;
  onBlur(e: React.FocusEvent<any>): void;
  onBlur<T = string | any>(
    fieldOrEvent: T
  ): T extends string ? (e: any) => void : void;
  onChange(e: React.ChangeEvent<any>): void;
  onChange<T = string | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
  selectValues: FormikSelectValues;
  required?: boolean;
  value: any;
  infoText?: string;
  showDisabledInfoTextIcon?: boolean
}

class FormikTextField extends React.Component<
  FormikSelectProps & TextFieldProps
> {
  render() {
    const {
      name,
      label,
      selectValues,
      errorText,
      touched,
      onBlur,
      onChange,
      classes,
      value,
      infoText,
      ...rest
    } = this.props;
    return (
      <Grid container alignContent="center">
        <Grid item xs={this.props.infoText || this.props.showDisabledInfoTextIcon ? 11 : 12}>
          <TextField
            id={name}
            name={name}
            label={label}
            value={value}
            helperText={touched ? errorText : ""}
            error={touched && Boolean(errorText)}
            onBlur={onBlur}
            onChange={onChange}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            select
            {...rest}
          >
            {selectValues.map((v) => (
              <MenuItem key={v.value} value={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {(this.props.infoText || this.props.showDisabledInfoTextIcon) && (
          <Grid item xs={1}>
            <Tooltip
              className={this.props.showDisabledInfoTextIcon ? classes.infoTextDisabled : classes.infoText}
              title={this.props.infoText}
              placement="top"
            >
              <InformationOutline fontSize="small" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(FormikTextField);
